const shadows = {
  xs: "0rem 0.0625rem 0.125rem 0rem rgba(16, 24, 40, 0.04)",
  sm: "0rem 0.0625rem 0.125rem 0rem rgba(16, 24, 40, 0.06), 0rem 0.0625rem 0.1875rem 0rem rgba(16, 24, 40, 0.10)",
  md: "0px 0.75rem 1rem -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
  lg: "0rem 0.25rem 0.375rem -0.125rem rgba(16, 24, 40, 0.02), 0rem 0.75rem 1rem -0.25rem rgba(16, 24, 40, 0.08)",
  xl: "0rem 0.5rem 0.5rem -0.25rem rgba(16, 24, 40, 0.02), 0rem 20rem 20.25rem -0.25rem rgba(16, 24, 40, 0.08)",
  "2xl": "0rem 20.25rem 3rem -0.75rem rgba(16, 24, 40, 0.18)",
  "3xl": "0rem 30.125rem 60.25rem -0.75rem rgba(16, 24, 40, 0.14)",
  inner: "0rem 0.125rem 0.25rem 0rem rgba(0, 0, 0, 0.06) inset",
  focus: "0px 0px 0px 0.125rem var(--chakra-colors-pairs-alpha-alphaPrimary20)",
  "focus-xs":
    "0rem 0rem 0rem 0.25rem rgba(197, 179, 229, 0.25), 0rem 0.0625rem 0.125rem 0rem rgba(16, 24, 40, 0.04)",
  "focus-sm":
    "0rem 0rem 0rem 0.25rem rgba(197, 179, 229, 0.25), 0rem 0.0625rem 0.125rem 0rem rgba(16, 24, 40, 0.06), 0rem 0.0625rem 0.1875rem 0rem rgba(16, 24, 40, 0.10)",
  gray: "0rem 0rem 0rem 0.125rem rgba(152, 162, 179, 0.14)",
  "gray-xs":
    "0rem 0rem 0rem 0.25rem rgba(152, 162, 179, 0.14), 0rem 0.0625rem 0.125rem 0rem rgba(16, 24, 40, 0.04)",
  "gray-sm":
    "0rem 0rem 0rem 0.25rem rgba(152, 162, 179, 0.14), 0rem 0.0625rem 0.25rem 0rem rgba(16, 24, 40, 0.06), 0rem 0.0625rem 0.1875rem 0rem rgba(16, 24, 40, 0.10)",
  error: "0rem 0rem 0rem 0.125rem rgba(240, 68, 56, 0.24)",
  "error-xs":
    "0rem 0rem 0rem 0.25rem rgba(240, 68, 56, 0.24), 0rem 0.0625rem 0.125rem 0rem rgba(16, 24, 40, 0.04)",
};

export default shadows;
