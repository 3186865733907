export enum paletteOptions {
  primary = "primary",
  warning = "warning",
  danger = "danger",
  success = "success",
}

export enum variantOptions {
  soft = "soft",
  strong = "strong",
}
