import React from "react";
import {
  BulkActions as ChakraBulkActions,
  BulkActionsProps as ChakraBulkActionsProps,
} from "@saas-ui-pro/react";
import { HStack } from "@chakra-ui/react";
import { runIfFn } from "@chakra-ui/utils";
import { useTranslation } from "../ManaUIProvider";
import { Switch, SwitchProps } from "../Switch";
import { CloseButton } from "../CloseButton";

export interface BulkActionsProps extends ChakraBulkActionsProps {
  onClose: () => void;
}

export default function BulkActions(props: BulkActionsProps) {
  const { variant, selections, children, onClose, ...rest } = props;
  const translations = useTranslation("BulkActions");

  const title =
    selections.length === 1
      ? translations.selectedItem
      : translations.selectedItems;

  return <ChakraBulkActions selections={selections} title={title} {...rest}>
        {runIfFn(children, { selections })}
        <CloseButton
          size="lg"
          variant="onDark"
          aria-label={translations.close}
          tooltipLabel={translations.close}
          onClick={onClose}
        />
    </ChakraBulkActions>;
}

BulkActions.SelectAllSwitch = function SelectAll(props: Pick<SwitchProps, "isChecked" | "onChange">) {
  const translations = useTranslation("BulkActions");

  return <HStack
    borderRight="1px solid"
    borderRightColor="borders.neutral.strong.resting"
    pr={3}
    mr={1}
  >
    <Switch
      size="sm"
      {...props}
    >
      {translations.selectAll}
    </Switch>
  </HStack>;
}

//@TODO
//Use omit to restrict the overriding of title
//Remove variant as a prop
