import React from "react";
import { Flex, Box, useStyleConfig } from "@chakra-ui/react";
import { EntityLabelProps } from "./props";
import { useEntityTypeInfo } from "./useEntityTypeInfo";

export const EntityLabel = (props: EntityLabelProps) => {
  const { entityTypeName, Icon } = useEntityTypeInfo(props);
  const styles = useStyleConfig("EntityLabel");

  return (
    <Flex display="flex" alignItems="center" __css={styles} {...props}>
      <Icon me="0.75em" /> <Box as="span">{entityTypeName}</Box>
    </Flex>
  );
};
