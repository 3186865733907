import React, { useState } from "react";
import {
  CommandBarInputProps,
  CommandBarInput as SaasCommandBarInput,
  useCommandBarContext,
} from "@saas-ui/command-bar";
import { Flex, Link } from "@chakra-ui/react";
import { CloseButton } from "../CloseButton";
import { FALMagnifyingGlass } from "../../icons";
import { useTranslation } from "../ManaUIProvider";

export const CommandBarInput = (props: CommandBarInputProps) => {
  const ctx = useCommandBarContext();
  const { onClose } = ctx.getDialogProps(props);
  const [value, setValue] = useState(props.value);
  const translations = useTranslation("SuiCommandBar");

  const onChange = (value: string) => {
    setValue(value);
    if (typeof props.onChange === "function") props.onChange(value);
  };

  return (
    <Flex alignItems="center" ps={4}>
      <FALMagnifyingGlass />
      <SaasCommandBarInput {...props} value={value} onChange={onChange} />
      {value !== "" && (
        <Link mr={4} as={"button"} onClick={() => onChange("")}>
          {translations.clear}
        </Link>
      )}
      <CloseButton tooltipLabel={translations.close} onClick={onClose} />
    </Flex>
  );
};
