import React, { useMemo } from "react";
import { Icon, createIcon, ChakraProps, forwardRef } from "@chakra-ui/react";
import { IconProps } from "./props";
import { CreateIconOptions } from "./fortawesome";

const defaultProps = (displayName?: string) => {
  let axis = "1.25em";

  if (displayName === "FALEllipsis" || displayName === "FALPenField") {
    axis = "1.375em";
  }

  return {
    width: axis,
    height: axis,
    padding: "0.125em",
    lineHeight: 0,
  };
};

export const iconFactory = (faIcon: CreateIconOptions) =>
  forwardRef(function WrappedIcon(props: IconProps, ref) {
    const { iconSize, ...rest } = props;
    const mergedProps = {
      ...defaultProps(faIcon.displayName),
      ...rest,
    } as ChakraProps;

    const icon = useMemo(() => createIcon(faIcon), []);

    return (
      <Icon
        as={icon}
        overflow="visible"
        aria-hidden="true"
        fontSize={iconSize}
        lineHeight={0}
        ref={ref}
        {...mergedProps}
      />
    );
  });
