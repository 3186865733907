import { Divider } from "../../Divider";
import { PopoverAnchor, useDisclosure } from "@chakra-ui/react";
import {
  Combobox,
  ComboboxActionOption,
  ComboboxContent,
  ComboboxInput,
  ComboboxItems,
  ComboboxOption,
  ButtonOption,
  useComboboxContext,
} from "../../Combobox";
import { TextareaActionProps, TextareaAction } from "./Action";
import { TextAreaMethods, useTextareaContext } from "../TextareaContext";
import React from "react";

export type ComboboxActionToolbarOption<T> = Omit<ButtonOption<T>, "onClick">;

export type TextareaComboBoxActionProps<T> = TextareaActionProps & {
  options: ComboboxActionToolbarOption<T>[];
  optionToString?: (item: ComboboxOption<T> | null) => string;
  onSelection?: (
    item: ComboboxActionToolbarOption<T>,
    api: TextAreaMethods,
  ) => void;
};

type TriggerProps<T> = Omit<
  TextareaComboBoxActionProps<T>,
  "options" | "optionToString"
>;

const Trigger = <T,>({ isDisabled, icon, label, onClick }: TriggerProps<T>) => {
  const { triggerRef, onOpen, onClose, isOpen } = useComboboxContext();
  return (
    <PopoverAnchor>
      <TextareaAction
        ref={triggerRef}
        isDisabled={isDisabled}
        icon={icon}
        label={label}
        onClick={(e) => {
          isOpen ? onClose() : onOpen();
          onClick && onClick(e);
        }}
      />
    </PopoverAnchor>
  );
};

export const TextareaComboBoxAction = <T,>({
  isDisabled,
  icon,
  label,
  onClick,
  options,
  optionToString,
  onSelection,
}: TextareaComboBoxActionProps<T>) => {
  const disclosure = useDisclosure();
  const { replaceSelection, getCurrentSelection, replaceValue } =
    useTextareaContext();

  const wrappedOptions: ComboboxActionOption<T>[] = options.map((option) => ({
    ...option,
    onClick: () => {
      onSelection &&
        onSelection(option, {
          replaceSelection,
          getCurrentSelection,
          replaceValue,
        });
    },
  }));

  return (
    <Combobox
      options={wrappedOptions}
      optionToString={optionToString || ((opt) => opt?.label?.toString() || "")}
      {...disclosure}
    >
      <Trigger
        {...{ isDisabled, icon, label, onClick, options, optionToString }}
      />
      <ComboboxContent>
        <ComboboxInput size="xl" />
        <Divider my={2} palette="neutral.separator" />
        <ComboboxItems />
      </ComboboxContent>
    </Combobox>
  );
};
