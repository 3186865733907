export enum sizeOptions {
  sm = "sm",
  md = "md",
  lg = "lg",
}

export enum variantOptions {
  elevated = "elevated",
  outline = "outline",
  filled = "filled",
  transparent = "transparent",
}
