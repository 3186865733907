import React from "react";
import { Menu, MenuButton, Box, VStack, Flex, useStyleConfig } from "@chakra-ui/react";
import { Button } from "../Button";
import { IconButton } from "../IconButton";
import { FALArrowLeft, FALArrowRight, FALEllipsis } from "../../icons";
import { useInboxLayoutContext } from "./Inbox";
import { useTranslation } from "../ManaUIProvider";
import { CloseButton } from "../CloseButton";
import {variantOptions} from "./theme/themeOptions";

interface InboxRightPanelProps {
  title: string;
  subtitle?: string;
  onPrevious: () => void;
  onNext: () => void;
  hasPrevious: boolean;
  hasNext: boolean;
  headerActions?: React.ReactNode;
  variant?: variantOptions;
}

export const InboxRightPanel = (
  props: React.PropsWithChildren<InboxRightPanelProps>,
) => {
  const {
    title,
    subtitle,
    children,
    headerActions,
    onPrevious,
    onNext,
    hasPrevious,
    hasNext,
  } = props;
  const { onClose } = useInboxLayoutContext();
  const translations = useTranslation("Layout");
  const styles = useStyleConfig("InboxRightPanel", props);

  return (
    <Flex __css={styles}>
      <VStack alignItems="flex-start" mb={2}>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          w="full"
          gap={2}
        >
          <Button
            display={{ base: "flex", md: "none" }}
            mr={5}
            variant="outline.soft"
            gap={2}
            onClick={onClose}
          >
            <FALArrowLeft />
            Back
          </Button>

          <VStack flexGrow={1} alignItems="flex-start">
            <Box textStyle="2xl-medium">{title}</Box>
            {subtitle && <Flex>{subtitle}</Flex>}
          </VStack>

          <Flex gap={2} alignItems={"center"}>
            <Flex gap={2}>
              <IconButton
                icon={<FALArrowLeft />}
                isDisabled={!hasPrevious}
                variant="outline.soft"
                onClick={onPrevious}
                aria-label={translations.previous}
                tooltipLabel={translations.previous}
              />
              <IconButton
                icon={<FALArrowRight />}
                isDisabled={!hasNext}
                variant="outline.soft"
                onClick={onNext}
                aria-label={translations.next}
                tooltipLabel={translations.next}
              />
            </Flex>

            {!!headerActions && (
              <Menu>
                <IconButton
                  as={MenuButton}
                  variant="ghost"
                  size="lg"
                  icon={<FALEllipsis />}
                  aria-label={translations.actions}
                  tooltipLabel={translations.actions}
                />
                {headerActions}
              </Menu>
            )}
            <CloseButton
              display={{ base: "none", md: "flex" }}
              onClick={onClose}
              tooltipLabel={translations.close}
            />
          </Flex>
        </Flex>
      </VStack>
      <Flex py={4} flexDirection={"column"} overflowY="auto" flexGrow={1}>
        {children}
      </Flex>
    </Flex>
  );
};
