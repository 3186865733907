/* eslint-disable react-refresh/only-export-components */
// eslint incorrectly believes all caps exports (FALA, FALB, etc.) are not components
// DO NOT EDIT: autogenerated by brokkr

import { iconFactory } from "./iconFactory";
import React from "react";
import { IconProps } from "../icons/props";

import { falA } from "./fortawesome/fal-a.ts";
import { falAddressCard } from "./fortawesome/fal-address-card.ts";
import { falAlarmClock } from "./fortawesome/fal-alarm-clock.ts";
import { falAlignCenter } from "./fortawesome/fal-align-center.ts";
import { falAlignJustify } from "./fortawesome/fal-align-justify.ts";
import { falAlignLeft } from "./fortawesome/fal-align-left.ts";
import { falAlignRight } from "./fortawesome/fal-align-right.ts";
import { falAnglesLeft } from "./fortawesome/fal-angles-left.ts";
import { falAnglesRight } from "./fortawesome/fal-angles-right.ts";
import { falAnglesUpDown } from "./fortawesome/fal-angles-up-down.ts";
import { falArrowDown } from "./fortawesome/fal-arrow-down.ts";
import { falArrowDownLeftAndArrowUpRightToCenter } from "./fortawesome/fal-arrow-down-left-and-arrow-up-right-to-center.ts";
import { falArrowDownRight } from "./fortawesome/fal-arrow-down-right.ts";
import { falArrowDownShortWide } from "./fortawesome/fal-arrow-down-short-wide.ts";
import { falArrowDownToBracket } from "./fortawesome/fal-arrow-down-to-bracket.ts";
import { falArrowDownToLine } from "./fortawesome/fal-arrow-down-to-line.ts";
import { falArrowLeft } from "./fortawesome/fal-arrow-left.ts";
import { falArrowPointer } from "./fortawesome/fal-arrow-pointer.ts";
import { fasArrowPointer } from "./fortawesome/fas-arrow-pointer.ts";
import { falArrowProgress } from "./fortawesome/fal-arrow-progress.ts";
import { falArrowRight } from "./fortawesome/fal-arrow-right.ts";
import { falArrowRightArrowLeft } from "./fortawesome/fal-arrow-right-arrow-left.ts";
import { falArrowRotateLeft } from "./fortawesome/fal-arrow-rotate-left.ts";
import { falArrowRotateRight } from "./fortawesome/fal-arrow-rotate-right.ts";
import { falArrowTurnDownLeft } from "./fortawesome/fal-arrow-turn-down-left.ts";
import { falArrowTurnDownRight } from "./fortawesome/fal-arrow-turn-down-right.ts";
import { falArrowUp } from "./fortawesome/fal-arrow-up.ts";
import { falArrowUpFromBracket } from "./fortawesome/fal-arrow-up-from-bracket.ts";
import { falArrowUpRight } from "./fortawesome/fal-arrow-up-right.ts";
import { falArrowUpRightAndArrowDownLeftFromCenter } from "./fortawesome/fal-arrow-up-right-and-arrow-down-left-from-center.ts";
import { falArrowUpRightFromSquare } from "./fortawesome/fal-arrow-up-right-from-square.ts";
import { falArrowUpShortWide } from "./fortawesome/fal-arrow-up-short-wide.ts";
import { falArrowsLeftRight } from "./fortawesome/fal-arrows-left-right.ts";
import { falArrowsRepeat } from "./fortawesome/fal-arrows-repeat.ts";
import { falArrowsRotate } from "./fortawesome/fal-arrows-rotate.ts";
import { falArrowsUpDownLeftRight } from "./fortawesome/fal-arrows-up-down-left-right.ts";
import { falAt } from "./fortawesome/fal-at.ts";
import { falB } from "./fortawesome/fal-b.ts";
import { falBallotCheck } from "./fortawesome/fal-ballot-check.ts";
import { falBan } from "./fortawesome/fal-ban.ts";
import { falBars } from "./fortawesome/fal-bars.ts";
import { falBarsFilter } from "./fortawesome/fal-bars-filter.ts";
import { falBarsSort } from "./fortawesome/fal-bars-sort.ts";
import { falBell } from "./fortawesome/fal-bell.ts";
import { fasBell } from "./fortawesome/fas-bell.ts";
import { falBellOn } from "./fortawesome/fal-bell-on.ts";
import { falBellSlash } from "./fortawesome/fal-bell-slash.ts";
import { fasBellSlash } from "./fortawesome/fas-bell-slash.ts";
import { falBlockQuote } from "./fortawesome/fal-block-quote.ts";
import { falBold } from "./fortawesome/fal-bold.ts";
import { falBook } from "./fortawesome/fal-book.ts";
import { fasBook } from "./fortawesome/fas-book.ts";
import { falBookOpenCover } from "./fortawesome/fal-book-open-cover.ts";
import { fasBookOpenCover } from "./fortawesome/fas-book-open-cover.ts";
import { falBookmark } from "./fortawesome/fal-bookmark.ts";
import { falBoxArchive } from "./fortawesome/fal-box-archive.ts";
import { falBoxOpen } from "./fortawesome/fal-box-open.ts";
import { falBoxesPacking } from "./fortawesome/fal-boxes-packing.ts";
import { falBracketsCurly } from "./fortawesome/fal-brackets-curly.ts";
import { falBracketsSquare } from "./fortawesome/fal-brackets-square.ts";
import { falBriefcase } from "./fortawesome/fal-briefcase.ts";
import { falBrightness } from "./fortawesome/fal-brightness.ts";
import { fasBrightness } from "./fortawesome/fas-brightness.ts";
import { falBrowser } from "./fortawesome/fal-browser.ts";
import { fasBrowser } from "./fortawesome/fas-browser.ts";
import { falBrowsers } from "./fortawesome/fal-browsers.ts";
import { fasBrowsers } from "./fortawesome/fas-browsers.ts";
import { falBug } from "./fortawesome/fal-bug.ts";
import { fasBug } from "./fortawesome/fas-bug.ts";
import { falBuilding } from "./fortawesome/fal-building.ts";
import { fasBuilding } from "./fortawesome/fas-building.ts";
import { falBuildingCircleCheck } from "./fortawesome/fal-building-circle-check.ts";
import { falBuildingMemo } from "./fortawesome/fal-building-memo.ts";
import { fasBuildingMemo } from "./fortawesome/fas-building-memo.ts";
import { falC } from "./fortawesome/fal-c.ts";
import { falCalendarCirclePlus } from "./fortawesome/fal-calendar-circle-plus.ts";
import { falCalendarClock } from "./fortawesome/fal-calendar-clock.ts";
import { falCalendarDay } from "./fortawesome/fal-calendar-day.ts";
import { falCalendarLines } from "./fortawesome/fal-calendar-lines.ts";
import { fasCalendarLines } from "./fortawesome/fas-calendar-lines.ts";
import { falCartShopping } from "./fortawesome/fal-cart-shopping.ts";
import { fasCartShopping } from "./fortawesome/fas-cart-shopping.ts";
import { falChartColumn } from "./fortawesome/fal-chart-column.ts";
import { falChartLineDown } from "./fortawesome/fal-chart-line-down.ts";
import { falChartLineUp } from "./fortawesome/fal-chart-line-up.ts";
import { falChartMixed } from "./fortawesome/fal-chart-mixed.ts";
import { falChartPie } from "./fortawesome/fal-chart-pie.ts";
import { fasChartPie } from "./fortawesome/fas-chart-pie.ts";
import { falCheck } from "./fortawesome/fal-check.ts";
import { falChevronDown } from "./fortawesome/fal-chevron-down.ts";
import { falChevronLeft } from "./fortawesome/fal-chevron-left.ts";
import { falChevronRight } from "./fortawesome/fal-chevron-right.ts";
import { falChevronUp } from "./fortawesome/fal-chevron-up.ts";
import { falCircle } from "./fortawesome/fal-circle.ts";
import { fasCircle } from "./fortawesome/fas-circle.ts";
import { falCircleC } from "./fortawesome/fal-circle-c.ts";
import { falCircleCheck } from "./fortawesome/fal-circle-check.ts";
import { fasCircleCheck } from "./fortawesome/fas-circle-check.ts";
import { falCircleExclamation } from "./fortawesome/fal-circle-exclamation.ts";
import { fasCircleExclamation } from "./fortawesome/fas-circle-exclamation.ts";
import { falCircleInfo } from "./fortawesome/fal-circle-info.ts";
import { fasCircleInfo } from "./fortawesome/fas-circle-info.ts";
import { falCircleNodes } from "./fortawesome/fal-circle-nodes.ts";
import { falCirclePlay } from "./fortawesome/fal-circle-play.ts";
import { falCirclePlus } from "./fortawesome/fal-circle-plus.ts";
import { falCircleUser } from "./fortawesome/fal-circle-user.ts";
import { falClock } from "./fortawesome/fal-clock.ts";
import { fasClock } from "./fortawesome/fas-clock.ts";
import { falClockRotateLeft } from "./fortawesome/fal-clock-rotate-left.ts";
import { falCode } from "./fortawesome/fal-code.ts";
import { fasCode } from "./fortawesome/fas-code.ts";
import { falCodeBranch } from "./fortawesome/fal-code-branch.ts";
import { falCodeCommit } from "./fortawesome/fal-code-commit.ts";
import { falColumns3 } from "./fortawesome/fal-columns-3.ts";
import { fasColumns3 } from "./fortawesome/fas-columns-3.ts";
import { falCommand } from "./fortawesome/fal-command.ts";
import { falCopy } from "./fortawesome/fal-copy.ts";
import { fasCopy } from "./fortawesome/fas-copy.ts";
import { falCopyright } from "./fortawesome/fal-copyright.ts";
import { fasCopyright } from "./fortawesome/fas-copyright.ts";
import { falCropSimple } from "./fortawesome/fal-crop-simple.ts";
import { falCube } from "./fortawesome/fal-cube.ts";
import { fasCube } from "./fortawesome/fas-cube.ts";
import { falD } from "./fortawesome/fal-d.ts";
import { falDash } from "./fortawesome/fal-dash.ts";
import { falDeleteLeft } from "./fortawesome/fal-delete-left.ts";
import { fasDeleteLeft } from "./fortawesome/fas-delete-left.ts";
import { falDesktop } from "./fortawesome/fal-desktop.ts";
import { fasDesktop } from "./fortawesome/fas-desktop.ts";
import { falDistributeSpacingHorizontal } from "./fortawesome/fal-distribute-spacing-horizontal.ts";
import { fasDistributeSpacingHorizontal } from "./fortawesome/fas-distribute-spacing-horizontal.ts";
import { falDistributeSpacingVertical } from "./fortawesome/fal-distribute-spacing-vertical.ts";
import { fasDistributeSpacingVertical } from "./fortawesome/fas-distribute-spacing-vertical.ts";
import { falDollarSign } from "./fortawesome/fal-dollar-sign.ts";
import { falDoorClosed } from "./fortawesome/fal-door-closed.ts";
import { fasDownLong } from "./fortawesome/fas-down-long.ts";
import { falDownload } from "./fortawesome/fal-download.ts";
import { falE } from "./fortawesome/fal-e.ts";
import { falEarthAmericas } from "./fortawesome/fal-earth-americas.ts";
import { fasEarthAmericas } from "./fortawesome/fas-earth-americas.ts";
import { falEllipsis } from "./fortawesome/fal-ellipsis.ts";
import { falEllipsisVertical } from "./fortawesome/fal-ellipsis-vertical.ts";
import { falEnvelope } from "./fortawesome/fal-envelope.ts";
import { fasEnvelope } from "./fortawesome/fas-envelope.ts";
import { falEnvelopeDot } from "./fortawesome/fal-envelope-dot.ts";
import { falEnvelopeOpen } from "./fortawesome/fal-envelope-open.ts";
import { falEuroSign } from "./fortawesome/fal-euro-sign.ts";
import { falEye } from "./fortawesome/fal-eye.ts";
import { fasEye } from "./fortawesome/fas-eye.ts";
import { falEyeSlash } from "./fortawesome/fal-eye-slash.ts";
import { fasEyeSlash } from "./fortawesome/fas-eye-slash.ts";
import { falF } from "./fortawesome/fal-f.ts";
import { falFaceFrownSlight } from "./fortawesome/fal-face-frown-slight.ts";
import { fasFaceFrownSlight } from "./fortawesome/fas-face-frown-slight.ts";
import { falFaceMeh } from "./fortawesome/fal-face-meh.ts";
import { fasFaceMeh } from "./fortawesome/fas-face-meh.ts";
import { falFaceSmile } from "./fortawesome/fal-face-smile.ts";
import { fasFaceSmile } from "./fortawesome/fas-face-smile.ts";
import { falFileCircleQuestion } from "./fortawesome/fal-file-circle-question.ts";
import { falFileCode } from "./fortawesome/fal-file-code.ts";
import { falFileCsv } from "./fortawesome/fal-file-csv.ts";
import { falFileDoc } from "./fortawesome/fal-file-doc.ts";
import { falFileExport } from "./fortawesome/fal-file-export.ts";
import { falFileImport } from "./fortawesome/fal-file-import.ts";
import { falFileLines } from "./fortawesome/fal-file-lines.ts";
import { falFileMagnifyingGlass } from "./fortawesome/fal-file-magnifying-glass.ts";
import { falFilePdf } from "./fortawesome/fal-file-pdf.ts";
import { falFileXls } from "./fortawesome/fal-file-xls.ts";
import { falFileXml } from "./fortawesome/fal-file-xml.ts";
import { falFilms } from "./fortawesome/fal-films.ts";
import { falFlag } from "./fortawesome/fal-flag.ts";
import { falFlagPennant } from "./fortawesome/fal-flag-pennant.ts";
import { falFloppyDisk } from "./fortawesome/fal-floppy-disk.ts";
import { falFloppyDisks } from "./fortawesome/fal-floppy-disks.ts";
import { falFolder } from "./fortawesome/fal-folder.ts";
import { fasFolder } from "./fortawesome/fas-folder.ts";
import { falFolderOpen } from "./fortawesome/fal-folder-open.ts";
import { fasFolderOpen } from "./fortawesome/fas-folder-open.ts";
import { falFont } from "./fortawesome/fal-font.ts";
import { falFontCase } from "./fortawesome/fal-font-case.ts";
import { fasFontCase } from "./fortawesome/fas-font-case.ts";
import { falForkKnife } from "./fortawesome/fal-fork-knife.ts";
import { falFrancSign } from "./fortawesome/fal-franc-sign.ts";
import { falFunction } from "./fortawesome/fal-function.ts";
import { fasFunction } from "./fortawesome/fas-function.ts";
import { falG } from "./fortawesome/fal-g.ts";
import { falGear } from "./fortawesome/fal-gear.ts";
import { falGearCode } from "./fortawesome/fal-gear-code.ts";
import { falGlobe } from "./fortawesome/fal-globe.ts";
import { fasGlobe } from "./fortawesome/fas-globe.ts";
import { falGlobePointer } from "./fortawesome/fal-globe-pointer.ts";
import { falGrid2 } from "./fortawesome/fal-grid-2.ts";
import { fasGrid2 } from "./fortawesome/fas-grid-2.ts";
import { fasGripDotsVertical } from "./fortawesome/fas-grip-dots-vertical.ts";
import { falGripLines } from "./fortawesome/fal-grip-lines.ts";
import { falGripLinesVertical } from "./fortawesome/fal-grip-lines-vertical.ts";
import { falH } from "./fortawesome/fal-h.ts";
import { falHandPointer } from "./fortawesome/fal-hand-pointer.ts";
import { falHandshakeSimple } from "./fortawesome/fal-handshake-simple.ts";
import { fasHandshakeSimple } from "./fortawesome/fas-handshake-simple.ts";
import { falHashtag } from "./fortawesome/fal-hashtag.ts";
import { falHeading } from "./fortawesome/fal-heading.ts";
import { fasHeading } from "./fortawesome/fas-heading.ts";
import { falHeart } from "./fortawesome/fal-heart.ts";
import { falHexagonNodes } from "./fortawesome/fal-hexagon-nodes.ts";
import { falHighlighter } from "./fortawesome/fal-highlighter.ts";
import { falHorizontalRule } from "./fortawesome/fal-horizontal-rule.ts";
import { falHospitalUser } from "./fortawesome/fal-hospital-user.ts";
import { falHotel } from "./fortawesome/fal-hotel.ts";
import { falHourglassClock } from "./fortawesome/fal-hourglass-clock.ts";
import { falHouse } from "./fortawesome/fal-house.ts";
import { fasHouse } from "./fortawesome/fas-house.ts";
import { falHouseMedical } from "./fortawesome/fal-house-medical.ts";
import { falI } from "./fortawesome/fal-i.ts";
import { falICursor } from "./fortawesome/fal-i-cursor.ts";
import { falIcons } from "./fortawesome/fal-icons.ts";
import { fasIcons } from "./fortawesome/fas-icons.ts";
import { falImage } from "./fortawesome/fal-image.ts";
import { fasImage } from "./fortawesome/fas-image.ts";
import { falImages } from "./fortawesome/fal-images.ts";
import { falInbox } from "./fortawesome/fal-inbox.ts";
import { fasInbox } from "./fortawesome/fas-inbox.ts";
import { falIndianRupeeSign } from "./fortawesome/fal-indian-rupee-sign.ts";
import { falItalic } from "./fortawesome/fal-italic.ts";
import { falJ } from "./fortawesome/fal-j.ts";
import { falK } from "./fortawesome/fal-k.ts";
import { falKey } from "./fortawesome/fal-key.ts";
import { falL } from "./fortawesome/fal-l.ts";
import { falLaptop } from "./fortawesome/fal-laptop.ts";
import { fasLaptop } from "./fortawesome/fas-laptop.ts";
import { falLayerGroup } from "./fortawesome/fal-layer-group.ts";
import { fasLeftLong } from "./fortawesome/fas-left-long.ts";
import { falLightbulbMessage } from "./fortawesome/fal-lightbulb-message.ts";
import { falLightbulbOn } from "./fortawesome/fal-lightbulb-on.ts";
import { fasLightbulbOn } from "./fortawesome/fas-lightbulb-on.ts";
import { falLink } from "./fortawesome/fal-link.ts";
import { falLinkHorizontal } from "./fortawesome/fal-link-horizontal.ts";
import { falLinkSimple } from "./fortawesome/fal-link-simple.ts";
import { falListCheck } from "./fortawesome/fal-list-check.ts";
import { falListOl } from "./fortawesome/fal-list-ol.ts";
import { falListTree } from "./fortawesome/fal-list-tree.ts";
import { falListUl } from "./fortawesome/fal-list-ul.ts";
import { falLocationDot } from "./fortawesome/fal-location-dot.ts";
import { fasLocationDot } from "./fortawesome/fas-location-dot.ts";
import { falLockKeyhole } from "./fortawesome/fal-lock-keyhole.ts";
import { falLockKeyholeOpen } from "./fortawesome/fal-lock-keyhole-open.ts";
import { falM } from "./fortawesome/fal-m.ts";
import { falMagnifyingGlass } from "./fortawesome/fal-magnifying-glass.ts";
import { falMagnifyingGlassMinus } from "./fortawesome/fal-magnifying-glass-minus.ts";
import { falMagnifyingGlassPlus } from "./fortawesome/fal-magnifying-glass-plus.ts";
import { falMedal } from "./fortawesome/fal-medal.ts";
import { fasMemo } from "./fortawesome/fas-memo.ts";
import { falMemoPad } from "./fortawesome/fal-memo-pad.ts";
import { falMessage } from "./fortawesome/fal-message.ts";
import { fasMessage } from "./fortawesome/fas-message.ts";
import { falMessageDots } from "./fortawesome/fal-message-dots.ts";
import { fasMessageDots } from "./fortawesome/fas-message-dots.ts";
import { falMessageLines } from "./fortawesome/fal-message-lines.ts";
import { fasMessageLines } from "./fortawesome/fas-message-lines.ts";
import { falMessagePlus } from "./fortawesome/fal-message-plus.ts";
import { falMessageSmile } from "./fortawesome/fal-message-smile.ts";
import { falMessages } from "./fortawesome/fal-messages.ts";
import { fasMessages } from "./fortawesome/fas-messages.ts";
import { falMessagesQuestion } from "./fortawesome/fal-messages-question.ts";
import { falMicrophoneStand } from "./fortawesome/fal-microphone-stand.ts";
import { falMinus } from "./fortawesome/fal-minus.ts";
import { falMobile } from "./fortawesome/fal-mobile.ts";
import { fasMobile } from "./fortawesome/fas-mobile.ts";
import { falMoneySimpleFromBracket } from "./fortawesome/fal-money-simple-from-bracket.ts";
import { falMoonStars } from "./fortawesome/fal-moon-stars.ts";
import { fasMoonStars } from "./fortawesome/fas-moon-stars.ts";
import { falMusic } from "./fortawesome/fal-music.ts";
import { fasMusic } from "./fortawesome/fas-music.ts";
import { falN } from "./fortawesome/fal-n.ts";
import { falO } from "./fortawesome/fal-o.ts";
import { falObjectsAlignBottom } from "./fortawesome/fal-objects-align-bottom.ts";
import { fasObjectsAlignBottom } from "./fortawesome/fas-objects-align-bottom.ts";
import { falObjectsAlignCenterHorizontal } from "./fortawesome/fal-objects-align-center-horizontal.ts";
import { fasObjectsAlignCenterHorizontal } from "./fortawesome/fas-objects-align-center-horizontal.ts";
import { falObjectsAlignCenterVertical } from "./fortawesome/fal-objects-align-center-vertical.ts";
import { fasObjectsAlignCenterVertical } from "./fortawesome/fas-objects-align-center-vertical.ts";
import { falObjectsAlignLeft } from "./fortawesome/fal-objects-align-left.ts";
import { fasObjectsAlignLeft } from "./fortawesome/fas-objects-align-left.ts";
import { falObjectsAlignRight } from "./fortawesome/fal-objects-align-right.ts";
import { fasObjectsAlignRight } from "./fortawesome/fas-objects-align-right.ts";
import { falObjectsAlignTop } from "./fortawesome/fal-objects-align-top.ts";
import { fasObjectsAlignTop } from "./fortawesome/fas-objects-align-top.ts";
import { falObjectsColumn } from "./fortawesome/fal-objects-column.ts";
import { falP } from "./fortawesome/fal-p.ts";
import { falPage } from "./fortawesome/fal-page.ts";
import { fasPage } from "./fortawesome/fas-page.ts";
import { falPaintbrushPencil } from "./fortawesome/fal-paintbrush-pencil.ts";
import { falPaperclip } from "./fortawesome/fal-paperclip.ts";
import { falPen } from "./fortawesome/fal-pen.ts";
import { fasPen } from "./fortawesome/fas-pen.ts";
import { falPenField } from "./fortawesome/fal-pen-field.ts";
import { falPeopleGroup } from "./fortawesome/fal-people-group.ts";
import { falPercent } from "./fortawesome/fal-percent.ts";
import { falPerson } from "./fortawesome/fal-person.ts";
import { falPersonCircleCheck } from "./fortawesome/fal-person-circle-check.ts";
import { falPersonDress } from "./fortawesome/fal-person-dress.ts";
import { falPhone } from "./fortawesome/fal-phone.ts";
import { fasPhone } from "./fortawesome/fas-phone.ts";
import { falPhotoFilm } from "./fortawesome/fal-photo-film.ts";
import { fasPhotoFilm } from "./fortawesome/fas-photo-film.ts";
import { falPizzaSlice } from "./fortawesome/fal-pizza-slice.ts";
import { fasPlug } from "./fortawesome/fas-plug.ts";
import { falPlus } from "./fortawesome/fal-plus.ts";
import { fasPlus } from "./fortawesome/fas-plus.ts";
import { falPuzzlePiece } from "./fortawesome/fal-puzzle-piece.ts";
import { fasPuzzlePiece } from "./fortawesome/fas-puzzle-piece.ts";
import { falQ } from "./fortawesome/fal-q.ts";
import { falQrcode } from "./fortawesome/fal-qrcode.ts";
import { falQuestion } from "./fortawesome/fal-question.ts";
import { falR } from "./fortawesome/fal-r.ts";
import { falRectangleCode } from "./fortawesome/fal-rectangle-code.ts";
import { falRectangleHistoryCirclePlus } from "./fortawesome/fal-rectangle-history-circle-plus.ts";
import { fasRectangleHistoryCirclePlus } from "./fortawesome/fas-rectangle-history-circle-plus.ts";
import { falRectangleWide } from "./fortawesome/fal-rectangle-wide.ts";
import { fasRectangleWide } from "./fortawesome/fas-rectangle-wide.ts";
import { falReply } from "./fortawesome/fal-reply.ts";
import { fasReply } from "./fortawesome/fas-reply.ts";
import { falRocket } from "./fortawesome/fal-rocket.ts";
import { fasRocket } from "./fortawesome/fas-rocket.ts";
import { falS } from "./fortawesome/fal-s.ts";
import { falShieldCheck } from "./fortawesome/fal-shield-check.ts";
import { falShop } from "./fortawesome/fal-shop.ts";
import { faShorten } from "./fortawesome/fa-shorten.ts";
import { falShuffle } from "./fortawesome/fal-shuffle.ts";
import { falSidebar } from "./fortawesome/fal-sidebar.ts";
import { fasSidebar } from "./fortawesome/fas-sidebar.ts";
import { falSidebarFlip } from "./fortawesome/fal-sidebar-flip.ts";
import { falSitemap } from "./fortawesome/fal-sitemap.ts";
import { falSlashForward } from "./fortawesome/fal-slash-forward.ts";
import { falSlidersUp } from "./fortawesome/fal-sliders-up.ts";
import { falSparkles } from "./fortawesome/fal-sparkles.ts";
import { fasSparkles } from "./fortawesome/fas-sparkles.ts";
import { falSpellCheck } from "./fortawesome/fal-spell-check.ts";
import { falSpider } from "./fortawesome/fal-spider.ts";
import { falSquare } from "./fortawesome/fal-square.ts";
import { fasSquareCheck } from "./fortawesome/fas-square-check.ts";
import { fasSquareCode } from "./fortawesome/fas-square-code.ts";
import { fasSquareMinus } from "./fortawesome/fas-square-minus.ts";
import { falSquarePen } from "./fortawesome/fal-square-pen.ts";
import { falSquarePlus } from "./fortawesome/fal-square-plus.ts";
import { fasSquarePlus } from "./fortawesome/fas-square-plus.ts";
import { falSquarePollHorizontal } from "./fortawesome/fal-square-poll-horizontal.ts";
import { falSquareTerminal } from "./fortawesome/fal-square-terminal.ts";
import { falStarSharp } from "./fortawesome/fal-star-sharp.ts";
import { fasStarSharp } from "./fortawesome/fas-star-sharp.ts";
import { falStarSharpHalf } from "./fortawesome/fal-star-sharp-half.ts";
import { fasStarSharpHalf } from "./fortawesome/fas-star-sharp-half.ts";
import { falSterlingSign } from "./fortawesome/fal-sterling-sign.ts";
import { falStethoscope } from "./fortawesome/fal-stethoscope.ts";
import { falStrikethrough } from "./fortawesome/fal-strikethrough.ts";
import { falSubscript } from "./fortawesome/fal-subscript.ts";
import { falSuperscript } from "./fortawesome/fal-superscript.ts";
import { falSwapArrows } from "./fortawesome/fal-swap-arrows.ts";
import { falT } from "./fortawesome/fal-t.ts";
import { falTable } from "./fortawesome/fal-table.ts";
import { fasTable } from "./fortawesome/fas-table.ts";
import { falTableLayout } from "./fortawesome/fal-table-layout.ts";
import { falTablet } from "./fortawesome/fal-tablet.ts";
import { fasTablet } from "./fortawesome/fas-tablet.ts";
import { falTag } from "./fortawesome/fal-tag.ts";
import { fasTag } from "./fortawesome/fas-tag.ts";
import { falTags } from "./fortawesome/fal-tags.ts";
import { fasTags } from "./fortawesome/fas-tags.ts";
import { falText } from "./fortawesome/fal-text.ts";
import { falThumbsDown } from "./fortawesome/fal-thumbs-down.ts";
import { fasThumbsDown } from "./fortawesome/fas-thumbs-down.ts";
import { falThumbsUp } from "./fortawesome/fal-thumbs-up.ts";
import { fasThumbsUp } from "./fortawesome/fas-thumbs-up.ts";
import { falTrash } from "./fortawesome/fal-trash.ts";
import { fasTrash } from "./fortawesome/fas-trash.ts";
import { falTriangleExclamation } from "./fortawesome/fal-triangle-exclamation.ts";
import { fasTriangleExclamation } from "./fortawesome/fas-triangle-exclamation.ts";
import { falU } from "./fortawesome/fal-u.ts";
import { falUnderline } from "./fortawesome/fal-underline.ts";
import { fasUpLong } from "./fortawesome/fas-up-long.ts";
import { falUpload } from "./fortawesome/fal-upload.ts";
import { fasUpload } from "./fortawesome/fas-upload.ts";
import { falUser } from "./fortawesome/fal-user.ts";
import { fasUser } from "./fortawesome/fas-user.ts";
import { falUserGroup } from "./fortawesome/fal-user-group.ts";
import { fasUserGroup } from "./fortawesome/fas-user-group.ts";
import { falUserPlus } from "./fortawesome/fal-user-plus.ts";
import { fasUserPlus } from "./fortawesome/fas-user-plus.ts";
import { falUserTag } from "./fortawesome/fal-user-tag.ts";
import { fasUserTag } from "./fortawesome/fas-user-tag.ts";
import { fasUserTie } from "./fortawesome/fas-user-tie.ts";
import { falUserTieHair } from "./fortawesome/fal-user-tie-hair.ts";
import { falUsers } from "./fortawesome/fal-users.ts";
import { fasUsers } from "./fortawesome/fas-users.ts";
import { falV } from "./fortawesome/fal-v.ts";
import { falW } from "./fortawesome/fal-w.ts";
import { falWindowMaximize } from "./fortawesome/fal-window-maximize.ts";
import { fasWindowMaximize } from "./fortawesome/fas-window-maximize.ts";
import { falWrench } from "./fortawesome/fal-wrench.ts";
import { falX } from "./fortawesome/fal-x.ts";
import { falXmark } from "./fortawesome/fal-xmark.ts";
import { falXmarkLarge } from "./fortawesome/fal-xmark-large.ts";
import { falY } from "./fortawesome/fal-y.ts";
import { falYenSign } from "./fortawesome/fal-yen-sign.ts";
import { falZ } from "./fortawesome/fal-z.ts";

export const FALA: React.FC<IconProps> = iconFactory(falA);
export const FALAddressCard: React.FC<IconProps> = iconFactory(falAddressCard);
export const FALAlarmClock: React.FC<IconProps> = iconFactory(falAlarmClock);
export const FALAlignCenter: React.FC<IconProps> = iconFactory(falAlignCenter);
export const FALAlignJustify: React.FC<IconProps> = iconFactory(falAlignJustify);
export const FALAlignLeft: React.FC<IconProps> = iconFactory(falAlignLeft);
export const FALAlignRight: React.FC<IconProps> = iconFactory(falAlignRight);
export const FALAnglesLeft: React.FC<IconProps> = iconFactory(falAnglesLeft);
export const FALAnglesRight: React.FC<IconProps> = iconFactory(falAnglesRight);
export const FALAnglesUpDown: React.FC<IconProps> = iconFactory(falAnglesUpDown);
export const FALArrowDown: React.FC<IconProps> = iconFactory(falArrowDown);
export const FALArrowDownLeftAndArrowUpRightToCenter: React.FC<IconProps> = iconFactory(falArrowDownLeftAndArrowUpRightToCenter);
export const FALArrowDownRight: React.FC<IconProps> = iconFactory(falArrowDownRight);
export const FALArrowDownShortWide: React.FC<IconProps> = iconFactory(falArrowDownShortWide);
export const FALArrowDownToBracket: React.FC<IconProps> = iconFactory(falArrowDownToBracket);
export const FALArrowDownToLine: React.FC<IconProps> = iconFactory(falArrowDownToLine);
export const FALArrowLeft: React.FC<IconProps> = iconFactory(falArrowLeft);
export const FALArrowPointer: React.FC<IconProps> = iconFactory(falArrowPointer);
export const FASArrowPointer: React.FC<IconProps> = iconFactory(fasArrowPointer);
export const FALArrowProgress: React.FC<IconProps> = iconFactory(falArrowProgress);
export const FALArrowRight: React.FC<IconProps> = iconFactory(falArrowRight);
export const FALArrowRightArrowLeft: React.FC<IconProps> = iconFactory(falArrowRightArrowLeft);
export const FALArrowRotateLeft: React.FC<IconProps> = iconFactory(falArrowRotateLeft);
export const FALArrowRotateRight: React.FC<IconProps> = iconFactory(falArrowRotateRight);
export const FALArrowTurnDownLeft: React.FC<IconProps> = iconFactory(falArrowTurnDownLeft);
export const FALArrowTurnDownRight: React.FC<IconProps> = iconFactory(falArrowTurnDownRight);
export const FALArrowUp: React.FC<IconProps> = iconFactory(falArrowUp);
export const FALArrowUpFromBracket: React.FC<IconProps> = iconFactory(falArrowUpFromBracket);
export const FALArrowUpRight: React.FC<IconProps> = iconFactory(falArrowUpRight);
export const FALArrowUpRightAndArrowDownLeftFromCenter: React.FC<IconProps> = iconFactory(falArrowUpRightAndArrowDownLeftFromCenter);
export const FALArrowUpRightFromSquare: React.FC<IconProps> = iconFactory(falArrowUpRightFromSquare);
export const FALArrowUpShortWide: React.FC<IconProps> = iconFactory(falArrowUpShortWide);
export const FALArrowsLeftRight: React.FC<IconProps> = iconFactory(falArrowsLeftRight);
export const FALArrowsRepeat: React.FC<IconProps> = iconFactory(falArrowsRepeat);
export const FALArrowsRotate: React.FC<IconProps> = iconFactory(falArrowsRotate);
export const FALArrowsUpDownLeftRight: React.FC<IconProps> = iconFactory(falArrowsUpDownLeftRight);
export const FALAt: React.FC<IconProps> = iconFactory(falAt);
export const FALB: React.FC<IconProps> = iconFactory(falB);
export const FALBallotCheck: React.FC<IconProps> = iconFactory(falBallotCheck);
export const FALBan: React.FC<IconProps> = iconFactory(falBan);
export const FALBars: React.FC<IconProps> = iconFactory(falBars);
export const FALBarsFilter: React.FC<IconProps> = iconFactory(falBarsFilter);
export const FALBarsSort: React.FC<IconProps> = iconFactory(falBarsSort);
export const FALBell: React.FC<IconProps> = iconFactory(falBell);
export const FASBell: React.FC<IconProps> = iconFactory(fasBell);
export const FALBellOn: React.FC<IconProps> = iconFactory(falBellOn);
export const FALBellSlash: React.FC<IconProps> = iconFactory(falBellSlash);
export const FASBellSlash: React.FC<IconProps> = iconFactory(fasBellSlash);
export const FALBlockQuote: React.FC<IconProps> = iconFactory(falBlockQuote);
export const FALBold: React.FC<IconProps> = iconFactory(falBold);
export const FALBook: React.FC<IconProps> = iconFactory(falBook);
export const FASBook: React.FC<IconProps> = iconFactory(fasBook);
export const FALBookOpenCover: React.FC<IconProps> = iconFactory(falBookOpenCover);
export const FASBookOpenCover: React.FC<IconProps> = iconFactory(fasBookOpenCover);
export const FALBookmark: React.FC<IconProps> = iconFactory(falBookmark);
export const FALBoxArchive: React.FC<IconProps> = iconFactory(falBoxArchive);
export const FALBoxOpen: React.FC<IconProps> = iconFactory(falBoxOpen);
export const FALBoxesPacking: React.FC<IconProps> = iconFactory(falBoxesPacking);
export const FALBracketsCurly: React.FC<IconProps> = iconFactory(falBracketsCurly);
export const FALBracketsSquare: React.FC<IconProps> = iconFactory(falBracketsSquare);
export const FALBriefcase: React.FC<IconProps> = iconFactory(falBriefcase);
export const FALBrightness: React.FC<IconProps> = iconFactory(falBrightness);
export const FASBrightness: React.FC<IconProps> = iconFactory(fasBrightness);
export const FALBrowser: React.FC<IconProps> = iconFactory(falBrowser);
export const FASBrowser: React.FC<IconProps> = iconFactory(fasBrowser);
export const FALBrowsers: React.FC<IconProps> = iconFactory(falBrowsers);
export const FASBrowsers: React.FC<IconProps> = iconFactory(fasBrowsers);
export const FALBug: React.FC<IconProps> = iconFactory(falBug);
export const FASBug: React.FC<IconProps> = iconFactory(fasBug);
export const FALBuilding: React.FC<IconProps> = iconFactory(falBuilding);
export const FASBuilding: React.FC<IconProps> = iconFactory(fasBuilding);
export const FALBuildingCircleCheck: React.FC<IconProps> = iconFactory(falBuildingCircleCheck);
export const FALBuildingMemo: React.FC<IconProps> = iconFactory(falBuildingMemo);
export const FASBuildingMemo: React.FC<IconProps> = iconFactory(fasBuildingMemo);
export const FALC: React.FC<IconProps> = iconFactory(falC);
export const FALCalendarCirclePlus: React.FC<IconProps> = iconFactory(falCalendarCirclePlus);
export const FALCalendarClock: React.FC<IconProps> = iconFactory(falCalendarClock);
export const FALCalendarDay: React.FC<IconProps> = iconFactory(falCalendarDay);
export const FALCalendarLines: React.FC<IconProps> = iconFactory(falCalendarLines);
export const FASCalendarLines: React.FC<IconProps> = iconFactory(fasCalendarLines);
export const FALCartShopping: React.FC<IconProps> = iconFactory(falCartShopping);
export const FASCartShopping: React.FC<IconProps> = iconFactory(fasCartShopping);
export const FALChartColumn: React.FC<IconProps> = iconFactory(falChartColumn);
export const FALChartLineDown: React.FC<IconProps> = iconFactory(falChartLineDown);
export const FALChartLineUp: React.FC<IconProps> = iconFactory(falChartLineUp);
export const FALChartMixed: React.FC<IconProps> = iconFactory(falChartMixed);
export const FALChartPie: React.FC<IconProps> = iconFactory(falChartPie);
export const FASChartPie: React.FC<IconProps> = iconFactory(fasChartPie);
export const FALCheck: React.FC<IconProps> = iconFactory(falCheck);
export const FALChevronDown: React.FC<IconProps> = iconFactory(falChevronDown);
export const FALChevronLeft: React.FC<IconProps> = iconFactory(falChevronLeft);
export const FALChevronRight: React.FC<IconProps> = iconFactory(falChevronRight);
export const FALChevronUp: React.FC<IconProps> = iconFactory(falChevronUp);
export const FALCircle: React.FC<IconProps> = iconFactory(falCircle);
export const FASCircle: React.FC<IconProps> = iconFactory(fasCircle);
export const FALCircleC: React.FC<IconProps> = iconFactory(falCircleC);
export const FALCircleCheck: React.FC<IconProps> = iconFactory(falCircleCheck);
export const FASCircleCheck: React.FC<IconProps> = iconFactory(fasCircleCheck);
export const FALCircleExclamation: React.FC<IconProps> = iconFactory(falCircleExclamation);
export const FASCircleExclamation: React.FC<IconProps> = iconFactory(fasCircleExclamation);
export const FALCircleInfo: React.FC<IconProps> = iconFactory(falCircleInfo);
export const FASCircleInfo: React.FC<IconProps> = iconFactory(fasCircleInfo);
export const FALCircleNodes: React.FC<IconProps> = iconFactory(falCircleNodes);
export const FALCirclePlay: React.FC<IconProps> = iconFactory(falCirclePlay);
export const FALCirclePlus: React.FC<IconProps> = iconFactory(falCirclePlus);
export const FALCircleUser: React.FC<IconProps> = iconFactory(falCircleUser);
export const FALClock: React.FC<IconProps> = iconFactory(falClock);
export const FASClock: React.FC<IconProps> = iconFactory(fasClock);
export const FALClockRotateLeft: React.FC<IconProps> = iconFactory(falClockRotateLeft);
export const FALCode: React.FC<IconProps> = iconFactory(falCode);
export const FASCode: React.FC<IconProps> = iconFactory(fasCode);
export const FALCodeBranch: React.FC<IconProps> = iconFactory(falCodeBranch);
export const FALCodeCommit: React.FC<IconProps> = iconFactory(falCodeCommit);
export const FALColumns3: React.FC<IconProps> = iconFactory(falColumns3);
export const FASColumns3: React.FC<IconProps> = iconFactory(fasColumns3);
export const FALCommand: React.FC<IconProps> = iconFactory(falCommand);
export const FALCopy: React.FC<IconProps> = iconFactory(falCopy);
export const FASCopy: React.FC<IconProps> = iconFactory(fasCopy);
export const FALCopyright: React.FC<IconProps> = iconFactory(falCopyright);
export const FASCopyright: React.FC<IconProps> = iconFactory(fasCopyright);
export const FALCropSimple: React.FC<IconProps> = iconFactory(falCropSimple);
export const FALCube: React.FC<IconProps> = iconFactory(falCube);
export const FASCube: React.FC<IconProps> = iconFactory(fasCube);
export const FALD: React.FC<IconProps> = iconFactory(falD);
export const FALDash: React.FC<IconProps> = iconFactory(falDash);
export const FALDeleteLeft: React.FC<IconProps> = iconFactory(falDeleteLeft);
export const FASDeleteLeft: React.FC<IconProps> = iconFactory(fasDeleteLeft);
export const FALDesktop: React.FC<IconProps> = iconFactory(falDesktop);
export const FASDesktop: React.FC<IconProps> = iconFactory(fasDesktop);
export const FALDistributeSpacingHorizontal: React.FC<IconProps> = iconFactory(falDistributeSpacingHorizontal);
export const FASDistributeSpacingHorizontal: React.FC<IconProps> = iconFactory(fasDistributeSpacingHorizontal);
export const FALDistributeSpacingVertical: React.FC<IconProps> = iconFactory(falDistributeSpacingVertical);
export const FASDistributeSpacingVertical: React.FC<IconProps> = iconFactory(fasDistributeSpacingVertical);
export const FALDollarSign: React.FC<IconProps> = iconFactory(falDollarSign);
export const FALDoorClosed: React.FC<IconProps> = iconFactory(falDoorClosed);
export const FASDownLong: React.FC<IconProps> = iconFactory(fasDownLong);
export const FALDownload: React.FC<IconProps> = iconFactory(falDownload);
export const FALE: React.FC<IconProps> = iconFactory(falE);
export const FALEarthAmericas: React.FC<IconProps> = iconFactory(falEarthAmericas);
export const FASEarthAmericas: React.FC<IconProps> = iconFactory(fasEarthAmericas);
export const FALEllipsis: React.FC<IconProps> = iconFactory(falEllipsis);
export const FALEllipsisVertical: React.FC<IconProps> = iconFactory(falEllipsisVertical);
export const FALEnvelope: React.FC<IconProps> = iconFactory(falEnvelope);
export const FASEnvelope: React.FC<IconProps> = iconFactory(fasEnvelope);
export const FALEnvelopeDot: React.FC<IconProps> = iconFactory(falEnvelopeDot);
export const FALEnvelopeOpen: React.FC<IconProps> = iconFactory(falEnvelopeOpen);
export const FALEuroSign: React.FC<IconProps> = iconFactory(falEuroSign);
export const FALEye: React.FC<IconProps> = iconFactory(falEye);
export const FASEye: React.FC<IconProps> = iconFactory(fasEye);
export const FALEyeSlash: React.FC<IconProps> = iconFactory(falEyeSlash);
export const FASEyeSlash: React.FC<IconProps> = iconFactory(fasEyeSlash);
export const FALF: React.FC<IconProps> = iconFactory(falF);
export const FALFaceFrownSlight: React.FC<IconProps> = iconFactory(falFaceFrownSlight);
export const FASFaceFrownSlight: React.FC<IconProps> = iconFactory(fasFaceFrownSlight);
export const FALFaceMeh: React.FC<IconProps> = iconFactory(falFaceMeh);
export const FASFaceMeh: React.FC<IconProps> = iconFactory(fasFaceMeh);
export const FALFaceSmile: React.FC<IconProps> = iconFactory(falFaceSmile);
export const FASFaceSmile: React.FC<IconProps> = iconFactory(fasFaceSmile);
export const FALFileCircleQuestion: React.FC<IconProps> = iconFactory(falFileCircleQuestion);
export const FALFileCode: React.FC<IconProps> = iconFactory(falFileCode);
export const FALFileCsv: React.FC<IconProps> = iconFactory(falFileCsv);
export const FALFileDoc: React.FC<IconProps> = iconFactory(falFileDoc);
export const FALFileExport: React.FC<IconProps> = iconFactory(falFileExport);
export const FALFileImport: React.FC<IconProps> = iconFactory(falFileImport);
export const FALFileLines: React.FC<IconProps> = iconFactory(falFileLines);
export const FALFileMagnifyingGlass: React.FC<IconProps> = iconFactory(falFileMagnifyingGlass);
export const FALFilePdf: React.FC<IconProps> = iconFactory(falFilePdf);
export const FALFileXls: React.FC<IconProps> = iconFactory(falFileXls);
export const FALFileXml: React.FC<IconProps> = iconFactory(falFileXml);
export const FALFilms: React.FC<IconProps> = iconFactory(falFilms);
export const FALFlag: React.FC<IconProps> = iconFactory(falFlag);
export const FALFlagPennant: React.FC<IconProps> = iconFactory(falFlagPennant);
export const FALFloppyDisk: React.FC<IconProps> = iconFactory(falFloppyDisk);
export const FALFloppyDisks: React.FC<IconProps> = iconFactory(falFloppyDisks);
export const FALFolder: React.FC<IconProps> = iconFactory(falFolder);
export const FASFolder: React.FC<IconProps> = iconFactory(fasFolder);
export const FALFolderOpen: React.FC<IconProps> = iconFactory(falFolderOpen);
export const FASFolderOpen: React.FC<IconProps> = iconFactory(fasFolderOpen);
export const FALFont: React.FC<IconProps> = iconFactory(falFont);
export const FALFontCase: React.FC<IconProps> = iconFactory(falFontCase);
export const FASFontCase: React.FC<IconProps> = iconFactory(fasFontCase);
export const FALForkKnife: React.FC<IconProps> = iconFactory(falForkKnife);
export const FALFrancSign: React.FC<IconProps> = iconFactory(falFrancSign);
export const FALFunction: React.FC<IconProps> = iconFactory(falFunction);
export const FASFunction: React.FC<IconProps> = iconFactory(fasFunction);
export const FALG: React.FC<IconProps> = iconFactory(falG);
export const FALGear: React.FC<IconProps> = iconFactory(falGear);
export const FALGearCode: React.FC<IconProps> = iconFactory(falGearCode);
export const FALGlobe: React.FC<IconProps> = iconFactory(falGlobe);
export const FASGlobe: React.FC<IconProps> = iconFactory(fasGlobe);
export const FALGlobePointer: React.FC<IconProps> = iconFactory(falGlobePointer);
export const FALGrid2: React.FC<IconProps> = iconFactory(falGrid2);
export const FASGrid2: React.FC<IconProps> = iconFactory(fasGrid2);
export const FASGripDotsVertical: React.FC<IconProps> = iconFactory(fasGripDotsVertical);
export const FALGripLines: React.FC<IconProps> = iconFactory(falGripLines);
export const FALGripLinesVertical: React.FC<IconProps> = iconFactory(falGripLinesVertical);
export const FALH: React.FC<IconProps> = iconFactory(falH);
export const FALHandPointer: React.FC<IconProps> = iconFactory(falHandPointer);
export const FALHandshakeSimple: React.FC<IconProps> = iconFactory(falHandshakeSimple);
export const FASHandshakeSimple: React.FC<IconProps> = iconFactory(fasHandshakeSimple);
export const FALHashtag: React.FC<IconProps> = iconFactory(falHashtag);
export const FALHeading: React.FC<IconProps> = iconFactory(falHeading);
export const FASHeading: React.FC<IconProps> = iconFactory(fasHeading);
export const FALHeart: React.FC<IconProps> = iconFactory(falHeart);
export const FALHexagonNodes: React.FC<IconProps> = iconFactory(falHexagonNodes);
export const FALHighlighter: React.FC<IconProps> = iconFactory(falHighlighter);
export const FALHorizontalRule: React.FC<IconProps> = iconFactory(falHorizontalRule);
export const FALHospitalUser: React.FC<IconProps> = iconFactory(falHospitalUser);
export const FALHotel: React.FC<IconProps> = iconFactory(falHotel);
export const FALHourglassClock: React.FC<IconProps> = iconFactory(falHourglassClock);
export const FALHouse: React.FC<IconProps> = iconFactory(falHouse);
export const FASHouse: React.FC<IconProps> = iconFactory(fasHouse);
export const FALHouseMedical: React.FC<IconProps> = iconFactory(falHouseMedical);
export const FALI: React.FC<IconProps> = iconFactory(falI);
export const FALICursor: React.FC<IconProps> = iconFactory(falICursor);
export const FALIcons: React.FC<IconProps> = iconFactory(falIcons);
export const FASIcons: React.FC<IconProps> = iconFactory(fasIcons);
export const FALImage: React.FC<IconProps> = iconFactory(falImage);
export const FASImage: React.FC<IconProps> = iconFactory(fasImage);
export const FALImages: React.FC<IconProps> = iconFactory(falImages);
export const FALInbox: React.FC<IconProps> = iconFactory(falInbox);
export const FASInbox: React.FC<IconProps> = iconFactory(fasInbox);
export const FALIndianRupeeSign: React.FC<IconProps> = iconFactory(falIndianRupeeSign);
export const FALItalic: React.FC<IconProps> = iconFactory(falItalic);
export const FALJ: React.FC<IconProps> = iconFactory(falJ);
export const FALK: React.FC<IconProps> = iconFactory(falK);
export const FALKey: React.FC<IconProps> = iconFactory(falKey);
export const FALL: React.FC<IconProps> = iconFactory(falL);
export const FALLaptop: React.FC<IconProps> = iconFactory(falLaptop);
export const FASLaptop: React.FC<IconProps> = iconFactory(fasLaptop);
export const FALLayerGroup: React.FC<IconProps> = iconFactory(falLayerGroup);
export const FASLeftLong: React.FC<IconProps> = iconFactory(fasLeftLong);
export const FALLightbulbMessage: React.FC<IconProps> = iconFactory(falLightbulbMessage);
export const FALLightbulbOn: React.FC<IconProps> = iconFactory(falLightbulbOn);
export const FASLightbulbOn: React.FC<IconProps> = iconFactory(fasLightbulbOn);
export const FALLink: React.FC<IconProps> = iconFactory(falLink);
export const FALLinkHorizontal: React.FC<IconProps> = iconFactory(falLinkHorizontal);
export const FALLinkSimple: React.FC<IconProps> = iconFactory(falLinkSimple);
export const FALListCheck: React.FC<IconProps> = iconFactory(falListCheck);
export const FALListOl: React.FC<IconProps> = iconFactory(falListOl);
export const FALListTree: React.FC<IconProps> = iconFactory(falListTree);
export const FALListUl: React.FC<IconProps> = iconFactory(falListUl);
export const FALLocationDot: React.FC<IconProps> = iconFactory(falLocationDot);
export const FASLocationDot: React.FC<IconProps> = iconFactory(fasLocationDot);
export const FALLockKeyhole: React.FC<IconProps> = iconFactory(falLockKeyhole);
export const FALLockKeyholeOpen: React.FC<IconProps> = iconFactory(falLockKeyholeOpen);
export const FALM: React.FC<IconProps> = iconFactory(falM);
export const FALMagnifyingGlass: React.FC<IconProps> = iconFactory(falMagnifyingGlass);
export const FALMagnifyingGlassMinus: React.FC<IconProps> = iconFactory(falMagnifyingGlassMinus);
export const FALMagnifyingGlassPlus: React.FC<IconProps> = iconFactory(falMagnifyingGlassPlus);
export const FALMedal: React.FC<IconProps> = iconFactory(falMedal);
export const FASMemo: React.FC<IconProps> = iconFactory(fasMemo);
export const FALMemoPad: React.FC<IconProps> = iconFactory(falMemoPad);
export const FALMessage: React.FC<IconProps> = iconFactory(falMessage);
export const FASMessage: React.FC<IconProps> = iconFactory(fasMessage);
export const FALMessageDots: React.FC<IconProps> = iconFactory(falMessageDots);
export const FASMessageDots: React.FC<IconProps> = iconFactory(fasMessageDots);
export const FALMessageLines: React.FC<IconProps> = iconFactory(falMessageLines);
export const FASMessageLines: React.FC<IconProps> = iconFactory(fasMessageLines);
export const FALMessagePlus: React.FC<IconProps> = iconFactory(falMessagePlus);
export const FALMessageSmile: React.FC<IconProps> = iconFactory(falMessageSmile);
export const FALMessages: React.FC<IconProps> = iconFactory(falMessages);
export const FASMessages: React.FC<IconProps> = iconFactory(fasMessages);
export const FALMessagesQuestion: React.FC<IconProps> = iconFactory(falMessagesQuestion);
export const FALMicrophoneStand: React.FC<IconProps> = iconFactory(falMicrophoneStand);
export const FALMinus: React.FC<IconProps> = iconFactory(falMinus);
export const FALMobile: React.FC<IconProps> = iconFactory(falMobile);
export const FASMobile: React.FC<IconProps> = iconFactory(fasMobile);
export const FALMoneySimpleFromBracket: React.FC<IconProps> = iconFactory(falMoneySimpleFromBracket);
export const FALMoonStars: React.FC<IconProps> = iconFactory(falMoonStars);
export const FASMoonStars: React.FC<IconProps> = iconFactory(fasMoonStars);
export const FALMusic: React.FC<IconProps> = iconFactory(falMusic);
export const FASMusic: React.FC<IconProps> = iconFactory(fasMusic);
export const FALN: React.FC<IconProps> = iconFactory(falN);
export const FALO: React.FC<IconProps> = iconFactory(falO);
export const FALObjectsAlignBottom: React.FC<IconProps> = iconFactory(falObjectsAlignBottom);
export const FASObjectsAlignBottom: React.FC<IconProps> = iconFactory(fasObjectsAlignBottom);
export const FALObjectsAlignCenterHorizontal: React.FC<IconProps> = iconFactory(falObjectsAlignCenterHorizontal);
export const FASObjectsAlignCenterHorizontal: React.FC<IconProps> = iconFactory(fasObjectsAlignCenterHorizontal);
export const FALObjectsAlignCenterVertical: React.FC<IconProps> = iconFactory(falObjectsAlignCenterVertical);
export const FASObjectsAlignCenterVertical: React.FC<IconProps> = iconFactory(fasObjectsAlignCenterVertical);
export const FALObjectsAlignLeft: React.FC<IconProps> = iconFactory(falObjectsAlignLeft);
export const FASObjectsAlignLeft: React.FC<IconProps> = iconFactory(fasObjectsAlignLeft);
export const FALObjectsAlignRight: React.FC<IconProps> = iconFactory(falObjectsAlignRight);
export const FASObjectsAlignRight: React.FC<IconProps> = iconFactory(fasObjectsAlignRight);
export const FALObjectsAlignTop: React.FC<IconProps> = iconFactory(falObjectsAlignTop);
export const FASObjectsAlignTop: React.FC<IconProps> = iconFactory(fasObjectsAlignTop);
export const FALObjectsColumn: React.FC<IconProps> = iconFactory(falObjectsColumn);
export const FALP: React.FC<IconProps> = iconFactory(falP);
export const FALPage: React.FC<IconProps> = iconFactory(falPage);
export const FASPage: React.FC<IconProps> = iconFactory(fasPage);
export const FALPaintbrushPencil: React.FC<IconProps> = iconFactory(falPaintbrushPencil);
export const FALPaperclip: React.FC<IconProps> = iconFactory(falPaperclip);
export const FALPen: React.FC<IconProps> = iconFactory(falPen);
export const FASPen: React.FC<IconProps> = iconFactory(fasPen);
export const FALPenField: React.FC<IconProps> = iconFactory(falPenField);
export const FALPeopleGroup: React.FC<IconProps> = iconFactory(falPeopleGroup);
export const FALPercent: React.FC<IconProps> = iconFactory(falPercent);
export const FALPerson: React.FC<IconProps> = iconFactory(falPerson);
export const FALPersonCircleCheck: React.FC<IconProps> = iconFactory(falPersonCircleCheck);
export const FALPersonDress: React.FC<IconProps> = iconFactory(falPersonDress);
export const FALPhone: React.FC<IconProps> = iconFactory(falPhone);
export const FASPhone: React.FC<IconProps> = iconFactory(fasPhone);
export const FALPhotoFilm: React.FC<IconProps> = iconFactory(falPhotoFilm);
export const FASPhotoFilm: React.FC<IconProps> = iconFactory(fasPhotoFilm);
export const FALPizzaSlice: React.FC<IconProps> = iconFactory(falPizzaSlice);
export const FASPlug: React.FC<IconProps> = iconFactory(fasPlug);
export const FALPlus: React.FC<IconProps> = iconFactory(falPlus);
export const FASPlus: React.FC<IconProps> = iconFactory(fasPlus);
export const FALPuzzlePiece: React.FC<IconProps> = iconFactory(falPuzzlePiece);
export const FASPuzzlePiece: React.FC<IconProps> = iconFactory(fasPuzzlePiece);
export const FALQ: React.FC<IconProps> = iconFactory(falQ);
export const FALQrcode: React.FC<IconProps> = iconFactory(falQrcode);
export const FALQuestion: React.FC<IconProps> = iconFactory(falQuestion);
export const FALR: React.FC<IconProps> = iconFactory(falR);
export const FALRectangleCode: React.FC<IconProps> = iconFactory(falRectangleCode);
export const FALRectangleHistoryCirclePlus: React.FC<IconProps> = iconFactory(falRectangleHistoryCirclePlus);
export const FASRectangleHistoryCirclePlus: React.FC<IconProps> = iconFactory(fasRectangleHistoryCirclePlus);
export const FALRectangleWide: React.FC<IconProps> = iconFactory(falRectangleWide);
export const FASRectangleWide: React.FC<IconProps> = iconFactory(fasRectangleWide);
export const FALReply: React.FC<IconProps> = iconFactory(falReply);
export const FASReply: React.FC<IconProps> = iconFactory(fasReply);
export const FALRocket: React.FC<IconProps> = iconFactory(falRocket);
export const FASRocket: React.FC<IconProps> = iconFactory(fasRocket);
export const FALS: React.FC<IconProps> = iconFactory(falS);
export const FALShieldCheck: React.FC<IconProps> = iconFactory(falShieldCheck);
export const FALShop: React.FC<IconProps> = iconFactory(falShop);
export const FAShorten: React.FC<IconProps> = iconFactory(faShorten);
export const FALShuffle: React.FC<IconProps> = iconFactory(falShuffle);
export const FALSidebar: React.FC<IconProps> = iconFactory(falSidebar);
export const FASSidebar: React.FC<IconProps> = iconFactory(fasSidebar);
export const FALSidebarFlip: React.FC<IconProps> = iconFactory(falSidebarFlip);
export const FALSitemap: React.FC<IconProps> = iconFactory(falSitemap);
export const FALSlashForward: React.FC<IconProps> = iconFactory(falSlashForward);
export const FALSlidersUp: React.FC<IconProps> = iconFactory(falSlidersUp);
export const FALSparkles: React.FC<IconProps> = iconFactory(falSparkles);
export const FASSparkles: React.FC<IconProps> = iconFactory(fasSparkles);
export const FALSpellCheck: React.FC<IconProps> = iconFactory(falSpellCheck);
export const FALSpider: React.FC<IconProps> = iconFactory(falSpider);
export const FALSquare: React.FC<IconProps> = iconFactory(falSquare);
export const FASSquareCheck: React.FC<IconProps> = iconFactory(fasSquareCheck);
export const FASSquareCode: React.FC<IconProps> = iconFactory(fasSquareCode);
export const FASSquareMinus: React.FC<IconProps> = iconFactory(fasSquareMinus);
export const FALSquarePen: React.FC<IconProps> = iconFactory(falSquarePen);
export const FALSquarePlus: React.FC<IconProps> = iconFactory(falSquarePlus);
export const FASSquarePlus: React.FC<IconProps> = iconFactory(fasSquarePlus);
export const FALSquarePollHorizontal: React.FC<IconProps> = iconFactory(falSquarePollHorizontal);
export const FALSquareTerminal: React.FC<IconProps> = iconFactory(falSquareTerminal);
export const FALStarSharp: React.FC<IconProps> = iconFactory(falStarSharp);
export const FASStarSharp: React.FC<IconProps> = iconFactory(fasStarSharp);
export const FALStarSharpHalf: React.FC<IconProps> = iconFactory(falStarSharpHalf);
export const FASStarSharpHalf: React.FC<IconProps> = iconFactory(fasStarSharpHalf);
export const FALSterlingSign: React.FC<IconProps> = iconFactory(falSterlingSign);
export const FALStethoscope: React.FC<IconProps> = iconFactory(falStethoscope);
export const FALStrikethrough: React.FC<IconProps> = iconFactory(falStrikethrough);
export const FALSubscript: React.FC<IconProps> = iconFactory(falSubscript);
export const FALSuperscript: React.FC<IconProps> = iconFactory(falSuperscript);
export const FALSwapArrows: React.FC<IconProps> = iconFactory(falSwapArrows);
export const FALT: React.FC<IconProps> = iconFactory(falT);
export const FALTable: React.FC<IconProps> = iconFactory(falTable);
export const FASTable: React.FC<IconProps> = iconFactory(fasTable);
export const FALTableLayout: React.FC<IconProps> = iconFactory(falTableLayout);
export const FALTablet: React.FC<IconProps> = iconFactory(falTablet);
export const FASTablet: React.FC<IconProps> = iconFactory(fasTablet);
export const FALTag: React.FC<IconProps> = iconFactory(falTag);
export const FASTag: React.FC<IconProps> = iconFactory(fasTag);
export const FALTags: React.FC<IconProps> = iconFactory(falTags);
export const FASTags: React.FC<IconProps> = iconFactory(fasTags);
export const FALText: React.FC<IconProps> = iconFactory(falText);
export const FALThumbsDown: React.FC<IconProps> = iconFactory(falThumbsDown);
export const FASThumbsDown: React.FC<IconProps> = iconFactory(fasThumbsDown);
export const FALThumbsUp: React.FC<IconProps> = iconFactory(falThumbsUp);
export const FASThumbsUp: React.FC<IconProps> = iconFactory(fasThumbsUp);
export const FALTrash: React.FC<IconProps> = iconFactory(falTrash);
export const FASTrash: React.FC<IconProps> = iconFactory(fasTrash);
export const FALTriangleExclamation: React.FC<IconProps> = iconFactory(falTriangleExclamation);
export const FASTriangleExclamation: React.FC<IconProps> = iconFactory(fasTriangleExclamation);
export const FALU: React.FC<IconProps> = iconFactory(falU);
export const FALUnderline: React.FC<IconProps> = iconFactory(falUnderline);
export const FASUpLong: React.FC<IconProps> = iconFactory(fasUpLong);
export const FALUpload: React.FC<IconProps> = iconFactory(falUpload);
export const FASUpload: React.FC<IconProps> = iconFactory(fasUpload);
export const FALUser: React.FC<IconProps> = iconFactory(falUser);
export const FASUser: React.FC<IconProps> = iconFactory(fasUser);
export const FALUserGroup: React.FC<IconProps> = iconFactory(falUserGroup);
export const FASUserGroup: React.FC<IconProps> = iconFactory(fasUserGroup);
export const FALUserPlus: React.FC<IconProps> = iconFactory(falUserPlus);
export const FASUserPlus: React.FC<IconProps> = iconFactory(fasUserPlus);
export const FALUserTag: React.FC<IconProps> = iconFactory(falUserTag);
export const FASUserTag: React.FC<IconProps> = iconFactory(fasUserTag);
export const FASUserTie: React.FC<IconProps> = iconFactory(fasUserTie);
export const FALUserTieHair: React.FC<IconProps> = iconFactory(falUserTieHair);
export const FALUsers: React.FC<IconProps> = iconFactory(falUsers);
export const FASUsers: React.FC<IconProps> = iconFactory(fasUsers);
export const FALV: React.FC<IconProps> = iconFactory(falV);
export const FALW: React.FC<IconProps> = iconFactory(falW);
export const FALWindowMaximize: React.FC<IconProps> = iconFactory(falWindowMaximize);
export const FASWindowMaximize: React.FC<IconProps> = iconFactory(fasWindowMaximize);
export const FALWrench: React.FC<IconProps> = iconFactory(falWrench);
export const FALX: React.FC<IconProps> = iconFactory(falX);
export const FALXmark: React.FC<IconProps> = iconFactory(falXmark);
export const FALXmarkLarge: React.FC<IconProps> = iconFactory(falXmarkLarge);
export const FALY: React.FC<IconProps> = iconFactory(falY);
export const FALYenSign: React.FC<IconProps> = iconFactory(falYenSign);
export const FALZ: React.FC<IconProps> = iconFactory(falZ);
