import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { anatomy } from "@chakra-ui/theme-tools";
import typography from "../../../clayTheme/foundations/typography";

export const structuredListAnatomy = anatomy("structured-list").parts(
  "list",
  "item",
  "button",
  "header",
  "cell",
  "icon",
);

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(structuredListAnatomy.keys);

const baseStyle = definePartsStyle({
  header: {
    px: 4,
    py: 2,
    color: "text.neutralSoft",
    ...typography.textStyles["md-medium"],
  },
  item: {
    px: 2,
    py: 2,
    backgroundColor: "pairs.gray.whitePair",
  },
  cell: {
    px: 2,
  },
  button: {
    _hover: {
      backgroundColor: "pairs.gray.50",
    },
    _active: {
      backgroundColor: "pairs.gray.100",
    },
    _focusVisible: {
      shadow: "focus",
      backgroundColor: "pairs.gray.50",
    },
  },
});

const SuiStructuredList: ReturnType<typeof defineMultiStyleConfig> =
  defineMultiStyleConfig({ baseStyle });

export default SuiStructuredList;
