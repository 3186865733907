import React from "react";
import { Flex } from "@chakra-ui/react";

export interface TextareaToolbarProps {
  children: React.ReactNode;
  "aria-label"?: string;
  "aria-labelledby"?: string;
}

export function TextareaToolbar(props: TextareaToolbarProps) {
  const { children, ...rest } = props;
  const [isFocused, setIsFocused] = React.useState(false);

  const toolbarProps = useToolbarNavigation(isFocused);

  // TODO: ideally if we have a portal and the focus moves into the portal, we would
  // want to set isFocused to false. Not sure how to accomplish this.

  return (
    <Flex
      {...rest}
      {...toolbarProps}
      gap={1}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
    >
      {children}
    </Flex>
  );
}

// A toolbar requires some custom keyboard interactions for arrow keys based on orienation.
// See https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/toolbar_role for details.
// we also probably need to update our combobox to lock focus withinit like Chakra's menu does.
enum Orientation {
  Horizontal = "horizontal",
  Vertical = "vertical",
}

type ToolbarProps = {
  "aria-orientation": Orientation;
  role: "toolbar";
  ref: React.RefObject<HTMLDivElement>;
};

function useToolbarNavigation(
  isFocused: boolean,
  orientation: Orientation = Orientation.Horizontal,
): ToolbarProps {
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleKeyDown = makeKeyDownHandler(ref, orientation, isFocused);
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [ref, isFocused, orientation]);

  return {
    "aria-orientation": orientation,
    role: "toolbar",
    ref,
  };
}

const makeKeyDownHandler = (
  ref: React.RefObject<HTMLDivElement>,
  orientation: Orientation,
  isFocused: boolean,
) => {
  return (event: KeyboardEvent) => {
    if (!isFocused) {
      // if we're not in focus, we shouldn't watch the keydown event
      return;
    }

    if (event.defaultPrevented) {
      // do nothing if the event was already processed
      return;
    }

    if (!keyMatchesOrientation(event, orientation)) {
      // not a key we care about
      return;
    }

    if (!ref.current) {
      // no toolbar to navigate
      return;
    }

    event.preventDefault();

    // skip over any portals or other non-button children
    const children = Array.from(ref.current.children)
      .filter((child) => {
        return (
          child.role?.toLowerCase() === "button" ||
          child.nodeName.toLowerCase() === "button"
        );
      })
      .map((child) => child as HTMLButtonElement);

    if (!children.length) {
      return;
    }

    const increment = keyIncrement(event, orientation);

    let index = 0;

    if (document.activeElement) {
      const childIndex = children.indexOf(
        document.activeElement as HTMLButtonElement,
      );
      if (childIndex >= 0) {
        index = childIndex;
      }
    }

    let nextIndex = index + increment;
    if (nextIndex < 0) {
      nextIndex = children.length - 1;
    } else if (nextIndex >= children.length) {
      nextIndex = 0;
    }

    const prevChild = children[index];
    prevChild.removeAttribute("aria-activedescendant");

    const nextChild = children[nextIndex];
    nextChild.setAttribute("aria-activedescendant", "true");
    nextChild.focus();
  };
};

const keyMatchesOrientation = (
  event: KeyboardEvent,
  orientation: Orientation,
) => {
  if (orientation === Orientation.Horizontal) {
    return event.key === "ArrowRight" || event.key === "ArrowLeft";
  } else if (orientation === Orientation.Vertical) {
    return event.key === "ArrowDown" || event.key === "ArrowUp";
  }
  return false;
};

const keyIncrement = (event: KeyboardEvent, orientation: Orientation) => {
  if (orientation === Orientation.Horizontal) {
    return event.key === "ArrowRight" ? 1 : -1;
  } else if (orientation === Orientation.Vertical) {
    return event.key === "ArrowDown" ? 1 : -1;
  }
  return 0;
};
