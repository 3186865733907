import React from "react";
import { DateRangePickerTimeField as SaasTimeField } from "@saas-ui/date-picker";
import { HTMLChakraProps } from "@chakra-ui/react";

export interface DateRangeTimeFieldProps extends HTMLChakraProps<"div"> {
  startLabel?: string;
  endLabel?: string;
}

export const DateRangePickerTimeField = (props: DateRangeTimeFieldProps) => {
  return (
    <SaasTimeField
      sx={{
        justifyContent: "space-between",
        "& > *": {
          flexBasis: "50%",
        },
      }}
      {...props}
    />
  );
};
