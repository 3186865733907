import {LoginSubmitButton,
  isWhitelabeled,
  LoginActions,
  SsoLogin,
  EmployeeZendeskRedirectLogin} from '/src/com/yext/platform/users/js/newloginhelpers/logincomponents';
import NewLoginTemplate from '/src/com/yext/platform/users/js/newloginhelpers/newlogintemplate';
import {registerComponentForJsonDataWithUIKit} from '/ui/lib/easyuikit';
import {AJAX_HEADER, checkResponseStatus} from '/ui/lib/fetch';
import {yext} from '/ui/lib/msg';

const {
  Box,
  FALCircleExclamation,
  FALEnvelope,
  FALLockKeyhole,
  Flex,
  Form,
  FormControl,
  FormErrorMessage,
  InputLeftElement,
  VStack,
  Field,
} = require('@yext/mana-ui');

const SECTIONS = {
  USERNAME: 'USERNAME',
  SSO: 'SSO',
  PASSWORD: 'PASSWORD',
  ACCOUNT_ID: 'ACCOUNT_ID',
};

export function NewPartitionLogin({
  whitelabelInfo,
  username,
  rememberMe,
  global,
  ssoLoginType,
  b,
  reauth,
  passwordOnly,
  redirectUrl,
  isMobileRedirect,
  isAllState,
}) {
  const [section, setSection] = React.useState(SECTIONS.USERNAME);

  const getSubtitle = () => {
    return reauth
      ? yext.msg('In order to update users, you will need to verify your identity again.')
      : yext.msg('Please sign in to continue.');
  };

  const getTitle = () => {
    if (reauth) {
      return '';
    } else {
      return isWhitelabeled(whitelabelInfo)
        ? yext.msg('Welcome to {0}', whitelabelInfo.name)
        : yext.msg('Welcome to Yext');
    }
  };

  return (
    <NewLoginTemplate
      title={getTitle()}
      subtitle={getSubtitle()}
      cardContent={
        <CardContent
          whitelabelInfo={whitelabelInfo}
          initialUsername={username}
          initialRememberMe={rememberMe}
          reauth={reauth}
          global={global}
          ssoLoginType={ssoLoginType}
          b={b}
          passwordOnly={passwordOnly}
          redirectUrl={redirectUrl}
          section={section}
          setSection={setSection}
        />}
      whitelabelInfo={whitelabelInfo}
      pageType={'login'}
      isMobileRedirect={isMobileRedirect}
      showBackToUsername={section !== SECTIONS.USERNAME && !reauth}
      isAllState={isAllState}
    />
  );
}

function CardContent({
  whitelabelInfo,
  initialUsername,
  initialRememberMe,
  global,
  ssoLoginType,
  b,
  reauth,
  passwordOnly,
  redirectUrl,
  section,
  setSection,
}) {
  // Error message to be displayed
  const [error, setError] = React.useState('');
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = React.useState(false);
  const [username, setUsername] = React.useState(initialUsername);
  // Track the initial value for the username and password sections
  const [rememberMe, setRememberMe] = React.useState(initialRememberMe);
  const [loginTypes, setLoginTypes] = React.useState([]);
  const shouldShowSsoLogin = !isWhitelabeled(whitelabelInfo) && !passwordOnly;


  React.useEffect(() => {
    if ((global || reauth) && username) {
      // If it is a redirect from global login, and there is a username included,
      // and it is at the username submission stage of the login flow, submit username
      submitUsername({username, rememberMe});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** Make API call to get a list of login types for a given username. */
  const submitUsername = formData => {
    setError('');
    if (formData['username'].trim() === '') {
      setError(yext.msg('Please enter a username.'));
      return;
    }
    setUsername(formData['username']);
    setIsSubmitBtnDisabled(true);

    if (formData['rememberMe'] !== undefined) {
      setRememberMe(formData['rememberMe']);
    }
    const url = '/users/getLoginType';
    const request = new FormData();
    request.append('username', formData['username']);
    request.append('b', b);
    if (redirectUrl) {
      request.append('c', redirectUrl);
    }
    fetch(url, {
      method: 'POST',
      headers: AJAX_HEADER,
      body: request,
    })
      .then(checkResponseStatus)
      .then(response => response.json())
      .then(data => {
        determineUserLoginType(data, formData['username']);
      })
      .finally(() => setIsSubmitBtnDisabled(false));
  };

  /** Make API call to submit login */
  const submitLogin = formData => {
    setError('');
    if (formData['password'].trim() === '') {
      setError(yext.msg('Please enter a password.'));
      return;
    }
    setIsSubmitBtnDisabled(true);

    if (formData['rememberMe'] !== undefined) {
      setRememberMe(formData['rememberMe']);
    }

    const data = new FormData();
    data.append('username', username);
    data.append('password', formData['password']);
    const rememberMeValue = formData['rememberMe'] !== undefined
      ? formData['rememberMe']
      : rememberMe;
    data.append('rememberMe', rememberMeValue);
    data.append('reauth', reauth);
    let url = '/users/partition/login';
    // maintain the same redirectURL upon form submission in case the password is incorrect
    if (redirectUrl) {
      url = url + '?c=' + encodeURIComponent(redirectUrl + window.location.hash);
    }
    fetch(url, {
      method: 'POST',
      headers: AJAX_HEADER,
      body: data,
    })
      .then(checkResponseStatus)
      .then(response => response.json())
      .then(json => {
        if (json.error) {
          if (json.error === 'noProductAccess') {
            window.location.href = '/users/noproductaccess';
          } else {
            if (!reauth) {
              setSection(SECTIONS.USERNAME);
            }
            setError(json.error);
          }
        } else if (json.success) {
          window.location.href = json.success;
        }
      })
      .finally(() => setIsSubmitBtnDisabled(false));
  };

  const logIdpAttempt = (username, accountId) => {
    const data = new FormData();
    data.append('username', username);
    data.append('businessId', accountId);
    data.append('loginType', 'IDP_ONLY');
    data.append('error', 'IDP_INITIATED_ERROR');
    const url = '/users/logLoginAttempt';
    fetch(url, {
      method: 'POST',
      headers: AJAX_HEADER,
      body: data,
    });
  };

  const handleIdpOnlyLogin = (username, accountId) => {
    setSection(SECTIONS.USERNAME);
    setError(yext.msg('We can\'t log you in. Please reach out to your administrator'));
    logIdpAttempt(username, accountId);
  };

  const submitInitiateSso = b => {
    window.location.href = '/users/initiateSso?b=' + b;
  };


  /**
   * Given a list of loginTypes, determine the next phase of the login flow.
   *
   * Note: (APV-573) We should never return 0 login types.
   *
   * If there is just one user, proceed with the login flow based on login type
   * Else if there are multiple users,
   *   if all of those users belong to the same account and at least one of
   *   them is a password user, proceed as if it were a password user.
   *   else, ask for account ID to determine the user.
   * @param {!Array<*>} loginTypes
   */
  const determineUserLoginType = (loginTypes, username) => {
    if (loginTypes.length === 1) {
      switch (loginTypes[0]['loginType']) {
        case 'IDP_ONLY':
          handleIdpOnlyLogin(username, loginTypes[0]['businessId']);
          break;
        case 'SP_ONLY':
        case 'IDP_AND_SP':
          submitInitiateSso(loginTypes[0]['businessId']);
          break;
        case 'PASSWORD':
          setSection(SECTIONS.PASSWORD);
          break;
      }
    } else {
      if (shouldUsePasswordLogin(loginTypes)) {
        setSection(SECTIONS.PASSWORD);
      } else {
        setLoginTypes(loginTypes);
        setSection(SECTIONS.ACCOUNT_ID);
      }
    }
  };

  const onSSOClick = () => {
    switch (ssoLoginType) {
      case 'IDP_ONLY':
        handleIdpOnlyLogin(username, b);
        break;
      case 'SP_ONLY':
      case 'IDP_AND_SP':
        submitInitiateSso(b);
        break;
      default:
        setSection(SECTIONS.SSO);
    }
  };

  function UsernameSection() {
    return (
      <FormControl>
        <Form onSubmit={submitUsername}>
          {() => (
            <>
              <VStack
                spacing={5}
                align={'stretch'}
              >
                <FormControl isInvalid={error?.trim()}>
                  <Field
                    name={'username'}
                    type={'text'}
                    variant={'gray'}
                    label={yext.msg('Username')}
                    placeholder={'john@abccompany.com'}
                    isRequired={true}
                    defaultValue={username}
                    icon={<FALEnvelope/>}
                  />
                  <LoginError />
                </FormControl>
                <VStack
                  spacing={4}
                  align={'stretch'}
                >
                  <LoginSubmitButton whitelabelInfo={whitelabelInfo} isDisabled={isSubmitBtnDisabled}>
                    {yext.msg('Next')}
                  </LoginSubmitButton>
                  <LoginActions
                    initialRememberMe={rememberMe}
                  />
                  <EmployeeZendeskRedirectLogin redirectUrl={redirectUrl} />
                  <SsoLogin
                    onClick={onSSOClick}
                    shouldShowSsoLogin={shouldShowSsoLogin}
                  />
                </VStack>
              </VStack>
            </>
          )}
        </Form>
      </FormControl>
    );
  }

  function SSOSection() {
    return (
      <FormControl>
        <Form onSubmit={submitUsername}>
          {() => (
            <>
              <VStack
                spacing={5}
                align={'stretch'}
              >
                <Field
                  name={'username'}
                  type={'text'}
                  variant={'gray'}
                  label={yext.msg('Username')}
                  placeholder={'john@abccompany.com'}
                  isRequired={true}
                  defaultValue={username}
                  icon={<FALEnvelope/>}
                />
                <LoginSubmitButton whitelabelInfo={whitelabelInfo} isDisabled={isSubmitBtnDisabled}>
                  {yext.msg('Next')}
                </LoginSubmitButton>
              </VStack>
            </>
          )}
        </Form>
      </FormControl>
    );
  }

  function PasswordSection() {
    return (
      <FormControl>
        <Form onSubmit={submitLogin}>
          {() => (
            <>
              <VStack
                spacing={5}
                align={'stretch'}
              >
                <FormControl isInvalid={error?.trim()}>
                  <Field
                    name={'password'}
                    type={'password'}
                    variant={'gray'}
                    label={yext.msg('Password')}
                    isRequired={true}
                    leftAddon={<InputLeftElement><FALLockKeyhole/></InputLeftElement>}
                  />
                  <LoginError />
                </FormControl>
                <VStack
                  spacing={4}
                  align={'stretch'}
                >
                  <LoginSubmitButton whitelabelInfo={whitelabelInfo} isDisabled={isSubmitBtnDisabled}>
                    {reauth ? yext.msg('Verify') : yext.msg('Sign In')}
                  </LoginSubmitButton>
                  <LoginActions
                    initialRememberMe={rememberMe}
                  />
                </VStack>
              </VStack>
            </>)}
        </Form>
      </FormControl>
    );
  }

  /**
     * This method is used when there are multiple SSO users, then the user submits the account ID.
     * If the business ID matches a user with IDP initiated login. If so, we are done.
     * Else if the business ID matches a user with SP initiated login, we initiate SSO login:
     */
  const onAccountIdSubmit = ({accountId, rememberMe}) => {
    if (rememberMe !== undefined) {
      setRememberMe(rememberMe);
    }

    const loginTypesOfAccount =
        loginTypes.filter(
          type => type['businessId']
                && type['businessId'].toString() === accountId.toString());

    if (shouldUsePasswordLogin(loginTypesOfAccount)) {
      setSection(SECTIONS.PASSWORD);
      return;
    } else {
      for (const type of loginTypesOfAccount) {
        if (type['loginType'] === 'IDP_ONLY') {
          handleIdpOnlyLogin(username, accountId);
          return;
        } else if (type['loginType'] === 'SP_ONLY' || type['loginType'] === 'IDP_AND_SP') {
          submitInitiateSso(accountId);
          return;
        }
      }
    }
    setError('Invalid Login');
  };

  function AccountIdSection() {
    return (
      <Form onSubmit={onAccountIdSubmit}>
        {() => (
          <>
            <VStack
              spacing={5}
              align={'stretch'}
            >
              <FormControl isInvalid={error?.trim()}>
                <Field
                  name={'accountId'}
                  type={'text'}
                  variant={'gray'}
                  label={yext.msg('Account ID')}
                  isRequired={true}
                />
                <LoginError />
              </FormControl>
              <VStack
                spacing={4}
                align={'stretch'}
              >
                <LoginSubmitButton whitelabelInfo={whitelabelInfo} isDisabled={isSubmitBtnDisabled}>
                  {yext.msg('Next')}
                </LoginSubmitButton>
                <LoginActions
                  initialRememberMe={rememberMe}
                />
                <EmployeeZendeskRedirectLogin redirectUrl={redirectUrl} />
                <SsoLogin
                  onClick={onSSOClick}
                  shouldShowSsoLogin={shouldShowSsoLogin}
                />
              </VStack>
            </VStack>
          </>
        )}
      </Form>
    );
  }

  function LoginError() {
    return (
      <Flex mt={2} justifyContent={'space-between'} alignItems={'center'}>
        <Box display={'flex'} alignItems={'center'}>
          <FormErrorMessage>
            <FALCircleExclamation me={1.5} />
            {error}
          </FormErrorMessage>
        </Box>
      </Flex>
    );
  }

  switch (section) {
    case SECTIONS.USERNAME:
      return <UsernameSection />;
    case SECTIONS.SSO:
      return <SSOSection />;
    case SECTIONS.PASSWORD:
      return <PasswordSection />;
    case SECTIONS.ACCOUNT_ID:
      return <AccountIdSection />;
    default:
      return null;
  }
}

/**
 * If a given list of login types are all for the same account, and at least
 * one of them is a password user, use the password login flow.
 *
 * @param {!Array<*>} loginTypes
 */
const shouldUsePasswordLogin = loginTypes => {
  if (loginTypes.length === 0) {
    return false;
  }

  const businessId = loginTypes[0]['businessId'];
  let allLoginTypesInSameBusiness = true;
  let includesPasswordLoginType = false;
  for (const type of loginTypes) {
    if (businessId !== type['businessId']) {
      allLoginTypesInSameBusiness = false;
    }
    if (type['loginType'] === 'PASSWORD') {
      includesPasswordLoginType = true;
    }
  }
  return allLoginTypesInSameBusiness && includesPasswordLoginType;
};

document.addEventListener('DOMContentLoaded', () => {
  registerComponentForJsonDataWithUIKit('NewPartitionLogin', NewPartitionLogin);
});
