import React from "react";
import { chakra } from "@chakra-ui/react";
import {
  isButtonOption,
  isLinkOption,
  useComboboxContext,
} from "./ComboboxContext";
import { ComboboxItem } from "./ComboboxItem";

export const ComboboxItems = () => {
  const {
    combobox,
    selectedOptions: selectedOptions,
    options: options,
    isEqual,
  } = useComboboxContext();
  const { getMenuProps, highlightedIndex } = combobox;

  return (
    <chakra.ul
      {...getMenuProps(
        {
          display: "flex",
          flexDirection: "column",
          gap: 2,
          listStyleType: "none",
          overflowY: "auto",
        },
        { suppressRefError: true },
      )}
    >
      {options.map((item, index) => {
        const isSelected = isEqual
          ? (selectedOptions || []).some((x) => isEqual(x, item))
          : (selectedOptions || []).includes(item);
        const isHighlighted = highlightedIndex === index;

        let itemKey = item.value ? item.value : index;

        if (isButtonOption(item)) {
          itemKey = item.key;
        } else if (isLinkOption(item)) {
          itemKey = item.href;
        }

        return (
          <chakra.li key={itemKey}>
            <ComboboxItem
              item={item}
              index={index}
              isHighlighted={isHighlighted}
              isSelected={isSelected}
            >
              {item.label}
            </ComboboxItem>
          </chakra.li>
        );
      })}
    </chakra.ul>
  );
};
