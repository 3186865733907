import React from "react";
import { Box } from "@chakra-ui/react";
import { languageOptions } from "./theme/themeOptions";
import { manaCodeBlockThemeLight } from "./theme/mana";
import IconButton from "../IconButton/IconButton";
import { FALCopy } from "../../icons/FontAwesomeIcons";
import { EditorView, lineNumbers, ViewUpdate } from "@codemirror/view";
import { EditorState } from "@codemirror/state";
import { foldGutter, StreamLanguage } from "@codemirror/language";
import { css } from "@codemirror/lang-css";
import { html } from "@codemirror/lang-html";
import { markdown } from "@codemirror/lang-markdown";
import { java } from "@codemirror/lang-java";
import { javascript } from "@codemirror/lang-javascript";
import { json } from "@codemirror/lang-json";
import { shell } from "@codemirror/legacy-modes/mode/shell";
import { useNonce } from "../ManaUIProvider/NonceProvider";
import { useToast } from "../Toast/hooks";
import { useTranslation } from "../ManaUIProvider";

export interface CodeBlockProps {
  code: string;
  copyLabel?: string;
  language: keyof typeof languageOptions;
  hideLineNumbers?: boolean;
  disableCodeFolding?: boolean;
  disableMaxHeight?: boolean;
  enableLineWrapping?: boolean;
  editable?: boolean;
  onCodeChange?: (codeLines: string[]) => void;
}

export default function CodeBlock(props: CodeBlockProps) {
  const {
    code,
    language,
    hideLineNumbers,
    disableCodeFolding,
    disableMaxHeight,
    enableLineWrapping,
    copyLabel,
    editable,
    onCodeChange,
    ...rest
  } = props;

  const nonce = useNonce();
  const { copyLabelDefault, confirmation } = useTranslation("CodeBlock");

  const codemirrorElem = React.useRef(null);
  const toast = useToast();
  const onCopy = () => {
    toast({
      ...confirmation,
      status: "success",
    }),
      navigator.clipboard.writeText(code);
  };

  const customEditorStyles = EditorView.theme({
    "&": {
      padding: "1rem",
      paddingRight: "3rem",
      fontFamily: "var(--chakra-fonts-mono)",
      borderWidth: "var(--chakra-borderWidths-xs)",
      borderStyle: "solid",
      borderColor: "var(--chakra-colors-borders-neutral-soft-resting)",
      borderRadius: "var(--chakra-radii-sm)",
      ...(disableMaxHeight ? {} : { maxHeight: "37.5rem" }),
      ...(editable ? {
        "&:hover": {
          borderColor: "var(--chakra-colors-borders-primary-strong-hover)",
          boxShadow: "var(--chakra-shadows-gray)",
        },
      } : {}),

      "&:focus-within": {
        borderColor: "var(--chakra-colors-borders-primary-strong-active)",
        boxShadow:
          "var(--chakra-shadows-focus), inset 0 0 0 0.075rem var(--chakra-colors-borders-primary-strong-active)",
        outline: "none",
        "&:invalid": {
          borderColor: "var(--chakra-colors-borders-danger-strong-resting)",
          boxShadow: "var(--chakra-shadows-error)",
          "&:not(:placeholder-shown)": {
            boxShadow:
              "var(--chakra-shadows-error), inset 0 0 0 0.075rem var(--chakra-colors-borders-danger-strong-active)",
            "&:hover": {
              borderColor: "var(--chakra-colors-borders-danger-strong-active)",
              boxShadow:
                "var(--chakra-shadows-error), inset 0 0 0 0.075rem var(--chakra-colors-borders-danger-strong-active)",
            },
          },
        },
        "&:not(:placeholder-shown)": {
          boxShadow:
            "var(--chakra-shadows-focus), inset 0 0 0 0.075rem var(--chakra-colors-borders-primary-strong-active)",
          borderColor: "var(--chakra-colors-borders-primary-strong-active)",
        },
      },
    },
    ".cm-line": {
      padding: "0 1.25rem",
      lineHeight: "2",
    },
    ".cm-gutters": {
      borderRight: "none",
    },
    ".cm-gutter": {
      lineHeight: "2",
    },
  });

  React.useEffect(() => {
    const getLanguageHighlighting = (language: keyof typeof languageOptions) => {
      switch (language) {
        case "css":
          return css();
        case "shell":
          return StreamLanguage.define(shell);
        case "html":
          return html();
        case "java":
          return java();
        case "javascript":
          return javascript();
        case "json":
          return json();
        case "jsx":
          return javascript({ jsx: true });
        case "markdown":
          return markdown();
        case "typescript":
          return javascript({ typescript: true });
        default:
          return javascript();
      }
    };

    const view = new EditorView({
      state: EditorState.create({
        doc: code,
        extensions: [
          EditorView.cspNonce.of(nonce),
          EditorView.editable.of(editable ?? false),
          onCodeChange ? EditorView.updateListener.of((v: ViewUpdate) => {
            if (v.docChanged) {
              onCodeChange(v.state.doc.toJSON());
            }
          }) : [],
          hideLineNumbers ? [] : lineNumbers(),
          disableCodeFolding ? [] : foldGutter(),
          enableLineWrapping ? EditorView.lineWrapping : [],
          manaCodeBlockThemeLight,
          customEditorStyles,
          getLanguageHighlighting(language),
        ],
      }),
      parent: codemirrorElem.current!,
    });
    return function cleanup() {
      view.destroy();
    };
  }, [
    code,
    language,
    hideLineNumbers,
    disableCodeFolding,
    customEditorStyles,
    nonce,
    editable,
    onCodeChange,
  ]);

  return (
    <Box w="full" position="relative">
      {
        !editable && (
          <IconButton
            icon={<FALCopy />}
            aria-label={copyLabel ?? copyLabelDefault}
            tooltipLabel={copyLabel ?? copyLabelDefault}
            size="lg"
            variant="outline.soft"
            shape="round"
            position="absolute"
            zIndex={1}
            top={4}
            right={4}
            onClick={onCopy}
          />
        )
      }
      <div ref={codemirrorElem} {...rest} />
    </Box>
  );
}
