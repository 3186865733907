import { avatarAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle } = createMultiStyleConfigHelpers(avatarAnatomy.keys);

/**
 * Colors pulled from the Avatar component's 'Initials Palette' in Figma.
 */
const avatarColors = [
  {
    background: "components.avatar.bgInitials1",
    color: "text.onPrimarySoft",
  },
  {
    background: "components.avatar.bgInitials2",
    color: "components.avatar.textOnInitialsSoft",
  },
  {
    background: "components.avatar.bgInitials3",
    color: "components.avatar.textOnInitialsSoft",
  },
  {
    background: "components.avatar.bgInitials4",
    color: "components.avatar.textOnInitialsStrong",
  },
  {
    background: "components.avatar.bgInitials5",
    color: "components.avatar.textOnInitialsSoft",
  },
  {
    background: "components.avatar.bgInitials6",
    color: "components.avatar.textOnInitialsSoft",
  },
  {
    background: "components.avatar.bgInitials7",
    color: "components.avatar.textOnInitialsStrong",
  },
  {
    background: "components.avatar.bgInitials8",
    color: "components.avatar.textOnInitialsStrong",
  },
  {
    background: "components.avatar.bgInitials9",
    color: "components.avatar.textOnInitialsSoft",
  },
  {
    background: "components.avatar.bgInitials10",
    color: "components.avatar.textOnInitialsSoft",
  },
  {
    background: "components.avatar.bgInitials11",
    color: "components.avatar.textOnInitialsSoft",
  },
  {
    background: "components.avatar.bgInitials12",
    color: "components.avatar.textOnInitialsSoft",
  },
  {
    background: "components.avatar.bgInitials13",
    color: "components.avatar.textOnInitialsStrong",
  },
  {
    background: "components.avatar.bgInitials14",
    color: "components.avatar.textOnInitialsSoft",
  },
  {
    background: "components.avatar.bgInitials15",
    color: "components.avatar.textOnInitialsSoft",
  },
  {
    background: "components.avatar.bgInitials16",
    color: "components.avatar.textOnInitialsStrong",
  },
];

const baseStyle = definePartsStyle((props) => {
  const { as, name, index, src } = props;
  const withinAvatarGroup = props["data-within-avatar-group"];

  const safeIndex = (index || 0) % avatarColors.length;
  const avatarColor = name
    ? {
        background: avatarColors[safeIndex].background,
        color: avatarColors[safeIndex].color,
      }
    : {
        background: "surfaces.neutral.strong.resting",
        color: "icon.onNeutralStrong",
      };
  let avatarBorderColor = "";

  const shadow = src
    ? "inset 0 0 0 0.75px var(--chakra-colors-blackAlpha-200)"
    : undefined;

  if (withinAvatarGroup) {
    avatarBorderColor = "surfaces.page.white";
  } else if (!withinAvatarGroup) {
    if (!name) {
      avatarBorderColor = "surfaces.neutral.strong.resting";
    } else {
      avatarBorderColor = avatarColor.background;
    }
  }

  return {
    badge: {
      bg: "pairs.green.400",
      border: "none",
    },
    container: {
      bg: avatarColor.background,
      borderColor: avatarBorderColor,
      border: !withinAvatarGroup && src ? "none" : undefined,
      color: avatarColor.color,
      cursor: "default",
      fontWeight: "normal",
      transition: "box-shadow 200ms",
      position: "relative",
      _before: {
        content: src ? "''" : undefined,
        borderRadius: "full",
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        shadow: shadow,
      },
      _hover: {
        shadow:
          as == "button"
            ? `0 0 0 0.05rem var(--chakra-colors-text-primary)${src ? `, ${shadow}` : ""}`
            : undefined,
      },
      _focus: {
        shadow: as == "button" ? "focus-xs" : undefined,
      },
      _focusVisible: {
        shadow: as == "button" ? "focus-xs" : undefined,
        outline: "none",
      },
      _disabled: {
        opacity: 0.5,
        cursor: "not-allowed",
        _hover: {
          shadow: "none",
        },
      },
    },
    excessLabel: {
      bg: "surfaces.neutral.strong.resting",
      borderColor: "surfaces.page.white",
      borderRadius: "full",
      borderStyle: "solid",
      color: "icon.onNeutralStrong",
      cursor: "default",
      fontWeight: "normal",
    },
  };
});

export default baseStyle;
