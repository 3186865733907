import { tabsAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import typography from "../../../clayTheme/foundations/typography";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

const baseStyle = definePartsStyle((props) => ({
  tab: {
    _disabled: {
      opacity: 1,
    },
    "& > span": {
      display: "flex",
      alignItems: "center",
      gap: 2,
    },
    "& .chakra-icon": {
      fontSize: "sm",
      _onlyChild: {
        fontSize: "lg",
      },
    },
  },
  tablist: {
    gap: props.orientation === "vertical" ? 4 : 8,
    borderBottom: "none",
  },
}));

const tabBorder = (
  background: string,
  orientation: "vertical" | "horizontal" | undefined,
) => {
  const positioning =
    orientation === "vertical"
      ? { bottom: 0, left: 0, top: 0 }
      : { bottom: 0, left: 0, right: 0 };

  const sizing =
    orientation === "vertical"
      ? { height: "full", width: "3px" }
      : { height: "3px" };

  return {
    _after: {
      content: '""',
      position: "absolute",
      ...positioning,
      background,
      ...sizing,
      borderRadius: "xl",
    },
  };
};

const sizes = {
  md: definePartsStyle({
    tab: {
      fontSize: "lg",
    },
  }),
};

const variants = {
  line: definePartsStyle((props) => {
    const { orientation } = props;
    const borderProperty =
      orientation === "vertical" ? "borderLeft" : "borderBottom";

    return {
      tablist: {
        [borderProperty]: "none",
      },
      tab: {
        justifyContent: orientation === "vertical" ? "start" : "center",
        paddingY: orientation === "vertical" ? 2 : 3,
        paddingX: orientation === "vertical" ? 4 : 0,
        height: "auto",
        position: "relative",
        color: "text.neutralStrong",
        ...typography.textStyles.lg,
        [borderProperty]: "none",
        _disabled: {
          color: "text.disabled",
          _hover: {
            _after: {
              content: "none",
            },
          },
        },
        _selected: {
          color: "text.neutralDominant",
          [borderProperty]: "none",
          ...tabBorder("borders.primary.strong.resting", orientation),
          _hover: {
            ...tabBorder("borders.primary.strong.resting", orientation),
          },
        },
        _hover: {
          ...tabBorder("borders.neutral.soft.hover", orientation),
        },
        _focusVisible: {
          shadow: "none",
          "& > *": {
            shadow: "focus",
            borderRadius: "full",
          },
        },
      },
    };
  }),
  "soft-rounded": definePartsStyle((props) => {
    const { orientation } = props;

    return {
      tablist: {
        alignItems: orientation === "vertical" ? "start" : "center",
      },
      tab: {
        paddingY: 2,
        paddingX: 4,
        height: "auto",
        ...typography.textStyles.lg,
        fontWeight: "normal",
        color: "text.neutralStrong",
        _hover: {
          background: "surfaces.neutral.strong.hover",
        },
        _disabled: {
          color: "text.disabled",
          _hover: {
            background: "transparent",
          },
        },
        _focusVisible: {
          shadow: "focus",
        },
        _selected: {
          color: "text.neutralDominant",
          background: "surfaces.neutral.strong.resting",
          _hover: {
            background: "surfaces.neutral.strong.resting",
          },
        },
      },
    };
  }),
};

const Tabs: ReturnType<typeof defineMultiStyleConfig> = defineMultiStyleConfig({
  baseStyle,
  defaultProps: {
    variant: "line",
  },
  variants,
  sizes,
});

export default Tabs;
