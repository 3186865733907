import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from "@chakra-ui/styled-system";

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(["container"]);

const baseStyle = definePartsStyle({
  container: defineStyle({
    minHeight: 8,
    alignItems: "stretch",
    "& > *": {
      height: "auto",
    },
    "& > :not(:first-of-type):last-of-type": {
      borderRadius: "md",
    },
  }),
});

const ActiveFilter: ReturnType<typeof defineMultiStyleConfig> =
  defineMultiStyleConfig({
    baseStyle,
  });

export default ActiveFilter;
