import React from "react";
import { Flex, useStyleConfig } from "@chakra-ui/react";
import {variantOptions} from "./theme/themeOptions";

interface FillLayoutProps extends React.PropsWithChildren {
  variant?: variantOptions,
}

export const FillLayout = (props: FillLayoutProps) => {
  const styles = useStyleConfig("FillLayout", props);

  return (
    <Flex className="umm" __css={styles}>
      <Flex
        w="full"
        h="full"
        flexDirection="column"
        flexGrow="1"
        overflowY="auto"
        flexBasis="0"
        px={[5, 5, 8, 8, 10]}
      >
        {props.children}
      </Flex>
    </Flex>
  );
};
