import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

const helpers = createMultiStyleConfigHelpers([
  "menu",
  "input",
  "container",
  "inputGroup",
]);

const Labels = helpers.defineMultiStyleConfig({
  baseStyle: () => {
    return {
      inputGroup: {
        borderWidth: "xs",
        borderColor: "borders.neutral.soft.resting",
        fontSize: "md",
        borderRadius: "md",
        paddingX: "2",
        paddingY: "2",
        display: "flex",
        alignItems: "center",
        _hover: {
          borderColor: "borders.primary.strong.hover",
          shadow: "gray",
          _invalid: {
            borderColor: "borders.danger.strong.hover",
            shadow: "error",
          },
          "&[data-is-disabled='true']": {
            borderColor: "borders.neutral.soft.resting",
            shadow: "none",
          },
        },
        _focusWithin: {
          shadow: "focus",
          borderColor: "borders.secondary.soft.resting",
          outline: "none",
          _hover: {
            borderColor: "borders.primary.strong.hover",
          },
          _invalid: {
            borderColor: "borders.danger.strong.resting",
            shadow: "error",
            _hover: {
              borderColor: "borders.danger.strong.hover",
            },
          },
        },
        _disabled: {
          opacity: "0",
          cursor: "not-allowed",
        },
        "&[data-has-value='true']": {
          boxShadow:
            "0 0 0 .0625rem var(--chakra-colors-borders-primary-strong-active) inset",
          _hover: {
            boxShadow:
              "0 0 0 .0625rem var(--chakra-colors-borders-primary-strong-hover) inset",
          },
          _disabled: {
            boxShadow:
              "0 0 0 .0625rem var(--chakra-colors-borders-disabled-strong) inset",
          },
        },
      },
      menu: {
        bg: "surfaces.page.white",
        position: "absolute",
        zIndex: "modal",
        shadow: "lg",
        listStyle: "none",
        width: "100%",
        padding: "0",
        margin: "4px 0 0 0",
        maxHeight: 56,
        overflowY: "scroll",
      },
    };
  },
});

export default Labels;
