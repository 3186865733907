import React from "react";
import type { SystemStyleObject } from "@chakra-ui/styled-system";
import { cx } from "@chakra-ui/utils";
import {
  Flex,
  useAccordionItemState,
  useAccordionStyles,
  useAccordionContext,
} from "@chakra-ui/react";
import { FALChevronRight } from "../../icons";
import { IconProps } from "../../icons";

export type AccordionIconProps = IconProps;

/**
 * AccordionIcon that gives a visual cue of the open/close state of the accordion item.
 * It rotates `180deg` based on the open/close state.
 */

export function AccordionIcon(props: AccordionIconProps) {
  const { isOpen } = useAccordionItemState();
  const { reduceMotion } = useAccordionContext();

  const _className = cx("chakra-accordion__icon", props.className);
  const styles = useAccordionStyles();

  const iconWrapperStyles: SystemStyleObject = {
    transform: isOpen ? "rotate(90deg)" : undefined,
    transition: reduceMotion ? undefined : "transform 0.2s",
    transformOrigin: "center",
    ...styles.icon,
  };

  return (
    <Flex
      display="flex"
      justifyContent="center"
      alignItems="center"
      __css={iconWrapperStyles}
      className={_className}
    >
      <FALChevronRight aria-hidden {...props} />
    </Flex>
  );
}

AccordionIcon.displayName = "AccordionIcon";
