import React from "react";
import {
  DatePickerModalProps as SaasDatePickerModalProps,
  DatePickerStatic,
  DatePickerCalendar,
} from "@saas-ui/date-picker";
import { ButtonGroup, VStack } from "@chakra-ui/react";
import { Button } from "../Button";
import { useDatePickerModal } from "./useDatePickerModal";
import { useTranslation } from "../ManaUIProvider";
import { Modal } from "@saas-ui/react";

export interface DatePickerModalProps extends SaasDatePickerModalProps {}

export default function DatePickerModal(props: DatePickerModalProps) {
  const { children, ...rest } = props;
  const { onClose, onSubmit, modalProps, datePickerProps } =
    useDatePickerModal(rest);
  const translations = useTranslation("DatePicker");

  const footer = (
    <ButtonGroup>
      <Button variant="ghost" onClick={onClose}>
        {translations.cancel}
      </Button>
      <Button variant="primary" onClick={onSubmit}>
        {translations.submit}
      </Button>
    </ButtonGroup>
  );

  return (
    <Modal
      title={translations.title}
      size="xs"
      footer={footer}
      motionPreset="none"
      {...rest}
      {...modalProps}
    >
      <VStack>
        <DatePickerStatic {...datePickerProps}>
          <DatePickerCalendar />
          {children}
        </DatePickerStatic>
      </VStack>
    </Modal>
  );
}
