import React from "react";
import {
  CircularProgress as ChakraCircularProgress,
  CircularProgressLabel as ChakraCircularProgressLabel,
  CircularProgressProps as ChakraCircularProgressProps,
} from "@chakra-ui/react";
import { sizeOptions, variantOptions } from "./theme/themeOptions";
import typography from "../../clayTheme/foundations/typography";
import space from "../../clayTheme/foundations/spacing";

export interface CircularProgressProps
  extends Omit<
    ChakraCircularProgressProps,
    | "capIsRound"
    | "children"
    | "isIndeterminate"
    | "max"
    | "min"
    | "size"
    | "thickness"
    | "valueText"
  > {
  size: keyof typeof sizeOptions;
  variant: keyof typeof variantOptions;
  isGaugeMode?: boolean | undefined;
  gaugeThresholds?: number[];
}

export default function CircularProgress(props: CircularProgressProps) {
  const {
    size,
    value,
    variant,
    isGaugeMode,
    gaugeThresholds = [40, 70],
    ...rest
  } = props;

  const trackColor =
    variant === variantOptions.whiteAlpha
      ? "pairs.alpha.alphaWhite200"
      : "pairs.alpha.alphaBlack100";

  const labelColor =
    variant === variantOptions.whiteAlpha
      ? "whiteAlpha.700"
      : "text.neutralDominant";

  const resolvedSize = size === sizeOptions.lg ? space["8"] : space["12"];
  const resolvedLabelSize =
    size === sizeOptions.lg ? "0.5rem" : typography.fontSizes.xs;

  const filledTrackColorOptions: Record<variantOptions, string> = {
    [variantOptions.neutral]: "surfaces.neutral.dominant.resting",
    [variantOptions.primary]: "surfaces.primary.strong.resting",
    [variantOptions.secondary]: "borders.secondary.strong.resting",
    [variantOptions.success]: "surfaces.success.dominant.resting",
    [variantOptions.warning]: "surfaces.warning.strong.resting",
    [variantOptions.danger]: "borders.danger.strong.resting",
    [variantOptions.blackAlpha]: "pairs.alpha.alphaBlack600",
    [variantOptions.whiteAlpha]: "pairs.alpha.alphaWhite600",
  };

  const roundedValue = !value ? 0 : Math.round(value);

  const getFilledTrackColor = (variant: keyof typeof variantOptions) => {
    let filledTrackColor = "";

    if (!isGaugeMode) {
      filledTrackColor = filledTrackColorOptions[variant];
      return filledTrackColor;
    }

    if (roundedValue <= gaugeThresholds[0]) {
      filledTrackColor = "borders.danger.strong.resting";
    } else if (
      roundedValue > gaugeThresholds[0] &&
      roundedValue <= gaugeThresholds[1]
    ) {
      filledTrackColor = "surfaces.warning.strong.resting";
    } else {
      filledTrackColor = "surfaces.success.dominant.resting";
    }

    return filledTrackColor;
  };

  return (
    <ChakraCircularProgress
      trackColor={trackColor}
      color={getFilledTrackColor(variant)}
      value={roundedValue}
      max={100}
      min={0}
      size={resolvedSize}
      {...rest}
    >
      <ChakraCircularProgressLabel
        sx={{
          fontVariantNumeric: "tabular-nums",
          color: labelColor,
          fontSize: resolvedLabelSize,
        }}
      >
        {roundedValue}%
      </ChakraCircularProgressLabel>
    </ChakraCircularProgress>
  );
}
