import { formErrorAnatomy as parts } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from "@chakra-ui/styled-system";
import typography from "../../../clayTheme/foundations/typography";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyleText = defineStyle({
  color: "text.danger",
  mt: 0,
  ...typography.textStyles.xs,
});

const baseStyle = definePartsStyle({
  text: baseStyleText,
});

const FormError: ReturnType<typeof defineMultiStyleConfig> =
  defineMultiStyleConfig({
    baseStyle,
  });

export default FormError;
