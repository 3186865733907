import React from "react";
import { Box } from "@chakra-ui/react";
import { useComboboxContext } from "./ComboboxContext";

interface CountBadgeProps {
  count: number;
}

export function CountBadge(props: CountBadgeProps) {
  const ctx = useComboboxContext();

  const baseStyles = {
    borderRadius: "sm",
    px: 1,
    py: 0.5,
    textStyle: "xs-medium",
  };
  const disabledStyles = { bg: "surfaces.neutral.strong.resting" };
  const activeStyles =
    ctx.variant === "ghost"
      ? {
          borderColor: "borders.neutral.soft.resting",
          borderWidth: "xs",
          borderStyle: "solid",
        }
      : { bg: "surfaces.secondary.strong.resting" };

  const sx = {
    ...baseStyles,
    ...(ctx.isDisabled ? disabledStyles : activeStyles),
  };

  return <Box sx={sx}>{props.count}</Box>;
}
