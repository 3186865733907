import {registerComponent, registerComponentForJsonData, createReactComponent} from '/ui/lib/easyreact';
import {yext} from '/ui/lib/msg';

const {ManaUIProvider} = require('@yext/mana-ui');

/**
 * Create your React components, wrapped automatically with
 * in the UIKit provider.
 *
 * This will introduce global styles for font, line height, etc.
 *
 * USAGE:
 * import {createComponentWithUIKit} from '/ui/lib/easyreact.jsx';
 * import MyComponent from './MyComponent';
 *
 * createComponentWithUIKit({
 *  'WhateverTheReactIdAttributeIs': MyComponent,
 * });
 *
 * It is recommended (but not required) to use the same react ID as the name of the component.
 *
 * @param {!Object<string, ?>} componentMap A map of react ID to the component that will be rendered.
 */
export function createComponentWithUIKit(componentMap) {
  Object.entries(componentMap).forEach(([key, value]) => {
    componentMap[key] = props => <UIKitRenderer>{React.createElement(value, props)}</UIKitRenderer>;
  });
  createReactComponent(componentMap);
}

/**
 * Register a component to be rendered with protocol buffer data and
 * automatically wrapped in the UIKit provider.
 *
 * This will introduce global styles for font, line height, etc.
 *
 * Intended to be used with the renderWithProto method in
 * src/com/yext/play/common/app/controllers/React.java
 *
 * USAGE:
 * import {registerComponentWithUIKit} from '/ui/lib/easyreact.jsx';
 * import OverviewData from 'goog:proto.foostorm.OverviewData';
 *
 * export function MyComponent({data}) {
 *   // your stuff here
 * }
 *
 * registerComponentWithUIKit('MyComponent', MyComponent, {data: OverviewData.deserializeBinary});
 *
 * @param {string} name
 * @param {?} component
 * @param {?} propInfo
 */
export function registerComponentWithUIKit(name, component, propInfo) {
  registerComponent(name, props => <UIKitRenderer>{React.createElement(component, props)}</UIKitRenderer>, propInfo);
}

/**
 * Register a component to be rendered with JSON data automatically
 * wrapped in the UIKit provider.
 *
 * This will introduce global styles for font, line height, etc.
 *
 * Prefer using the registerComponent function instead of this because
 * registerComponent provides better type-safety.
 *
 * Intended to be used with the render method in
 * src/com/yext/play/common/app/controllers/React.java
 *
 * USAGE:
 * import {registerComponentForJsonDataWithUIKit} from '/ui/lib/easyreact.jsx';
 *
 * export function MyComponent(props) {
 *   // your stuff here
 * }
 *
 * registerComponentForJsonDataWithUIKit('MyComponent', MyComponent);
 *
 * @param {string} name
 * @param {?} component
 */
export function registerComponentForJsonDataWithUIKit(name, component) {
  registerComponentForJsonData(name, props => <UIKitRenderer>{React.createElement(component, props)}</UIKitRenderer>);
}

/**
 * @param {{
 *   children: (React.ReactNode|undefined),
 * }} props
 *
 * @return {?}
 */
export function UIKitRenderer(props) {
  /**
    * Set a nonce for libraries that expect webpack style nonce variable
    * A chakra dependency, react-remove-scroll depends on this behavior
    * Dependency chain:
    *  * https://github.com/chakra-ui/chakra-ui/blob/71983c77422541a53ac0645f268b2faed2be63e1/packages/components/package.json#L50
    *  * https://github.com/theKashey/react-remove-scroll
    *  * https://github.com/theKashey/react-style-singleton
    */

  window['__webpack_nonce__'] = window['cspStyleNonce'];
  const translations = {
    SuiDataGrid: {
      page: yext.msgc('Used when displaying pagination information in a data grid: \'Page x of y\' or \'Show x / Page\'', 'Page'),
      of: yext.msgc('Used when displaying the number of pages in a data grid: \'Page x of y\'', 'of'),
      nextPage: yext.msgc('Label for a button that will move the user to the next page of a data grid', 'Next page'),
      previousPage: yext.msgc('Label for a button that will move the user to the previous page of a data grid', 'Previous page'),
      expandRows: yext.msgc('Label for a button that will act on a data grid with collapsible rows', 'Expand all rows'),
      collapseRows: yext.msgc('Label for a button that will act on a data grid with collapsible rows', 'Collapse all rows'),
      deselectAllRows: yext.msgc('Label for a control that will deselect all rows of a data grid', 'Deselect all rows'),
      selectAllRows: yext.msgc('Label for a control that will select all rows of a data grid', 'Select all rows'),
      selectRow: yext.msgc('Label for a control that will select a single row of a data grid', 'Select row'),
      deselectRow: yext.msgc('Label for a control that will deselect a single row of a data grid', 'Deselect row'),
      sortAscending: yext.msgc('Label for a control that will sort a data grid\'s rows in an ascending order using values in the current column', 'Sort ascending'),
      sortDescending: yext.msgc('Label for a control that will sort a data grid\'s rows in an descending order using values in the current column', 'Sort descending'),
      show: yext.msgc('Used when pagination information in a data grid: \'Show x / Page\'', 'Show'),
      noResultsTitle: yext.msgc('Message displayed when a search matches no results.', 'No Results Found'),
      noResultsDescription: yext.msgc('Instructions displayed when a search matches no results.', 'Try adjusting your search.'),
      clearFilters: yext.msgc('Label on a button that will clear currently applied filters', 'Clear Filters'),
    },
    BulkActions: {
      selectedItem: yext.msgc('text indicating how many items are selected', '{0} selected item', ':selections'),
      selectedItems: yext.msgc('text indicating how many items are selected', '{0} selected items', ':selections'),
      selectAll: yext.msgc('Label for a switch to select all items', 'Select All'),
      close: yext.msgc('Label for a button to close the component', 'Close'),
    },
    Breadcrumb: {
      home: yext.msgc('Text for the root link of a breadcrumb navigation links which will return the user to the home page of their account', 'Home'),
    },
    Combobox: {
      placeholder: yext.msgc('Placeholder text for a text input used to filter options', 'Search'),
      selectAll: yext.msgc('Label for a button to select all options in the menu', 'Select All'),
      deselectAll: yext.msgc('Label for a button to select all options in the menu', 'Deselect All'),
      clear: yext.msgc('Label for a button to apply selected options', 'Clear'),
      apply: yext.msgc('Label for a button to clear selected options', 'Apply'),
    },
    SuiCommandBar: {
      clear: yext.msgc('Label for a button that will clear the value of a text input', 'Clear'),
      close: yext.msgc('Label for a button that will close the command bar', 'Close'),
    },
    DatePicker: {
      cancel: yext.msgc('Label for a button that will close an input control', 'Cancel'),
      submit: yext.msgc('Label for a button that will confirm a user\'s input selection', 'Submit'),
      title: yext.msgc('Prompt for a user to pick a date from a calendar input', 'Select a date'),
    },
    Drawer: {
      toggleExpand: yext.msgc('Label for a button that will expand a drawer', 'Toggle Expand'),
      moreActions: yext.msgc('Label for a button that opens a menu of available actions', 'More Actions'),
      close: yext.msgc('Label for a button that will close a drawer', 'Close'),
    },
    Labels: {
      noResults: yext.msgc('Message shown when no results in an autocomplete dropdown match the user\'s current input', 'No matching results'),
    },
    SearchInput: {
      placeholder: yext.msgc('Placeholder for search input', 'Search'),
    },
    Filters: {
      advanced: yext.msgc('Heading for a section display advanced data filters', 'Advanced'),
      filters: yext.msgc('Heading for section displaying data filters', 'Filters'),
      addFilter: yext.msgc('Label for button that adds a filter', 'Add Filter'),
      clear: yext.msgc('Label for button that clears active filters', 'Clear'),
      resetToDefaults: yext.msgc('Label for button that resets filters to their default state', 'Reset to Defaults'),
      apply: yext.msgc('Label for a button that applies filter options', 'Apply'),
      applyFilters: yext.msgc('Label for button that applies filters', 'Apply Filters'),
      view: yext.msgc('Label for button that provides controls to change the visible columns of a data grid', 'View'),
      isOperator: yext.msgc('Label describing how a filter compares data to a selected filter value, as in: "{filter field} {label} {filter value}', 'is'),
      isNotOperator: yext.msgc('Label describing how a filter compares data to a selected filter value, as in: "{filter field} {label} {filter value}', 'is not'),
      containsOperator: yext.msgc('Label describing how a filter compares data to a selected filter value, as in: "{filter field} {label} {filter value}', 'contains'),
      containsNotOperator: yext.msgc('Label describing how a filter compares data to a selected filter value, as in: "{filter field} {label} {filter value}', 'does not contain'),
      lessThanOperator: yext.msgc('Label describing how a filter compares data to a selected filter value, as in: "{filter field} {label} {filter value}', 'less than'),
      moreThanOperator: yext.msgc('Label describing how a filter compares data to a selected filter value, as in: "{filter field} {label} {filter value}', 'more than'),
      beforeOperator: yext.msgc('Label describing how a filter compares data to a selected filter value, as in: "{filter field} {label} {filter value}', 'before'),
      afterOperator: yext.msgc('Label describing how a filter compares data to a selected filter value, as in: "{filter field} {label} {filter value}', 'after'),
      selectAll: yext.msgc('Label for a button to select all options in the menu', 'Select All'),
      clearAll: yext.msgc('Label for a button to clear all options in the menu', 'Clear All'),
    },
    Layout: {
      previous: yext.msgc('Label for a button that moves focus to a previous item', 'Previous'),
      next: yext.msgc('Label for a button that moves focus to a next item', 'Next'),
      actions: yext.msgc('Label for a button that opens an action menu', 'Additional actions'),
      close: yext.msgc('Label for a button that closes a layout panel', 'Close'),
      toggleMenu: yext.msgc('Label for a button that toggles a menu in a layout panel', 'Toggle Menu'),
    },
    CodeBlock: {
      copyLabelDefault: yext.msgc('label for a button that copies text to clipboard', 'copy to clipboard'),
      confirmation: {
        title: yext.msgc('title for a notification that displays after copying text to a clipboard', 'Copied!'),
        description: yext.msgc('description for a notification that displays after copying text to a clipboard', 'Code successfully copied to clipboard.'),
      },
    },
    TextAreaActions: {
      emojis: yext.msgc('prompt to select an emoji', 'Select an emoji'),
      embeddedFields: yext.msgc('prompt to select an embedded field', 'Select an embedded field'),
    },
    Avatar: {
      anonymous: yext.msg('Anonymous'),
    },
    Timestamp: {
      underOneMinuteLabel: yext.msg('just now'),
    },
    CloseButton: {
      ariaLabel: yext.msgc('Label for a button that will close an element like drawer or modal', 'Close'),
    },
    Banner: {
      close: yext.msgc('Label for a button that will close an banner', 'Close'),
      retry: yext.msgc('Label for a button that will retry a failed function', 'Retry'),
      learnMore: yext.msgc('Label for a link that that takes to user to a learn more page', 'Learn more'),
    },
    Note: {
      prefix: yext.msg('Nowz:'),
    },
  };
  return (
    <React.StrictMode>
      <ManaUIProvider nonce={window['cspStyleNonce']} activateGlobalStyles translations={translations}>
        {props.children}
      </ManaUIProvider>
    </React.StrictMode>
  );
}
