import React from "react";
import {
  Accordion as ChakraAccordion,
  AccordionProps as ChakraAccordionProps,
} from "@chakra-ui/react";

export interface AccordionProps extends ChakraAccordionProps {}

export default function Accordion(props: AccordionProps) {
  return <ChakraAccordion allowToggle {...props} />;
}
