export enum variantOptions {
  neutral = "neutral",
  primary = "primary",
  secondary = "secondary",
  success = "success",
  warning = "warning",
  danger = "danger",
  blackAlpha = "blackAlpha",
  whiteAlpha = "whiteAlpha",
}

export enum sizeOptions {
  lg = "lg",
  "2xl" = "2xl",
}
