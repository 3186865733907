import { IconProps } from "@chakra-ui/react";
import {
  FALBoxOpen,
  FALBriefcase,
  FALCalendarDay,
  FALDoorClosed,
  FALFileCircleQuestion,
  FALForkKnife,
  FALHotel,
  FALHouseMedical,
  FALLocationDot,
  FALMessagesQuestion,
  FALMoneySimpleFromBracket,
  FALStethoscope,
  FALUsers,
  FALUserTieHair,
  FALA,
  FALB,
  FALC,
  FALD,
  FALE,
  FALF,
  FALG,
  FALH,
  FALI,
  FALJ,
  FALK,
  FALL,
  FALM,
  FALN,
  FALO,
  FALP,
  FALQ,
  FALR,
  FALS,
  FALT,
  FALU,
  FALV,
  FALW,
  FALX,
  FALY,
  FALZ,
} from "../../icons";
import { EntityType } from "./types";

const iconForLetter = new Map([
  ["A", FALA],
  ["B", FALB],
  ["C", FALC],
  ["D", FALD],
  ["E", FALE],
  ["F", FALF],
  ["G", FALG],
  ["H", FALH],
  ["I", FALI],
  ["J", FALJ],
  ["K", FALK],
  ["L", FALL],
  ["M", FALM],
  ["N", FALN],
  ["O", FALO],
  ["P", FALP],
  ["Q", FALQ],
  ["R", FALR],
  ["S", FALS],
  ["T", FALT],
  ["U", FALU],
  ["V", FALV],
  ["W", FALW],
  ["X", FALX],
  ["Y", FALY],
  ["Z", FALZ],
]);

const entityTypeNames: Map<EntityType, string> = new Map([
  [EntityType.Location, "Location"],
  [EntityType.HealthcareProfessional, "Healthcare Professional"],
  [EntityType.HealthcareFacility, "Healthcare Facility"],
  [EntityType.Event, "Event"],
  [EntityType.ATM, "ATM"],
  [EntityType.Restaurant, "Restaurant"],
  [EntityType.Organization, "Organization"],
  [EntityType.Hotel, "Hotel"],
  [EntityType.Job, "Job"],
  [EntityType.FAQ, "FAQ"],
  [EntityType.HotelRoomType, "Hotel Room Type"],
  [EntityType.HelpArticle, "Help Article"],
  [EntityType.Product, "Product"],
  [EntityType.FinancialProfessional, "Financial Professional"],
]);

const entityTypeIcons: Map<EntityType, (props: IconProps) => React.ReactNode> =
  new Map([
    [EntityType.Location, FALLocationDot],
    [EntityType.HealthcareProfessional, FALStethoscope],
    [EntityType.HealthcareFacility, FALHouseMedical],
    [EntityType.Event, FALCalendarDay],
    [EntityType.ATM, FALMoneySimpleFromBracket],
    [EntityType.Restaurant, FALForkKnife],
    [EntityType.Organization, FALUsers],
    [EntityType.Hotel, FALHotel],
    [EntityType.Job, FALBriefcase],
    [EntityType.FAQ, FALMessagesQuestion],
    [EntityType.HotelRoomType, FALDoorClosed],
    [EntityType.HelpArticle, FALFileCircleQuestion],
    [EntityType.Product, FALBoxOpen],
    [EntityType.FinancialProfessional, FALUserTieHair],
  ]);

function getOrDefault<K, V>(
  map: Map<K, V>,
  key: K | undefined,
  defaultValue: V,
) {
  if (key === undefined) return defaultValue;
  return map.get(key) || defaultValue;
}

export interface EntityTypeInfo {
  entityTypeName: string;
  Icon: (props: IconProps) => React.ReactNode;
}

type hookProps = { typeId?: EntityType; name?: string };

export const useEntityTypeInfo = ({
  typeId,
  name,
}: hookProps): EntityTypeInfo => {
  const entityTypeName = getOrDefault(entityTypeNames, typeId, name || "C");
  const firstLetter = entityTypeName[0].toUpperCase();
  const Icon = getOrDefault(
    entityTypeIcons,
    typeId,
    getOrDefault(iconForLetter, firstLetter, FALC),
  );

  return {
    entityTypeName,
    Icon,
  };
};
