import { tagAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { defineStyle } from "@chakra-ui/styled-system";
import { sizeOptions } from "./themeOptions";
import typography from "../../../clayTheme/foundations/typography";

const { definePartsStyle } = createMultiStyleConfigHelpers(tagAnatomy.keys);

const sm = defineStyle({
  height: 5,
  paddingX: 1.5,
  gap: 1,
  ...typography.textStyles.xs,
});

const md = defineStyle({
  height: 6,
  paddingX: 2.5,
  gap: 1.5,
  ...typography.textStyles.sm,
});

const lg = defineStyle({
  height: 8,
  paddingX: 3,
  gap: 2,
  ...typography.textStyles.md,
});

const xl = defineStyle({
  height: 10,
  paddingX: 3.5,
  gap: 2.5,
  ...typography.textStyles.lg,
});

const twoXl = defineStyle({
  height: 12,
  paddingX: 4,
  gap: 3,
  ...typography.textStyles.lg,
});

export default defineStyle({
  sizes: {
    [sizeOptions.sm]: definePartsStyle({ container: sm }),
    [sizeOptions.md]: definePartsStyle({ container: md }),
    [sizeOptions.lg]: definePartsStyle({ container: lg }),
    [sizeOptions.xl]: definePartsStyle({ container: xl }),
    [sizeOptions["2xl"]]: definePartsStyle({ container: twoXl }),
  },
});
