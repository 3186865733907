import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(["container", "inner", "top", "left", "right"]);

const baseStyle = definePartsStyle((props) => {
  const isOpen = props.isOpen;

  const rightPanelDisplay = {
    base: isOpen ? undefined : "none",
  };

  const rightPanelPosition = { base: "fixed", md: "static" };
  const rightBorder = isOpen
    ? {
        borderLeftWidth: "xs",
        borderLeftStyle: "solid",
        borderLeftColor: "borders.neutral.soft.resting",
      }
    : {};

  const rightWidth = {
    w: { base: isOpen ? "full" : "0%", md: isOpen ? "full" : "0%" },
  };

  return {
    container: {
      display: "flex",
      flexDirection: "column",
      h: "full",
      w: "full",
    },
    top: {
      px: [5, 5, 8, 8, 10],
    },
    inner: {
      display: "flex",
      justifyContent: "space-between",
      flexBasis: 0,
      minHeight: 0,
      flexGrow: 1,
    },
    left: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      w: { base: "full", md: !isOpen ? "full" : "512px" },
      overflowY: "auto",
    },
    right: {
      bg: "surfaces.page.white",
      zIndex: { base: "modal", md: 0 },
      overflowY: "auto",
      bottom: 0,
      top: 0,
      ...rightBorder,
      display: rightPanelDisplay,
      position: rightPanelPosition,
      overflowX: "hidden",
      ...rightWidth,
    },
  };
});

const InboxLayout = defineMultiStyleConfig({
  baseStyle,
});

export default InboxLayout;
