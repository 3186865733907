import React, { PropsWithChildren } from "react";
import { CacheProvider } from "@emotion/react";
import { useCache } from "./useEmotionCache";

export interface EmotionCacheProviderProps {
  nonce: string | undefined;
}

export const EmotionCacheProvider: React.FC<
  PropsWithChildren<EmotionCacheProviderProps>
> = ({ nonce, children }) => {
  const cache = useCache({ nonce });

  return <CacheProvider value={cache}>{children}</CacheProvider>;
};
