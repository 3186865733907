
import {isWhitelabeled} from '/src/com/yext/platform/users/js/newloginhelpers/logincomponents';
import {openCookieDrawer} from '/src/com/yext/storm/js/osano/osano';
import {yext} from '/ui/lib/msg';

const {
  FALCopyright,
  Link,
  Text,
  VStack,
} = require('@yext/mana-ui');

/**
 * Component to render the privacy policy and cookie preferences footer for the login page
 */
export default function FinePrint({whitelabelInfo}) {
  return (
    <VStack spacing={0.5} position={'relative'} bottom={4}>
      <Text fontSize={'xs'} color={'text.neutralStrong'}>
        {yext.msg('By using our site you agree to the terms of our')}
        {' '}
        <Link
          as={'a'}
          href={'https://www.yext.com/privacy-policy'}
          palette={'chameleon'}
          isExternal
        >
          {yext.msg('Privacy Policy')}
        </Link>
      </Text>
      <Text fontSize={'xs'} color={'text.neutralStrong'} spacing={1}>
        {!isWhitelabeled(whitelabelInfo)
          && <>
            <FALCopyright />
            {' '}
            {yext.msg('{0} Yext, Inc. All Rights Reserved.', new Date().getFullYear())}
            {' '}
          </>
        }
        <Link
          as={'button'}
          onClick={openCookieDrawer}
          palette={'chameleon'}
        >
          {yext.msg('Cookie Preferences')}
        </Link>
      </Text>
    </VStack>
  );
}
