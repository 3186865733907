import {
  ResponsiveArray,
  ResponsiveObject,
  ResponsiveValue,
  useBreakpointValue as useChakraBreakpointValue,
} from "@chakra-ui/react";

export function useBreakpointValue<T>(
  val: T | ResponsiveValue<T>,
): T | undefined {
  const normalized =
    typeof val === "string"
      ? ([val] as ResponsiveArray<T>)
      : (val as ResponsiveObject<T>);

  return useChakraBreakpointValue(normalized);
}
