import React from "react";
import { PopoverContent } from "@chakra-ui/react";
import { useComboboxContext } from "./ComboboxContext";
import { prefixDataAttr } from "./utilities";
import { maxHeight } from "src/components/Combobox/theme/themeOptions";

export interface ComboboxContentProps {
  maxHeight?: keyof typeof maxHeight;
  children: React.ReactNode;
}

export const ComboboxContent = (props: ComboboxContentProps) => {
  const { maxHeight = "lg" } = props;
  const { isOpen, onClose, triggerRef, popoverRef } = useComboboxContext();
  const keyId = React.useId();

  React.useEffect(() => {
    function closePopover(e: MouseEvent) {
      if (isOpen && e.target) {
        const targetEl = e.target as HTMLElement;
        const trigggerContainsTarget = refContainsElement(targetEl, triggerRef);
        const popoverContainsTarget = refContainsElement(targetEl, popoverRef);

        const prefixParentEl = targetEl.closest(`[${prefixDataAttr}]`);
        const isPrefixClick = !!prefixParentEl;

        if (
          !trigggerContainsTarget &&
          !popoverContainsTarget &&
          !isPrefixClick
        ) {
          onClose?.();
        }
      }
    }
    document.addEventListener("click", closePopover);
    return () => document.removeEventListener("click", closePopover);
  }, [isOpen, onClose, popoverRef, triggerRef]);
  
  return (
    <PopoverContent
      ref={popoverRef}
      maxHeight={maxHeight}
      key={`popover-content-${keyId}`}
    >
      {props.children}
    </PopoverContent>
  );
};

function refContainsElement(
  el: HTMLElement,
  ref: React.RefObject<HTMLElement> | undefined,
): boolean {
  if (ref?.current?.contains(el)) {
    return true;
  }

  if (ref?.current?.querySelector(`[id="${el.id}"]`)) {
    return true;
  }

  return false;
}
