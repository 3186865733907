import {
    defaultOperators as saasDefaultOperators,
    getDataGridFilter as baseGetDataGridFilter,
    FilterType,
    FilterOperatorId,
  } from "@saas-ui-pro/react";
  import { DateRange } from "./FiltersProvider";
  
  const betweenDatesOperator = {
    id: "betweenDates" as FilterOperatorId,
    label: "betweeenDates",
    // https://github.com/saas-js/saas-ui-pro/issues/106
    types: ["date-range", "date-range-with-time"] as unknown as FilterType[],
    comparator(
      value: string | Date | undefined,
      filterValue: DateRange[] | undefined,
    ) {
      const dateRange = filterValue?.at(0);
      const startDate = dateRange?.startDate;
      const endDate = dateRange?.endDate;
      if (startDate && endDate && value) {
        if (value instanceof Date) {
          return startDate <= value && endDate >= value;
        }
        if (typeof value === "string") {
          const zonedDate = new Date(value);
          const dataDate = new Date(
            zonedDate.getUTCFullYear(),
            zonedDate.getUTCMonth(),
            zonedDate.getUTCDate(),
            zonedDate.getUTCHours(),
            zonedDate.getUTCMinutes(),
            zonedDate.getUTCSeconds(),
            zonedDate.getUTCMilliseconds(),
          );
          return startDate <= dataDate && endDate >= dataDate;
        }
      }
      return false;
    },
  };
  
  export const defaultOperators = [...saasDefaultOperators, betweenDatesOperator];
  
  export const getDataGridFilter = <D extends object>(
    type: FilterType = "string",
    operators = defaultOperators,
  ) => baseGetDataGridFilter<D>(type, operators);
  