import { defineStyleConfig } from "@chakra-ui/react";

const ActiveFiltersList = defineStyleConfig({
  baseStyle: () => {
    return {
      border: "none",
    };
  },
});

export default ActiveFiltersList;
