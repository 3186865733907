export enum EntityType {
  Location = 0,
  HealthcareProfessional = 1,
  HealthcareFacility = 2,
  Event = 3,
  ATM = 4,
  Restaurant = 5,
  Organization = 6,
  Hotel = 4000,
  Job = 4001,
  FAQ = 4002,
  HotelRoomType = 5700,
  HelpArticle = 29100,
  Product = 30000,
  FinancialProfessional = 31640,
}
