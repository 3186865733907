import React from "react";
import { PopoverFooter } from "@chakra-ui/react";

export interface ComboboxFooterProps {
  children: React.ReactNode;
}

export const ComboboxFooter = (props: ComboboxFooterProps) => {
  return <PopoverFooter>{props.children}</PopoverFooter>;
};
