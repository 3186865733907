import { defineStyleConfig } from "@chakra-ui/react";
import { variantOptions, sizeOptions } from "./themeOptions";
import typography from "../../../clayTheme/foundations/typography";
import { toTitleCase } from "../../../utils/string";

function buttonStyles(color: string, weight: string) {
  return {
    borderWidth: "xs",
    color: `text.on${toTitleCase(color)}${toTitleCase(weight)}`,
    "& .chakra-icon": {
      color: `icon.on${toTitleCase(color)}${toTitleCase(weight)}`,
    },
    backgroundColor: `surfaces.${color}.${weight}.resting`,
    ".chakra-button__group[data-attached][data-orientation=horizontal] > &:not(:last-of-type)":
      {
        marginEnd: ".0625rem",
      },
    ".chakra-button__group[data-attached][data-orientation=vertical] > &:not(:last-of-type)":
      {
        marginBottom: ".0625rem",
      },
    _hover: {
      backgroundColor: `surfaces.${color}.${weight}.hover`,
      _disabled: {
        color: "text.disabledOnStrong",
        "& .chakra-icon": {
          color: "icon.disabledOnStrong",
        },
        backgroundColor: "surfaces.neutral.strong.disabled",
        borderColor: "borders.neutral.strong.disabled",
      },
    },
    _focusVisible: {
      shadow: "focus",
    },
    _active: {
      backgroundColor: `surfaces.${color}.${weight}.active`,
    },
    _disabled: {
      color: "text.disabledOnStrong",
      "& .chakra-icon": {
        color: "icon.disabledOnStrong",
      },
      backgroundColor: "surfaces.neutral.strong.disabled",
      borderColor: "borders.neutral.strong.disabled",
      opacity: 1,
    },
  };
}

const Button = defineStyleConfig({
  baseStyle: () => {
    return {
      display: "flex",
      flexDirection: "row",
      gap: "2",
      borderRadius: "full",
      _disabled: {
        "& .chakra-icon": {
          color: "icon.disabled",
        },
      },
    };
  },
  sizes: {
    [sizeOptions.md]: ({ variant }) => {
      if (variant === variantOptions.toggle) {
        return {
          ...typography.textStyles.xs,
          h: "1.125rem",
          px: 1.5,
          "& .chakra-icon": {
            fontSize: "xs",
          },
        };
      }
      return {
        px: 2.5,
        py: 0,
        h: 6,
        ...typography.textStyles.sm,
      };
    },
    [sizeOptions.lg]: ({ variant }) => {
      if (variant === variantOptions.toggle) {
        return {
          ...typography.textStyles.sm,
          h: 6,
          px: 1.5,
        };
      }
      return {
        px: 3,
        py: 0,
        h: "2rem",
        ...typography.textStyles.md,
      };
    },
    [sizeOptions.xl]: ({ variant }) => {
      if (variant === variantOptions.toggle) {
        return {
          ...typography.textStyles.md,
          h: 7,
          px: 1.5,
        };
      }
      return {
        px: 3.5,
        py: 0,
        h: "2.5rem",
        ...typography.textStyles.lg,
        ".chakra-button__group[data-attached][data-orientation=horizontal] > &:last-of-type":
          {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            ps: 2,
            pe: 2.5,
          },
      };
    },
    [sizeOptions["2xl"]]: ({ variant }) => {
      if (variant === variantOptions.toggle) {
        return {
          ...typography.textStyles.lg,
          h: 8,
          px: 2.5,
        };
      }
      return {
        px: 4,
        py: 0,
        h: "3rem",
        ...typography.textStyles.lg,
      };
    },
  },
  variants: {
    [variantOptions.primary]: {
      ...buttonStyles("primary", "strong"),
    },
    [variantOptions.secondary]: {
      ...buttonStyles("primary", "soft"),
    },
    [variantOptions["neutral.dominant"]]: {
      ...buttonStyles("neutral", "dominant"),
    },
    [variantOptions["neutral.strong"]]: {
      ...buttonStyles("neutral", "strong"),
    },
    [variantOptions["neutral.soft"]]: {
      borderWidth: "xs",
      color: "text.onNeutralSoft",
      "& .chakra-icon": {
        color: "icon.onNeutralSoft",
      },
      backgroundColor: "surfaces.neutral.soft.resting",
      ".chakra-button__group[data-attached][data-orientation=horizontal] > &:not(:last-of-type)":
        {
          marginEnd: ".0625rem",
        },
      ".chakra-button__group[data-attached][data-orientation=vertical] > &:not(:last-of-type)":
        {
          marginBottom: ".0625rem",
        },
      _hover: {
        backgroundColor: "surfaces.neutral.soft.hover",
        _disabled: {
          color: "text.disabledOnSoft",
          backgroundColor: "surfaces.neutral.soft.disabled",
          borderColor: "borders.neutral.soft.disabled",
        },
      },
      _focusVisible: {
        shadow: "focus",
      },
      _active: {
        backgroundColor: "surfaces.neutral.soft.active",
      },
      _disabled: {
        color: "text.disabledOnSoft",
        backgroundColor: "surfaces.neutral.soft.disabled",
        borderColor: "borders.neutral.soft.disabled",
        opacity: 1,
      },
    },
    [variantOptions["outline.dominant"]]: {
      borderWidth: "xs",
      borderColor: "borders.neutral.dominant.resting",
      color: "text.neutralDominant",
      "& .chakra-icon": {
        color: "icon.neutralDominant",
      },
      ".chakra-button__group[data-attached][data-orientation=horizontal] > &:not(:last-of-type)":
        {
          marginEnd: "0",
          borderEnd: "none",
        },
      ".chakra-button__group[data-attached][data-orientation=vertical] > &:not(:last-of-type)":
        {
          marginBottom: "0",
          borderBottom: "none",
        },
      _hover: {
        backgroundColor: "surfaces.alpha.dominant.200",
        borderColor: "borders.neutral.dominant.hover",
        _disabled: {
          color: "text.disabled",
          "& .chakra-icon": {
            color: "icon.disabled",
          },
          borderColor: "borders.disabled.strong",
          backgroundColor: "surfaces.neutral.hush.disabled",
          opacity: "unset",
        },
      },
      _active: {
        backgroundColor: "unset",
      },
      _focusVisible: {
        shadow: "focus",
        borderColor: "borders.secondary.soft.resting",
        backgroundColor: "surfaces.alpha.soft.50",
      },
      _disabled: {
        color: "text.disabled",
        "& .chakra-icon": {
          color: "icon.disabled",
        },
        borderColor: "borders.disabled.strong",
        backgroundColor: "surfaces.neutral.hush.disabled",
        opacity: "unset",
      },
    },
    [variantOptions["outline.soft"]]: {
      borderWidth: "xs",
      borderColor: "borders.neutral.soft.resting",
      color: "text.neutralDominant",
      "& .chakra-icon": {
        color: "icon.neutralDominant",
      },
      backgroundColor: "surfaces.alpha.soft.300",
      ".chakra-button__group[data-attached][data-orientation=horizontal] > &:not(:last-of-type)":
        {
          marginEnd: "0",
          borderEnd: "none",
        },
      ".chakra-button__group[data-attached][data-orientation=vertical] > &:not(:last-of-type)":
        {
          marginBottom: "0",
          borderBottom: "none",
        },
      _hover: {
        backgroundColor: "surfaces.outline.soft.hover",
        borderColor: "borders.neutral.soft.hover",
        _disabled: {
          color: "text.disabled",
          "& .chakra-icon": {
            color: "icon.disabled",
          },
          backgroundColor: "surfaces.neutral.hush.disabled",
          borderColor: "borders.disabled.strong",
          opacity: "unset",
        },
      },
      _active: {
        backgroundColor: "surfaces.neutral.strong.active",
      },
      _focusVisible: {
        shadow: "focus",
        borderColor: "borders.secondary.soft.resting",
        backgroundColor: "surfaces.alpha.soft.200",
      },
      _disabled: {
        color: "text.disabled",
        "& .chakra-icon": {
          color: "icon.disabled",
        },
        backgroundColor: "surfaces.neutral.hush.disabled",
        borderColor: "borders.disabled.strong",
        opacity: "unset",
      },
    },
    [variantOptions["outline.chameleon"]]: {
      borderWidth: "xs",
      borderColor: "currentColor",
      color: "currentColor",
      _hover: {
        backgroundColor: "surfaces.alpha.dominant.50",
        _disabled: {
          color: "text.disabled",
          "& .chakra-icon": {
            color: "icon.disabled",
          },
          borderColor: "borders.disabled.strong",
          backgroundColor: "surfaces.neutral.hush.disabled",
          opacity: "unset",
        },
      },
      _active: {
        backgroundColor: "transparent",
      },
      _focusVisible: {
        shadow: "focus",
        backgroundColor: "surfaces.alpha.soft.50",
      },
      _disabled: {
        color: "text.disabled",
        "& .chakra-icon": {
          color: "icon.disabled",
        },
        borderColor: "borders.disabled.strong",
        backgroundColor: "surfaces.neutral.hush.disabled",
        opacity: "unset",
      },
    },
    [variantOptions.ghost]: ({ colorMode }) => ({
      color: "text.neutralDominant",
      "& .chakra-icon": {
        color: "icon.neutralDominant",
      },
      _hover: {
        backgroundColor: "surfaces.alpha.dominant.100",
      },
      _active: {
        backgroundColor: "unset",
      },
      _focusVisible: {
        backgroundColor: "surfaces.alpha.dominant.50",
        shadow: "focus",
      },
      _disabled: {
        color: "text.disabled",
        "& .chakra-icon": {
          color: "icon.disabled",
        },
        bg:
          colorMode === "light"
            ? "transparent"
            : "surfaces.neutral.soft.disabled",
      },
    }),
    [variantOptions.success]: {
      ...buttonStyles("success", "strong"),
    },
    [variantOptions.danger]: {
      ...buttonStyles("danger", "dominant"),
    },
    [variantOptions.toggle]: {
      borderRadius: "full",
      color: "text.neutralStrong",
      m: 0,
      _hover: {
        backgroundColor: "surfaces.alpha.dominant.100",
      },
      _focusVisible: {
        shadow: "focus",
      },
      _checked: {
        backgroundColor: "surfaces.page.white",
        color: "text.neutralDominant",
      },
    },
    [variantOptions.toggleOutline]: {
      borderRadius: "none",
      borderColor: "transparent",
      background: "transparent",
      border: "none",
      color: "text.neutralDominant",
      m: 0,
      _hover: {
        backgroundColor: "surfaces.alpha.dominant.100",
      },
      _focusVisible: {
        shadow: "focus",
      },
      _checked: {
        backgroundColor: "surfaces.neutral.strong.active",
      },
    },
    [variantOptions.soft]: {},
    [variantOptions.strong]: {},
  },
  defaultProps: {
    size: sizeOptions.md,
    variant: variantOptions.primary,
  },
});

export default Button;
