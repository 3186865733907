import React from "react";
import {
  SearchInput as ChakraSearchInput,
  SearchInputProps as ChakraSearchInputProps,
} from "@saas-ui/react";
import { FALMagnifyingGlass } from "../../icons/FontAwesomeIcons";
import { forwardRef } from "@chakra-ui/react";
import { useTranslation } from "../ManaUIProvider";

export interface SearchInputProps extends ChakraSearchInputProps {}

export default forwardRef(function SearchInput(props, ref) {
  const translations = useTranslation("SearchInput");

  return (
    <ChakraSearchInput
      placeholder={translations.placeholder}
      icon={<FALMagnifyingGlass />}
      {...props}
      ref={ref}
    />
  );
});
