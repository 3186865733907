import React from "react";
import {
  NumberDecrementStepper as ChakraNumberDecrementStepper,
  NumberDecrementStepperProps as ChakraNumberDecrementStepperProps,
} from "@chakra-ui/react";
import { FALChevronDown } from "../../icons";

export interface NumberDecrementStepperProps
  extends ChakraNumberDecrementStepperProps {}

export default function NumberDecrementStepper(
  props: NumberDecrementStepperProps,
) {
  return (
    <ChakraNumberDecrementStepper {...props}>
      <FALChevronDown />
    </ChakraNumberDecrementStepper>
  );
}
