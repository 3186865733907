import React, { FC } from "react";
import {
  CloseButton as ChakraCloseButton,
  CloseButtonProps as ChakraCloseButtonProps,
  forwardRef,
} from "@chakra-ui/react";
import { Tooltip } from "../Tooltip";
import { variantOptions, sizeOptions } from "./theme/themeOptions";
import { useTranslation } from "../ManaUIProvider";

export interface CloseButtonProps
  extends Omit<ChakraCloseButtonProps, "children"> {
  variant?: keyof typeof variantOptions;
  size?: keyof typeof sizeOptions;
  "aria-label"?: string;
  tooltipLabel?: React.ReactNode;
}

const CloseButton: FC<CloseButtonProps> = forwardRef((props, ref) => {
  const translations = useTranslation("CloseButton");
  const {
    "aria-label": ariaLabel = translations.ariaLabel,
    variant,
    size,
    tooltipLabel,
    ...rest
  } = props;

  const closeButton = (
    <ChakraCloseButton
      aria-label={ariaLabel}
      variant={variant}
      size={size}
      ref={ref}
      {...rest}
    />
  );

  if (tooltipLabel) {
    return <Tooltip label={tooltipLabel}>{closeButton}</Tooltip>;
  }

  return closeButton;
});

export default CloseButton;
