import { defineStyleConfig } from "@chakra-ui/react";
import { cssVar } from "@chakra-ui/theme-tools";
import typography from "../../../clayTheme/foundations/typography";

// https://github.com/chakra-ui/chakra-ui/issues/4695#issuecomment-991023319
const $arrowBg = cssVar("popper-arrow-bg");

const Tooltip = defineStyleConfig({
  baseStyle: () => {
    return {
      color: "text.onNeutralDominant",
      backgroundColor: "surfaces.neutral.dominant.resting",
      ...typography.textStyles.sm,
      paddingX: "3",
      paddingY: "1",
      borderRadius: "sm",
      shadow: "xs",
      border: "0",
      [$arrowBg.variable]: "colors.surfaces.neutral.dominant.resting",
      gap: "15",
      "--tooltip-fg-muted": "text.onNeutralDominant",
    };
  },
});

export default Tooltip;
