import React from "react";
import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from "@chakra-ui/react";
import { paletteOptions, sizes } from "./theme/themeOptions";
import { FALArrowUpRightFromSquare } from "../../icons";

export interface LinkProps extends Omit<ChakraLinkProps, "size"> {
  palette?: keyof typeof paletteOptions;
  external?: boolean;
  disabled?: boolean;
  size?: keyof typeof sizes;
}

const Link = (props: LinkProps) => {
  const {
    children,
    palette = "chameleon",
    external, // Deprecated
    isExternal, // From ChakraLinkProps
    disabled,
    size = sizes.inherit,
    ...rest
  } = props;

  /**
   * If legacy `external` prop is true and Chakra's `isExternal` prop is false,
   * manually set `target` and `rel` HTML attributes.
   *
   * Otherwise, rely on Chakra's `isExternal` prop to set `target` and `rel`.
   *
   * This code can be deleted once the legacy `external` prop is marked
   * as deprecated and removed.
   */
  const targetAndRelFromExternalProp =
    external && !isExternal ? { target: "_blank", rel: "noopener" } : undefined;

  return (
    <ChakraLink
      /* @ts-expect-error intentional colorScheme misuse */
      colorScheme={palette}
      size={size}
      isExternal={isExternal}
      disabled={props.as === "button" && disabled}
      {...targetAndRelFromExternalProp}
      {...rest}
    >
      {children}
      {(isExternal || external) && <FALArrowUpRightFromSquare />}
    </ChakraLink>
  );
};

export default Link;
