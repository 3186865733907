import { popoverAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { cssVar } from "@chakra-ui/theme-tools";
import typography from "../../../clayTheme/foundations/typography";
import { variantOptions } from "./themeOptions";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(popoverAnatomy.keys);

// https://github.com/chakra-ui/chakra-ui/issues/4695#issuecomment-991023319
const $arrowBg = cssVar("popper-arrow-bg");
const $arrowShadow = cssVar("popper-arrow-shadow-color");

const baseStyle = definePartsStyle(() => {
  return {
    content: {
      color: "text.neutralDominant",
      shadow: "md",
      borderRadius: "lg",
      borderColor: "borders.secondary.soft.resting",
      borderWidth: "sm",
    },
    arrow: {
      [$arrowBg.variable]: `colors.surfaces.highlight.secondary`,
      [$arrowShadow.variable]: `colors.borders.secondary.soft.resting`,
    },
    header: {
      ...typography.textStyles["lg-semibold"],
      border: "none",
      p: 0,
      mb: 3,
    },
    body: {
      p: 0,
      ...typography.textStyles["md"],
    },
    footer: {
      mt: 6,
      p: 0,
      border: "none",
      color: "components.popover.textFooter",
      ...typography.textStyles["md"],
    },
    closeButton: {
      // TODO: submit issue about this, the popover overrides the default border
      // radius and doesn't pass through the user's size, unlike the close button
      // usages in the modal and drawer components.
      borderRadius: "full",
      top: 5,
      right: 5,
    },
  };
});

const variants = {
  [variantOptions.default]: definePartsStyle({
    content: {
      backgroundColor: "surfaces.highlight.secondary",
      p: 6,
    },
  }),
  [variantOptions.menu]: definePartsStyle({
    content: {
      p: 4,
      borderWidth: "xs",
      borderColor: "borders.neutral.hush.resting",
    },
  }),
};

const defaultProps = {
  variant: variantOptions.default,
};

const Popover: ReturnType<typeof defineMultiStyleConfig> =
  defineMultiStyleConfig({ baseStyle, variants, defaultProps });

export default Popover;
