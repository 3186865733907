export enum paletteOptions {
  "neutral.separator" = "neutral.separator",
  "neutral.dominant" = "neutral.dominant",
  primary = "primary",
}

export enum variantOptions {
  solid = "solid",
  dashed = "dashed",
}
