export enum sizeOptions {
  lg = "lg",
  xl = "xl",
  "2xl" = "2xl",
}

export enum variantOptions {
  "" = "",
  gray = "gray",
}
