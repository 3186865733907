import { defineStyleConfig } from "@chakra-ui/react";
import { paletteOptions } from "./themeOptions";

const colorMapping = {
  [paletteOptions["neutral.separator"]]: "borders.separator",
  [paletteOptions["neutral.dominant"]]: "text.neutralDominant",
  [paletteOptions.primary]: "borders.primary.strong.resting",
};

const Divider = defineStyleConfig({
  baseStyle: (props) => {
    const { colorScheme } = props;

    return {
      borderColor:
        // @ts-expect-error intentional misuse of colorScheme
        colorMapping[colorScheme] ||
        colorMapping[paletteOptions["neutral.separator"]],
      borderWidth: "sm",
      opacity: 1,
    };
  },
});

export default Divider;
