import {
  Image as ChakraImage,
  ImageProps as ChakraImageProps,
} from "@chakra-ui/react";

// Removing this leads to an "indirect export not found" error
// in storybook.
const Image = ChakraImage;
type ImageProps = ChakraImageProps;

export { Image, type ImageProps };
