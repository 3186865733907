import React from "react";
import {
  Progress as ChakraProgress,
  ProgressProps as ChakraProgressProps,
  Box,
  Flex,
} from "@chakra-ui/react";
import { FALArrowsRotate } from "../../icons";

export interface ProgressProps extends ChakraProgressProps {
  showLabel?: boolean;
}

export default function Progress(props: ProgressProps) {
  const { showLabel, isIndeterminate, value, ...rest } = props;
  const roundedValue = !value ? 0 : Math.round(value);

  return (
    <Flex gap={1} alignItems="center">
      <ChakraProgress
        value={roundedValue}
        isIndeterminate={isIndeterminate}
        flexBasis="100%"
        {...rest}
      />
      {showLabel && (
        <Box
          as="span"
          fontSize="md"
          sx={{ fontVariantNumeric: "tabular-nums" }}
          width={10}
          flexShrink={0}
          textAlign={isIndeterminate ? "center" : "right"}
        >
          {isIndeterminate ? <FALArrowsRotate mt="-3px" /> : `${roundedValue}%`}
        </Box>
      )}
    </Flex>
  );
}
