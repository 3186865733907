import React, { PropsWithChildren } from "react";
import { chakra, defineStyle, useMultiStyleConfig } from "@chakra-ui/react";
import { UseComboboxReturnValue } from "downshift";
import { TypeaheadGroup, Label } from "./types";
import { useTranslation } from "../ManaUIProvider";

interface DropdownProps {
  results: TypeaheadGroup[];
  comboboxState: UseComboboxReturnValue<Label>;
}

export default function Dropdown(props: DropdownProps) {
  const { results, comboboxState } = props;
  const { isOpen, getMenuProps } = comboboxState;
  const styles = useMultiStyleConfig("Labels");

  return (
    <chakra.ul
      {...getMenuProps({
        __css: styles.menu,
      })}
    >
      {isOpen &&
        results.reduce<{ output: React.ReactNode[]; indexAgg: number }>(
          (agg, group) => {
            const groupOutput = (
              <React.Fragment key={group.group}>
                {(group.group || results.length > 1) && (
                  <SectionHeading>
                    {(group.group || "Uncategorized").toUpperCase()}
                  </SectionHeading>
                )}
                {group.results.length === 0 && <NoResults />}
                {group.results.map((item, i) => (
                  <ListItem
                    key={agg.indexAgg + i}
                    item={item}
                    comboboxState={comboboxState}
                    index={agg.indexAgg + i}
                  >
                    {item}
                  </ListItem>
                ))}
              </React.Fragment>
            );
            return {
              output: [...agg.output, groupOutput],
              indexAgg: agg.indexAgg + group.results.length,
            };
          },
          { output: [], indexAgg: 0 },
        ).output}
    </chakra.ul>
  );
}

const listItemStyles = defineStyle({
  paddingY: 1,
  paddingX: 2,
});

const SectionHeading = ({ children }: PropsWithChildren) => (
  <chakra.li
    __css={{
      ...listItemStyles,
      color: "gray.600",
    }}
  >
    {children}
  </chakra.li>
);

const NoResults = () => {
  const noResultsMsg = useTranslation("Labels.noResults");

  return <chakra.li sx={listItemStyles}>{noResultsMsg}</chakra.li>;
};

interface ListItemProps {
  comboboxState: UseComboboxReturnValue<Label>;
  item: Label;
  index: number;
}

const ListItem = (props: PropsWithChildren<ListItemProps>) => {
  const { item, index, comboboxState } = props;
  const { getItemProps, highlightedIndex } = comboboxState;

  return (
    <chakra.li
      {...getItemProps({
        item,
        index: index,
        __css: {
          ...listItemStyles,
          backgroundColor: highlightedIndex === index ? "#bde4ff" : undefined,
        },
      })}
    >
      {item}
    </chakra.li>
  );
};
