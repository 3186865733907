import {
  UseComboboxState,
  UseComboboxStateChange,
  UseComboboxStateChangeOptions,
  UseSelectState,
  UseSelectStateChange,
  UseSelectStateChangeOptions,
  useCombobox,
  useSelect,
} from "downshift";
import React, {
  isValidElement,
  PropsWithChildren,
  useRef,
  useState,
} from "react";
import {
  ComboboxOption,
  ComboboxProps,
  ComboboxProvider,
  isButtonOption,
  isLinkOption,
} from "./ComboboxContext";
import { useDebounce } from './debounce';
import { Popover, useDisclosure } from "@chakra-ui/react";

const Combobox = function ComboBoxI<T extends ComboboxOption<unknown>>(
  props: ComboboxProps<T>,
) {
  const popoverRef = useRef<HTMLDivElement | null>(null);
  const triggerRef = useRef<HTMLButtonElement | null>(null);
  const [selectedItemsUncontrolled, setSelectedItemsUncontrolled] = useState<
    T[]
  >([]);

  const hasInput = hasInputChild(props);

  const setSelectedItems =
    props.setSelectedOptions || setSelectedItemsUncontrolled;
  const selectedOptions = props.selectedOptions || selectedItemsUncontrolled;

  const disclosureControls = useDisclosure();

  const onOpen =
    props.onOpen === undefined ? disclosureControls.onOpen : props.onOpen;
  const onClose =
    props.onClose === undefined ? disclosureControls.onClose : props.onClose;
  const isOpen =
    props.isOpen === undefined ? disclosureControls.isOpen : props.isOpen;

  const onCloseWithFocusReturn = () => {
    triggerRef?.current?.focus();
    onClose();
  };

  const isEqual = props.isEqual;

  const optionToString =
    props.optionToString === undefined
      ? (item: T | null) => {
          if (item === null) return "";
          if (isButtonOption(item)) return "";
          if (isLinkOption(item)) return item.href;
          if (typeof item === "string") return item;
          if (typeof item.value === "string") return item.value;
          if (typeof item.label === "string") return item.label;
          if (typeof item.value === "number") return item.value.toString();
          return "";
        }
      : props.optionToString;

  const onSelectedItemChange = ({
    selectedItem,
  }: UseSelectStateChange<T> | UseComboboxStateChange<T>) => {
    if (!props.multiple) {
      if (selectedItem) {
        setSelectedItems([selectedItem]);
      }
      return;
    }
    if (!selectedItem) {
      return;
    }
    const index = isEqual
      ? selectedOptions.findIndex((item) => isEqual(selectedItem, item))
      : selectedOptions.indexOf(selectedItem);
    if (index > 0) {
      setSelectedItems([
        ...selectedOptions.slice(0, index),
        ...selectedOptions.slice(index + 1),
      ]);
    } else if (index === 0) {
      setSelectedItems([...selectedOptions.slice(1)]);
    } else {
      setSelectedItems([...selectedOptions, selectedItem]);
    }
  };

  if (hasInput)
    return (
      <ComboboxVersion
        popoverRef={popoverRef}
        triggerRef={triggerRef}
        hasInput={hasInput}
        isOpen={isOpen}
        onOpen={onOpen}
        isEqual={props.isEqual}
        selectedOptions={selectedOptions}
        onSelectedItemChange={onSelectedItemChange}
        onClose={onCloseWithFocusReturn}
        optionToString={optionToString}
        options={props.options}
        multiple={props.multiple}
        variant={props.variant}
        isDisabled={props.isDisabled}
        filterOptions={props.filterOptions}
        popoverCloseOnBlur={props.popoverCloseOnBlur}
      >
        {props.children}
      </ComboboxVersion>
    );

  return (
    <SelectVersion
      popoverRef={popoverRef}
      triggerRef={triggerRef}
      hasInput={hasInput}
      isOpen={isOpen}
      onOpen={onOpen}
      isEqual={props.isEqual}
      optionToString={optionToString}
      selectedOptions={selectedOptions}
      onSelectedItemChange={onSelectedItemChange}
      onClose={onCloseWithFocusReturn}
      options={props.options}
      multiple={props.multiple}
      variant={props.variant}
      isDisabled={props.isDisabled}
      filterOptions={props.filterOptions}
      popoverCloseOnBlur={props.popoverCloseOnBlur}
    >
      {props.children}
    </SelectVersion>
  );
};

const hasInputChild = (
  props: PropsWithChildren<{ overrideSearchInputDetection?: boolean }>,
) => {
  if (props.overrideSearchInputDetection) return true;

  for (const child of React.Children.toArray(props.children)) {
    if (!isValidElement(child)) continue;

    if (
      typeof child.type !== "string" &&
      // @ts-expect-error the displayName property does exist
      child.type.displayName?.includes("ComboboxInput")
    )
      return true;
    if (hasInputChild(child.props)) return true;
  }

  return false;
};

interface ComboboxInstanceProps<T extends ComboboxOption<unknown>>
  extends ComboboxProps<T> {
  hasInput: boolean;
  optionToString: (item: T | null) => string;
  onSelectedItemChange: (
    args: UseSelectStateChange<T> | UseComboboxStateChange<T>,
  ) => void;
  popoverCloseOnBlur?: boolean;
}

const SelectVersion = <T extends ComboboxOption<unknown>>(
  props: ComboboxInstanceProps<T>,
) => {
  const {
    popoverRef,
    triggerRef,
    hasInput,
    onClose,
    onOpen,
    isOpen,
    selectedOptions,
    options,
    variant,
    isDisabled,
    onSelectedItemChange,
    popoverCloseOnBlur,
  } = props;
  const select = useSelect({
    items: props.options,
    itemToString: props.optionToString,
    stateReducer: selectStateReducer,
    // there's no actual selection happening in the hook, we're doing it custom via selectedItems.
    selectedItem: null,
    isOpen: isOpen,
    onSelectedItemChange,
  });

  return (
    <ComboboxProvider
      value={{
        ...props,
        combobox: select,
        selectedOptions,
        options,
        isOpen,
        hasInput,
        onClose,
        onOpen,
        popoverRef,
        triggerRef,
        variant,
        isDisabled,
      }}
    >
      <Popover
        onOpen={onOpen}
        onClose={onClose}
        isOpen={isOpen}
        returnFocusOnClose={true}
        autoFocus={hasInput}
        closeOnBlur={popoverCloseOnBlur}
        variant="menu"
        placement="bottom-start"
      >
        {props.children}
      </Popover>
    </ComboboxProvider>
  );
};

const ComboboxVersion = <T extends ComboboxOption<unknown>>(
  props: ComboboxInstanceProps<T>,
) => {
  const {
    popoverRef,
    triggerRef,
    hasInput,
    onClose,
    onOpen,
    isOpen,
    isDisabled,
    selectedOptions,
    options,
    variant,
    onSelectedItemChange,
    popoverCloseOnBlur,
  } = props;

  const searchbarRef = useRef(null);
  const [inputValue, setInputValue] = useState("");
  const [visibleItems, setVisibleItems] = React.useState(options);

  const filterFunc = props.filterOptions
    ? props.filterOptions
    : (inputValue: string) =>
        options.filter((option) =>
          props
            .optionToString(option)
            .toLowerCase()
            .includes(inputValue.toLowerCase()),
        );

  React.useEffect(() => {
    if (inputValue.length === 0) {
      setVisibleItems(options);
    }
  }, [options]);

  const setItemsFromFilterFn = async (inputValue: string) => {
    const result = filterFunc(inputValue);
    const filterFuncItems = Array.isArray(result) ? result : await result;
    setVisibleItems(filterFuncItems);
  };

  const debounceFetchResults = useDebounce(setItemsFromFilterFn);

  const combobox = useCombobox({
    inputValue,
    onInputValueChange({ inputValue }) {
      setInputValue(inputValue || "");
      debounceFetchResults(inputValue || "");
    },
    items: visibleItems,
    // there's no actual selection happening in the hook, we're doing it custom via selectedItems.
    selectedItem: null,
    itemToString: props.optionToString,
    isOpen: isOpen,
    onSelectedItemChange,
    stateReducer: comboboxStateReducer,
  });

  return (
    <ComboboxProvider
      value={{
        ...props,
        combobox,
        selectedOptions,
        options: visibleItems,
        isOpen,
        hasInput,
        onClose,
        onOpen,
        isDisabled,
        popoverRef,
        triggerRef,
        searchbarRef,
        variant,
      }}
    >
      <Popover
        onOpen={onOpen}
        onClose={onClose}
        isOpen={isOpen}
        initialFocusRef={searchbarRef}
        returnFocusOnClose={true}
        autoFocus={hasInput}
        closeOnBlur={popoverCloseOnBlur}
        variant="menu"
        placement="bottom-start"
      >
        {props.children}
      </Popover>
    </ComboboxProvider>
  );
};

const selectStateReducer = <T extends ComboboxOption<unknown>>(
  state: UseSelectState<T>,
  actionAndChanges: UseSelectStateChangeOptions<T>,
) => {
  const { changes, type } = actionAndChanges;
  switch (type) {
    case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
    case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
    case useSelect.stateChangeTypes.ItemClick:
      if (changes.selectedItem && isLinkOption(changes.selectedItem)) {
        window.location.href = changes.selectedItem.href;
        return {
          ...changes,
          inputValue: state.inputValue, // don't change the input value
          selectedItem: state.selectedItem, // don't actually select anything
        };
      }

      if (changes.selectedItem && isButtonOption(changes.selectedItem)) {
        return {
          ...changes,
          inputValue: state.inputValue, // don't change the input value
          selectedItem: state.selectedItem, // don't actually select anything
        };
      }

      return {
        ...changes,
        highlightedIndex: state.highlightedIndex,
      };
    default:
      return changes;
  }
};

const comboboxStateReducer = <T extends ComboboxOption<unknown>>(
  state: UseComboboxState<T>,
  actionAndChanges: UseComboboxStateChangeOptions<T>,
) => {
  const { changes, type } = actionAndChanges;
  switch (type) {
    case useCombobox.stateChangeTypes.InputKeyDownEnter:
    case useCombobox.stateChangeTypes.ItemClick:
      if (changes.selectedItem && isLinkOption(changes.selectedItem)) {
        window.location.href = changes.selectedItem.href;
        return {
          ...changes,
          inputValue: state.inputValue, // don't change the input value
          selectedItem: state.selectedItem, // don't actually select anything
        };
      }

      if (changes.selectedItem && isButtonOption(changes.selectedItem)) {
        return {
          ...changes,
          inputValue: state.inputValue, // don't change the input value
          selectedItem: state.selectedItem, // don't actually select anything
        };
      }

      return {
        ...changes,
        highlightedIndex: state.highlightedIndex,
        inputValue: "", // don't add the item string as input value at selection.
      };
    case useCombobox.stateChangeTypes.InputBlur:
      return {
        ...changes,
        selectedItem: null, // don't add currently highlighted item to selection
        inputValue: state.inputValue, // don't add the item string as input value at selection.
      };
    default:
      return changes;
  }
};

export default Combobox;
