import React from "react";
import {
  Select as ChakraSelect,
  SelectProps as ChakraSelectProps,
} from "@saas-ui/react";

export interface SelectProps extends ChakraSelectProps {}

export default function Select(props: SelectProps) {
  return <ChakraSelect {...props} />;
}
