import { tagAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import baseStyle from "./baseStyle";
import sizes from "./sizes";
import { sizeOptions } from "./themeOptions";

export const defaultProps = {
  size: sizeOptions.md,
};

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  tagAnatomy.keys,
);

const theme: ReturnType<typeof defineMultiStyleConfig> = defineMultiStyleConfig(
  {
    baseStyle,
    ...sizes,
    defaultProps,
  },
);

export default theme;
