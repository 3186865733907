import React, { forwardRef } from "react";
import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
} from "@chakra-ui/react";

export interface ButtonProps extends Omit<ChakraButtonProps, "colorScheme"> {
  href?: string;
}

const Button = forwardRef(function ButtonWrapper(props: ButtonProps, ref) {
  return <ChakraButton {...props} ref={ref} />;
});

export default Button;
