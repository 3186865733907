/**
 * @fileoverview React utilities for creating responsive layouts for mobile and
 * small viewport browsers.
 * Whenever possible, CSS with breakpoints should be used to create responsive
 * layouts. These should only be used when the order of elements in the DOM
 * needs to differ responsively.
 * Using an existing Responsivity context provider, e.g. the provider from the
 * Page component, should be preferred over using the hook directly whenever
 * possible for performance reasons.
 */

/**
 * Extracted from closure library
 * https://github.com/google/closure-library/blob/master/closure/goog/functions/functions.js#L454-L486
 *
  * @param {function(...?): ?} f Function to call.
 * @param {number} interval Interval over which to debounce. The function will
 *     only be called after the full interval has elapsed since the last call.
 * @param {SCOPE=} opt_scope Object in whose scope to call the function.
 * @return {function(...?): undefined} Wrapped function.
 * @template SCOPE
 */
const debounce = function(f, interval, opt_scope) {
  let timeout = 0;
  return (function(...var_args) {
    clearTimeout(timeout);
    timeout = setTimeout(function() {
      f.apply(opt_scope, var_args);
    }, interval);
  });
};

// Provides a context whose value indicates whether to render a mobile layout.
export const ResponsivityContext = React.createContext({isSmallViewport: false});

export const DESKTOP_BREAKPOINT = 768; // 768px, taken from ui-breakpoint-vars

// This can be kept fairly low, since expensive operations only happen when
// the viewport crosses threshold breakpoint.
// Below around 100ms, React re-rendering time is generally more
// significant than this debounce interval.
const DEBOUNCE_INTERVAL = 100; // 100ms

/**
 * Returns whether the screen should be considered a small viewport.
 * The implementation of this is subject to change, and should not be depended
 * upon.
 *
 * @returns {boolean}
 */
export function isViewportSmallViewport() {
  // This must measure the visual viewport, not the layout viewport.
  // The main case in which they differ is mobile pages where the content does
  // not fit in the visual viewport, causing the layout viewport to be expanded
  // past the value provided by the viewport HTMl meta in order to fit the
  // content.
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Viewport_meta_tag
  if (!window?.matchMedia) {
    // Styleguide polyfills browser globals with JSDOM, which doesn't support
    // window.matchMedia.
    // https://github.com/jsdom/jsdom/issues/3522
    return false;
  }
  return window.matchMedia(`screen and (max-width: ${DESKTOP_BREAKPOINT}px)`).matches;
}

/**
 * React Hook for determining whether to render a mobile or desktop layout.
 * When possible, the ResponsivityContext provided via the Page component should
 * be preferred for perfomance reasons.
 *
 * @returns {boolean}
 */
export function useIsSmallViewport() {
  const [isSmallViewport, setIsSmallViewport] = React.useState(() => isViewportSmallViewport());
  React.useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      // React won't re-render if isMobile hasn't changed, so no need to check
      // here if this is a no-op.
      setIsSmallViewport(isViewportSmallViewport());
    }, DEBOUNCE_INTERVAL);

    window.addEventListener('resize', debouncedHandleResize);
    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, []);
  return isSmallViewport;
}
