import { formAnatomy as parts } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from "@chakra-ui/styled-system";
import typography from "../../../clayTheme/foundations/typography";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyleHelperText = defineStyle({
  color: "text.neutralStrong",
  mt: 0,
  ...typography.textStyles.xs,
  "&.form-helper-text-disabled": {
    color: "text.disabled",
  },
});

const baseStyle = definePartsStyle({
  helperText: baseStyleHelperText,
});

const Form: ReturnType<typeof defineMultiStyleConfig> = defineMultiStyleConfig({
  baseStyle,
});

export default Form;
