import { useCallback } from 'react';
import debounce from 'lodash/debounce';

export const DEFAULT_DEBOUNCE_INTERVAL = 500;

export function useDebounce<T extends (...args: any[]) => any>(
  callback: T,
  delay: number = DEFAULT_DEBOUNCE_INTERVAL
): (...args: Parameters<T>) => void {
  const debouncedCallback = useCallback(
    debounce((...args: Parameters<T>) => callback(...args), delay),
    [callback, delay]
  );

  return debouncedCallback;
}
