import React from "react";
import {
  Spinner as ChakraSpinner,
  SpinnerProps as ChakraSpinnerProps,
} from "@chakra-ui/react";
import { paletteOptions } from "./theme/themeOptions";

export interface SpinnerProps extends Omit<ChakraSpinnerProps, "colorScheme"> {
  palette?: keyof typeof paletteOptions;
}

export default function Spinner(props: SpinnerProps) {
  const { palette, ...rest } = props;
  return (
    <ChakraSpinner colorScheme={props.palette} {...rest} thickness="2px" />
  );
}
