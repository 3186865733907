import React, { useState, useEffect } from "react";
import {
  Combobox,
  ComboboxTrigger,
  ComboboxContent,
  ComboboxItems,
  ComboboxInput,
  ComboboxOption,
} from "../Combobox";
import { Divider } from "../Divider";
import { DateRange, QuickFilterItem } from "./FiltersProvider";
import { Button } from "../Button";
import { Link } from "../Link";
import { Box, Flex, useDisclosure } from "@chakra-ui/react";
import { useTranslation } from "../ManaUIProvider";
import { ModalsContextValue, useModals } from "@saas-ui/react";
import {
  getLocalTimeZone,
  DateRangePicker,
  DateRangePickerTimeField,
  DateRangePickerCalendar,
  DateRangeValue,
} from "@saas-ui/date-picker";
import { parseAbsoluteToLocal } from "@internationalized/date";
import { FilterItem } from "./FiltersProvider";
import {
  DatePickerTranslations,
  FiltersTranslations,
} from "../ManaUIProvider/TranslationProvider";
import { endOfDay, startOfDay } from "date-fns";

interface SelectFilterProps {
  filter: QuickFilterItem;
  selectedOptions: QuickFilterItem[];
  onChange: (options: QuickFilterItem[]) => void;
  onClear: () => void;
  onApply: (options: QuickFilterItem[]) => void;
  onClose: () => void;
  optionToString?: (option: QuickFilterItem) => string;
  filterFunction?: (inputValue: string, options: QuickFilterItem[]) => QuickFilterItem[];
}

export const SelectFilter = (props: SelectFilterProps) => {
  const translations = useTranslation("Filters");
  const disclosure = useDisclosure();
  const modals = useModals();

  const handleClose = () => {
    disclosure.onClose();
    props?.onClose();
  };

  const isDateRangeFilter =
    props.filter.type === "date-range" ||
    props.filter.type === "date-range-with-time";
  const datePickerTranslations = useTranslation("DatePicker");

  // If a custom date range filter is specified, then create an option that opens up a date range selection modal
  const [actionOptions, setActionOptions] =
    React.useState<FilterItem[]>(typeof props.filter.items === "function"
       ? []
       : (props.filter.items?.map((filterItem) => {
           if (isDateRangeFilter) {
             if (filterItem.value === "custom") {
               return generateCustomDateRangeOption(
                 filterItem,
                 props,
                 datePickerTranslations,
                 translations,
                 modals,
               );
             }
           }

           return filterItem;
         }) || []) as FilterItem[]);

  const getActionOptionsForItemFn = async (inputValue: string): Promise<FilterItem[]> => {
    if (props.filter?.items && typeof props.filter.items === "function") {
      return await props.filter.items(inputValue);
    }
    return new Promise(() => []);
  };

  const setInitialActionOptionsForItemFn = async () => {
    if (typeof props.filter.items === "function") {
      const fnActionItems: FilterItem[] = await getActionOptionsForItemFn("");
      setActionOptions(fnActionItems);
    }
  };

  React.useEffect(() => {
    setInitialActionOptionsForItemFn();
  }, [props.filter.items]);

  const [allSelected, setAllSelected] = useState<boolean>(false);
  useEffect(() => {
    if (props && props.selectedOptions && actionOptions) {
      setAllSelected(props.selectedOptions.length === actionOptions.length);
    }
  }, [props, props.selectedOptions, actionOptions]);

  return (
    <Combobox
      {...disclosure}
      onClose={handleClose}
      multiple={isDateRangeFilter ? false : true}
      isEqual={(a: ComboboxOption<unknown>, b: ComboboxOption<unknown>) => {
        return JSON.stringify(a.value) === JSON.stringify(b.value);
      }}
      // @ts-expect-error combobox types don't support generic options
      selectedOptions={props.selectedOptions}
      // @ts-expect-error combobox types don't support generic options
      setSelectedOptions={props.onChange}
      // @ts-expect-error combobox types don't support generic options
      options={actionOptions || []}
      // @ts-expect-error combobox types don't support generic options
      optionToString={props.optionToString || ((opt) => (opt?.value || "").toString())}
      // @ts-expect-error combobox types don't support generic options
      filterOptions={
        typeof props.filter.items === "function"
          ? async (inputValue: string): Promise<FilterItem[]> => {
            return await getActionOptionsForItemFn(inputValue);
          }
          : (inputValue: string): Promise<FilterItem[]> => {
            if (props.filterFunction) {
              // @ts-expect-error combobox types don't support generic options
              return props.filterFunction(inputValue, actionOptions as QuickFilterItem[]);
            }
            // @ts-expect-error combobox types don't support generic options
            return actionOptions.filter(option => {
              const value = option.value?.toString().toLowerCase() || "";
              const label = option.label?.toString().toLowerCase() || "";
              return (
                value.includes(inputValue.toLowerCase()) ||
                label.includes(inputValue.toLowerCase()));
            });
          }
      }
    >
      <ComboboxTrigger size="lg">{props.filter.label}</ComboboxTrigger>
      <ComboboxContent>
        {props.filter.showSearchbar && (
          <>
            <ComboboxInput size={"xl"} />
            <Divider my={3.5} palette="neutral.separator" />
          </>
        )}
        {props.filter.selectAll
          && <Box px={3} py={2.5}>
            <Link
              palette="primary"
              as="button"
              size="md"
              justifyContent="start"
              onClick={() => props.onChange(allSelected ? [] : actionOptions as FilterItem[])}
            >
              {allSelected ? translations.clearAll : translations.selectAll}
            </Link>
          </Box>}
        <ComboboxItems />
        {!props.filter.applyOnChange && (
          <>
            <Divider my={2.5} palette="neutral.separator" />
            <Flex gap={3} justifyContent={"flex-end"}>
              <Button size="lg" variant="outline.soft" onClick={props.onClear}>
                {translations.clear}
              </Button>
              <Button
                size="lg"
                variant={"primary"}
                onClick={() => {
                  props.onApply(props.selectedOptions);
                  disclosure.onClose();
                }}
              >
                {translations.apply}
              </Button>
            </Flex>
          </>
        )}
      </ComboboxContent>
    </Combobox>
  );
};

function generateCustomDateRangeOption(
  filterItem: FilterItem,
  props: SelectFilterProps,
  datePickerTranslations: DatePickerTranslations,
  translations: FiltersTranslations,
  modals: ModalsContextValue,
): FilterItem | ComboboxOption<unknown> {
  return {
    ...filterItem,
    onClick: () => {
      let initialDateRange: DateRangeValue | undefined;
      let activeDateRange: DateRangeValue | undefined;
      const hasTimeField = props.filter.type === "date-range-with-time";

      if (props.selectedOptions && props.selectedOptions.length > 0) {
        const currentDates = props.selectedOptions.at(0)?.value as
          | DateRange
          | undefined;
        if (currentDates && currentDates.startDate && currentDates.endDate) {
          initialDateRange = {
            start: parseAbsoluteToLocal(currentDates?.startDate?.toISOString()),
            end: parseAbsoluteToLocal(currentDates?.endDate?.toISOString()),
          };
        }
      }

      const id = modals.open({
        title: filterItem.label,
        contentProps: {
          minW: "fit-content",
        },
        onClose: () => {
          if (initialDateRange) {
            props.onApply([
              amendDateRangeFilterItem(
                filterItem,
                initialDateRange,
                hasTimeField,
              ),
            ]);
          }
        },
        body: (
          <>
            <DateRangePicker
              defaultValue={
                initialDateRange?.start && initialDateRange?.end
                  ? { start: initialDateRange.start, end: initialDateRange.end }
                  : undefined
              }
              granularity="minute"
              onChange={(update) => (activeDateRange = update)}
            >
              <DateRangePickerCalendar />
              {hasTimeField && <DateRangePickerTimeField />}
            </DateRangePicker>
          </>
        ),
        footer: (
          <>
            <Button
              onClick={() => {
                if (initialDateRange) {
                  props.onApply([
                    amendDateRangeFilterItem(
                      filterItem,
                      initialDateRange,
                      hasTimeField,
                    ),
                  ]);
                }
                modals.close(id);
              }}
              mr="3"
              variant={"ghost"}
            >
              {datePickerTranslations.cancel}
            </Button>
            <Button
              onClick={() => {
                if (activeDateRange) {
                  props.onApply([
                    amendDateRangeFilterItem(
                      filterItem,
                      activeDateRange,
                      hasTimeField,
                    ),
                  ]);
                  initialDateRange = activeDateRange;
                }
                modals.close(id);
              }}
            >
              {translations.apply}
            </Button>
          </>
        ),
      });
    },
  };
}

function amendDateRangeFilterItem(
  filterItem: FilterItem,
  dateRange: DateRangeValue,
  hasTimeField: boolean,
): QuickFilterItem {
  const startDate: Date | undefined =
    dateRange?.start?.toDate(getLocalTimeZone());
  const endDate: Date | undefined = dateRange?.end?.toDate(getLocalTimeZone());
  let rangeValue: DateRange | undefined;
  if (startDate && endDate) {
    rangeValue = {
      startDate: hasTimeField ? startDate : startOfDay(startDate),
      endDate: hasTimeField ? endDate : endOfDay(endDate),
    };
  }
  return { ...filterItem, value: rangeValue };
}
