import React from "react";
import {
  NProgress as ChakraNProgress,
  NProgressProps as ChakraNProgressProps,
} from "@saas-ui/react";
import { Box } from "@chakra-ui/react";
import { sizeOptions } from "./theme/themeOptions";

export interface NProgressProps extends ChakraNProgressProps {}

export default function NProgress(props: NProgressProps) {
  const { size, ...rest } = props;
  const containerHeight = size === sizeOptions.sm ? 2 : 1;

  return (
    <Box
      position="relative"
      width="100%"
      borderRadius="full"
      overflow="hidden"
      height={containerHeight}
    >
      <ChakraNProgress position="absolute" size={size} {...rest} />
    </Box>
  );
}
