import { avatarAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, cssVar } from "@chakra-ui/react";
import { sizeOptions } from "./themeOptions";
import typography from "../../../clayTheme/foundations/typography";

const { definePartsStyle } = createMultiStyleConfigHelpers(avatarAnatomy.keys);

const $fs = cssVar("avatar-font-size");
const $size = cssVar("avatar-size");

type fontSizeOption = keyof typeof typography.fontSizes;

function getBox(
  borderWidth: string,
  fontSize: fontSizeOption | string,
  boxRatio: number = 2.5,
) {
  const size = typography.fontSizes[fontSize as fontSizeOption]
    ? typography.fontSizes[fontSize as fontSizeOption]
    : fontSize;

  return {
    container: {
      [$size.variable]: `calc(${size} * ${boxRatio})`,
      [$fs.variable]: size,
      borderWidth: borderWidth,
    },
    excessLabel: {
      [$size.variable]: `calc(${size} * ${boxRatio})`,
      [$fs.variable]: size,
      borderWidth: borderWidth,
    },
  };
}

export default {
  sizes: {
    [sizeOptions.xs]: definePartsStyle({
      badge: {
        boxSize: 1,
        bottom: "px",
        right: "px",
      },
      ...getBox("0.8px", "0.375rem"), // no font token
    }),
    [sizeOptions.sm]: definePartsStyle({
      badge: {
        boxSize: 1.5,
        bottom: "px",
        right: "px",
      },
      ...getBox("0.8px", "0.5rem"), // no font token
    }),
    [sizeOptions.md]: definePartsStyle({
      badge: {
        boxSize: 2,
      },
      ...getBox("1px", "0.625rem", 2.4), // no font token
    }),
    [sizeOptions.lg]: definePartsStyle({
      badge: {
        boxSize: 2.5,
      },
      ...getBox("1.5px", "md", 16 / 7),
    }),
    [sizeOptions.xl]: definePartsStyle({
      badge: {
        boxSize: 3,
      },
      ...getBox("2px", "lg"),
    }),
    [sizeOptions["2xl"]]: definePartsStyle({
      badge: {
        boxSize: 4,
      },
      ...getBox("2.5px", "xl", 8 / 3),
    }),
    [sizeOptions["3xl"]]: definePartsStyle({
      badge: {
        boxSize: 5,
      },
      ...getBox("3px", "2xl", 2.8),
    }),
  },
};
