import React from "react";
import {
  chakra,
  forwardRef,
  HTMLChakraProps,
  useTabsStyles,
  useTab,
  useTabsContext,
  UseTabOptions,
} from "@chakra-ui/react";
import { defineStyle } from "@chakra-ui/styled-system";
import { cx } from "@chakra-ui/utils";
import { tabVariantOptions } from "./theme/themeOptions";

export interface TabProps extends UseTabOptions, HTMLChakraProps<"button"> {
  variant?: keyof typeof tabVariantOptions;
}

/**
 * Tab button used to activate a specific tab panel. It renders a `button`,
 * and is responsible for automatic and manual selection modes.
 */
export const Tab = forwardRef<TabProps, "button">(function Tab(props, ref) {
  const styles = useTabsStyles();
  const tabProps = useTab({ ...props, ref });
  const { children, ...propsWithoutChildren } = tabProps;
  const tabsContext = useTabsContext();

  const variantStyles =
    props.variant === tabVariantOptions.icon
      ? {
          "& .chakra-icon": {
            fontSize: "lg",
          },
          justifyContent:
            tabsContext.orientation === "vertical" ? "start" : "center",
          paddingX: 3,
          _disabled: {
            opacity: 0.5,
            cursor: "not-allowed",
            _active: {
              background: "transparent",
            },
            _hover: {
              background: "transparent",
              _after: {
                content: "none",
              },
            },
          },
        }
      : {};

  const tabStyles = defineStyle({
    outline: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ...styles.tab,
    ...variantStyles,
  });

  return (
    <chakra.button
      {...propsWithoutChildren}
      className={cx("chakra-tabs__tab", props.className)}
      __css={tabStyles}
    >
      <chakra.span>{children}</chakra.span>
    </chakra.button>
  );
});

Tab.displayName = "Tab";
