import { defineStyleConfig } from "@chakra-ui/react";
import { variantOptions } from "./themeOptions";

const IconCheckbox = defineStyleConfig({
  baseStyle: (props) => {
    return {
      position: "relative",
      p: props.src ? 0 : 1.5,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "pairs.gray.50",
      borderRadius: "sm",
      cursor: "pointer",
      height: "2rem",
      width: "2rem",
      fontSize: "lg",
      // https://stackoverflow.com/a/46677156
      "& .chakra-icon": {
        pointerEvents: "none",
      },
      _after: {
        content: "''",
        borderRadius: "sm",
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        boxShadow:
          "inset 0px 0px 0px 0.05rem var(--chakra-colors-borders-neutral-hush-resting)",
      },
      _hover: {
        _after: {
          boxShadow:
            "inset 0px 0px 0px 0.05rem var(--chakra-colors-borders-primary-strong-hover)",
        },
      },
      _checked: {
        _after: {
          boxShadow:
            "inset 0px 0px 0px 0.125rem var(--chakra-colors-borders-primary-strong-hover)",
        },
        _hover: {
          _after: {
            shadow:
              "inset 0px 0px 0px 0.125rem var(--chakra-colors-borders-primary-strong-hover), var(--chakra-shadows-focus)",
          },
        },
      },
      _focusVisible: {
        shadow: "focus",
      },
      _active: {
        borderColor: "borders.primary.strong.active",
      },
      _disabled: {
        border: "none",
        cursor: "not-allowed",
        opacity: 0.4,
        _hover: {
          border: "none",
          shadow: "none",
        },
      },
    };
  },
  variants: {
    [variantOptions.purple]: {
      backgroundColor: "purple.100",
    },
    [variantOptions.yellow]: {
      backgroundColor: "yellow.300",
    },
    [variantOptions.green]: {
      backgroundColor: "green.200",
    },
  },
  defaultProps: {
    colorScheme: "",
  },
});

export default IconCheckbox;
