export enum sizeOption {
  xs = "xs",
  sm = "sm",
  md = "md",
  lg = "lg",
  xl = "xl",
  "2xl" = "2xl",
  "3xl" = "3xl",
}

export const sizes = {
  [sizeOption.xs]: {
    height: 4,
    width: 4,
  },
  [sizeOption.sm]: {
    height: 5,
    width: 5,
  },
  [sizeOption.md]: {
    height: 6,
    width: 6,
  },
  [sizeOption.lg]: {
    height: 8,
    width: 8,
  },
  [sizeOption.xl]: {
    height: 10,
    width: 10,
  },
  [sizeOption["2xl"]]: {
    height: 12,
    width: 12,
  },
  [sizeOption["3xl"]]: {
    height: 14,
    width: 14,
  },
};
