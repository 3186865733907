import { defineStyleConfig } from "@chakra-ui/react";
import {
  sizes,
  sizeOption,
} from "../../../clayTheme/components/dashedButtonStyles";
import { baseStyle as iconButtonBaseStyle } from "../../../clayTheme/components/iconButtonCommonStyles";

const CompleteButton = defineStyleConfig({
  baseStyle: (props) => ({
    ...iconButtonBaseStyle(),
    backgroundColor: props.isComplete
      ? "surfaces.success.dominant.resting"
      : "components.inputs.bgRestingTransparent",
    borderWidth: "xs",
    borderStyle: props.isComplete
      ? "surfaces.success.dominant.resting"
      : "dashed",
    borderColor: props.isComplete
      ? "surfaces.success.dominant.resting"
      : "borders.neutral.strong.resting",
    color: props.isComplete ? "text.onSuccessDominant" : "icon.neutralStrong",
    _focusVisible: {
      shadow: "focus",
      outline: "none",
    },
    _hover: {
      backgroundColor: props.isComplete
        ? "surfaces.success.dominant.hover"
        : "surfaces.alpha.dominant.50",
      borderColor: props.isComplete
        ? "surfaces.success.dominant.hover"
        : undefined,
    },
    _active: {
      backgroundColor: props.isComplete
        ? "surfaces.success.dominant.resting"
        : "components.inputs.bgRestingTransparent",
      borderColor: props.isComplete
        ? "surfaces.success.dominant.resting"
        : undefined,
    },
    _disabled: {
      color: props.isComplete ? "text.onSuccessLight" : "text.disabledOnSoft",
      borderColor: props.isComplete
        ? "surfaces.success.strong.resting"
        : "borders.disabled.soft.resting",
      backgroundColor: props.isComplete
        ? "surfaces.success.strong.resting"
        : "components.inputs.bgRestingTransparent",
      cursor: "not-allowed",
      _hover: {
        color: props.isComplete ? "text.onSuccessLight" : "text.disabledOnSoft",
        borderColor: props.isComplete
          ? "surfaces.success.strong.resting"
          : "borders.disabled.soft.resting",
        backgroundColor: props.isComplete
          ? "surfaces.success.strong.resting"
          : "components.inputs.bgRestingTransparent",
      },
    },
  }),
  sizes: sizes,
  defaultProps: {
    size: sizeOption.md,
  },
});

export default CompleteButton;
