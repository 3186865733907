import { IconButton } from "../../IconButton";
import { IconButtonProps } from "@chakra-ui/react";
import React, { ReactElement } from "react";

export type TextareaActionProps = Omit<IconButtonProps, "aria-label"> & {
  isDisabled?: boolean;
  icon: ReactElement;
  label: string;
};

// NOTE: the emoji picker is experimental and does not include translations
export const TextareaAction = React.forwardRef<
  HTMLButtonElement,
  TextareaActionProps
>(function ForwardedTextareaAction({ isDisabled, icon, label, ...rest }, ref) {
  return (
    <IconButton
      ref={ref}
      {...rest}
      shape="square"
      isDisabled={isDisabled}
      icon={icon}
      size="lg"
      variant="ghost"
      aria-label={label}
      tooltipLabel={label}
    />
  );
});
