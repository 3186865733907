import { defineStyleConfig } from "@chakra-ui/react";
import {
  baseStyle as iconButtonBaseStyle,
  sizeOption,
} from "../../../clayTheme/components/iconButtonCommonStyles";
import { variantOptions } from "./themeOptions";

export default defineStyleConfig({
  baseStyle: () => ({
    ...iconButtonBaseStyle(),
    backgroundColor: "components.inputs.bgRestingTransparent",
    position: "relative",
    _focusVisible: {
      shadow: "focus",
    },
    "& .chakra-icon": {
      display: "none",
    },
    _before: {
      content: "''",
      mask: "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' focusable='false' class='chakra-icon chakra-icon mjolnir-nnd6fl'%3E%3Cpath fill='currentColor' d='M420.7 36.7c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6L246.6 256 443.3 452.7c6.2 6.2 6.2 16.4 0 22.6s-16.4 6.2-22.6 0L224 278.6 27.3 475.3c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6L201.4 256 4.7 59.3c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L224 233.4 420.7 36.7z'%3E%3C/path%3E%3C/svg%3E\")",
      maskRepeat: "no-repeat",
      maskPosition: "center",
      backgroundColor: "currentColor",
    },
  }),
  sizes: {
    [sizeOption.sm]: {
      height: 5,
      width: 5,
      _before: {
        height: "0.5rem",
        width: "0.5rem",
      },
    },
    [sizeOption.md]: {
      height: 6,
      width: 6,
      _before: {
        height: "0.625rem",
        width: "0.625rem",
      },
    },
    [sizeOption.lg]: {
      height: 8,
      width: 8,
      _before: {
        height: "0.875rem",
        width: "0.875rem",
      },
    },
  },
  defaultProps: {
    size: sizeOption.lg,
    variant: variantOptions.onLight,
  },
  variants: {
    chameleon: {
      color: "currentColor",
      _hover: {
        backgroundColor: "surfaces.alpha.dominant.50",
      },
      _active: {
        backgroundColor: "components.inputs.bgRestingTransparent",
      },
      _disabled: {
        color: "text.disabledOnSoft",
      },
    },
    onLight: {
      color: "text.neutralDominant",
      _hover: {
        backgroundColor: "surfaces.alpha.dominant.50",
      },
      _active: {
        backgroundColor: "components.inputs.bgRestingTransparent",
      },
      _disabled: {
        color: "text.disabledOnSoft",
      },
    },
    onDark: {
      color: "text.onPrimaryStrong",
      _hover: {
        backgroundColor: "surfaces.alpha.soft.50",
      },
      _active: {
        backgroundColor: "components.inputs.bgRestingTransparent",
      },
      _disabled: {
        color: "text.disabledOnStrong",
      },
    },
  },
});
