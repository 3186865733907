import { SystemStyleFunction } from "@chakra-ui/theme-tools";

const baseStyle: SystemStyleFunction = () => {
  return {
    px: "0.1rem",
    textAlign: "end",
    outline: "none",
    rounded: "md",
    ["&[data-placeholder]"]: {
      color: "text.placeholder",
    },
    _focus: {
      background: `components.datePicker.bgRangeEnds`,
      color: "white",
    },
    "&[data-read-only]": {
      bg: "transparent",
    },
  };
};

export default {
  baseStyle,
  defaultProps: {
    colorScheme: "primary",
  },
};
