import React from "react";
import { ThemingProps, Box, BoxProps, useStyleConfig } from "@chakra-ui/react";
import { sizeOptions, variantOptions } from "./theme/themeOptions";
import {
  TikTok,
  Apple,
  X,
  AdobeAcrobat,
  FALCirclePlay,
  FALImage,
  FALLinkSimple,
  FALListUl,
  FALPuzzlePiece,
  Figma,
  Google,
  GoogleBusiness,
  GoogleDocs,
  GoogleForms,
  GoogleSheets,
  GoogleSlides,
  InstagramOutline,
  Linkedin,
  MSExcel,
  MSPowerpoint,
  MSWord,
} from "../../icons";
import { FacebookOnLight, Instagram, Herold } from "../../icons/ManualIcons";

export interface AssetBadgeProps extends BoxProps, ThemingProps<"AssetBadge"> {}

const variantToIconMap = (variant: string, size: string) => {
  const faSize = size === "md" ? "xs" : "lg";

  const logoSize = size === "md" ? 2.5 : 3;

  return {
    [variantOptions.list]: <FALListUl iconSize={faSize} />,
    [variantOptions.image]: <FALImage iconSize={faSize} />,
    [variantOptions.video]: <FALCirclePlay iconSize={faSize} />,
    [variantOptions.link]: <FALLinkSimple iconSize={faSize} />,
    [variantOptions.pdf]: <AdobeAcrobat boxSize={logoSize} />,
    [variantOptions.word]: <MSWord boxSize={logoSize} />,
    [variantOptions.excel]: <MSExcel boxSize={logoSize} />,
    [variantOptions.powerpoint]: <MSPowerpoint boxSize={logoSize} />,
    [variantOptions.figma]: <Figma boxSize={logoSize} />,
    [variantOptions.docs]: <GoogleDocs boxSize={logoSize} />,
    [variantOptions.slides]: <GoogleSlides boxSize={logoSize} />,
    [variantOptions.sheets]: <GoogleSheets boxSize={logoSize} />,
    [variantOptions.forms]: <GoogleForms boxSize={logoSize} />,
    [variantOptions.facebook]: <FacebookOnLight boxSize={logoSize} />,
    [variantOptions.x]: <X boxSize={logoSize} />,
    [variantOptions.instagram]: <InstagramOutline boxSize={logoSize} />,
    [variantOptions.tiktok]: <TikTok boxSize={logoSize} />,
    [variantOptions.linkedin]: <Linkedin boxSize={logoSize} />,
    [variantOptions.apple]: <Apple boxSize={logoSize} />,
    [variantOptions["google-business"]]: <GoogleBusiness boxSize={logoSize} />,
    [variantOptions.google]: <Google boxSize={logoSize} />,
    [variantOptions.herold]: <Herold boxSize={logoSize} />,
    [variantOptions.widget]: <FALPuzzlePiece iconSize={faSize} />,
    "": <FALListUl iconSize={faSize} />,
  }[variant];
};

export default function AssetBadge(props: AssetBadgeProps) {
  const { variant, size } = props;

  const styles = useStyleConfig("AssetBadge", props);

  if (variant === variantOptions.instagram) {
    return <Instagram __css={styles} />;
  }

  const badgeSize = size || sizeOptions.md;
  const badgeVariant = variant || "";

  const icon = variantToIconMap(badgeVariant.toString(), badgeSize.toString());

  return <Box __css={styles}>{icon}</Box>;
}
