import { defineStyleConfig } from "@chakra-ui/react";
import { toTitleCase } from "../../../utils/string";
import { statusOptions } from "./themeOptions";

function statusStyles(status: statusOptions) {
  const statusToColors = {
    [statusOptions.info]: ["neutral", "dominant"],
    [statusOptions.system]: ["primary", "strong"],
    [statusOptions.loading]: ["secondary", "strong"],
    [statusOptions.warning]: ["warning", "strong"],
    [statusOptions.danger]: ["danger", "strong"],
    [statusOptions.success]: ["success", "dominant"],
  };

  return {
    backgroundColor: `surfaces.${statusToColors[status][0]}.${statusToColors[status][1]}.resting`,
    color: `text.on${toTitleCase(statusToColors[status][0])}${toTitleCase(statusToColors[status][1])}`,
  };
}

const Toast = defineStyleConfig({
  baseStyle: ({ status }) => {
    return {
      borderRadius: "lg",
      fontSize: "sm",
      display: "flex",
      flexDirection: "row",
      padding: 4,
      width: "24rem",
      maxWidth: "24rem",
      alignItems: "start",
      ...statusStyles(status),
    };
  },
});

export default Toast;
