import React, { useContext } from "react";
import {
  AlertDialog as ChakraAlertDialog,
  AlertDialogBody as ChakraAlertDialogBody,
  AlertDialogFooter as ChakraAlertDialogFooter,
  AlertDialogHeader as ChakraAlertDialogHeader,
  AlertDialogContent as ChakraAlertDialogContent,
  AlertDialogOverlay as ChakraAlertDialogOverlay,
  AlertDialogCloseButton as ChakraAlertDialogCloseButton,
  AlertDialogProps as ChakraAlertDialogProps,
  ModalHeaderProps as ChakraAlertDialogHeaderProps,
  ModalBodyProps as ChakraAlertDialogBodyProps,
  ModalFooterProps as ChakraAlertDialogFooterProps,
  ModalContentProps as ChakraAlertDialogContentProps,
  ModalOverlayProps as ChakraAlertDialogOverlayProps,
  ModalCloseButtonProps as ChakraAlertDialogCloseButtonProps,
  Flex,
  ThemingProps,
} from "@chakra-ui/react";
import { variantOptions } from "./theme/themeOptions";
import { AlertDialogContext } from "./AlertDialogContext";
import {
  FALCircleExclamation,
  FALCircleInfo,
  FALTriangleExclamation,
} from "../../icons";

export interface AlertDialogProps
  extends Omit<ChakraAlertDialogProps, "size" | "variant">,
    ThemingProps<"AlertDialog"> {
  variant: keyof typeof variantOptions;
}

export function AlertDialog(props: AlertDialogProps) {
  const { variant, size, ...rest } = props;

  return (
    <AlertDialogContext.Provider value={{ variant }}>
      <ChakraAlertDialog size={size} {...rest} />
    </AlertDialogContext.Provider>
  );
}

export function AlertDialogBody(props: ChakraAlertDialogBodyProps) {
  return <ChakraAlertDialogBody {...props} />;
}

export function AlertDialogFooter(props: ChakraAlertDialogFooterProps) {
  return <ChakraAlertDialogFooter {...props} />;
}

export function AlertDialogHeader(props: ChakraAlertDialogHeaderProps) {
  const { children, ...rest } = props;
  const theme = useContext(AlertDialogContext);

  let alertIconStyles = {
    icon: <></>,
    background: "",
    borderColor: "",
    color: "",
  };

  if (theme.variant === "neutral") {
    alertIconStyles = {
      icon: <FALCircleInfo iconSize="lg" />,
      background: "pairs.gray.600",
      borderColor: "borders.alpha.neutral",
      color: "text.onNeutralDominant",
    };
  } else if (theme.variant === "system") {
    alertIconStyles = {
      icon: <FALCircleInfo iconSize="lg" />,
      background: "blue.300",
      borderColor: "borders.alpha.primary",
      color: "text.onPrimaryStrong",
    };
  } else if (theme.variant === "warning") {
    alertIconStyles = {
      icon: <FALTriangleExclamation iconSize="lg" />,
      background: "surfaces.warning.strong.resting",
      borderColor: "borders.alpha.warning",
      color: "text.onWarningStrong",
    };
  } else if (theme.variant === "danger") {
    alertIconStyles = {
      icon: <FALCircleExclamation iconSize="lg" />,
      background: "surfaces.danger.dominant.resting",
      borderColor: "borders.alpha.danger",
      color: "text.onDangerDominant",
    };
  }

  return (
    <ChakraAlertDialogHeader {...rest}>
      <Flex flexDirection="row" alignItems="top" justifyContent="flex-start">
        <Flex
          as="span"
          flexShrink={0}
          me={3}
          height={6}
          width={6}
          borderRadius="full"
          borderWidth="0.25rem"
          boxSizing="content-box"
          borderColor={alertIconStyles.borderColor}
          background={alertIconStyles.background}
          backgroundClip="content-box"
          color={alertIconStyles.color}
          alignItems="center"
          justifyContent="center"
        >
          {alertIconStyles.icon}
        </Flex>
        <Flex as="span" mt="1px">
          {children}
        </Flex>
      </Flex>
    </ChakraAlertDialogHeader>
  );
}

export function AlertDialogContent(props: ChakraAlertDialogContentProps) {
  return <ChakraAlertDialogContent {...props} />;
}

export function AlertDialogOverlay(props: ChakraAlertDialogOverlayProps) {
  return <ChakraAlertDialogOverlay {...props} />;
}

export function AlertDialogCloseButton(
  props: ChakraAlertDialogCloseButtonProps,
) {
  return <ChakraAlertDialogCloseButton {...props} />;
}
