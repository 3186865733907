import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(["container"]);

export default defineMultiStyleConfig({
  baseStyle: {
    container: {
      ".mjolnir-data-grid & ": {
        paddingX: 6,
        paddingTop: 8,
        paddingBottom: 4,
      },
    },
  },
});
