declare global {
  interface Window {
    currentLanguageFallbacks?: string[];
  }
}

/**
 * Get the current language codes for the account (if running in Storm) or
 * fallback to the browser's set languages.
 */
export function getLanguages() {
  if (
    window["currentLanguageFallbacks"] &&
    Array.isArray(window["currentLanguageFallbacks"])
  ) {
    return window["currentLanguageFallbacks"].slice();
  } else {
    return navigator.languages.slice();
  }
}

/**
 * Get the current language code for the account (if running in Storm) or
 * fallback to the browser's set language.
 *
 * Prefer using getLanguages as this function does not return the full list of
 * fallback languages.
 */
export function getLanguage() {
  return getLanguages()[0];
}
