import React, { ForwardRefRenderFunction, forwardRef, useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  HStack,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  ChakraProps,
} from "@chakra-ui/react";
import { Textarea, TextareaProps } from "../Textarea";
import { Divider } from "../Divider";
import { Combobox } from "../Combobox";
import { ComboboxContent } from "../Combobox/ComboboxContent";
import { ComboboxTrigger } from "../Combobox/ComboboxTrigger";
import {
  createForm,
  useBaseField,
  GetBaseField,
  useWatch,
  BaseFieldProps,
  FieldValues,
  splitProps,
  createField,
  useFormContext,
  createFormDialog,
} from "@saas-ui/react";
import { FALCircleExclamation } from "../../icons";
import { ComboboxInput } from "../Combobox/ComboboxInput";
import { ComboboxItems } from "../Combobox/ComboboxItems";
import {
  ComboboxProps,
  ComboboxSelectOption,
} from "../Combobox/ComboboxContext";
import { Labels, LabelsProps } from "../Labels";
import { Label } from "../Labels/types";

const getBaseField: GetBaseField<{
  maxLength?: number;
}> = () => {
  return {
    extraProps: ["maxLength"],
    BaseField: BaseField,
  };
};

const BaseField = (
  props: Omit<BaseFieldProps<FieldValues, string>, "name"> & {
    name: string;
    children: React.ReactNode;
  } & {
    maxLength?: number | undefined;
  },
) => {
  const [{ children, maxLength }, fieldProps] = splitProps(props, [
    "children",
    "maxLength",
  ]);

  const { controlProps, label, help, error } = useBaseField(fieldProps);

  const formLabelStyle = {
    _disabled: {
      color: "text.disabled",
      opacity: 1,
    },
  };

  return (
    <FormControl {...controlProps} isInvalid={!!error}>
      <HStack alignItems="center" mb="2" spacing="0">
        <FormLabel mb="0" sx={formLabelStyle}>
          {label}
        </FormLabel>
      </HStack>
      <Box>
        {children}
        {(help || error?.message || props.type === "textarea") && (
          <Flex mt={2} justifyContent={"space-between"} alignItems="center">
            <Box display="flex" alignItems={"center"}>
              {help && !error?.message ? (
                <FormHelperText
                  className={
                    controlProps.isDisabled
                      ? "form-helper-text-disabled"
                      : undefined
                  }
                >
                  {help}
                </FormHelperText>
              ) : null}
              {error?.message && (
                <FormErrorMessage>
                  <FALCircleExclamation me={1.5} />
                  {error?.message}
                </FormErrorMessage>
              )}
            </Box>
            {props.type === "textarea" && (
              <TextareaCount
                ml={"auto"}
                name={props.name}
                maxLength={maxLength}
                formIsDisabled={controlProps.isDisabled}
              />
            )}
          </Flex>
        )}
      </Box>
    </FormControl>
  );
};

interface TextareaCountProps extends ChakraProps {
  name: string;
  type?: string;
  maxLength?: number;
  formIsDisabled?: boolean;
}

function TextareaCount(props: TextareaCountProps) {
  const { name, maxLength, formIsDisabled, ...rest } = props;

  const fieldValue = useWatch({ name: props.name }) || "";

  let textareaCountColor = "text.neutralStrong";

  if (formIsDisabled) {
    textareaCountColor = "text.disabled";
  }

  // Show text.danger for this condition even if the form is disabled
  if (maxLength && fieldValue.length > maxLength) {
    textareaCountColor = "text.danger";
  }

  return (
    <Box
      color={textareaCountColor}
      textStyle="xs"
      {...rest}
      data-testid={"textarea-count"}
    >
      {fieldValue.length}
      {props.maxLength && ` / ${props.maxLength}`}
    </Box>
  );
}

const CustomInput = createField(
  forwardRef<HTMLInputElement | null, { icon?: React.ReactNode }>(
    function CustomInput(props, ref) {
      return (
        <InputGroup>
          {props.icon && (
            <InputLeftElement fontSize="md">{props.icon}</InputLeftElement>
          )}
          <Input ref={ref} {...props} />
        </InputGroup>
      );
    },
  ),
);

const ForwardedTextAreaRenderFunction: ForwardRefRenderFunction<
  HTMLTextAreaElement,
  TextareaProps
> = (props, ref) => {
  return <Textarea ref={ref} {...props} />;
};

export const TextareaField = createField(
  React.forwardRef(ForwardedTextAreaRenderFunction),
  {
    isControlled: true,
  },
);

interface CustomComboboxProps
  extends ComboboxProps<ComboboxSelectOption<unknown>> {
  name: string;
  multiple?: boolean;
}
const CustomCombobox = createField(function CustomInput(
  props: CustomComboboxProps,
) {
  const formCtx = useFormContext();
  const [selectedOptions, setSelectedOptions] = useState<
    ComboboxSelectOption<unknown>[]
  >([]);

  const setSelectedWrapper = (selected: ComboboxSelectOption<unknown>[]) => {
    setSelectedOptions(selected);
    if (props.multiple) {
      formCtx.setValue(
        props.name,
        selected.map((item) => item.value),
      );
    } else {
      if (selected.length) {
        formCtx.setValue(props.name, selected[0].value);
      }
    }
  };

  return (
    <Combobox<ComboboxSelectOption<unknown>>
      {...props}
      selectedOptions={selectedOptions}
      setSelectedOptions={setSelectedWrapper}
    >
      <ComboboxTrigger size="xl" />
      <ComboboxContent>
        <ComboboxInput size={"xl"} />
        <Divider my={2} palette="neutral.separator" />
        <ComboboxItems />
      </ComboboxContent>
    </Combobox>
  );
});

interface CustomLabelsProps
  extends Omit<
    LabelsProps,
    | "onAddLabel"
    | "onRemoveLabel"
    | "inputValue"
    | "labels"
    | "onInputValueChange"
  > {
  name: string;
}

interface CustomSelectProps
  extends ComboboxProps<ComboboxSelectOption<unknown>> {
  name: string;
  multiple?: boolean;
}
const CustomSelect = createField(function CustomInput(
  props: CustomSelectProps,
) {
  const formCtx = useFormContext();
  const [selectedOptions, setSelectedOptions] = useState<
    ComboboxSelectOption<unknown>[]
  >([]);

  const setSelectedWrapper = (selected: ComboboxSelectOption<unknown>[]) => {
    setSelectedOptions(selected);
    if (props.multiple) {
      formCtx.setValue(
        props.name,
        selected.map((item) => item.value),
      );
    } else {
      if (selected.length) {
        formCtx.setValue(props.name, selected[0].value);
      }
    }
  };

  return (
    <Combobox<ComboboxSelectOption<unknown>>
      {...props}
      selectedOptions={selectedOptions}
      setSelectedOptions={setSelectedWrapper}
    >
      <ComboboxTrigger size="xl" />
      <ComboboxContent>
        <ComboboxItems />
      </ComboboxContent>
    </Combobox>
  );
});

const CustomLabels = createField(
  function CustomInput(props: CustomLabelsProps) {
    const formCtx = useFormContext();
    const [inputValue, setInputValue] = useState("");
    const [selectedLabels, setSelectedLabels] = useState<Label[]>([]);

    const onAddLabel = (newLabel: Label) => {
      setSelectedLabels((labels) => {
        const newLabels = [...labels, newLabel];
        formCtx.setValue(props.name, newLabels);
        return newLabels;
      });
      setInputValue("");
    };

    const onRemoveLabel = (labelToRemove: Label) => {
      setSelectedLabels((labels) => {
        const newLabels = labels.filter((label) => label !== labelToRemove);
        formCtx.setValue(props.name, newLabels);
        return newLabels;
      });

      setInputValue("");
    };

    return (
      <Labels
        labels={selectedLabels}
        inputValue={inputValue}
        onInputValueChange={(e) => setInputValue(e)}
        onAddLabel={onAddLabel}
        onRemoveLabel={onRemoveLabel}
        {...props}
      />
    );
  },
  { isControlled: true },
);

export const Form = createForm({
  getBaseField,
  fields: {
    text: CustomInput,
    textarea: TextareaField,
    combobox: CustomCombobox,
    labels: CustomLabels,
    select: CustomSelect,
  },
});

export const FormDialog = createFormDialog(Form);
