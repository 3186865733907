import { modalAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";
import { sizeOptions } from "./themeOptions";
import typography from "../../../clayTheme/foundations/typography";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(modalAnatomy.keys);

const sizes = {
  [sizeOptions.xs]: definePartsStyle({
    dialog: defineStyle({
      width: "20rem",
    }),
  }),
  [sizeOptions.sm]: definePartsStyle({
    dialog: defineStyle({
      width: "24rem",
    }),
  }),
  [sizeOptions.md]: definePartsStyle({
    dialog: defineStyle({
      width: "28rem",
    }),
  }),
  [sizeOptions.lg]: definePartsStyle({
    dialog: defineStyle({
      width: "32rem",
    }),
  }),
  [sizeOptions.xl]: definePartsStyle({
    dialog: defineStyle({
      width: "36rem",
    }),
  }),
  [sizeOptions["2xl"]]: definePartsStyle({
    dialog: defineStyle({
      width: "42rem",
    }),
  }),
  [sizeOptions["3xl"]]: definePartsStyle({
    dialog: defineStyle({
      width: "48rem",
    }),
  }),
  [sizeOptions["4xl"]]: definePartsStyle({
    dialog: defineStyle({
      width: "56rem",
    }),
  }),
  [sizeOptions["5xl"]]: definePartsStyle({
    dialog: defineStyle({
      width: "64rem",
    }),
  }),
  [sizeOptions["6xl"]]: definePartsStyle({
    dialog: defineStyle({
      width: "72rem",
    }),
  }),
};

const baseStyle = definePartsStyle(() => {
  return {
    overlay: {
      background: "blackAlpha.300",
    },
    dialog: {
      shadow: "none",
      backgroundColor: "surfaces.lifted.white",
      borderWidth: "xs",
      borderColor: "borders.neutral.hush.resting",
      borderStyle: "solid",
      borderRadius: "xl",
    },
    header: {
      pt: 6,
      pb: 4,
      ps: 6,
      pe: 16,
      ...typography.textStyles["2xl-semibold"],
    },
    closeButton: {
      mt: 2,
    },
    body: {
      py: 2,
      px: 6,
      ...typography.textStyles.lg,
    },
    footer: {
      pt: 4,
      pb: 6,
      px: 6,
    },
  };
});

const Modal: ReturnType<typeof defineMultiStyleConfig> = defineMultiStyleConfig(
  { baseStyle, sizes },
);

export default Modal;
