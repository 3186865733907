export enum sizeOptions {
  xs = "xs",
  sm = "sm",
  md = "md",
  lg = "lg",
  xl = "xl",
  "2xl" = "2xl",
  "3xl" = "3xl",
  "4xl" = "4xl",
  "5xl" = "5xl",
  "6xl" = "6xl",
}
