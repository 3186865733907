export enum variantOptions {
  list = "list",
  image = "image",
  video = "video",
  link = "link",
  pdf = "pdf",
  word = "word",
  excel = "excel",
  powerpoint = "powerpoint",
  figma = "figma",
  docs = "docs",
  slides = "slides",
  sheets = "sheets",
  forms = "forms",
  facebook = "facebook",
  x = "x",
  instagram = "instagram",
  tiktok = "tiktok",
  linkedin = "linkedin",
  apple = "apple",
  "google-business" = "google-business",
  google = "google",
  herold = "herold",
  widget = "widget",
}

export enum sizeOptions {
  md = "md",
  lg = "lg",
}
