import { defineStyleConfig } from "@chakra-ui/react";

const Kbd = defineStyleConfig({
  variants: {
    solid: {
      bg: "components.hotkey.bgSoft",
      color: "components.hotkey.textSoft",
      borderRadius: "md",
      borderWidth: "sm",
      borderBottomWidth: "sm",
      borderColor: "components.hotkey.borderSoft",
      padding: "0.5 1.5",
      fontSize: "xs",
      fontWeight: "semibold",
      lineHeight: "150%",
      fontFamily: "body",
      letterSpacing: "0.1px",
    },
  },
});

export default Kbd;
