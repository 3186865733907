import React from "react";
import { Box } from "@chakra-ui/react";
import { UseMultipleSelectionReturnValue } from "downshift";
import { Label as LabelType } from "./types";
import { Tag } from "../Tag";

export default function SelectedLabels({
  multiSelectState,
  labels,
  disabled,
}: {
  multiSelectState: UseMultipleSelectionReturnValue<LabelType>;
  labels: LabelType[];
  disabled: boolean;
}) {
  return labels.map((label, i) => {
    return (
      <Box
        {...multiSelectState.getSelectedItemProps({
          selectedItem: label,
          index: i,
          sx: {
            borderRadius: "full",
            _focus: {
              shadow: "focus",
              outline: "none",
            },
          },
        })}
        key={i}
      >
        <Tag
          key={i}
          tabIndex={-1}
          disabled={disabled}
          onClick={
            disabled
              ? undefined
              : (e) => {
                  // https://github.com/downshift-js/downshift/issues/1068#issuecomment-643990521
                  e.stopPropagation();
                  multiSelectState.removeSelectedItem(label);
                }
          }
        >
          {label}
        </Tag>
      </Box>
    );
  });
}
