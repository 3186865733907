import React from "react";
import {
  Radio as ChakraRadio,
  RadioProps as ChakraRadioProps,
} from "@chakra-ui/react";

export interface RadioProps extends Omit<ChakraRadioProps, "colorScheme"> {}

const Radio = (props: RadioProps) => {
  return <ChakraRadio {...props} />;
};

export default Radio;
