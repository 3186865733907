import { avatarAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

import baseStyle from "./baseStyle";
import sizes from "./sizes";

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  avatarAnatomy.keys,
);

const theme: ReturnType<typeof defineMultiStyleConfig> = defineMultiStyleConfig(
  {
    baseStyle,
    ...sizes,
  },
);

export default theme;
