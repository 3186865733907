export enum variantOptions {
  "neutral.strong" = "neutral.strong",
  "outline.soft" = "outline.soft",
}

export enum sizeOptions {
  md = "md",
  lg = "lg",
  xl = "xl",
  "2xl" = "2xl",
}
