import React, { FC, PropsWithChildren } from "react";
import { ThemeProvider } from "./ThemeProvider";
import { ThemeProps } from "./theme";
import {
  EmotionCacheProvider,
  EmotionCacheProviderProps,
} from "./EmotionCache";
import { NonceProvider } from "./NonceProvider";
import {
  TranslationProvider,
  TranslationProviderProps,
} from "./TranslationProvider";
import { ManaUIContext } from "./ManaUIContext";
import { useReset } from "./Reset";
import { ModalsProvider } from "@saas-ui/react";

export type ManaUIProps = EmotionCacheProviderProps &
  ThemeProps &
  TranslationProviderProps;

export const ManaUIProvider: FC<PropsWithChildren<ManaUIProps>> = ({
  children,
  activateGlobalStyles,
  nonce = "",
  translations,
}) => {
  useReset(nonce);

  return (
    <>
      <ManaUIContext.Provider value={true}>
        <EmotionCacheProvider nonce={nonce}>
          <NonceProvider nonce={nonce}>
            <TranslationProvider translations={translations}>
              <ThemeProvider activateGlobalStyles={activateGlobalStyles}>
                <ModalsProvider>{children}</ModalsProvider>
              </ThemeProvider>
            </TranslationProvider>
          </NonceProvider>
        </EmotionCacheProvider>
      </ManaUIContext.Provider>
    </>
  );
};
