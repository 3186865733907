import React from "react";
import { sizeOptions } from "../Input/theme/themeOptions";
import { SearchInput } from "../SearchInput";
import { useComboboxContext } from "./ComboboxContext";
import { useTranslation } from "../ManaUIProvider";
import { ChakraProps } from "@chakra-ui/react";

export interface ComboboxInputProps extends ChakraProps {
  size?: keyof typeof sizeOptions;
}
const ComboboxInput = function ComboboxInput(props: ComboboxInputProps) {
  const ctx = useComboboxContext();
  const placeholderMsg = useTranslation("Combobox.placeholder");

  if (!ctx.combobox.getInputProps) {
    return null;
  }

  return (
    <SearchInput
      size={props.size}
      placeholder={placeholderMsg}
      my={1}
      {...ctx.combobox.getInputProps({
        onReset: () => {
          ctx.combobox.setInputValue("");
        },
        ref: ctx.searchbarRef,
        // By default Downshift will consume the event and prevent it from propogating
        // which means if the control is used inside a modal the modal will not close as
        // expected. The default behavior is to close the open suggestion list, but since
        // our list is always expanded, this functionality isn't relevant to us
        onKeyDown: (e) => {
          if (e.code === "Escape") {
            const prv = e.preventDefault;
            e.preventDefault = () => (e.preventDefault = prv);
          }
        },
      })}
      {...props}
    />
  );
};

ComboboxInput.displayName = "ComboboxInput";
export { ComboboxInput };
