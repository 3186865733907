import { useMemo } from "react";

let resetInsertionPoint: HTMLMetaElement | null = null;
if (!resetInsertionPoint) {
  resetInsertionPoint = document.createElement("meta");
  resetInsertionPoint.name = "mjolnir-reset-insertion-point";
  resetInsertionPoint.content = "";
  document.head.appendChild(resetInsertionPoint);
}

let insertionPoint: HTMLMetaElement | null = null;
if (!insertionPoint) {
  insertionPoint = document.createElement("meta");
  insertionPoint.name = "mjolnir-insertion-point";
  insertionPoint.content = "";
  document.head.appendChild(insertionPoint);
}

export const useResetInsertionPoint = (): HTMLElement => {
  return useMemo(() => resetInsertionPoint!, []);
};

export const useEmotionInsertionPoint = (): HTMLElement => {
  return useMemo(() => insertionPoint!, []);
};
