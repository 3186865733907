import React from "react";
import {
  Flex,
  Text,
  ChakraProps,
  ThemingProps,
  useStyleConfig,
} from "@chakra-ui/react";

import { useTranslation } from "../ManaUIProvider";
import { sizeOptions, variantOptions } from "./theme/themeOptions";

export interface NoteProps extends ChakraProps, ThemingProps<"Note"> {
  size?: sizeOptions;
  variant?: variantOptions;
  content: string;
}

export default function Note(props: NoteProps) {
  const styles = useStyleConfig("Note", props);
  const translations = useTranslation("Note");

  return (
    <Flex __css={styles}>
      <Text fontWeight={600}>{translations.prefix}</Text>
      <Text>{props.content}</Text>
    </Flex>
  );
}
