import { defineStyleConfig } from "@chakra-ui/react";
import { shapeOptions, sizeOptions } from "./themeOptions";
import { variantOptions } from "../theme/themeOptions";

export const iconSizeFromButtonSize = (size: keyof typeof sizeOptions) => {
  return {
    xs: 3,
    md: 4,
    lg: 4,
    xl: 5,
    "2xl": 5,
  }[size];
};

const sizes = {
  [sizeOptions.xs]: {
    height: 4,
    width: 4,
  },
  [sizeOptions.md]: {
    height: 6,
    width: 6,
  },
  [sizeOptions.lg]: {
    height: 8,
    width: 8,
  },
  [sizeOptions.xl]: {
    height: 10,
    width: 10,
  },
  [sizeOptions["2xl"]]: {
    height: 12,
    width: 12,
  },
};

const variantStyles = (variant: variantOptions) => {
  if (variant === variantOptions.ghost) {
    return {
      color: "icon.neutralDominant",
      backgroundColor: "components.inputs.bgRestingTransparent",
      _hover: {
        color: "text.onNeutralSoft",
        backgroundColor: "surfaces.alpha.dominant.50",
        _disabled: {
          color: "icon.disabled",
        },
      },
      _focusVisible: {
        shadow: "focus",
        outline: "none",
      },
      _disabled: {
        color: "icon.disabled",
        cursor: "not-allowed",
        _hover: {
          backgroundColor: "components.inputs.bgRestingTransparent",
        },
      },
    };
  } else if (variant === variantOptions["neutral.dominant"]) {
    return {
      backgroundColor: "surfaces.neutral.dominant.resting",
      color: "icon.onNeutralDominant",
      _hover: {
        backgroundColor: "surfaces.neutral.dominant.hover",
      },
      _focusVisible: {
        shadow: "focus",
        outline: "none",
        backgroundColor: "surfaces.neutral.dominant.active",
      },
      _disabled: {
        backgroundColor: "surfaces.neutral.dominant.disabled",
        color: "icon.disabledOnStrong",
        cursor: "not-allowed",
      },
    };
  } else if (variant === variantOptions["neutral.strong"]) {
    return {
      backgroundColor: "surfaces.neutral.strong.resting",
      color: "icon.neutralStrong",
      _hover: {
        backgroundColor: "surfaces.neutral.strong.hover",
      },
      _focusVisible: {
        backgroundColor: "surfaces.neutral.strong.active",
        shadow: "focus",
        outline: "none",
      },
      _disabled: {
        backgroundColor: "surfaces.neutral.strong.disabled",
        color: "icon.disabledOnSoft",
        cursor: "not-allowed",
      },
    };
  } else if (variant === variantOptions["neutral.soft"]) {
    return {
      backgroundColor: "surfaces.neutral.soft.resting",
      color: "icon.neutralSoft",
      _hover: {
        backgroundColor: "surfaces.neutral.soft.hover",
      },
      _focusVisible: {
        backgroundColor: "surfaces.neutral.soft.active",
        shadow: "focus",
        outline: "none",
      },
      _disabled: {
        backgroundColor: "surfaces.neutral.soft.disabled",
        color: "icon.disabledOnSoft",
        cursor: "not-allowed",
      },
    };
  } else if (variant === variantOptions["outline.dominant"]) {
    return {
      color: "icon.neutralDominant",
      borderColor: "borders.neutral.dominant.resting",
      backgroundColor: "transparent",
      borderWidth: "xs",
      _hover: {
        borderColor: "borders.neutral.dominant.hover",
        backgroundColor: "surfaces.alpha.dominant.50",
      },
      _focusVisible: {
        borderColor: "borders.neutral.dominant.hover",
        backgroundColor: "surfaces.outline.dominant.resting",
        shadow: "focus",
        outline: "none",
      },
      _disabled: {
        color: "icon.disabled",
        borderColor: "borders.disabled.soft",
        cursor: "not-allowed",
        _hover: {
          backgroundColor: "transparent",
        },
      },
    };
  } else if (variant === variantOptions["outline.soft"]) {
    return {
      color: "icon.neutralDominant",
      borderColor: "borders.neutral.soft.resting",
      borderWidth: "xs",
      backgroundColor: "transparent",
      _hover: {
        borderColor: "borders.neutral.soft.hover",
        backgroundColor: "surfaces.outline.soft.hover",
      },
      _focusVisible: {
        borderColor: "borders.neutral.soft.hover",
        backgroundColor: "components.input.bgRestingTransparent",
        shadow: "focus",
        outline: "none",
      },
      _disabled: {
        color: "icon.disabled",
        borderColor: "borders.disabled.soft",
        cursor: "not-allowed",
        _hover: {
          backgroundColor: "transparent",
        },
      },
    };
  } else if (variant === variantOptions["outline.white"]) {
    return {
      color: "icon.neutralDominant",
      borderColor: "borders.neutral.hush.resting",
      borderWidth: "xs",
      backgroundColor: "transparent",
      _hover: {
        borderColor: "borders.neutral.hush.hover",
        backgroundColor: "surfaces.neutral.white.hover",
      },
      _focusVisible: {
        borderColor: "borders.neutral.hush.hover",
        backgroundColor: "components.input.bgRestingTransparent",
        shadow: "focus",
        outline: "none",
      },
      _disabled: {
        color: "icon.disabled",
        borderColor: "borders.disabled.hush",
        backgroundColor: "surfaces.neutral.white.disabled",
        cursor: "not-allowed",
        _hover: {
          backgroundColor: "surfaces.neutral.white.disabled",
        },
      },
    };
  } else if (variant === variantOptions.primary) {
    return {
      backgroundColor: "surfaces.primary.strong.resting",
      color: "icon.onPrimaryStrong",
      _hover: {
        backgroundColor: "surfaces.primary.strong.hover",
      },
      _focusVisible: {
        shadow: "focus",
        outline: "none",
        backgroundColor: "surfaces.primary.strong.active",
      },
      _disabled: {
        backgroundColor: "surfaces.primary.strong.disabled",
        color: "icon.disabledOnSoft",
        cursor: "not-allowed",
      },
    };
  } else if (variant === variantOptions.secondary) {
    return {
      backgroundColor: "surfaces.secondary.strong.resting",
      color: "icon.onSecondaryStrong",
      _hover: {
        backgroundColor: "surfaces.secondary.strong.hover",
      },
      _focusVisible: {
        shadow: "focus",
        outline: "none",
        backgroundColor: "surfaces.secondary.strong.active",
      },
      _disabled: {
        backgroundColor: "surfaces.secondary.strong.disabled",
        color: "icon.disabledOnSoft",
        cursor: "not-allowed",
      },
    };
  } else if (variant === variantOptions.danger) {
    return {
      backgroundColor: "surfaces.danger.dominant.resting",
      color: "icon.onDangerDominant",
      _hover: {
        backgroundColor: "surfaces.danger.dominant.hover",
      },
      _focusVisible: {
        shadow: "error",
        outline: "none",
        backgroundColor: "surfaces.danger.dominant.active",
      },
      _disabled: {
        backgroundColor: "surfaces.danger.dominant.disabled",
        color: "icon.disabledOnSoft",
        cursor: "not-allowed",
      },
    };
  } else if (variant === variantOptions.success) {
    return {
      backgroundColor: "surfaces.success.dominant.resting",
      color: "icon.onSuccessDominant",
      _hover: {
        backgroundColor: "surfaces.success.dominant.hover",
      },
      _focusVisible: {
        shadow: "focus",
        outline: "none",
        backgroundColor: "surfaces.success.dominant.active",
      },
      _disabled: {
        backgroundColor: "surfaces.success.dominant.disabled",
        color: "icon.disabledOnSoft",
        cursor: "not-allowed",
      },
    };
  } else if (variant === variantOptions.inverse) {
    return {
      backgroundColor: "transparent",
      color: "icon.onNeutralDominant",
      _hover: {
        backgroundColor: "surfaces.alpha.soft.100",
      },
      _focusVisible: {
        shadow: "focus",
        outline: "none",
        backgroundColor: "transparent",
      },
      _disabled: {
        backgroundColor: "transparent",
        color: "icon.disabledOnStrong",
        cursor: "not-allowed",
      },
    };
  }
  return {};
};

const IconButton = defineStyleConfig({
  baseStyle: (props) => ({
    borderRadius: props.shape === shapeOptions.square ? "sm" : "full",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .chakra-icon": {
      pointerEvents: "none",
    },
  }),
  sizes: sizes,
  variants: {
    [variantOptions.ghost]: variantStyles(variantOptions.ghost),
    [variantOptions["neutral.dominant"]]: variantStyles(
      variantOptions["neutral.dominant"],
    ),
    [variantOptions["neutral.strong"]]: variantStyles(
      variantOptions["neutral.strong"],
    ),
    [variantOptions["neutral.soft"]]: variantStyles(
      variantOptions["neutral.soft"],
    ),
    [variantOptions["outline.dominant"]]: variantStyles(
      variantOptions["outline.dominant"],
    ),
    [variantOptions["outline.soft"]]: variantStyles(
      variantOptions["outline.soft"],
    ),
    [variantOptions["outline.white"]]: variantStyles(
      variantOptions["outline.white"],
    ),
    [variantOptions["inverse"]]: variantStyles(variantOptions["inverse"]),
    [variantOptions.primary]: variantStyles(variantOptions.primary),
    [variantOptions.secondary]: variantStyles(variantOptions.secondary),
    [variantOptions.danger]: variantStyles(variantOptions.danger),
    [variantOptions.success]: variantStyles(variantOptions.success),
  },
  defaultProps: {
    size: sizeOptions.md,
    variant: variantOptions.ghost,
  },
});

export default IconButton;
