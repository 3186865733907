import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import typography from "../../../clayTheme/foundations/typography";

// Relevant parts of Saas UI Banner anatomy plus custom part for outer container
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(["container", "content", "title", "actions"]);

const baseStyle = definePartsStyle({
  container: {
    color: "text.neutralDominant",
    border: "none",
    width: "max-content",
    height: "60px",
    py: 3,
    ps: 5,
    pe: 4,
    alignItems: "center",
    overflow: "visible",
    // Need to include the ampersand selector in order for these styles to be honored
    "&": {
      position: "sticky",
      bottom: 4,
      bg: "pairs.gray.100",
      borderRadius: "full",
      backgroundColor: "surfaces.neutral.dominant.resting",
      color: "text.onNeutralDominant",
      shadow: "lg",
    },
  },
  title: {
    ...typography.textStyles["md"],
    fontVariantNumeric: "tabular-nums",
  },
  content: {
    display: "flex",
    flex: 1,
    flexDirection: ["column", null, "row"],
  },
  actions: {
    me: 0,
  },
});

const BulkActions: ReturnType<typeof defineMultiStyleConfig> =
  defineMultiStyleConfig({
    baseStyle,
    defaultProps: {},
  });

export default BulkActions;
