import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { sizeOptions } from "./themeOptions";
import { inputAnatomy } from "@chakra-ui/anatomy";
import { baseStyle as sharedBaseStyle } from "./commonStateStyles.ts";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const Input: ReturnType<typeof defineMultiStyleConfig> = defineMultiStyleConfig(
  {
    baseStyle: () =>
      definePartsStyle({
        field: {
          ...sharedBaseStyle(),
          borderRadius: "lg",
        },
        element: {
          fontSize: "sm",
          color: "text.placeholder",
          height: "full",
        },
      }),
    sizes: {
      [sizeOptions.lg]: definePartsStyle({
        field: {
          height: "2rem",
          ps: 2.5,
          pe: 1.5,
          py: 1,
        },
      }),
      [sizeOptions.xl]: definePartsStyle({
        field: {
          height: "2.5rem",
          ps: 3,
          pe: 1.5,
          py: 2,
        },
      }),
      [sizeOptions["2xl"]]: definePartsStyle({
        field: {
          height: "3rem",
          ps: 3.5,
          pe: 1.5,
          py: 3,
        },
      }),
    },
    variants: {
      gray: definePartsStyle({
        field: {
          borderColor: "borders.neutral.hush.resting",
          backgroundColor: "components.inputs.bgRestingGray",
        },
      }),
      "": {},
    },
    defaultProps: {
      size: sizeOptions.lg,
      variant: "",
    },
  },
);

export default Input;
