import NewLoginTemplate from '/src/com/yext/platform/users/js/newloginhelpers/newlogintemplate';
import {registerComponentForJsonDataWithUIKit} from '/ui/lib/easyuikit';
import {yext} from '/ui/lib/msg';

const {Box, Text, Link} = require('@yext/mana-ui');

/**
 * Component to render the no product access page
 */
export function NoProductAccess({whitelabelInfo, supportEmail}) {
  return (
    <NewLoginTemplate
      title={yext.msg('No Product Access')}
      cardContent={<CardContent
        supportEmail={supportEmail} />}
      whitelabelInfo={whitelabelInfo}
    />
  );
}

function CardContent({supportEmail}) {
  return (
    <Box>
      <Text fontSize={'sm'}>
        {yext.msg('You are logged in but do not have access to the products for this account. You can access documentation by going directly to any documentation URL you have been provided.')}
      </Text>
      <br />
      <Text
        fontSize={'sm'}
        dangerouslySetInnerHTML={{__html: yext.msg('If you need additional assistance, please <a href="mailto:{supportEmail}" >contact support</a>.', {supportEmail})}} />
    </Box>);
}

document.addEventListener('DOMContentLoaded', () => {
  registerComponentForJsonDataWithUIKit('NoProductAccess', NoProductAccess);
});
