import React from "react";
import {
  ThemingProps,
  InputGroup,
  Input,
  Box,
  useMultiStyleConfig,
  chakra,
  ChakraProps,
  Tooltip,
  HStack,
} from "@chakra-ui/react";
import { FALTag } from "../../icons";
import { useCombobox, useMultipleSelection } from "downshift";
import Dropdown from "./Dropdown";
import SelectedLabels from "./SelectedLabels";
import { Label as LabelType, TypeaheadGroup } from "./types";
import { Badge } from "../Badge";

export interface LabelsProps extends ChakraProps, ThemingProps<"Labels"> {
  inputPlaceholder?: string;
  inputValue?: string;
  labels: LabelType[];
  onAddLabel: (label: LabelType) => void;
  onRemoveLabel: (label: LabelType) => void;
  onInputValueChange: (value: string) => void;
  tid?: string;
  inputElementRef?: React.RefObject<HTMLInputElement>;
  typeaheadResults?: TypeaheadGroup[];
  splitOnComma?: boolean;
  disabled?: boolean;
  disabledTooltipText?: string;
  icon?: React.ReactNode;
  readOnly?: boolean,
}

export default function Labels(props: LabelsProps) {
  const {
    inputPlaceholder,
    inputValue,
    labels,
    onAddLabel,
    onRemoveLabel,
    onInputValueChange,
    tid,
    inputElementRef,
    typeaheadResults,
    splitOnComma,
    disabled = false,
    disabledTooltipText,
    icon = <FALTag color={disabled ? "icon.disabled" : ""} />,
    readOnly = false,
    ...rest
  } = props;

  const disabledTooltip = <Tooltip label={disabledTooltipText}>{icon}</Tooltip>;

  const { filteredResults, comboboxState, handleKeyDown } =
    useLabelsCombobox(props);
  const multiSelectState = useLabelsMultipleSelection(props);

  const styles = useMultiStyleConfig("Labels", props);

  if (readOnly) {
    return (
      <HStack spacing={2} align="center">
        {icon}
        {labels.length > 0 &&
          labels.map((label) => (
            <Badge key={label} palette="neutralStrong" variant="solid">
              {label}
            </Badge>
          )
        )}
      </HStack>
    )
  }

  return (
    <chakra.div data-testid={tid} {...rest}>
      <InputGroup
        __css={styles.inputGroup}
        data-is-disabled={disabled}
        data-has-value={inputValue !== ""}
      >
        <Box
          display="flex"
          w="full"
          alignItems="center"
          justifyContent="start"
          gap={2}
          flexWrap="wrap"
        >
          {disabled ? disabledTooltip : icon}
          <SelectedLabels
            labels={labels}
            disabled={disabled}
            multiSelectState={multiSelectState}
          />
          {!disabled && (
            <Input
              minWidth={"100px"}
              width={0}
              paddingX={1}
              flexGrow={1}
              style={{ border: "none", boxShadow: "none", width: "full" }}
              {...comboboxState.getInputProps(
                multiSelectState.getDropdownProps({
                  ref: inputElementRef,
                  onKeyDown: handleKeyDown,
                  placeholder: inputPlaceholder,
                  value: inputValue,
                  disabled,
                }),
              )}
            />
          )}
        </Box>
      </InputGroup>
      <Dropdown results={filteredResults} comboboxState={comboboxState} />
    </chakra.div>
  );
}

const useLabelsMultipleSelection = (
  args: Pick<LabelsProps, "labels" | "onAddLabel" | "onRemoveLabel">,
) => {
  const { labels, onAddLabel, onRemoveLabel } = args;
  return useMultipleSelection({
    selectedItems: labels,
    onStateChange: ({ type, selectedItems }) => {
      switch (type) {
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
        case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
          for (const existingItem of labels) {
            if (!selectedItems?.find((item) => item === existingItem)) {
              onRemoveLabel(existingItem);
            }
          }
          for (const newItem of selectedItems || []) {
            if (!labels.find((item) => item === newItem)) {
              onAddLabel(newItem);
            }
          }
          break;
        default:
          break;
      }
    },
  });
};

const useLabelsCombobox = (
  args: Pick<
    LabelsProps,
    | "labels"
    | "inputValue"
    | "typeaheadResults"
    | "onAddLabel"
    | "splitOnComma"
    | "onInputValueChange"
  >,
) => {
  const {
    labels,
    inputValue,
    typeaheadResults,
    onAddLabel,
    splitOnComma,
    onInputValueChange,
  } = args;
  const filteredResults = React.useMemo(() => {
    const alreadySelected = new Set(labels);
    return (typeaheadResults || []).map((group) => ({
      ...group,
      results: group.results.filter(
        (item) =>
          !alreadySelected.has(item) &&
          item.toLowerCase().includes((inputValue || "").toLowerCase()),
      ),
    }));
  }, [typeaheadResults, inputValue, labels]);

  const flattenedOptions = filteredResults.flatMap((group) => group.results);

  let highlightedIndex = 0;
  const comboboxState = useCombobox({
    items: flattenedOptions,
    itemToString(item) {
      return item || "";
    },
    defaultHighlightedIndex: 0, // after selection, highlight the first item.
    selectedItem: null,
    inputValue,
    stateReducer(_state, actionAndChanges) {
      const { changes, type } = actionAndChanges;

      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true, // keep the menu open after selection.
            highlightedIndex: highlightedIndex || 0, // with the first option highlighted.
          };
        default:
          return changes;
      }
    },
    onStateChange({
      inputValue: newInputValue,
      type,
      selectedItem: newSelectedItem,
    }) {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          if (newSelectedItem) {
            const labelExists = labels.find(
              (label) => label === newSelectedItem,
            );
            if (!labelExists) onAddLabel(newSelectedItem);
          }
          onInputValueChange("");
          break;

        case useCombobox.stateChangeTypes.InputBlur:
          if (inputValue) {
            const labelExists = labels.find((label) => label === inputValue);
            if (!labelExists) onAddLabel(inputValue);
          }
          onInputValueChange("");
          break;

        case useCombobox.stateChangeTypes.InputChange:
          onInputValueChange(newInputValue || "");

          break;
        default:
          break;
      }
    },
  });

  highlightedIndex = comboboxState.highlightedIndex;
  const { selectItem } = comboboxState;

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const labelExists = labels.find((label) => label === inputValue);

    if (
      e.key === "Tab" ||
      (splitOnComma && e.key === "," && !stringIsAllCommas(inputValue || ""))
    ) {
      if (inputValue === "") return;
      e.preventDefault();

      if (!labelExists) onAddLabel(inputValue || "");
    } else if (e.key === "Enter") {
      if (highlightedIndex !== -1) {
        selectItem(flattenedOptions[highlightedIndex]);
      } else {
        if (!labelExists) onAddLabel(inputValue || "");
      }
    }
  };

  return { comboboxState, filteredResults, handleKeyDown };
};

const stringIsAllCommas = (str: string) => {
  return str.length > 0 && str[0] === "," && new Set(str.split("")).size === 1;
};
