import { anatomy } from "@chakra-ui/theme-tools";
import typography from "../../../clayTheme/foundations/typography";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

const commandBarAnatomy = anatomy("command-bar").parts(
  "container",
  "dialog",
  "input",
  "list",
  "loading",
  "empty",
  "group",
  "item",
  "separator",
);

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(commandBarAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    display: "flex",
    p: 4,
    maxHeight: "500px",
    borderWidth: "xs",
    borderColor: "borders.neutral.soft.active",
    background: "surfaces.page.white",
    flexDirection: "column",
    minHeight: 16,
    borderRadius: "md",
    zIndex: "modal",
    flex: 1,
    shadow: "none",
  },
  input: {
    px: 5,
    height: 8,
    outline: "none",
    bg: "surfaces.page.white",
    w: "full",
  },
  list: {
    overflow: "auto",
    overscrollBehavior: "contain",
    flex: 1,
    _before: {
      my: 2,
      display: "block",
      content: "''",
      height: "1px",
      background: "pairs.gray.500",
      w: "full",
    },
  },
  group: {
    ...typography.textStyles["lg-semibold"],
    p: 5,
  },
  item: {
    ...typography.textStyles.sm,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    borderRadius: "lg",
    fontSize: "md",
    py: 3.5,
    px: 5,
    my: 1,
    gap: 4,
    bg: "surfaces.page.white",
    userSelect: "none",
    transition: "common",
    transitionDuration: "normal",
    _selected: {
      bg: "pairs.gray.50",
    },
    _hover: {
      bg: "pairs.gray.50",
    },
    _disabled: {
      opacity: "0.4",
      cursor: "not-allowed",
      _hover: {
        bg: "surfaces.page.white",
      },
    },
    _active: {
      _disabled: {
        bg: "transparent",
        cursor: "not-allowed",
      },
    },
  },
  empty: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    color: "muted",
    fontSize: "md",
    h: 16,
  },
  loading: {
    display: "flex",
    alignItems: "center",
    color: "muted",
    fontSize: "md",
    px: 4,
    py: 2,
    h: 12,
  },
});

const CommandBar = defineMultiStyleConfig({
  defaultProps: {
    size: "md",
  },
  baseStyle,
});

export default CommandBar;
