import React from "react";
import {
  Box,
  Button,
  ChakraProps,
  Flex,
  ThemingProps,
  useDisclosure,
  useStyleConfig,
} from "@chakra-ui/react";
import { CloseButton } from "../../components/CloseButton";
import { Link } from "../../components/Link";
import {
  FALCircleCheck,
  FALCircleInfo,
  FALEye,
  FALEyeSlash,
} from "../../icons";
import { paletteOptions } from "./theme/themeOptions";
import { useTranslation } from "../ManaUIProvider";

export interface BannerProps
  extends Omit<ChakraProps, "colorScheme">,
    Omit<ThemingProps<"Banner">, "colorScheme"> {
  title: string;
  details?: string;
  hiddenDetails?: React.ReactElement | string;
  hiddenDetailsExpandLabel?: string;
  hiddenDetailsCollapseLabel?: string;
  icon?: React.ReactElement;
  onCTAClick?: () => void;
  ctaHref?: string;
  ctaLabel?: string;
  onClose?: () => void;
  palette?: keyof typeof paletteOptions;
  isRounded?: boolean;
}

export default function Banner(props: BannerProps) {
  const {
    size,
    icon,
    variant,
    palette,
    title,
    details,
    hiddenDetails,
    hiddenDetailsExpandLabel,
    hiddenDetailsCollapseLabel,
    onCTAClick,
    ctaHref,
    ctaLabel,
    onClose,
    ...rest
  } = props;
  const { isOpen, onToggle } = useDisclosure();
  const translations = useTranslation("Banner");

  const paletteWithFallback = palette || "primary";
  const variantWithFallback = variant || "soft";

  const styles = useStyleConfig("Banner", {
    ...props,
    palette: paletteWithFallback,
    variant: variantWithFallback,
  });

  const resolvedIcon =
    icon ||
    (palette === "success" ? (
      <FALCircleCheck iconSize="xl" />
    ) : (
      <FALCircleInfo iconSize="xl" />
    ));

  return (
    <Box __css={styles} {...rest}>
      <Box mr={4} display="flex" justifyContent="center">
        {resolvedIcon}
      </Box>

      <Flex
        alignSelf="stretch"
        display="flex"
        flexWrap="wrap"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
        mr="auto"
      >
        <Flex flexWrap={"wrap"}>
          <Box fontWeight="semibold" mr={2}>
            {title}
          </Box>
          {details && <Box flex="1 1 auto">{details}</Box>}
        </Flex>
        {hiddenDetails && (
          <Flex mt={3} flexDirection="column" alignItems="flex-start">
            <Link as={"button"} palette="chameleon" onClick={onToggle}>
              {isOpen ? <FALEyeSlash mr={1} /> : <FALEye mr={1} />}
              {isOpen ? hiddenDetailsCollapseLabel : hiddenDetailsExpandLabel}
            </Link>
            {isOpen && (
              <Flex mt={3} flex="1 1 auto">
                {hiddenDetails}
              </Flex>
            )}
          </Flex>
        )}
      </Flex>

      <Box display="flex" alignItems="center">
        {onCTAClick ? (
          <Button
            mx={4}
            size="md"
            onClick={() => onCTAClick()}
            variant="outline.chameleon"
          >
            {ctaLabel || translations.retry}
          </Button>
        ) : ctaHref ? (
          <Link href={ctaHref} palette="chameleon" mx={4}>
            {ctaLabel || translations.learnMore}
          </Link>
        ) : null}
        {onClose && (
          <CloseButton
            variant="chameleon"
            size="md"
            tooltipLabel={translations.close}
            onClick={() => onClose()}
          />
        )}
      </Box>
    </Box>
  );
}
