import { defineStyleConfig } from "@chakra-ui/react";

const Note = defineStyleConfig({
  baseStyle: ({ theme }) => {
    return {
      maxWidth: "3xl",
      width: "fit-content",
      paddingY: theme.space["1.5"],
      backgroundColor: "surfaces.neutral.hush.resting",
      borderRadius: "md",
      justifyContent: "center",
      display: "flex",
    };
  },
  sizes: {
    xs: ({ theme }) => {
      return {
        fontSize: "xs",
        gap: theme.space["1"],
      };
    },
    sm: ({ theme }) => {
      return {
        fontSize: "sm",
        gap: theme.space["1.5"],
      };
    },
  },
  variants: {
    indented: ({ theme }) => {
      return {
        paddingLeft: theme.space["6"],
        paddingRight: theme.space["3"],
      };
    },
    flush: ({ theme }) => {
      return {
        paddingX: theme.space["3"],
      };
    },
  },
  defaultProps: {
    variant: "flush",
    size: "sm",
  },
});

export default Note;
