import React, { useMemo } from "react";
import {
  useToast as useChakraToast,
  UseToastOptions as ChakraUseToastOptions,
} from "@chakra-ui/react";
import Toast, { ToastProps } from "./Toast";

interface UseToastOptions
  extends Omit<
      ChakraUseToastOptions,
      "title" | "description" | "status" | "position" | "variant"
    >,
    ToastProps {}

export const useToast = () => {
  const toast = useChakraToast();

  const showToast = useMemo(() => {
    const hook = (options: UseToastOptions) =>
      toast({
        id: options.id,
        duration: 5000,
        render: ({ onClose }) => (
          <Toast {...options} {...toast} onClose={onClose} />
        ),
      });
    hook.close = toast.close;
    return hook;
  }, [toast]);

  return showToast;
};
