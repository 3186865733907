import React from "react";
import {
  useStyleConfig,
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
} from "@chakra-ui/react";
import { sizeOption } from "./theme/themeOptions";
import { FALUserPlus } from "../../icons";

export interface AddButtonProps extends Omit<ChakraButtonProps, "children"> {
  icon?: React.JSX.Element;
}

export default function AddButton(props: AddButtonProps) {
  const { icon, size, ...rest } = props;

  const styles = useStyleConfig("AddButton", props);

  const defaultIconProps = {
    iconSize: (size as sizeOption) || sizeOption.md,
  };

  const nullSafeIcon = icon || <FALUserPlus />;

  const newProps = {
    ...defaultIconProps,
    ...nullSafeIcon.props,
  };

  const clonedIcon = React.cloneElement(nullSafeIcon, newProps);

  return (
    <ChakraButton __css={styles} {...rest}>
      {clonedIcon}
    </ChakraButton>
  );
}
