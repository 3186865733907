import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(["map", "marker"]);

const baseStyle = definePartsStyle({
  map: {
    height: "100%",
  },
  marker: {},
});

const Map: ReturnType<typeof defineMultiStyleConfig> = defineMultiStyleConfig({
  baseStyle,
});

export default Map;
