import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { cssVar } from "@chakra-ui/react";
const $accentColor = cssVar("stepper-accent-color");

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers([
    // saas ui parts
    "container",
    "item",
    "content",
    // default parts
    "stepper",
    "step",
    "title",
    "description",
    "indicator",
    "separator",
    "icon",
    "number",
  ]);

const baseStyle = definePartsStyle(() => ({
  container: {
    color: "text.neutralDominant",
  },
  description: {
    color: "text.neutralSoft",
    maxWidth: "150px",
  },
  stepper: {
    [$accentColor.variable]: "colors.surfaces.primary.strong.resting",
  },
  indicator: {
    fontSize: "sm",
  },
  step: {
    gap: 3,
  },
  title: {
    maxWidth: "150px",
  },
}));

const Stepper: ReturnType<typeof defineMultiStyleConfig> =
  defineMultiStyleConfig({
    defaultProps: {
      variant: "outline",
      colorScheme: "primary",
    },
    baseStyle,
  });

export default Stepper;
