import React from "react";
import { FALPenField } from "../../../icons";
import { TextareaActionProps } from "./Action";
import { TextareaComboBoxAction } from "./ComboBoxAction";
import { useTranslation } from "../../ManaUIProvider/TranslationProvider";

export type EmbeddableField = {
  id: string;
  name: string;
};

export type TextareaEmbeddedFieldActionProps = Pick<
  TextareaActionProps,
  "isDisabled"
> & {
  embeddableOptions: EmbeddableField[];
};

export const TextareaEmbeddedFieldAction: React.FC<
  TextareaEmbeddedFieldActionProps
> = ({ isDisabled, embeddableOptions }) => {
  const translations = useTranslation("TextAreaActions");
  const options = embeddableOptions.map((field) => {
    return {
      label: field.name,
      data: field,
      key: field.id,
    };
  });

  return (
    <TextareaComboBoxAction<EmbeddableField>
      isDisabled={isDisabled}
      icon={<FALPenField />}
      label={translations.embeddedFields}
      onSelection={(item, api) => {
        api.replaceSelection(`[[${item.data?.id}]]`);
      }}
      options={options}
      optionToString={(item) => `[[${item?.data?.id}]]`}
    />
  );
};
