import React from "react";
import { Box, ChakraProps, Flex, useStyleConfig } from "@chakra-ui/react";

import { StarFull, StarHalf, StarEmpty } from "../../icons/ManualIcons";

export interface StarRatingProps extends ChakraProps {
  rating?: number;
  defaultRating?: number;
  ratingLabelPlacement?: "left" | "right";
  onStarClick?: (newRating: number) => void;
}

export default function StarRating(props: StarRatingProps) {
  const { rating, defaultRating, onStarClick, ...rest } = props;

  const styles = useStyleConfig("StarRating", props);

  const [savedRating, setSavedRating] = React.useState(
    rating !== undefined ? rating : defaultRating || 0,
  );
  const [currentRating, setCurrentRating] = React.useState(savedRating);

  const clampedRating = Math.max(0, Math.min(5, currentRating || 0));

  const handleStarClick = (i: number) => {
    const newRating = i + 1;
    setCurrentRating(newRating);
    setSavedRating(newRating);
    onStarClick?.(newRating);
  };

  const handleMouseOver = (i: number) => {
    setCurrentRating(i + 1);
  };

  const handleMouseOut = () => {
    setCurrentRating(savedRating);
  };

  React.useEffect(() => {
    if (rating !== undefined) {
      setCurrentRating(rating);
      return;
    }
    if (defaultRating !== undefined) {
      setCurrentRating(defaultRating);
    }
  }, [rating, defaultRating]);

  const controlledRating = new Array(5).fill(null).map((_, i) => {
    return (
      <Box paddingLeft={0.25} paddingRight={0.25} key={i}>
        {currentRating - i >= 0.75 ? (
          <Flex>
            <StarFull key={i} />
          </Flex>
        ) : currentRating - i >= 0.25 ? (
          <Flex>
            <StarHalf key={i} />
          </Flex>
        ) : (
          <Flex>
            <StarEmpty key={i} />
          </Flex>
        )}
      </Box>
    );
  });

  const uncontrolledRating = new Array(5).fill(null).map((_, i) => {
    return (
      <Box paddingLeft={0.25} paddingRight={0.25} key={i}>
        {currentRating - i >= 0.75 ? (
          <Flex>
            <StarFull
              onClick={() => handleStarClick(i)}
              onMouseOver={() => handleMouseOver(i)}
              onMouseOut={handleMouseOut}
            />
          </Flex>
        ) : currentRating - i >= 0.25 ? (
          <Flex>
            <StarHalf
              onClick={() => handleStarClick(i)}
              onMouseOver={() => handleMouseOver(i)}
              onMouseOut={handleMouseOut}
            />
          </Flex>
        ) : (
          <Flex>
            <StarEmpty
              onClick={() => handleStarClick(i)}
              onMouseOver={() => handleMouseOver(i)}
              onMouseOut={handleMouseOut}
            />
          </Flex>
        )}
      </Box>
    );
  });

  if (props.ratingLabelPlacement === "left") {
    return (
      <Flex {...rest} __css={styles} align="center">
        <Box textStyle={"5xl-medium"} color="text.neutralDominant" me={3}>
          {currentRating !== undefined && clampedRating.toFixed(1)}
        </Box>

        {rating !== undefined ? controlledRating : uncontrolledRating}
      </Flex>
    );
  }

  return (
    <Flex {...rest} __css={styles} align="center">
      {rating !== undefined ? controlledRating : uncontrolledRating}

      <Box textStyle={"md-medium"} color="text.neutralDominant" ms={3}>
        {currentRating !== undefined && clampedRating.toFixed(1)}
      </Box>
    </Flex>
  );
}
