import React from "react";
import {
  NumberIncrementStepper as ChakraNumberIncrementStepper,
  NumberIncrementStepperProps as ChakraNumberIncrementStepperProps,
} from "@chakra-ui/react";
import { FALChevronUp } from "../../icons";

export interface NumberIncrementStepperProps
  extends ChakraNumberIncrementStepperProps {}

export default function NumberIncrementStepper(
  props: NumberIncrementStepperProps,
) {
  return (
    <ChakraNumberIncrementStepper {...props}>
      <FALChevronUp />
    </ChakraNumberIncrementStepper>
  );
}
