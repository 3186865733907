import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { paletteOptions } from "./themeOptions";
import typography from "../../../clayTheme/foundations/typography";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(["container", "secondaryTime"]);

// 3 palette options, neutral.dominant, neutral.strong, chameleon
// chamleon also inherits parent font size

const colorHelper = (palette: string) => {
  return (
    {
      [paletteOptions["neutral.dominant"]]: "text.neutralDominant",
      [paletteOptions["neutral.strong"]]: "text.neutralStrong",
      [paletteOptions.chameleon]: "currentColor",
    }[palette] || "text.neutralDominant"
  );
};

const baseStyle = definePartsStyle(({ palette }) => {
  return {
    container: {
      color: colorHelper(palette),
      ...typography.textStyles.sm,
      fontSize:
        palette === paletteOptions.chameleon
          ? "inherit"
          : typography.fontSizes.md,
    },
    secondaryTime: {
      color:
        palette === paletteOptions.chameleon
          ? "currentColor"
          : "text.neutralSoft",
      // TODO: consider proportionate fontSize for secondaryTime when palette === chameleon
      ...typography.textStyles.sm,
      marginBlockStart: 0.5,
    },
  };
});

const Timestamp: ReturnType<typeof defineMultiStyleConfig> =
  defineMultiStyleConfig({
    baseStyle,
  });

export default Timestamp;
