import React, { FC } from "react";
import { paletteOptions } from "./theme/themeOptions";
import { Circle, BoxProps } from "@chakra-ui/react";

export type CircleIconProps = Omit<
  BoxProps,
  "bgColor" | "color" | "padding"
> & {
  palette: keyof typeof paletteOptions;
};

export const CircleIcon: FC<CircleIconProps> = (props) => {
  const { children, palette, display, ...rest } = props;
  const token = tokenFromPalette(palette);
  return (
    <Circle
      p="0.5em"
      display={display ?? "inline-flex"}
      bgColor={token}
      {...rest}
    >
      {children}
    </Circle>
  );
};

const tokenFromPalette = (palette: keyof typeof paletteOptions) => {
  switch (palette) {
    case "blue":
      return "blue.100";
    case "purple":
      return "purple.100";
    case "green":
      return "green.200";
    case "yellow":
      return "yellow.400";
    case "red":
      return "red.200";
    case "pink":
      return "pink.100";
    case "cyan":
      return "cyan.100";
    case "orange":
      return "orange.400";
    case "teal":
      return "teal.200";
    case "gray":
    default:
      return "gray.200";
  }
};
