import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers([
    "container",
    "inner",
    "table",
    "thead",
    "tbody",
    "tfoot",
    "tr",
    "th",
    "title",
    "resizer",
    "td",
    "caption",
  ]);

const baseStyle = definePartsStyle(() => ({
  container: {
    m: 0,
  },
  inner: {
    my: 3.5,
  },
  title: {
    textStyle: "textStyles.sm",
    color: "text.neutralSoft",
    paddingX: 0,
    paddingY: 0,
    padding: 0,
    "& svg": {
      color: "icon.neutralDominant",
    },
  },
  // just using the tr property doesn't work, but adding a class and
  // using `className: cx('sui-data-grid', 'mjolnir-data-grid', props.className)` to
  // extend the class names allows me to do this here to get the border color to apply
  tbody: {
    ".mjolnir-data-grid & tr": {
      borderColor: "pairs.gray.100",
      _dark: {
        borderColor: "pairs.gray.100",
      },
    },
  },
  thead: {
    tr: {
      borderBottomWidth: 1,
      borderBottomStyle: "solid",
      borderBottomColor: "pairs.gray.100",
      _dark: {
        borderBottomColor: "pairs.gray.100",
      },
      th: {
        borderBottom: "none",
      },
    },
  },
  // for some reason i have to use both px shorthand and the long paddingX, etc. to get the padding to apply.
  th: {
    px: 6,
    paddingX: 6,
    py: 3.5,
    paddingY: 3.5,
    "& .chakra-checkbox__control": {
      width: 5,
      height: 5,
      svg: {
        color: "white",
      },
    },
  },
  td: {
    px: 6,
    paddingX: 6,
    py: 3.5,
    paddingY: 3.5,
    "& .chakra-checkbox__control": {
      width: 5,
      height: 5,
    },
  },
}));

const SuiDataGrid: ReturnType<typeof defineMultiStyleConfig> =
  defineMultiStyleConfig({
    baseStyle,
  });

export default SuiDataGrid;
