import { anatomy } from "@chakra-ui/theme-tools";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { sizeOptions } from "./themeOptions";

export const nprogressAnatomy = anatomy("nprogress").parts("container", "bar");

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(nprogressAnatomy.keys);

const sizes = {
  [sizeOptions.xs]: definePartsStyle({
    container: { height: 1 },
    bar: { height: 1 },
  }),
  [sizeOptions.sm]: definePartsStyle({
    container: { height: 2 },
    bar: { height: 2 },
  }),
};

const baseStyle = definePartsStyle({
  container: {
    bg: "surfaces.neutral.strong.resting",
    borderRadius: "full",
  },
  bar: {
    bg: "surfaces.primary.strong.resting",
    borderRadius: "full",
    transitionProperty: "width",
    transitionDuration: "0.2s",
    transitionTimingFunction: "ease-in-out",
    // Need to specify dark mode bg color, otherwise Chakra returns "colors.primary.500"
    _dark: {
      bg: "surfaces.primary.strong.resting",
    },
  },
});
const SuiNProgress: ReturnType<typeof defineMultiStyleConfig> =
  defineMultiStyleConfig({
    baseStyle,
    sizes,
    defaultProps: {
      size: sizeOptions.sm,
    },
  });

export default SuiNProgress;
