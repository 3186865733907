export const AJAX_HEADER = {'X-Requested-With': 'XMLHttpRequest'};

/**
 * Turns error responses from fetch calls into JS errors
 * @param {Response} resp the response from a fetch call
 * @returns {Promise<!Response>}
 */
export async function checkResponseStatus(resp) {
  if (!resp) {
    throw new Error('No response');
  }
  if (!resp.ok) {
    throw new Error(`${resp.status}: ${resp.statusText}\n${await resp.text()}`);
  }
  return resp;
}

/**
 * Checks whether a error object was caused by aborting a fetch request
 * @param {?} error
 * @returns {boolean}
 */
export function isAbort(error) {
  return error instanceof Error && error.name === 'AbortError';
}
