import React from "react";
import { Box, ThemingProps, useMultiStyleConfig } from "@chakra-ui/react";
import { usePanels } from "../Panel";
import { createContext } from "@chakra-ui/react-utils";

interface InboxLayoutContextValue {
  onOpen: () => void;
  onClose: () => void;
  onToggle: () => void;
  isOpen: boolean;
}

export const useInboxLayout = (defaultIsOpen = false) => {
  const panels = usePanels({
    Left: { isOpen: !defaultIsOpen },
    Right: { isOpen: defaultIsOpen },
  });

  return {
    onOpen: () => panels.openPanel("Right"),
    onClose: () => panels.closePanel("Right"),
    onToggle: () => panels.togglePanel("Right"),
    isOpen: panels.isOpen("Right"),
  };
};

const [InboxLayoutProvider, useInboxLayoutContext] =
  createContext<InboxLayoutContextValue>({
    name: "InboxLayoutContext",
    errorMessage:
      "useInboxLayoutContext: `context` is undefined, you may have forgotten to wrap your components in `<InboxLayout />`",
  });
export { InboxLayoutProvider, useInboxLayoutContext };

export interface InboxLayoutProps extends ThemingProps<"TwoPanelLayout"> {
  left: React.ReactNode;
  right: React.ReactNode;
  top?: React.ReactNode;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onToggle: () => void;
}

export const InboxLayout = (props: InboxLayoutProps) => {
  const styles = useMultiStyleConfig("InboxLayout", props);
  const defaultFunctions = useInboxLayout();
  const { isOpen, onClose, onOpen, onToggle } = {
    isOpen: props.isOpen === null ? defaultFunctions.isOpen : props.isOpen,
    onClose: props.onClose === null ? defaultFunctions.onClose : props.onClose,
    onOpen: props.onOpen === null ? defaultFunctions.onOpen : props.onOpen,
    onToggle:
      props.onToggle === null ? defaultFunctions.onToggle : props.onToggle,
  };

  return (
    <InboxLayoutProvider
      value={{
        isOpen,
        onClose,
        onOpen,
        onToggle,
      }}
    >
      <Box __css={styles.container}>
        {props.top && <Box __css={styles.top}>{props.top}</Box>}
        <Box __css={styles.inner}>
          <Box __css={styles.left}>{props.left}</Box>
          <Box __css={styles.right}>{props.right}</Box>
        </Box>
      </Box>
    </InboxLayoutProvider>
  );
};
