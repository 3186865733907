import React from "react";
import {
  Box,
  Image,
  CheckboxProps,
  useStyleConfig,
  useCheckbox,
} from "@chakra-ui/react";

export type IconCheckboxProps = CheckboxProps & (ImgProps | IconProps);

interface ImgProps {
  src: string;
  icon?: never;
}

interface IconProps {
  src?: never;
  icon: React.JSX.Element;
}

const IconCheckbox = (props: IconCheckboxProps) => {
  const { getInputProps, getCheckboxProps } = useCheckbox(props);
  const input = getInputProps();
  const checkbox = getCheckboxProps();

  const { icon, src, ...rest } = props;

  const styles = useStyleConfig("IconCheckbox", props);

  const clonedIcon = icon ? React.cloneElement(icon, icon?.props || {}) : null;

  return (
    <Box {...rest}>
      <input {...input} />
      <Box {...checkbox} __css={styles}>
        {src ? <Image src={src} /> : clonedIcon}
      </Box>
    </Box>
  );
};

export default IconCheckbox;
