export enum variantOptions {
  neutral = "neutral",
  system = "system",
  warning = "warning",
  danger = "danger",
}

export enum sizeOptions {
  xs = "xs",
  lg = "lg",
}
