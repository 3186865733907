// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck https://github.com/pd4d10/vite-plugin-svgr/issues/91
import React from "react";
import { Icon, IconProps } from "@chakra-ui/react";
export { default as EmptyAlert } from "../icons/manual-icons/empty/empty-alert.svg?react";
export { default as EmptyBeach } from "../icons/manual-icons/empty/empty-beach.svg?react";
export { default as EmptyBell } from "../icons/manual-icons/empty/empty-bell.svg?react";
export { default as EmptyBox } from "../icons/manual-icons/empty/empty-box.svg?react";
export { default as EmptyCalendar } from "../icons/manual-icons/empty/empty-calendar.svg?react";
export { default as EmptyClipboard } from "../icons/manual-icons/empty/empty-clipboard.svg?react";
export { default as EmptyClock } from "../icons/manual-icons/empty/empty-clock.svg?react";
export { default as EmptyCoffee } from "../icons/manual-icons/empty/empty-coffee.svg?react";
export { default as EmptyCreditcard } from "../icons/manual-icons/empty/empty-creditcard.svg?react";
export { default as EmptyDate } from "../icons/manual-icons/empty/empty-date.svg?react";
export { default as EmptyDocument } from "../icons/manual-icons/empty/empty-document.svg?react";
export { default as EmptyDocuments } from "../icons/manual-icons/empty/empty-documents.svg?react";
export { default as EmptyDone } from "../icons/manual-icons/empty/empty-done.svg?react";
export { default as EmptyDragNDrop } from "../icons/manual-icons/empty/empty-drag-n-drop.svg?react";
export { default as EmptyEditDocuments } from "../icons/manual-icons/empty/empty-edit-documents.svg?react";
export { default as EmptyError } from "../icons/manual-icons/empty/empty-error.svg?react";
export { default as EmptyFolder } from "../icons/manual-icons/empty/empty-folder.svg?react";
export { default as EmptyHouse } from "../icons/manual-icons/empty/empty-house.svg?react";
export { default as EmptyImages } from "../icons/manual-icons/empty/empty-images.svg?react";
export { default as EmptyInbox } from "../icons/manual-icons/empty/empty-inbox.svg?react";
export { default as EmptyLike } from "../icons/manual-icons/empty/empty-like.svg?react";
export { default as EmptyLock } from "../icons/manual-icons/empty/empty-lock.svg?react";
export { default as EmptyMessages } from "../icons/manual-icons/empty/empty-messages.svg?react";
export { default as EmptyPencil } from "../icons/manual-icons/empty/empty-pencil.svg?react";
export { default as EmptyPin } from "../icons/manual-icons/empty/empty-pin.svg?react";
export { default as EmptyQandA } from "../icons/manual-icons/empty/empty-q&a.svg?react";
export { default as EmptyQuestion } from "../icons/manual-icons/empty/empty-question.svg?react";
export { default as EmptySearch } from "../icons/manual-icons/empty/empty-search.svg?react";
export { default as EmptyShield } from "../icons/manual-icons/empty/empty-shield.svg?react";
export { default as EmptyShopping } from "../icons/manual-icons/empty/empty-shopping.svg?react";
export { default as EmptySnooze } from "../icons/manual-icons/empty/empty-snooze.svg?react";
export { default as EmptyStar } from "../icons/manual-icons/empty/empty-star.svg?react";
export { default as EmptyStop } from "../icons/manual-icons/empty/empty-stop.svg?react";
export { default as EmptyTag } from "../icons/manual-icons/empty/empty-tag.svg?react";
export { default as EmptyUser } from "../icons/manual-icons/empty/empty-user.svg?react";
export { default as EmptyWallet } from "../icons/manual-icons/empty/empty-wallet.svg?react";

export { default as StarFull } from "../icons/manual-icons/stars/full.svg?react";
export { default as StarHalf } from "../icons/manual-icons/stars/half.svg?react";
export { default as StarEmpty } from "../icons/manual-icons/stars/empty.svg?react";

import { default as FacebookSSOInner } from "./manual-icons/FacebookSSO.svg?react";
export const FacebookSSO = (props: IconProps) => (
  <Icon as={FacebookSSOInner} {...props} />
);

import { default as GitHubSSOInner } from "./manual-icons/GithubSSO.svg?react";
export const GitHubSSO = (props: IconProps) => (
  <Icon as={GitHubSSOInner} {...props} />
);

import { default as InstagramInner } from "./manual-icons/Instagram.svg?react";
export const Instagram = (props: IconProps) => (
  <Icon as={InstagramInner} {...props} />
);

import { default as HeroldInner } from "./manual-icons/Herold.svg?react";
export const Herold = (props: IconProps) => (
  <Icon as={HeroldInner} {...props} />
);

import { default as FacebookOnLightInner } from "./manual-icons/Facebook.svg?react";
export const FacebookOnLight = (props: IconProps) => (
  <Icon as={FacebookOnLightInner} {...props} />
);
