export enum paletteOptions {
  primary = "primary",
  chameleon = "chameleon",
}

export enum sizes {
  xs = "xs",
  sm = "sm",
  md = "md",
  lg = "lg",
  xl = "xl",
  inherit = "inherit",
}
