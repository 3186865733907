export enum paletteOptions {
  primary = "primary",
  secondary = "secondary",
  gray = "gray",
  whiteAlpha = "whiteAlpha",
  blackAlpha = "blackAlpha",
}

export enum sizeOptions {
  xs = "xs",
  sm = "sm",
  md = "md",
  lg = "lg",
  xl = "xl",
}
