// DO NOT EDIT: autogenerated by brokkr

export const colors = {
  "current": "currentColor",
  "black": "#000000",
  "white": "#ffffff",
  "transparent": "transparent",
  "whiteAlpha": {
    "50": "rgba(255, 255, 255, 0.04)",
    "100": "rgba(255, 255, 255, 0.06)",
    "200": "rgba(255, 255, 255, 0.08)",
    "300": "rgba(255, 255, 255, 0.16)",
    "400": "rgba(255, 255, 255, 0.24)",
    "500": "rgba(255, 255, 255, 0.36)",
    "600": "rgba(255, 255, 255, 0.48)",
    "700": "rgba(255, 255, 255, 0.64)",
    "800": "rgba(255, 255, 255, 0.8)",
    "900": "rgba(255, 255, 255, 0.92)"
  },
  "blackAlpha": {
    "50": "rgba(28, 29, 32, 0.04)",
    "100": "rgba(28, 29, 32, 0.06)",
    "200": "rgba(28, 29, 32, 0.08)",
    "300": "rgba(28, 29, 32, 0.16)",
    "400": "rgba(28, 29, 32, 0.24)",
    "500": "rgba(28, 29, 32, 0.36)",
    "600": "rgba(28, 29, 32, 0.48)",
    "700": "rgba(28, 29, 32, 0.64)",
    "800": "rgba(28, 29, 32, 0.8)",
    "900": "rgba(28, 29, 32, 0.92)"
  },
  "gray": {
    "50": "#f7f9fe",
    "100": "#f1f3f7",
    "200": "#e9ebef",
    "300": "#dadce0",
    "400": "#b7b9bc",
    "500": "#97999d",
    "600": "#6f7074",
    "700": "#5b5d60",
    "800": "#3c3e41",
    "825": "#3d3f42",
    "850": "#323437",
    "875": "#27292c",
    "900": "#1c1d20"
  },
  "red": {
    "50": "#ffeaed",
    "100": "#f8d7da",
    "200": "#f1aeb5",
    "300": "#ea868f",
    "400": "#e35d6a",
    "500": "#dc3545",
    "600": "#b02a37",
    "700": "#842029",
    "800": "#58151c",
    "900": "#2c0b0e"
  },
  "orange": {
    "50": "#fff3e0",
    "100": "#ffe5d0",
    "200": "#fecba1",
    "300": "#feb272",
    "400": "#fd9843",
    "500": "#fd7e14",
    "600": "#ca6510",
    "700": "#985c0c",
    "800": "#653208",
    "900": "#331904"
  },
  "yellow": {
    "50": "#fef8e0",
    "100": "#fff3cd",
    "200": "#ffe69c",
    "300": "#ffda6a",
    "400": "#ffcd39",
    "500": "#ffc107",
    "600": "#cc9a06",
    "700": "#997404",
    "800": "#664d03",
    "900": "#332701"
  },
  "green": {
    "50": "#e5f4ec",
    "100": "#d1e7dd",
    "200": "#a3cfbb",
    "300": "#75b798",
    "400": "#479f76",
    "500": "#198754",
    "600": "#146c43",
    "700": "#0f5132",
    "800": "#0a3622",
    "900": "#051b11"
  },
  "teal": {
    "50": "#e0f2f1",
    "100": "#d2f4ea",
    "200": "#a6e9d5",
    "300": "#79dfc1",
    "400": "#4dd4ac",
    "500": "#20c997",
    "600": "#1aa179",
    "700": "#13795b",
    "800": "#0d503c",
    "900": "#06281e"
  },
  "blue": {
    "50": "#e9e8fe",
    "100": "#c8c5fa",
    "200": "#a29ff8",
    "300": "#7979f5",
    "400": "#5a58f2",
    "500": "#3b34ec",
    "600": "#372ae0",
    "700": "#2d1bd3",
    "800": "#2402c8",
    "900": "#1d00af"
  },
  "cyan": {
    "50": "#e0f7fa",
    "100": "#cff4fc",
    "200": "#9eeaf9",
    "300": "#63dff6",
    "400": "#3dd5f3",
    "500": "#0dcaf0",
    "600": "#0aa2c0",
    "700": "#087990",
    "800": "#055160",
    "900": "#032830"
  },
  "purple": {
    "50": "#eee8f7",
    "100": "#e2d9f3",
    "200": "#c5b3e6",
    "300": "#a98eda",
    "400": "#8c68cd",
    "500": "#6f42c1",
    "600": "#59359a",
    "700": "#432874",
    "800": "#2c1a4d",
    "900": "#160d27"
  },
  "pink": {
    "50": "#fce4ec",
    "100": "#f7d6e6",
    "200": "#efadce",
    "300": "#e685b5",
    "400": "#de5c9d",
    "500": "#d63384",
    "600": "#ab296a",
    "700": "#801f4f",
    "800": "#561435",
    "900": "#2b0a1a"
  },
  "colorAlpha": {
    "primaryOnDark": "#25245d",
    "secondaryOnDark": "#2d2440",
    "successOnDark": "#253731",
    "dangerOnDark": "#442a2f",
    "warningOnDark": "#493e1b",
    "internalOnDark": "#5e163a",
    "accent1OnDark": "#4c2606",
    "accent2OnDark": "#043d48",
    "accent3OnDark": "#24433a"
  }
}

export const semanticColors = {
  "text": {
    "neutralDominant": {
      "default": "pairs.gray.900",
      "_dark": "pairs.gray.900"
    },
    "neutralStrong": {
      "default": "gray.700",
      "_dark": "gray.300"
    },
    "disabledOnSoft": {
      "default": "gray.500",
      "_dark": "gray.500"
    },
    "placeholder": {
      "default": "#6f7074",
      "_dark": "gray.400"
    },
    "danger": {
      "default": "red.500",
      "_dark": "red.300"
    },
    "link": {
      "default": "blue.400",
      "_dark": "blue.200"
    },
    "success": {
      "default": "green.500",
      "_dark": "green.300"
    },
    "secondary": {
      "default": "purple.500",
      "_dark": "purple.200"
    },
    "onNeutralStrong": {
      "default": "pairs.gray.900",
      "_dark": "pairs.gray.900"
    },
    "primary": {
      "default": "blue.400",
      "_dark": "blue.200"
    },
    "warning": {
      "default": "orange.700",
      "_dark": "yellow.400"
    },
    "onNeutralSoft": {
      "default": "pairs.gray.900",
      "_dark": "pairs.gray.900"
    },
    "onPrimaryStrong": {
      "default": "white",
      "_dark": "gray.900"
    },
    "onPrimarySoft": {
      "default": "gray.900",
      "_dark": "gray.900"
    },
    "onSecondarySoft": {
      "default": "gray.900",
      "_dark": "gray.900"
    },
    "onSuccessSoft": {
      "default": "gray.900",
      "_dark": "gray.900"
    },
    "onWarningSoft": {
      "default": "gray.900",
      "_dark": "gray.900"
    },
    "onSuccessStrong": {
      "default": "gray.900",
      "_dark": "gray.900"
    },
    "onSecondaryStrong": {
      "default": "gray.900",
      "_dark": "gray.900"
    },
    "onWarningStrong": {
      "default": "gray.900",
      "_dark": "gray.900"
    },
    "onDangerSoft": {
      "default": "gray.900",
      "_dark": "gray.900"
    },
    "onDangerStrong": {
      "default": "gray.900",
      "_dark": "gray.900"
    },
    "onNeutralDominant": {
      "default": "pairs.gray.whitePair",
      "_dark": "pairs.gray.whitePair"
    },
    "onSuccessDominant": {
      "default": "white",
      "_dark": "white"
    },
    "onDangerDominant": {
      "default": "white",
      "_dark": "white"
    },
    "onSecondaryDominant": {
      "default": "gray.900",
      "_dark": "gray.900"
    },
    "inverse": {
      "default": "white",
      "_dark": "black"
    },
    "neutralSoft": {
      "default": "gray.600",
      "_dark": "gray.500"
    },
    "disabledOnStrong": {
      "default": "gray.600",
      "_dark": "gray.500"
    },
    "onNeutralHush": {
      "default": "pairs.gray.900",
      "_dark": "pairs.gray.900"
    },
    "disabled": {
      "default": "gray.400",
      "_dark": "gray.500"
    },
    "disabledOnPrimary": {
      "default": "blue.200",
      "_dark": "blue.200"
    }
  },
  "surfaces": {
    "page": {
      "white": {
        "default": "white",
        "_dark": "black"
      },
      "soft": {
        "default": "gray.50",
        "_dark": "gray.900"
      },
      "strong": {
        "default": "gray.100",
        "_dark": "gray.875"
      }
    },
    "primary": {
      "strong": {
        "resting": {
          "default": "blue.400",
          "_dark": "blue.200"
        },
        "hover": {
          "default": "blue.600",
          "_dark": "blue.300"
        },
        "active": {
          "default": "blue.400",
          "_dark": "blue.200"
        },
        "disabled": {
          "default": "gray.300",
          "_dark": "gray.800"
        }
      },
      "soft": {
        "resting": {
          "default": "blue.50",
          "_dark": "blue.50"
        },
        "hover": {
          "default": "blue.100",
          "_dark": "blue.100"
        },
        "active": {
          "default": "blue.50",
          "_dark": "blue.50"
        },
        "disabled": {
          "default": "pairs.alpha.alphaBlack100",
          "_dark": "pairs.alpha.alphaBlack100"
        }
      }
    },
    "success": {
      "strong": {
        "resting": {
          "default": "green.200",
          "_dark": "green.200"
        },
        "hover": {
          "default": "green.300",
          "_dark": "green.300"
        },
        "active": {
          "default": "green.200",
          "_dark": "green.200"
        },
        "disabled": {
          "default": "gray.300",
          "_dark": "gray.800"
        }
      },
      "soft": {
        "resting": {
          "default": "green.50",
          "_dark": "green.50"
        },
        "hover": {
          "default": "green.100",
          "_dark": "green.100"
        },
        "active": {
          "default": "green.50",
          "_dark": "green.50"
        },
        "disabled": {
          "default": "pairs.alpha.alphaBlack100",
          "_dark": "pairs.alpha.alphaBlack50"
        }
      },
      "dominant": {
        "resting": {
          "default": "green.500",
          "_dark": "green.500"
        },
        "hover": {
          "default": "green.600",
          "_dark": "green.600"
        },
        "active": {
          "default": "green.500",
          "_dark": "green.500"
        },
        "disabled": {
          "default": "gray.300",
          "_dark": "gray.800"
        }
      }
    },
    "alpha": {
      "dominant": {
        "50": {
          "default": "blackAlpha.50",
          "_dark": "whiteAlpha.100"
        },
        "100": {
          "default": "blackAlpha.100",
          "_dark": "whiteAlpha.200"
        },
        "200": {
          "default": "blackAlpha.200",
          "_dark": "whiteAlpha.300"
        },
        "300": {
          "default": "blackAlpha.300",
          "_dark": "whiteAlpha.400"
        }
      },
      "soft": {
        "50": {
          "default": "whiteAlpha.50",
          "_dark": "blackAlpha.50"
        },
        "100": {
          "default": "whiteAlpha.100",
          "_dark": "blackAlpha.100"
        },
        "200": {
          "default": "whiteAlpha.200",
          "_dark": "blackAlpha.200"
        },
        "300": {
          "default": "whiteAlpha.300",
          "_dark": "blackAlpha.300"
        }
      },
      "fixed": {
        "light": {
          "default": "whiteAlpha.100",
          "_dark": "whiteAlpha.100"
        },
        "dark": {
          "default": "blackAlpha.100",
          "_dark": "blackAlpha.100"
        }
      }
    },
    "secondary": {
      "strong": {
        "resting": {
          "default": "purple.100",
          "_dark": "purple.100"
        },
        "hover": {
          "default": "purple.200",
          "_dark": "purple.300"
        },
        "active": {
          "default": "purple.100",
          "_dark": "purple.100"
        },
        "disabled": {
          "default": "gray.300",
          "_dark": "gray.800"
        }
      },
      "soft": {
        "resting": {
          "default": "purple.50",
          "_dark": "purple.50"
        },
        "hover": {
          "default": "purple.100",
          "_dark": "purple.200"
        },
        "active": {
          "default": "purple.50",
          "_dark": "purple.50"
        },
        "disabled": {
          "default": "pairs.alpha.alphaBlack100",
          "_dark": "pairs.alpha.alphaBlack100"
        }
      },
      "dominant": {
        "resting": {
          "default": "purple.200",
          "_dark": "purple.200"
        },
        "hover": {
          "default": "purple.300",
          "_dark": "purple.400"
        },
        "active": {
          "default": "purple.200",
          "_dark": "purple.200"
        },
        "disabled": {
          "default": "gray.300",
          "_dark": "gray.800"
        }
      }
    },
    "neutral": {
      "strong": {
        "resting": {
          "default": "pairs.gray.200",
          "_dark": "pairs.gray.200"
        },
        "hover": {
          "default": "pairs.gray.300",
          "_dark": "pairs.gray.300"
        },
        "active": {
          "default": "gray.300",
          "_dark": "gray.700"
        },
        "disabled": {
          "default": "gray.300",
          "_dark": "gray.800"
        }
      },
      "soft": {
        "resting": {
          "default": "gray.50",
          "_dark": "gray.800"
        },
        "hover": {
          "default": "gray.100",
          "_dark": "gray.700"
        },
        "active": {
          "default": "gray.50",
          "_dark": "gray.800"
        },
        "disabled": {
          "default": "blackAlpha.100",
          "_dark": "blackAlpha.200"
        }
      },
      "dominant": {
        "hover": {
          "default": "pairs.gray.700",
          "_dark": "pairs.gray.700"
        },
        "active": {
          "default": "pairs.gray.900",
          "_dark": "pairs.gray.900"
        },
        "resting": {
          "default": "pairs.gray.900",
          "_dark": "pairs.gray.900"
        },
        "disabled": {
          "default": "gray.300",
          "_dark": "gray.800"
        }
      },
      "hush": {
        "hover": {
          "default": "blackAlpha.100",
          "_dark": "whiteAlpha.100"
        },
        "active": {
          "default": "pairs.alpha.alphaBlack200",
          "_dark": "pairs.alpha.alphaBlack200"
        },
        "resting": {
          "default": "blackAlpha.50",
          "_dark": "whiteAlpha.50"
        },
        "disabled": {
          "default": "whiteAlpha.50",
          "_dark": "blackAlpha.50"
        }
      },
      "white": {
        "resting": {
          "default": "pairs.gray.whitePair",
          "_dark": "pairs.gray.whitePair"
        },
        "hover": {
          "default": "pairs.gray.50",
          "_dark": "pairs.gray.50"
        },
        "active": {
          "default": "pairs.gray.whitePair",
          "_dark": "pairs.gray.whitePair"
        },
        "disabled": {
          "default": "whiteAlpha.900",
          "_dark": "blackAlpha.900"
        }
      },
      "black": {
        "resting": {
          "default": "pairs.gray.blackPair",
          "_dark": "pairs.gray.blackPair"
        },
        "hover": {
          "default": "gray.800",
          "_dark": "gray.50"
        },
        "active": {
          "default": "pairs.gray.blackPair",
          "_dark": "pairs.gray.blackPair"
        },
        "disabled": {
          "default": "gray.300",
          "_dark": "gray.800"
        }
      }
    },
    "danger": {
      "dominant": {
        "resting": {
          "default": "red.500",
          "_dark": "red.500"
        },
        "hover": {
          "default": "red.600",
          "_dark": "red.600"
        },
        "active": {
          "default": "red.500",
          "_dark": "red.500"
        },
        "disabled": {
          "default": "gray.300",
          "_dark": "gray.800"
        }
      },
      "soft": {
        "resting": {
          "default": "red.50",
          "_dark": "red.50"
        },
        "hover": {
          "default": "red.100",
          "_dark": "red.100"
        },
        "active": {
          "default": "red.50",
          "_dark": "red.50"
        },
        "disabled": {
          "default": "pairs.alpha.alphaBlack100",
          "_dark": "pairs.alpha.alphaBlack100"
        }
      },
      "strong": {
        "resting": {
          "default": "red.300",
          "_dark": "red.300"
        },
        "hover": {
          "default": "red.400",
          "_dark": "red.400"
        },
        "active": {
          "default": "red.300",
          "_dark": "red.300"
        },
        "disabled": {
          "default": "gray.300",
          "_dark": "gray.800"
        }
      }
    },
    "outline": {
      "soft": {
        "hover": {
          "default": "pairs.alpha.alphaBlack50",
          "_dark": "whiteAlpha.200"
        },
        "active": {
          "default": "pairs.gray.50",
          "_dark": "whiteAlpha.100"
        },
        "resting": {
          "default": "whiteAlpha.50",
          "_dark": "whiteAlpha.100"
        }
      },
      "strong": {
        "hover": {
          "default": "pairs.gray.50",
          "_dark": "pairs.gray.50"
        },
        "active": {
          "default": "transparent",
          "_dark": "transparent"
        },
        "resting": {
          "default": "transparent",
          "_dark": "transparent"
        },
        "disabled": {
          "default": "transparent",
          "_dark": "transparent"
        }
      }
    },
    "warning": {
      "strong": {
        "resting": {
          "default": "yellow.400",
          "_dark": "yellow.400"
        },
        "hover": {
          "default": "yellow.500",
          "_dark": "yellow.500"
        },
        "active": {
          "default": "yellow.400",
          "_dark": "yellow.400"
        },
        "disabled": {
          "default": "gray.300",
          "_dark": "colorAlpha.warningOnDark"
        }
      },
      "soft": {
        "resting": {
          "default": "yellow.100",
          "_dark": "yellow.100"
        },
        "hover": {
          "default": "yellow.200",
          "_dark": "yellow.200"
        },
        "active": {
          "default": "yellow.100",
          "_dark": "yellow.100"
        },
        "disabled": {
          "default": "pairs.alpha.alphaBlack100",
          "_dark": "pairs.alpha.alphaBlack100"
        }
      }
    },
    "highlight": {
      "secondary": {
        "default": "#f5efff",
        "_dark": "colorAlpha.secondaryOnDark"
      },
      "primary": {
        "default": "#f7f7ff",
        "_dark": "colorAlpha.primaryOnDark"
      },
      "neutral": {
        "default": "gray.50",
        "_dark": "gray.825"
      }
    },
    "lifted": {
      "white": {
        "default": "white",
        "_dark": "gray.875"
      },
      "soft": {
        "default": "gray.50",
        "_dark": "gray.850"
      },
      "strong": {
        "default": "gray.100",
        "_dark": "gray.825"
      }
    }
  },
  "pairs": {
    "gray": {
      "50": {
        "default": "gray.50",
        "_dark": "gray.900"
      },
      "100": {
        "default": "gray.100",
        "_dark": "gray.800"
      },
      "200": {
        "default": "gray.200",
        "_dark": "gray.700"
      },
      "300": {
        "default": "gray.300",
        "_dark": "gray.600"
      },
      "400": {
        "default": "gray.400",
        "_dark": "gray.500"
      },
      "500": {
        "default": "gray.500",
        "_dark": "gray.400"
      },
      "600": {
        "default": "gray.600",
        "_dark": "gray.300"
      },
      "700": {
        "default": "gray.700",
        "_dark": "gray.200"
      },
      "800": {
        "default": "gray.800",
        "_dark": "gray.100"
      },
      "900": {
        "default": "gray.900",
        "_dark": "gray.50"
      },
      "whitePair": {
        "default": "white",
        "_dark": "black"
      },
      "blackPair": {
        "default": "black",
        "_dark": "white"
      }
    },
    "red": {
      "50": {
        "default": "red.50",
        "_dark": "red.900"
      },
      "100": {
        "default": "red.100",
        "_dark": "red.800"
      },
      "200": {
        "default": "red.200",
        "_dark": "red.700"
      },
      "300": {
        "default": "red.300",
        "_dark": "red.600"
      },
      "400": {
        "default": "red.400",
        "_dark": "red.500"
      },
      "500": {
        "default": "red.500",
        "_dark": "red.400"
      },
      "600": {
        "default": "red.600",
        "_dark": "red.300"
      },
      "700": {
        "default": "red.700",
        "_dark": "red.200"
      },
      "800": {
        "default": "red.800",
        "_dark": "red.100"
      },
      "900": {
        "default": "red.900",
        "_dark": "red.50"
      }
    },
    "orange": {
      "50": {
        "default": "orange.50",
        "_dark": "orange.900"
      },
      "100": {
        "default": "orange.100",
        "_dark": "orange.800"
      },
      "200": {
        "default": "orange.200",
        "_dark": "orange.700"
      },
      "300": {
        "default": "orange.300",
        "_dark": "orange.600"
      },
      "400": {
        "default": "orange.400",
        "_dark": "orange.500"
      },
      "500": {
        "default": "orange.500",
        "_dark": "orange.400"
      },
      "600": {
        "default": "orange.600",
        "_dark": "orange.300"
      },
      "700": {
        "default": "orange.700",
        "_dark": "orange.200"
      },
      "800": {
        "default": "orange.800",
        "_dark": "orange.100"
      },
      "900": {
        "default": "orange.900",
        "_dark": "orange.50"
      }
    },
    "green": {
      "50": {
        "default": "green.50",
        "_dark": "green.900"
      },
      "100": {
        "default": "green.100",
        "_dark": "green.800"
      },
      "200": {
        "default": "green.200",
        "_dark": "green.700"
      },
      "300": {
        "default": "green.300",
        "_dark": "green.600"
      },
      "400": {
        "default": "green.400",
        "_dark": "green.500"
      },
      "500": {
        "default": "green.500",
        "_dark": "green.400"
      },
      "600": {
        "default": "green.600",
        "_dark": "green.300"
      },
      "700": {
        "default": "green.700",
        "_dark": "green.200"
      },
      "800": {
        "default": "green.800",
        "_dark": "green.100"
      },
      "900": {
        "default": "green.900",
        "_dark": "green.50"
      }
    },
    "blue": {
      "50": {
        "default": "blue.50",
        "_dark": "blue.900"
      },
      "100": {
        "default": "blue.100",
        "_dark": "blue.800"
      },
      "200": {
        "default": "blue.200",
        "_dark": "blue.700"
      },
      "300": {
        "default": "blue.300",
        "_dark": "blue.600"
      },
      "400": {
        "default": "blue.400",
        "_dark": "blue.500"
      },
      "500": {
        "default": "blue.500",
        "_dark": "blue.400"
      },
      "600": {
        "default": "blue.600",
        "_dark": "blue.300"
      },
      "700": {
        "default": "blue.700",
        "_dark": "blue.200"
      },
      "800": {
        "default": "blue.800",
        "_dark": "blue.100"
      },
      "900": {
        "default": "blue.900",
        "_dark": "blue.50"
      }
    },
    "yellow": {
      "50": {
        "default": "yellow.50",
        "_dark": "yellow.900"
      },
      "100": {
        "default": "yellow.100",
        "_dark": "yellow.800"
      },
      "200": {
        "default": "yellow.200",
        "_dark": "yellow.700"
      },
      "300": {
        "default": "yellow.300",
        "_dark": "yellow.600"
      },
      "400": {
        "default": "yellow.400",
        "_dark": "yellow.500"
      },
      "500": {
        "default": "yellow.500",
        "_dark": "yellow.400"
      },
      "600": {
        "default": "yellow.600",
        "_dark": "yellow.300"
      },
      "700": {
        "default": "yellow.700",
        "_dark": "yellow.200"
      },
      "800": {
        "default": "yellow.800",
        "_dark": "yellow.100"
      },
      "900": {
        "default": "yellow.900",
        "_dark": "yellow.50"
      }
    },
    "teal": {
      "50": {
        "default": "teal.50",
        "_dark": "teal.900"
      },
      "100": {
        "default": "teal.100",
        "_dark": "teal.800"
      },
      "200": {
        "default": "teal.200",
        "_dark": "teal.700"
      },
      "300": {
        "default": "teal.300",
        "_dark": "teal.600"
      },
      "400": {
        "default": "teal.400",
        "_dark": "teal.500"
      },
      "500": {
        "default": "teal.500",
        "_dark": "teal.400"
      },
      "600": {
        "default": "teal.600",
        "_dark": "teal.300"
      },
      "700": {
        "default": "teal.700",
        "_dark": "teal.200"
      },
      "800": {
        "default": "teal.800",
        "_dark": "teal.100"
      },
      "900": {
        "default": "teal.900",
        "_dark": "teal.50"
      }
    },
    "cyan": {
      "50": {
        "default": "cyan.50",
        "_dark": "cyan.900"
      },
      "100": {
        "default": "cyan.100",
        "_dark": "cyan.800"
      },
      "200": {
        "default": "cyan.200",
        "_dark": "cyan.700"
      },
      "300": {
        "default": "cyan.300",
        "_dark": "cyan.600"
      },
      "400": {
        "default": "cyan.400",
        "_dark": "cyan.500"
      },
      "500": {
        "default": "cyan.500",
        "_dark": "cyan.400"
      },
      "600": {
        "default": "cyan.600",
        "_dark": "cyan.300"
      },
      "700": {
        "default": "cyan.700",
        "_dark": "cyan.200"
      },
      "800": {
        "default": "cyan.800",
        "_dark": "cyan.100"
      },
      "900": {
        "default": "cyan.900",
        "_dark": "cyan.50"
      }
    },
    "purple": {
      "50": {
        "default": "purple.50",
        "_dark": "purple.900"
      },
      "100": {
        "default": "purple.100",
        "_dark": "purple.800"
      },
      "200": {
        "default": "purple.200",
        "_dark": "purple.700"
      },
      "300": {
        "default": "purple.300",
        "_dark": "purple.600"
      },
      "400": {
        "default": "purple.400",
        "_dark": "purple.500"
      },
      "500": {
        "default": "purple.500",
        "_dark": "purple.400"
      },
      "600": {
        "default": "purple.600",
        "_dark": "purple.300"
      },
      "700": {
        "default": "purple.700",
        "_dark": "purple.200"
      },
      "800": {
        "default": "purple.800",
        "_dark": "purple.100"
      },
      "900": {
        "default": "purple.900",
        "_dark": "purple.50"
      }
    },
    "pink": {
      "50": {
        "default": "pink.50",
        "_dark": "pink.900"
      },
      "100": {
        "default": "pink.100",
        "_dark": "pink.800"
      },
      "200": {
        "default": "pink.200",
        "_dark": "pink.700"
      },
      "300": {
        "default": "pink.300",
        "_dark": "pink.600"
      },
      "400": {
        "default": "pink.400",
        "_dark": "pink.500"
      },
      "500": {
        "default": "pink.500",
        "_dark": "pink.400"
      },
      "600": {
        "default": "pink.600",
        "_dark": "pink.300"
      },
      "700": {
        "default": "pink.700",
        "_dark": "pink.200"
      },
      "800": {
        "default": "pink.800",
        "_dark": "pink.100"
      },
      "900": {
        "default": "pink.900",
        "_dark": "pink.50"
      }
    },
    "alpha": {
      "alphaWhite50": {
        "default": "whiteAlpha.50",
        "_dark": "blackAlpha.50"
      },
      "alphaWhite100": {
        "default": "whiteAlpha.100",
        "_dark": "blackAlpha.100"
      },
      "alphaWhite200": {
        "default": "whiteAlpha.200",
        "_dark": "blackAlpha.200"
      },
      "alphaWhite300": {
        "default": "whiteAlpha.300",
        "_dark": "blackAlpha.300"
      },
      "alphaWhite400": {
        "default": "whiteAlpha.400",
        "_dark": "blackAlpha.400"
      },
      "alphaWhite500": {
        "default": "whiteAlpha.500",
        "_dark": "blackAlpha.500"
      },
      "alphaWhite600": {
        "default": "whiteAlpha.600",
        "_dark": "blackAlpha.600"
      },
      "alphaWhite700": {
        "default": "whiteAlpha.700",
        "_dark": "blackAlpha.700"
      },
      "alphaWhite800": {
        "default": "whiteAlpha.800",
        "_dark": "blackAlpha.800"
      },
      "alphaWhite900": {
        "default": "whiteAlpha.900",
        "_dark": "blackAlpha.900"
      },
      "alphaBlack50": {
        "default": "blackAlpha.50",
        "_dark": "whiteAlpha.50"
      },
      "alphaBlack100": {
        "default": "blackAlpha.100",
        "_dark": "whiteAlpha.100"
      },
      "alphaBlack200": {
        "default": "blackAlpha.200",
        "_dark": "whiteAlpha.200"
      },
      "alphaBlack300": {
        "default": "blackAlpha.300",
        "_dark": "whiteAlpha.300"
      },
      "alphaBlack400": {
        "default": "blackAlpha.400",
        "_dark": "whiteAlpha.400"
      },
      "alphaBlack500": {
        "default": "blackAlpha.500",
        "_dark": "whiteAlpha.500"
      },
      "alphaBlack600": {
        "default": "blackAlpha.600",
        "_dark": "whiteAlpha.600"
      },
      "alphaBlack700": {
        "default": "blackAlpha.700",
        "_dark": "whiteAlpha.700"
      },
      "alphaBlack800": {
        "default": "blackAlpha.800",
        "_dark": "whiteAlpha.800"
      },
      "alphaBlack900": {
        "default": "blackAlpha.900",
        "_dark": "whiteAlpha.900"
      },
      "alphaPrimary10": {
        "default": "#a29ff81a",
        "_dark": "#5a58f21a"
      },
      "alphaPrimary20": {
        "default": "#a29ff833",
        "_dark": "#5a58f233"
      },
      "alphaPrimary30": {
        "default": "#a29ff84d",
        "_dark": "#5a58f24d"
      },
      "alphaPrimary40": {
        "default": "#a29ff866",
        "_dark": "#5a58f266"
      },
      "alphaSecondary10": {
        "default": "#a98eda1a",
        "_dark": "#8c68cd1a"
      },
      "alphaSecondary20": {
        "default": "#a98eda33",
        "_dark": "#8c68cd33"
      },
      "alphaSecondary30": {
        "default": "#a98eda4d",
        "_dark": "#8c68cd4d"
      },
      "alphaSecondary40": {
        "default": "#a98eda66",
        "_dark": "#8c68cd66"
      }
    }
  },
  "components": {
    "avatar": {
      "borderGroup": {
        "default": "surfaces.page.white",
        "_dark": "surfaces.page.white"
      },
      "bgInitialsDefault": {
        "default": "blue.400",
        "_dark": "blue.400"
      },
      "textOnDominant": {
        "default": "white",
        "_dark": "white"
      },
      "bgInitials1": {
        "default": "orange.400",
        "_dark": "orange.400"
      },
      "bgInitials2": {
        "default": "teal.200",
        "_dark": "teal.200"
      },
      "bgInitials3": {
        "default": "yellow.500",
        "_dark": "yellow.500"
      },
      "bgInitials4": {
        "default": "cyan.700",
        "_dark": "cyan.700"
      },
      "bgInitials5": {
        "default": "purple.200",
        "_dark": "purple.200"
      },
      "bgInitials6": {
        "default": "red.400",
        "_dark": "red.400"
      },
      "bgInitials7": {
        "default": "blue.400",
        "_dark": "blue.400"
      },
      "bgInitials8": {
        "default": "pink.700",
        "_dark": "pink.700"
      },
      "bgInitials9": {
        "default": "pink.200",
        "_dark": "pink.200"
      },
      "bgInitials10": {
        "default": "teal.500",
        "_dark": "teal.500"
      },
      "bgInitials11": {
        "default": "yellow.300",
        "_dark": "yellow.300"
      },
      "bgInitials12": {
        "default": "cyan.400",
        "_dark": "cyan.400"
      },
      "bgInitials13": {
        "default": "purple.600",
        "_dark": "purple.600"
      },
      "bgInitials14": {
        "default": "red.300",
        "_dark": "red.300"
      },
      "bgInitials15": {
        "default": "blue.200",
        "_dark": "blue.200"
      },
      "bgInitials16": {
        "default": "pink.500",
        "_dark": "pink.500"
      },
      "textOnInitialsSoft": {
        "default": "gray.900",
        "_dark": "gray.900"
      },
      "textOnInitialsStrong": {
        "default": "white",
        "_dark": "white"
      }
    },
    "badge": {
      "textNeutral": {
        "default": "gray.900",
        "_dark": "white"
      },
      "textPrimary": {
        "default": "blue.500",
        "_dark": "blue.200"
      },
      "textSecondary": {
        "default": "purple.500",
        "_dark": "purple.300"
      },
      "textWarning": {
        "default": "yellow.800",
        "_dark": "yellow.500"
      },
      "textSuccess": {
        "default": "green.600",
        "_dark": "green.300"
      },
      "textDanger": {
        "default": "red.600",
        "_dark": "red.300"
      },
      "bgPrimaryStrong": {
        "default": "blue.400",
        "_dark": "blue.400"
      },
      "textDisabled": {
        "default": "gray.700",
        "_dark": "gray.400"
      },
      "bgSecondaryStrong": {
        "default": "purple.500",
        "_dark": "purple.500"
      },
      "bgSuccessStrong": {
        "default": "green.500",
        "_dark": "green.500"
      },
      "textWhite": {
        "default": "white",
        "_dark": "white"
      },
      "borderAsset": {
        "default": "pairs.gray.whitePair",
        "_dark": "pairs.gray.whitePair"
      },
      "bgDisabledStrong": {
        "default": "gray.200",
        "_dark": "pairs.alpha.alphaBlack400"
      },
      "bgDangerStrong": {
        "default": "red.500",
        "_dark": "red.500"
      },
      "bgWarningStrong": {
        "default": "yellow.400",
        "_dark": "yellow.400"
      },
      "bgSuccessSoft": {
        "default": "green.50",
        "_dark": "colorAlpha.successOnDark"
      },
      "bgDangerSoft": {
        "default": "red.50",
        "_dark": "colorAlpha.dangerOnDark"
      },
      "bgWarningSoft": {
        "default": "yellow.50",
        "_dark": "colorAlpha.warningOnDark"
      },
      "bgPrimarySoft": {
        "default": "blue.50",
        "_dark": "colorAlpha.primaryOnDark"
      },
      "bgSecondarySoft": {
        "default": "purple.50",
        "_dark": "colorAlpha.secondaryOnDark"
      },
      "textNeutralDominant": {
        "default": "gray.900",
        "_dark": "white"
      },
      "textNeutralStrong": {
        "default": "gray.700",
        "_dark": "gray.300"
      },
      "bgNeutralStrong": {
        "default": "surfaces.alpha.dominant.300",
        "_dark": "pairs.alpha.alphaBlack400"
      },
      "textNeutralSoft": {
        "default": "gray.600",
        "_dark": "gray.400"
      },
      "bgNeutralSoft": {
        "default": "surfaces.alpha.dominant.100",
        "_dark": "pairs.alpha.alphaBlack100"
      },
      "bgInternalStrong": {
        "default": "pink.500",
        "_dark": "pink.400"
      },
      "bgAccent1Soft": {
        "default": "pink.100",
        "_dark": "colorAlpha.internalOnDark"
      },
      "textAccent1": {
        "default": "pink.600",
        "_dark": "pink.300"
      },
      "textBlack": {
        "default": "gray.900",
        "_dark": "gray.900"
      },
      "bgWhiteSoft": {
        "default": "whiteAlpha.300",
        "_dark": "whiteAlpha.300"
      },
      "bgWhiteStrong": {
        "default": "white",
        "_dark": "white"
      },
      "bgAccent2Strong": {
        "default": "orange.400",
        "_dark": "orange.500"
      },
      "bgAccent2Soft": {
        "default": "orange.100",
        "_dark": "colorAlpha.accent1OnDark"
      },
      "borderAccent1": {
        "default": "pink.400",
        "_dark": "pink.400"
      },
      "textAccent2": {
        "default": "orange.800",
        "_dark": "orange.500"
      },
      "textAccent3": {
        "default": "cyan.800",
        "_dark": "cyan.400"
      },
      "bgAccent3Strong": {
        "default": "cyan.300",
        "_dark": "cyan.300"
      },
      "bgAccent3Soft": {
        "default": "cyan.100",
        "_dark": "colorAlpha.accent2OnDark"
      },
      "borderAccent3": {
        "default": "cyan.300",
        "_dark": "cyan.300"
      },
      "textAccent4": {
        "default": "teal.700",
        "_dark": "teal.300"
      },
      "bgAccent4Strong": {
        "default": "teal.300",
        "_dark": "teal.300"
      },
      "bgAccent4Soft": {
        "default": "teal.50",
        "_dark": "colorAlpha.accent3OnDark"
      },
      "borderAccent4": {
        "default": "teal.300",
        "_dark": "cyan.300"
      },
      "borderAccent2": {
        "default": "orange.500",
        "_dark": "orange.500"
      }
    },
    "accordion": {
      "borderActive": {
        "default": "blue.500",
        "_dark": "blue.200"
      }
    },
    "switch": {
      "bgResting": {
        "default": "gray.400",
        "_dark": "gray.400"
      }
    },
    "inputs": {
      "bgRestingWhite": {
        "default": "white",
        "_dark": "whiteAlpha.100"
      },
      "bgRestingGray": {
        "default": "gray.50",
        "_dark": "whiteAlpha.100"
      },
      "bgRestingDisabled": {
        "default": "surfaces.neutral.soft.disabled",
        "_dark": "surfaces.neutral.soft.disabled"
      },
      "bgRestingTransparent": {
        "default": "transparent",
        "_dark": "transparent"
      }
    },
    "popover": {
      "textFooter": {
        "default": "text.neutralStrong",
        "_dark": "purple.100"
      }
    },
    "datePicker": {
      "bgCurrent": {
        "default": "blackAlpha.100",
        "_dark": "whiteAlpha.100"
      },
      "bgRangeEnds": {
        "default": "blue.400",
        "_dark": "blue.300"
      },
      "bgRangeMiddle": {
        "default": "pairs.alpha.alphaPrimary30",
        "_dark": "pairs.alpha.alphaPrimary30"
      },
      "bgHover": {
        "default": "gray.100",
        "_dark": "gray.800"
      },
      "bgRangeActive": {
        "default": "blue.400",
        "_dark": "blue.300"
      },
      "textOnCurrent": {
        "default": "text.neutralDominant",
        "_dark": "text.neutralDominant"
      },
      "textOnRangeEnds": {
        "default": "text.onPrimaryStrong",
        "_dark": "text.onPrimarySoft"
      },
      "textOnRangeMiddle": {
        "default": "text.onPrimarySoft",
        "_dark": "white"
      }
    },
    "hotkey": {
      "borderDominant": {
        "default": "gray.600",
        "_dark": "gray.300"
      },
      "bgDominant": {
        "default": "gray.800",
        "_dark": "gray.100"
      },
      "textDominant": {
        "default": "gray.400",
        "_dark": "gray.600"
      },
      "borderSoft": {
        "default": "gray.300",
        "_dark": "gray.600"
      },
      "bgSoft": {
        "default": "gray.100",
        "_dark": "gray.800"
      },
      "textSoft": {
        "default": "gray.600",
        "_dark": "gray.400"
      }
    },
    "assetBadge": {
      "bgGray": {
        "default": "gray.100",
        "_dark": "gray.100"
      },
      "iconNeutralDominant": {
        "default": "gray.900",
        "_dark": "gray.900"
      },
      "borderWhite": {
        "default": "white",
        "_dark": "white"
      },
      "bgPurple": {
        "default": "purple.100",
        "_dark": "purple.100"
      },
      "bgYellow": {
        "default": "yellow.300",
        "_dark": "yellow.300"
      },
      "bgGreen": {
        "default": "green.200",
        "_dark": "green.200"
      },
      "bgBlack": {
        "default": "black",
        "_dark": "gray.100"
      }
    },
    "calendar": {
      "bgScheduled": {
        "default": "blue.100",
        "_dark": "blue.100"
      },
      "bgRejected": {
        "default": "yellow.300",
        "_dark": "yellow.300"
      },
      "bgFailed": {
        "default": "red.300",
        "_dark": "red.300"
      },
      "bgProcessing": {
        "default": "pairs.gray.300",
        "_dark": "pairs.gray.300"
      },
      "bgPending": {
        "default": "purple.200",
        "_dark": "purple.200"
      },
      "bgPublished": {
        "default": "green.400",
        "_dark": "green.400"
      }
    },
    "breadcrumb": {
      "iconSeparator": {
        "default": "pairs.gray.300",
        "_dark": "pairs.gray.300"
      }
    },
    "banner": {
      "textNeutral": {
        "default": "gray.900",
        "_dark": "white"
      },
      "textPrimary": {
        "default": "blue.500",
        "_dark": "blue.200"
      },
      "textSecondary": {
        "default": "purple.500",
        "_dark": "purple.300"
      },
      "textSuccess": {
        "default": "green.600",
        "_dark": "green.300"
      },
      "textWarning": {
        "default": "yellow.800",
        "_dark": "yellow.500"
      },
      "textDanger": {
        "default": "red.600",
        "_dark": "red.200"
      },
      "textDisabled": {
        "default": "gray.700",
        "_dark": "gray.400"
      },
      "textWhite": {
        "default": "white",
        "_dark": "white"
      },
      "bgPrimaryStrong": {
        "default": "blue.400",
        "_dark": "blue.400"
      },
      "bgPrimarySoft": {
        "default": "blue.50",
        "_dark": "colorAlpha.primaryOnDark"
      },
      "bgSecondaryStrong": {
        "default": "purple.500",
        "_dark": "purple.500"
      },
      "bgSecondarySoft": {
        "default": "purple.50",
        "_dark": "colorAlpha.secondaryOnDark"
      },
      "bgSuccessStrong": {
        "default": "green.500",
        "_dark": "green.500"
      },
      "bgSuccessSoft": {
        "default": "green.50",
        "_dark": "colorAlpha.successOnDark"
      },
      "bgDangerStrong": {
        "default": "red.300",
        "_dark": "red.300"
      },
      "bgDangerSoft": {
        "default": "red.50",
        "_dark": "colorAlpha.dangerOnDark"
      },
      "bgWarningStrong": {
        "default": "yellow.400",
        "_dark": "yellow.400"
      },
      "bgWarningSoft": {
        "default": "yellow.50",
        "_dark": "colorAlpha.warningOnDark"
      },
      "bgDisabledStrong": {
        "default": "gray.200",
        "_dark": "pairs.alpha.alphaBlack400"
      },
      "borderAsset": {
        "default": "pairs.gray.blackPair",
        "_dark": "pairs.gray.blackPair"
      },
      "borderWarning": {
        "default": "components.banner.textWarning",
        "_dark": "components.banner.textWarning"
      },
      "borderPrimary": {
        "default": "components.banner.textPrimary",
        "_dark": "components.banner.textPrimary"
      },
      "borderSuccess": {
        "default": "components.banner.textSuccess",
        "_dark": "components.banner.textSuccess"
      },
      "borderSecondary": {
        "default": "components.banner.textSecondary",
        "_dark": "components.banner.textSecondary"
      },
      "borderDanger": {
        "default": "components.banner.textDanger",
        "_dark": "components.banner.textDanger"
      },
      "borderDisabled": {
        "default": "components.banner.textDisabled",
        "_dark": "components.banner.textDisabled"
      },
      "borderWhite": {
        "default": "components.banner.textWhite",
        "_dark": "components.banner.textWhite"
      },
      "iconWarning": {
        "default": "components.banner.textWarning",
        "_dark": "components.banner.textWarning"
      },
      "iconPrimary": {
        "default": "components.banner.textPrimary",
        "_dark": "components.banner.textPrimary"
      },
      "iconSuccess": {
        "default": "components.banner.textSuccess",
        "_dark": "components.banner.textSuccess"
      },
      "iconSecondary": {
        "default": "components.banner.textSecondary",
        "_dark": "components.banner.textSecondary"
      },
      "iconDanger": {
        "default": "components.banner.textDanger",
        "_dark": "components.banner.textDanger"
      },
      "iconDisabled": {
        "default": "components.banner.textDisabled",
        "_dark": "components.banner.textDisabled"
      },
      "iconWhite": {
        "default": "components.banner.textWhite",
        "_dark": "components.banner.textWhite"
      },
      "iconNeutral": {
        "default": "components.banner.textNeutral",
        "_dark": "components.banner.textNeutral"
      }
    },
    "menu": {
      "bgSelected": {
        "default": "surfaces.highlight.secondary",
        "_dark": "surfaces.alpha.dominant.300"
      }
    },
    "checkboxButton": {
      "bgSelected": {
        "default": "surfaces.primary.soft.resting",
        "_dark": "surfaces.primary.strong.resting"
      },
      "textSelected": {
        "default": "text.onPrimarySoft",
        "_dark": "text.onPrimaryStrong"
      },
      "borderSelected": {
        "default": "borders.primary.strong.resting",
        "_dark": "pairs.blue.500"
      }
    },
    "circleIcon": {
      "neutral": {
        "default": "gray.200",
        "_dark": "gray.200"
      },
      "secondary": {
        "default": "purple.100",
        "_dark": "purple.100"
      },
      "success": {
        "default": "green.200",
        "_dark": "green.200"
      },
      "warning": {
        "default": "yellow.400",
        "_dark": "yellow.400"
      },
      "danger": {
        "default": "red.200",
        "_dark": "red.200"
      },
      "accent1": {
        "default": "pink.100",
        "_dark": "pink.100"
      },
      "accent2": {
        "default": "cyan.100",
        "_dark": "cyan.100"
      },
      "accent3": {
        "default": "orange.300",
        "_dark": "orange.300"
      },
      "accent4": {
        "default": "teal.200",
        "_dark": "teal.200"
      }
    },
    "overlay": {
      "darken": {
        "default": "blackAlpha.300",
        "_dark": "blackAlpha.600"
      }
    },
    "chart": {
      "fill": {
        "qual": {
          "color1": {
            "default": "blue.400",
            "_dark": "blue.300"
          },
          "color2": {
            "default": "pink.300",
            "_dark": "pink.300"
          },
          "color3": {
            "default": "yellow.500",
            "_dark": "yellow.400"
          },
          "color4": {
            "default": "teal.300",
            "_dark": "teal.200"
          },
          "color5": {
            "default": "orange.500",
            "_dark": "orange.400"
          },
          "color6": {
            "default": "cyan.500",
            "_dark": "cyan.500"
          },
          "color7": {
            "default": "pink.500",
            "_dark": "pink.500"
          },
          "color8": {
            "default": "yellow.300",
            "_dark": "yellow.300"
          },
          "color9": {
            "default": "teal.500",
            "_dark": "teal.400"
          },
          "color10": {
            "default": "orange.300",
            "_dark": "orange.300"
          }
        },
        "seq": {
          "color1": {
            "default": "blue.100",
            "_dark": "blue.600"
          },
          "color2": {
            "default": "blue.200",
            "_dark": "blue.500"
          },
          "color3": {
            "default": "blue.300",
            "_dark": "blue.400"
          },
          "color4": {
            "default": "blue.400",
            "_dark": "blue.300"
          },
          "color5": {
            "default": "blue.500",
            "_dark": "blue.200"
          },
          "color6": {
            "default": "blue.600",
            "_dark": "blue.100"
          },
          "color7": {
            "default": "blue.700",
            "_dark": "blue.50"
          }
        },
        "div": {
          "color1": {
            "default": "red.500",
            "_dark": "red.500"
          },
          "color2": {
            "default": "red.300",
            "_dark": "red.300"
          },
          "color3": {
            "default": "orange.300",
            "_dark": "orange.300"
          },
          "color4": {
            "default": "yellow.300",
            "_dark": "yellow.300"
          },
          "color5": {
            "default": "green.200",
            "_dark": "green.100"
          },
          "color6": {
            "default": "green.400",
            "_dark": "green.200"
          },
          "color7": {
            "default": "green.600",
            "_dark": "green.400"
          }
        }
      },
      "lines": {
        "axis": {
          "default": "gray.400",
          "_dark": "gray.400"
        },
        "grid": {
          "default": "gray.300",
          "_dark": "gray.600"
        }
      }
    }
  },
  "borders": {
    "neutral": {
      "dominant": {
        "resting": {
          "default": "pairs.gray.900",
          "_dark": "pairs.gray.900"
        },
        "hover": {
          "default": "pairs.gray.700",
          "_dark": "pairs.gray.700"
        },
        "active": {
          "default": "pairs.gray.900",
          "_dark": "pairs.gray.900"
        },
        "disabled": {
          "default": "pairs.gray.300",
          "_dark": "pairs.gray.300"
        }
      },
      "strong": {
        "resting": {
          "default": "gray.700",
          "_dark": "gray.300"
        },
        "hover": {
          "default": "pairs.gray.600",
          "_dark": "pairs.gray.600"
        },
        "active": {
          "default": "gray.500",
          "_dark": "gray.700"
        },
        "disabled": {
          "default": "gray.300",
          "_dark": "gray.800"
        }
      },
      "soft": {
        "resting": {
          "default": "pairs.gray.300",
          "_dark": "pairs.gray.300"
        },
        "hover": {
          "default": "pairs.gray.200",
          "_dark": "pairs.gray.200"
        },
        "active": {
          "default": "pairs.gray.400",
          "_dark": "pairs.gray.400"
        },
        "disabled": {
          "default": "gray.100",
          "_dark": "gray.800"
        }
      },
      "hush": {
        "resting": {
          "default": "gray.100",
          "_dark": "gray.800"
        },
        "hover": {
          "default": "gray.200",
          "_dark": "gray.700"
        },
        "active": {
          "default": "gray.100",
          "_dark": "gray.800"
        },
        "disabled": {
          "default": "gray.50",
          "_dark": "whiteAlpha.100"
        }
      },
      "fixed": {
        "resting": {
          "default": "gray.900",
          "_dark": "gray.900"
        },
        "hover": {
          "default": "gray.700",
          "_dark": "gray.700"
        },
        "active": {
          "default": "gray.900",
          "_dark": "gray.900"
        },
        "disabled": {
          "default": "gray.50",
          "_dark": "whiteAlpha.100"
        }
      }
    },
    "primary": {
      "strong": {
        "resting": {
          "default": "blue.400",
          "_dark": "blue.100"
        },
        "hover": {
          "default": "blue.500",
          "_dark": "blue.200"
        },
        "active": {
          "default": "blue.400",
          "_dark": "blue.100"
        }
      },
      "soft": {
        "resting": {
          "default": "blue.200",
          "_dark": "blue.200"
        },
        "hover": {
          "default": "blue.300",
          "_dark": "blue.300"
        },
        "active": {
          "default": "blue.200",
          "_dark": "blue.200"
        }
      }
    },
    "secondary": {
      "strong": {
        "resting": {
          "default": "purple.400",
          "_dark": "purple.200"
        },
        "hover": {
          "default": "purple.500",
          "_dark": "purple.300"
        },
        "active": {
          "default": "purple.400",
          "_dark": "purple.200"
        }
      },
      "soft": {
        "resting": {
          "default": "purple.100",
          "_dark": "purple.600"
        },
        "hover": {
          "default": "purple.300",
          "_dark": "purple.500"
        },
        "active": {
          "default": "purple.200",
          "_dark": "purple.400"
        }
      }
    },
    "success": {
      "strong": {
        "resting": {
          "default": "green.400",
          "_dark": "green.200"
        },
        "hover": {
          "default": "green.500",
          "_dark": "green.300"
        },
        "active": {
          "default": "green.400",
          "_dark": "green.200"
        }
      },
      "soft": {
        "resting": {
          "default": "green.200",
          "_dark": "green.400"
        },
        "hover": {
          "default": "green.300",
          "_dark": "green.500"
        },
        "active": {
          "default": "green.200",
          "_dark": "green.400"
        }
      }
    },
    "danger": {
      "strong": {
        "resting": {
          "default": "red.400",
          "_dark": "red.400"
        },
        "hover": {
          "default": "red.600",
          "_dark": "red.200"
        },
        "active": {
          "default": "red.400",
          "_dark": "red.400"
        }
      },
      "soft": {
        "resting": {
          "default": "red.200",
          "_dark": "red.300"
        },
        "hover": {
          "default": "red.400",
          "_dark": "red.500"
        },
        "active": {
          "default": "red.200",
          "_dark": "red.300"
        }
      }
    },
    "warning": {
      "strong": {
        "resting": {
          "default": "yellow.400",
          "_dark": "yellow.400"
        },
        "hover": {
          "default": "yellow.500",
          "_dark": "yellow.500"
        },
        "active": {
          "default": "yellow.400",
          "_dark": "yellow.400"
        }
      },
      "soft": {
        "resting": {
          "default": "yellow.200",
          "_dark": "yellow.200"
        },
        "hover": {
          "default": "yellow.300",
          "_dark": "yellow.300"
        },
        "active": {
          "default": "yellow.200",
          "_dark": "yellow.200"
        }
      }
    },
    "disabled": {
      "strong": {
        "default": "pairs.gray.300",
        "_dark": "pairs.gray.300"
      },
      "soft": {
        "default": "pairs.gray.200",
        "_dark": "pairs.gray.200"
      },
      "dominant": {
        "default": "pairs.gray.500",
        "_dark": "pairs.gray.500"
      },
      "hush": {
        "default": "pairs.gray.50",
        "_dark": "pairs.gray.50"
      }
    },
    "inverse": {
      "strong": {
        "default": "white",
        "_dark": "white"
      },
      "soft": {
        "default": "whiteAlpha.500",
        "_dark": "whiteAlpha.500"
      }
    },
    "separator": {
      "default": "pairs.gray.200",
      "_dark": "pairs.gray.100"
    },
    "active": {
      "soft": {
        "default": "borders.primary.soft.hover",
        "_dark": "borders.primary.soft.hover"
      },
      "strong": {
        "default": "borders.primary.strong.hover",
        "_dark": "borders.primary.strong.hover"
      }
    },
    "alpha": {
      "primary": {
        "default": "blue.50",
        "_dark": "#5a58f266"
      },
      "success": {
        "default": "green.100",
        "_dark": "#479f7666"
      },
      "danger": {
        "default": "red.200",
        "_dark": "#dc354566"
      },
      "warning": {
        "default": "yellow.200",
        "_dark": "#ffc10733"
      },
      "neutral": {
        "default": "surfaces.alpha.dominant.300",
        "_dark": "surfaces.alpha.dominant.300"
      },
      "secondary": {
        "default": "purple.50",
        "_dark": "#6f42c166"
      }
    },
    "edge": {
      "default": "pairs.gray.300",
      "_dark": "pairs.gray.200"
    }
  },
  "icon": {
    "neutralDominant": {
      "default": "text.neutralDominant",
      "_dark": "text.neutralDominant"
    },
    "neutralStrong": {
      "default": "text.neutralStrong",
      "_dark": "text.neutralStrong"
    },
    "neutralSoft": {
      "default": "text.neutralSoft",
      "_dark": "text.neutralSoft"
    },
    "primary": {
      "default": "text.primary",
      "_dark": "text.primary"
    },
    "secondary": {
      "default": "text.secondary",
      "_dark": "text.secondary"
    },
    "success": {
      "default": "text.success",
      "_dark": "text.success"
    },
    "danger": {
      "default": "text.danger",
      "_dark": "text.danger"
    },
    "warning": {
      "default": "text.warning",
      "_dark": "text.warning"
    },
    "placeholder": {
      "default": "text.placeholder",
      "_dark": "text.placeholder"
    },
    "link": {
      "default": "text.link",
      "_dark": "text.link"
    },
    "inverse": {
      "default": "text.inverse",
      "_dark": "text.inverse"
    },
    "disabled": {
      "default": "gray.400",
      "_dark": "gray.500"
    },
    "disabledOnSoft": {
      "default": "gray.500",
      "_dark": "gray.500"
    },
    "disabledOnStrong": {
      "default": "gray.600",
      "_dark": "gray.500"
    },
    "onNeutralDominant": {
      "default": "text.onNeutralDominant",
      "_dark": "text.onNeutralDominant"
    },
    "onNeutralStrong": {
      "default": "text.onNeutralStrong",
      "_dark": "text.onNeutralStrong"
    },
    "onNeutralSoft": {
      "default": "text.onNeutralSoft",
      "_dark": "text.onNeutralSoft"
    },
    "onNeutralHush": {
      "default": "text.onNeutralHush",
      "_dark": "text.onNeutralHush"
    },
    "onPrimaryStrong": {
      "default": "text.onPrimaryStrong",
      "_dark": "text.onPrimaryStrong"
    },
    "onPrimarySoft": {
      "default": "text.onPrimarySoft",
      "_dark": "text.onPrimarySoft"
    },
    "onSecondaryDominant": {
      "default": "text.onSecondaryDominant",
      "_dark": "text.onSecondaryDominant"
    },
    "onSecondaryStrong": {
      "default": "text.onSecondaryStrong",
      "_dark": "text.onSecondaryStrong"
    },
    "onSecondarySoft": {
      "default": "text.onSecondarySoft",
      "_dark": "text.onSecondarySoft"
    },
    "onSuccessDominant": {
      "default": "text.onSuccessDominant",
      "_dark": "text.onSuccessDominant"
    },
    "onSuccessStrong": {
      "default": "text.onSuccessStrong",
      "_dark": "text.onSuccessStrong"
    },
    "onSuccessSoft": {
      "default": "text.onSuccessSoft",
      "_dark": "text.onSuccessSoft"
    },
    "onDangerDominant": {
      "default": "text.onDangerDominant",
      "_dark": "text.onDangerDominant"
    },
    "onDangerStrong": {
      "default": "text.onDangerStrong",
      "_dark": "text.onDangerStrong"
    },
    "onDangerSoft": {
      "default": "text.onDangerSoft",
      "_dark": "text.onDangerSoft"
    },
    "onWarningStrong": {
      "default": "text.onWarningStrong",
      "_dark": "text.onWarningStrong"
    },
    "onWarningSoft": {
      "default": "text.onWarningSoft",
      "_dark": "text.onWarningSoft"
    },
    "primaryHover": {
      "default": "surfaces.primary.strong.hover",
      "_dark": "surfaces.primary.strong.hover"
    },
    "disabledOnPrimary": {
      "default": "blue.200",
      "_dark": "blue.200"
    }
  }
}