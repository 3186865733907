import React from "react";
import { createContext, useContext } from "react";

export interface DataGridTranslations {
  page: string;
  of: string;
  nextPage: string;
  previousPage: string;
  expandRows: string;
  collapseRows: string;
  deselectAllRows: string;
  selectAllRows: string;
  selectRow: string;
  deselectRow: string;
  sortAscending: string;
  sortDescending: string;
  show: string;
  noResultsTitle: string;
  noResultsDescription: string;
  clearFilters: string;
}

export interface FiltersTranslations {
  filters: string;
  advanced: string;
  addFilter: string;
  clear: string;
  resetToDefaults: string;
  apply: string;
  applyFilters: string;
  view: string;
  isOperator: string;
  isNotOperator: string;
  containsOperator: string;
  containsNotOperator: string;
  lessThanOperator: string;
  moreThanOperator: string;
  beforeOperator: string;
  afterOperator: string;
  selectAll: string;
  clearAll: string;
}

export interface DrawerTranslations {
  toggleExpand: string;
  moreActions: string;
  close: string;
}

export interface AvatarTranslations {
  anonymous: string;
}

export interface BreadcrumbTranslations {
  home: string;
}

export interface SearchInputTranslations {
  placeholder: string;
}

export interface ComboboxTranslations {
  placeholder: string;
  selectAll: string;
  deselectAll: string;
  clear: string;
  apply: string;
}

export interface DatePickerTranslations {
  title: string;
  cancel: string;
  submit: string;
}

export interface CommandBarTranslations {
  clear: string;
  close: string;
}

export interface LabelsTranslations {
  noResults: string;
}

export interface CodeBlockTranslations {
  copyLabelDefault: string;
  confirmation: {
    title: string;
    description: string;
  };
}

export interface BulkActionsTranslations {
  selectedItem: string;
  selectedItems: string;
  selectAll: string;
  close: string;
}

export interface ActionsTranslations {
  emojis: string;
  embeddedFields: string;
}

export interface LayoutTranslations {
  previous: string;
  next: string;
  actions: string;
  close: string;
  toggleMenu: string;
}

export interface TimestampTranslations {
  underOneMinuteLabel: string;
}

export interface CloseButtonTranslations {
  ariaLabel: string;
}

export interface BannerTranslations {
  close: string;
  retry: string;
  learnMore: string;
}

export interface NoteTranslations {
  prefix: string;
}

export interface ManaUITranslations {
  SuiDataGrid: DataGridTranslations;
  Avatar: AvatarTranslations;
  Breadcrumb: BreadcrumbTranslations;
  BulkActions: BulkActionsTranslations;
  Combobox: ComboboxTranslations;
  Drawer: DrawerTranslations;
  DatePicker: DatePickerTranslations;
  SuiCommandBar: CommandBarTranslations;
  Labels: LabelsTranslations;
  Filters: FiltersTranslations;
  CodeBlock: CodeBlockTranslations;
  SearchInput: SearchInputTranslations;
  TextAreaActions: ActionsTranslations;
  Layout: LayoutTranslations;
  Timestamp: TimestampTranslations;
  CloseButton: CloseButtonTranslations;
  Banner: BannerTranslations;
  Note: NoteTranslations;
}

const TranslationContext = createContext<ManaUITranslations | null>(null);

export interface TranslationProviderProps {
  translations: ManaUITranslations;
}

type Paths<T> = T extends object
  ? {
    [K in keyof T]: `${Exclude<K, symbol>}${"" | `.${Paths<T[K]>}`}`;
  }[keyof T]
  : never;

export const TranslationProvider = (
  props: React.PropsWithChildren<TranslationProviderProps>,
) => {
  const { translations, ...rest } = props;
  return (
    <TranslationContext.Provider value={translations} {...rest}>
      {props.children}
    </TranslationContext.Provider>
  );
};

export const useTranslation = <T extends Paths<ManaUITranslations>>(
  path: T,
): T extends keyof ManaUITranslations ? ManaUITranslations[T] : string => {
  const ctx = useContext(TranslationContext);
  if (!ctx) {
    throw new Error("useTranslation hook called outside of ManaUIProvider");
  }
  if (path in ctx) {
    const rootPath: keyof ManaUITranslations =
      path as keyof ManaUITranslations;
    return ctx[rootPath] as T extends keyof ManaUITranslations
      ? ManaUITranslations[T]
      : string;
  }
  return path.split(".").reduce((previous, current) => {
    if (typeof previous === "string") return previous;
    // @ts-expect-error
    return previous[current];
  }, ctx) as unknown as T extends keyof ManaUITranslations
    ? ManaUITranslations[T]
    : string;
};
