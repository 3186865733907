import { defineStyleConfig } from "@chakra-ui/react";
import { baseStyle as iconButtonBaseStyle } from "../../../clayTheme/components/iconButtonCommonStyles";
import {
  sizes,
  sizeOption,
} from "../../../clayTheme/components/dashedButtonStyles";

const AddButton = defineStyleConfig({
  baseStyle: () => ({
    ...iconButtonBaseStyle(),
    backgroundColor: "components.inputs.bgRestingTransparent",
    borderWidth: "xs",
    borderStyle: "dashed",
    borderColor: "borders.neutral.strong.resting",
    color: "icon.neutralStrong",
    _focusVisible: {
      shadow: "focus",
      outline: "none",
    },
    _hover: {
      backgroundColor: "surfaces.alpha.dominant.50",
    },
    _active: {
      backgroundColor: "components.inputs.bgRestingTransparent",
    },
    _disabled: {
      color: "text.disabledOnSoft",
      borderColor: "borders.disabled.soft.resting",
      cursor: "not-allowed",
      _hover: {
        color: "text.disabledOnSoft",
        borderColor: "borders.disabled.soft.resting",
        backgroundColor: "components.inputs.bgRestingTransparent",
      },
    },
  }),
  sizes: sizes,
  defaultProps: {
    size: sizeOption.md,
    colorScheme: "transparent",
  },
});

export default AddButton;
