const commonPasswords = [
  "23WKoa0FP78dk",
  "BhRh0h2Oof6XbqJEH",
  "Boy4u2OwnNYC",
  "CzPS5NYNDWCkSC",
  "diunilaobu8*",
  "Efwe5tgwa5twhgd",
  "Findaupair007",
  "g00dPa$$w0rD",
  "Gankutsuou1989",
  "Hd764nW5d7E1vb1",
  "Hd764nW5d7E1vbv",
  "IdeDeviceP0T",
  "Mailcreated5240",
  "nEMvXyHeqDd5OQxyXYZI",
  "NICK1234-rem936",
  "P030710P$E4O",
  "Paraklast1974",
  "PolniyPizdec0211",
  "PolniyPizdec1102",
  "PolniyPizdec110211",
  "ProductId20F",
  "Rfnthbyf1988",
  "SaUn24865709",
  "seo21SAAfd23",
  "Sojdlg123aljg",
  "Tojiik85521133",
  "xxPa33bq.aDNA",
  "zQjphsyf6ctifgu"];
/**
 * Helper function to validate password. Keep in sync with UserValidationError.java
 */
export function validatePassword({password, username, email, firstName, lastName}) {
  return passwordIsStrong(password)
    && passwordDoesNotContainUserInfo(password, username, email, firstName, lastName)
    && passwordIsUncommon(password);
}

const passwordDoesNotContainUserInfo = (password, username, email, firstName, lastName) => {
  if (username && password.includes(username)) {
    return false;
  }
  if (email && password.includes(email)) {
    return false;
  }
  if (firstName && password.includes(firstName)) {
    return false;
  }
  if (lastName && password.includes(lastName)) {
    return false;
  }
  return true;
}

const passwordIsStrong = password => {
  if (password.length < 12) {
    return false;
  }
  let hasUpperCase = false;
  let hasLowerCase = false;
  let hasDigit = false;
  let hasSpecialChar = false;

  for (let c of password) {
    if (c >= 'A' && c <= 'Z') {
      hasUpperCase = true;
    } else if (c >= 'a' && c <= 'z') {
      hasLowerCase = true;
    } else if (c >= '0' && c <= '9') {
      hasDigit = true;
    } else {
      hasSpecialChar = true;
    }
  }

  // Count the number of fulfilled requirements
  let fulfilledCriteriaCount = 0;
  if (hasUpperCase) fulfilledCriteriaCount++;
  if (hasLowerCase) fulfilledCriteriaCount++;
  if (hasDigit) fulfilledCriteriaCount++;
  if (hasSpecialChar) fulfilledCriteriaCount++;

  // Password must fulfill at least 3 out of 4 criteria
  return fulfilledCriteriaCount >= 3;
}

const passwordIsUncommon = password => {
  return !commonPasswords.includes(password);
}
