import borderWidths from "./borderWidths";
import breakpoints from "./breakpoints";
import { colors, semanticColors } from "./colors";
import radii from "./radii";
import shadows from "./shadows";
import space from "./spacing";
import typography from "./typography";
import blur from "./blur";

export const foundations = {
  breakpoints,
  radii,
  blur,
  colors,
  ...typography,
  shadows,
  space,
  borderWidths,
  semanticTokens: {
    colors: semanticColors,
  },
};
