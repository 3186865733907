import { useState, useMemo } from "react";

export interface PanelState {
  isOpen: boolean;
  [key: string]: unknown;
}

export interface UsePanelsReturn<Name extends string> {
  togglePanel<PanelName extends Name[][number]>(config: PanelName): void;
  openPanel<PanelName extends Name[][number]>(config: PanelName): void;
  closePanel<PanelName extends Name[][number]>(config: PanelName): void;
  isOpen<PanelName extends Name[][number]>(config: PanelName): boolean;
  getProperty<PanelName extends Name[][number]>(
    config: PanelName,
    property: string,
  ): unknown;
  setProperty<PanelName extends Name[][number]>(
    config: PanelName,
    property: string,
    value: unknown,
  ): void;
}

export const usePanels = <Name extends string>(props: {
  [key in Name]: PanelState;
}) => {
  const [state, setState] = useState<Record<Name[][number], PanelState>>(props);

  const helpers = useMemo(() => {
    function createPanelHelpers(_parts: {
      [key in Name]: PanelState;
    }): UsePanelsReturn<Name> {
      function getProperty<PanelName extends Name[][number]>(
        part: PanelName,
        property: string,
      ) {
        return state[part][property];
      }

      function setProperty<PanelName extends Name[][number]>(
        part: PanelName,
        property: string,
        value: unknown,
      ) {
        setState((old) => ({
          ...old,
          [part]: { ...old[part], [property]: value },
        }));
      }

      function openPanel<PanelName extends Name[][number]>(part: PanelName) {
        setProperty(part, "isOpen", true);
      }

      function closePanel<PanelName extends Name[][number]>(part: PanelName) {
        setProperty(part, "isOpen", false);
      }

      function isOpen<PanelName extends Name[][number]>(part: PanelName) {
        return state[part].isOpen;
      }

      function togglePanel<PanelName extends Name[][number]>(part: PanelName) {
        if (isOpen(part)) {
          closePanel(part);
        } else {
          openPanel(part);
        }
      }

      return {
        togglePanel,
        openPanel,
        closePanel,
        isOpen,
        getProperty,
        setProperty,
      };
    }
    return createPanelHelpers(props);
  }, [props, state]);

  return { ...helpers, state };
};
