import React from "react";
import { ChakraProps, InputProps } from "@chakra-ui/react";
import { ActiveFilterValueInput as ChakraActiveFilterValueInput } from "@saas-ui-pro/react";

export default function ActiveFilterValueInput(
  props: InputProps & ChakraProps,
) {
  return (
    <ChakraActiveFilterValueInput
      fontSize="sm"
      p="1"
      placeholder=""
      {...props}
    />
  );
}
