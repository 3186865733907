import React from "react";
import { BarChart as SaasBarChart, type BarChartProps } from "@saas-ui/charts";
import { semanticColors } from "../../clayTheme/foundations/colors";
export type { BarChartProps } from "@saas-ui/charts";

const defaultColors = [
  semanticColors.components.chart.fill.qual.color1.default,
  semanticColors.components.chart.fill.qual.color2.default,
  semanticColors.components.chart.fill.qual.color3.default,
  semanticColors.components.chart.fill.qual.color4.default,
  semanticColors.components.chart.fill.qual.color5.default,
  semanticColors.components.chart.fill.qual.color6.default,
  semanticColors.components.chart.fill.qual.color7.default,
  semanticColors.components.chart.fill.qual.color8.default,
  semanticColors.components.chart.fill.qual.color9.default,
  semanticColors.components.chart.fill.qual.color10.default,
];

export function BarChart(props: BarChartProps) {
  const radius: [number, number, number, number] = props.stack
    ? [0, 0, 0, 0]
    : [4, 4, 0, 0];

  return (
    <SaasBarChart
      {...props}
      radius={radius}
      variant={props.variant || "solid"}
      colors={props.colors || defaultColors}
    />
  );
}

export default BarChart;
