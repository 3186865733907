import React, { useContext, useEffect, useMemo } from "react";

import { Coordinate, MapContext, MapContextType } from "./Map";
import {
  MapPin,
  MapPinOptions,
  PinProperties,
} from "@yext/components-tsx-maps";
import { createPortal } from "react-dom";

export interface MarkerProps {
  id: string;
  coordinate: Coordinate;
  children: React.JSX.Element | [React.JSX.Element];
  hideOffscreen?: boolean;
  anchorX?: number;
  anchorY?: number;
  onClick?: (id: string) => void;
  onHover?: (hovered: boolean, id: string) => void;
  onFocus?: (focused: boolean, id: string) => void;
  statusOptions?: { [key: string]: boolean };
  zIndex?: number;
}

export default function AbstractMarker(
  markerProps: MarkerProps,
): React.JSX.Element {
  const { map, provider } = useContext(MapContext) as MapContextType;

  // TODO(luc): support more PinProperties
  const pinProperties = new PinProperties()
    .setAnchorX(markerProps.anchorX || 0)
    .setAnchorY(markerProps.anchorY || 0);

  const marker: MapPin = useMemo(() => {
    return new MapPinOptions()
      .withPropertiesForStatus(() => pinProperties)
      .withCoordinate(markerProps.coordinate)
      .withHideOffscreen(markerProps.hideOffscreen)
      .withProvider(provider)
      .build();
  }, [
    markerProps.coordinate,
    markerProps.hideOffscreen,
    pinProperties,
    provider,
  ]);

  // Sync z-index
  useEffect(() => {
    if (markerProps.zIndex !== 0 && !markerProps.zIndex) {
      return;
    }
    const wrapper: HTMLDivElement = marker.getProviderPin().getWrapperElement();
    if (wrapper) {
      wrapper.style.zIndex = markerProps.zIndex.toString();
    }
  }, [marker, markerProps.zIndex]);

  // Sync events
  useEffect(() => {
    const _onClick = markerProps.onClick ? markerProps.onClick : () => {};
    const _onFocus = markerProps.onFocus ? markerProps.onFocus : () => {};
    const _onHover = markerProps.onHover ? markerProps.onHover : () => {};

    marker.setMap(map);
    marker.setClickHandler(() => _onClick(markerProps.id));
    marker.setFocusHandler((focused: boolean) =>
      _onFocus(focused, markerProps.id),
    );
    marker.setHoverHandler((hovered: boolean) =>
      _onHover(hovered, markerProps.id),
    );

    return () => {
      marker.setMap(null);
    };
  }, [
    map,
    marker,
    markerProps.id,
    markerProps.onClick,
    markerProps.onFocus,
    markerProps.onHover,
  ]);

  const pinElement = marker.getProviderPin().getPinElement();

  // the below will hide the pin element (button) behind the portalled child elements, while
  // expanding to their size
  Object.assign(pinElement.style, {
    width: "auto",
    height: "auto",
    border: "none",
    "border-radius": "0",
    color: "inherit",
    font: "inherit",
    "font-size": "100%",
    margin: "0",
    padding: "0",
    "vertical-align": "baseline",
    "text-decoration": "none",
    appearance: "none",
    background: "none",
    cursor: "pointer",
    // this disables the pointer events for the wrapper,
    // letting us in turn enable it for the children (if and as desired)
    "pointer-events": "none",
  });

  pinElement.tabIndex = 1;

  // this intentionally does not use the Chakra-ui Portal component, because that one puts a white
  // box around the children
  // return <Portal containerRef={pinRef}>{markerProps.children}</Portal>;
  return createPortal(markerProps.children, pinElement);
}
