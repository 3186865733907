import React from "react";
import {
  Box,
  ChakraProps,
  ThemingProps,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import {
  orientationOptions,
  paletteOptions,
  timestampOptions,
} from "./theme/themeOptions";
import { getLanguages } from "../../utils/i18n";
import { useTranslation } from "../ManaUIProvider";

export interface TimestampProps extends ChakraProps, ThemingProps<"Timestamp"> {
  palette?: keyof typeof paletteOptions;
  date: Date;
  option?: keyof typeof timestampOptions;
  endDate?: Date;
  timeZone?: string;
  hideTimeZone?: boolean;
  orientation?: keyof typeof orientationOptions;
}

const formatAsDate: Intl.DateTimeFormatOptions = {
  month: "short",
  day: "numeric",
  year: "numeric",
};

const formatAsDateTimeWithSeconds: Intl.DateTimeFormatOptions = {
  month: "short",
  day: "numeric",
  year: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  timeZoneName: "short",
};

const formatAsDateTimeWithoutSeconds: Intl.DateTimeFormatOptions = {
  month: "short",
  day: "numeric",
  year: "numeric",
  hour: "numeric",
  minute: "numeric",
  timeZoneName: "short",
};

const formatAsTimeWithSeconds: Intl.DateTimeFormatOptions = {
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  timeZoneName: "short",
};

const formatAsTimeWithoutSeconds: Intl.DateTimeFormatOptions = {
  hour: "numeric",
  minute: "numeric",
  timeZoneName: "short",
};

interface RelativeTimeFormatterPropsType {
  date: Date;
  endDate: Date;
  underOneMinuteLabel: string;
  orientation?: keyof typeof orientationOptions;
  option?: keyof typeof timestampOptions;
}

function timestampDateAndTimeFormatter({
  date,
  option,
  timeZone,
  hideTimeZone,
}: TimestampProps): [string, string] {
  const dateFormat = formatAsDate;
  let timeFormat: Intl.DateTimeFormatOptions = (() => {
    switch (option) {
      case timestampOptions.DATE_TIME:
      case timestampOptions.DATE_TIME_RELATIVITY:
        return formatAsTimeWithoutSeconds;
      case timestampOptions.DATE_TIME_SECONDS:
      case timestampOptions.DATE_TIME_SECONDS_RELATIVITY:
        return formatAsTimeWithSeconds;
      default:
        return formatAsTimeWithoutSeconds;
    }
  })();

  if (timeZone) {
    // Timezone in IANA Format
    // https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
    timeFormat = { ...timeFormat, timeZone: timeZone };
  }

  if (hideTimeZone) {
    timeFormat = { ...timeFormat, timeZoneName: undefined };
  }

  const timestampDate = new Intl.DateTimeFormat(
    getLanguages(),
    dateFormat,
  ).format(date);

  const timestampTime = new Intl.DateTimeFormat(
    getLanguages(),
    timeFormat,
  ).format(date);

  return [timestampDate, timestampTime];
}

function timestampFormatter({
  date,
  option,
  endDate,
  timeZone,
  hideTimeZone,
}: TimestampProps) {
  let timestamp = "";

  let format: Intl.DateTimeFormatOptions = (() => {
    switch (option) {
      case timestampOptions.DATE:
      case timestampOptions.DATE_RANGE:
      case timestampOptions.DATE_RELATIVITY:
        return formatAsDate;
      case timestampOptions.DATE_TIME:
      case timestampOptions.DATE_TIME_RANGE:
      case timestampOptions.DATE_TIME_RELATIVITY:
        return formatAsDateTimeWithoutSeconds;
      case timestampOptions.DATE_TIME_SECONDS:
      case timestampOptions.DATE_TIME_SECONDS_RELATIVITY:
        return formatAsDateTimeWithSeconds;
      default:
        return formatAsDate;
    }
  })();

  if (timeZone) {
    // Timezone in IANA Format
    // https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
    format = { ...format, timeZone: timeZone };
  }

  if (hideTimeZone) {
    format = { ...format, timeZoneName: undefined };
  }

  if (
    option === timestampOptions.DATE_TIME_RANGE ||
    option === timestampOptions.DATE_RANGE
  ) {
    // @ts-expect-error 'formatRange' was originally missing from the
    // 'Intl.DateTimeFormat' interface [1], but was added on Feb 15, 2022 [2].
    //
    // However, the usage of 'formatRange' here is still returning an error of
    // "Property 'formatRange' does not exist on type 'DateTimeFormat'."
    //
    // [1] https://github.com/microsoft/TypeScript/issues/46905
    // [2] https://github.com/microsoft/TypeScript/pull/47740
    timestamp = new Intl.DateTimeFormat(getLanguages(), format).formatRange(
      date,
      endDate,
    );
  } else {
    timestamp = new Intl.DateTimeFormat(getLanguages(), format).format(date);
  }
  return timestamp;
}

function relativeTimeFormatter({
  date,
  endDate,
  underOneMinuteLabel,
  orientation,
  option,
}: RelativeTimeFormatterPropsType) {
  const DAYS_PER_WEEK = 7;
  const DAYS_PER_MONTH = 365 / 12;
  const DAYS_PER_YEAR = 365;

  const MS_PER_SEC = 1000;
  const MS_PER_MIN = MS_PER_SEC * 60; // 60,000 ms
  const MS_PER_HOUR = MS_PER_MIN * 60; // 3,600,000 ms
  const MS_PER_DAY = MS_PER_HOUR * 24; // 86,400,000 ms
  const MS_PER_WEEK = MS_PER_DAY * DAYS_PER_WEEK; // 604,800,000 ms
  const MS_PER_MONTH = MS_PER_DAY * DAYS_PER_MONTH; // 2,628,000,000 ms
  const MS_PER_YEAR = MS_PER_DAY * DAYS_PER_YEAR; // 31,536,000,000 ms

  const diff = date.getTime() - endDate.getTime();
  const abs_diff = Math.abs(diff);
  const rtf = new Intl.RelativeTimeFormat(getLanguages(), { style: "long" });

  /**
   * Design specified the following return matrix of absolute diff values:
   *
   * Under 1 minute: "just now"
   * Under 1 hour: "XX minutes ago" || "in XX minutes"
   * 1 hour to 23 hours: "XX hours ago" || "in XX hours"
   * 24 hours to 6 days: "XX days ago" || "in XX days"
   * 7 days to 29 days: "XX weeks ago" || "in XX weeks"
   * 30 days to 11 months: "XX months ago" || "in XX months"
   * 1 year and beyond: "XX years ago" || "in XX years"
   */

  let relativeTime = "";
  if (abs_diff <= MS_PER_MIN) {
    relativeTime = underOneMinuteLabel;
  } else if (abs_diff < MS_PER_HOUR) {
    relativeTime = rtf.format(Math.round(diff / MS_PER_MIN), "minute");
  } else if (abs_diff < MS_PER_DAY) {
    relativeTime = rtf.format(Math.round(diff / MS_PER_HOUR), "hour");
  } else if (abs_diff < MS_PER_WEEK) {
    relativeTime = rtf.format(Math.round(diff / MS_PER_DAY), "day");
  } else if (abs_diff < MS_PER_MONTH) {
    relativeTime = rtf.format(Math.round(diff / MS_PER_WEEK), "week");
  } else if (abs_diff < MS_PER_YEAR) {
    relativeTime = rtf.format(Math.round(diff / MS_PER_MONTH), "month");
  } else {
    relativeTime = rtf.format(Math.round(diff / MS_PER_YEAR), "year");
  }

  /** For visual differentiation, wrap the relative time in parenthesis if the orientation
   * is vertical and displaying a datetime.
   */
  if (
    orientation === orientationOptions.vertical &&
    (option === timestampOptions.DATE_TIME_RELATIVITY ||
      option === timestampOptions.DATE_TIME_SECONDS_RELATIVITY)
  ) {
    return `(${relativeTime})`;
  }
  return relativeTime;
}

export default function Timestamp(props: TimestampProps) {
  const {
    palette,
    date,
    option,
    endDate = new Date(),
    timeZone,
    hideTimeZone,
    orientation,
    ...rest
  } = props;

  const styles = useMultiStyleConfig("Timestamp", {
    ...props,
    palette,
  });

  const underOneMinuteLabel = useTranslation("Timestamp.underOneMinuteLabel");

  const timestamp = timestampFormatter({
    date,
    option,
    endDate,
    timeZone,
    hideTimeZone,
    orientation,
  });

  let timestampMarkup = <></>;

  if (
    orientation === orientationOptions.vertical &&
    (option === timestampOptions.DATE_TIME ||
      option === timestampOptions.DATE_TIME_RELATIVITY ||
      option === timestampOptions.DATE_TIME_SECONDS ||
      option === timestampOptions.DATE_TIME_SECONDS_RELATIVITY)
  ) {
    const [timestampDate, timestampTime] = timestampDateAndTimeFormatter({
      date,
      option,
      timeZone,
      hideTimeZone,
    });
    timestampMarkup = (
      <>
        <Box>{timestampDate}</Box>
        <Box __css={styles.secondaryTime}>{timestampTime}</Box>
      </>
    );
  } else {
    timestampMarkup = (
      <>
        <Box>{timestamp}</Box>
      </>
    );
  }

  if (
    endDate &&
    (option === timestampOptions.DATE_RELATIVITY ||
      option === timestampOptions.DATE_TIME_RELATIVITY ||
      option === timestampOptions.DATE_TIME_SECONDS_RELATIVITY)
  ) {
    const relativeTime = relativeTimeFormatter({
      date,
      endDate,
      underOneMinuteLabel,
      orientation,
      option,
    });
    timestampMarkup = (
      <>
        {timestampMarkup}
        <Box __css={styles.secondaryTime}>{relativeTime}</Box>
      </>
    );
  }

  return (
    <>
      <Box __css={styles.container} {...rest}>
        {timestampMarkup}
      </Box>
    </>
  );
}
