import { menuAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(menuAnatomy.keys);

const baseStyle = definePartsStyle({
  list: {
    p: 4,
    borderRadius: "lg",
    shadow: "md",
    borderWidth: "xs",
    borderColor: "borders.neutral.hush.resting",
    gap: 2,
    zIndex: "dropdown",
    maxHeight: "20rem",
  },
  item: {
    color: "text.neutralDominant",
    borderRadius: "lg",
    "&:not(:last-child)": {
      mb: 2,
    },
    px: 3,
    py: 1.5,
    _hover: {
      bg: "surfaces.neutral.hush.hover",
    },
    _focus: {
      bg: "surfaces.highlight.secondary",
    },
  },
});

const Menu: ReturnType<typeof defineMultiStyleConfig> = defineMultiStyleConfig({
  baseStyle,
});
export default Menu;
