import { defineStyleConfig } from "@chakra-ui/react";

const FormLabel = defineStyleConfig({
  baseStyle: () => ({
    color: "text.neutralDominant",
    fontWeight: "medium",
    mb: 2,
  }),
});

export default FormLabel;
