export enum variantOptions {
  "primary" = "primary",
  "secondary" = "secondary",
  "ghost" = "ghost",
  "danger" = "danger",
  "success" = "success",
  "neutral.dominant" = "neutral.dominant",
  "neutral.strong" = "neutral.strong",
  "neutral.soft" = "neutral.soft",
  "outline.dominant" = "outline.dominant",
  "outline.soft" = "outline.soft",
  "outline.white" = "outline.white",
  "inverse" = "inverse",
}

export enum sizeOptions {
  xs = "xs",
  md = "md",
  lg = "lg",
  xl = "xl",
  "2xl" = "2xl",
}

export enum shapeOptions {
  round = "round",
  square = "square",
}
