import React, { useState } from "react";
import { Box, useDisclosure, useControllableState, UseDisclosureReturn } from "@chakra-ui/react";
import { Button } from "../Button";
import Combobox from "./Combobox";
import { ComboboxContent } from "./ComboboxContent";
import { ComboboxItems } from "./ComboboxItems";
import { Link } from "../Link";
import {
  ComboboxSelectOption,
  ComboboxProps,
  useComboboxContext,
} from "./ComboboxContext";
import { Divider } from "../Divider";
import { useTranslation } from "../ManaUIProvider";
import { ComboboxInput } from "./ComboboxInput";
import {maxHeight} from "src/components/Combobox/theme/themeOptions";

export type MultiSelectProps = Omit<
  ComboboxProps<ComboboxSelectOption>,
  "multiple" | "children" | "options"
> & {
  options: ComboboxSelectOption[];
  trigger: React.ReactNode;
  onApply?: (selectedItems: ComboboxSelectOption[]) => void;
  showSearchInput?: boolean;
  selectedItems?: ComboboxSelectOption[];
  onSelectedItemsChange?: (selectedItems: ComboboxSelectOption[]) => void;
  useDisclosureFn?: UseDisclosureReturn;
  hideFooter?: boolean;
  maxHeight?: keyof typeof maxHeight;
};

export const MultiSelect: React.FC<MultiSelectProps> = (props) => {
  const { options, showSearchInput, useDisclosureFn, popoverCloseOnBlur, hideFooter, maxHeight} = props;
  const [selectedItems, setSelectedItems] = useControllableState({
    defaultValue: [],
    value: props.selectedItems,
    onChange: props.onSelectedItemsChange,
  });
  const [allSelected, setAllSelected] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosureFn || useDisclosure();

  const handleSetSelectedOptions: React.Dispatch<
    React.SetStateAction<ComboboxSelectOption[]>
  > = (items) => {
    setSelectedItems(items);
    setAllSelected(items.length === options.length);
  };

  return (
    <Combobox
      {...props}
      multiple={true}
      options={options}
      selectedOptions={selectedItems}
      setSelectedOptions={handleSetSelectedOptions}
      overrideSearchInputDetection={showSearchInput}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      popoverCloseOnBlur={popoverCloseOnBlur}
    >
      <MultiSelectContent
        allSelected={allSelected}
        setAllSelected={setAllSelected}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        hideFooter={hideFooter}
        maxHeight={maxHeight}
        {...props}
      />
    </Combobox>
  );
};

type MultiSelectContentProps = Omit<
  MultiSelectProps,
  "options" | "optionsToString"
> & {
  allSelected: boolean;
  setAllSelected: React.Dispatch<React.SetStateAction<boolean>>;
  selectedItems: ComboboxSelectOption[];
  setSelectedItems: React.Dispatch<
    React.SetStateAction<ComboboxSelectOption[]>
  >;
  hideFooter?: boolean;
  maxHeight?: keyof typeof maxHeight;
};

const MultiSelectContent: React.FC<MultiSelectContentProps> = ({
  onApply,
  showSearchInput,
  trigger,
  allSelected,
  setAllSelected,
  selectedItems,
  setSelectedItems,
  onClose,
  hideFooter,
  maxHeight,
}) => {
  const translations = useTranslation("Combobox");
  const options = useComboboxContext().options as ComboboxSelectOption[];
  const memoSetAllSelected = React.useCallback(
    (selected: boolean) => {
      setAllSelected(selected);
    },
    [setAllSelected],
  );

  React.useEffect(() => {
    const allAreSelected =
      options.filter((option) => {
        return selectedItems.find(
          (selected) => selected.value === option.value,
        );
      }).length === options.length;
    memoSetAllSelected(allAreSelected);
  }, [selectedItems, options, memoSetAllSelected]);

  return (
    <>
      {trigger}
      <ComboboxContent maxHeight={maxHeight}>
        <Box fontSize={"sm"} px={2} py={2}>
          <Link
            palette="primary"
            as="button"
            justifyContent={"start"}
            onClick={() => {
              setSelectedItems(allSelected ? [] : options);
              setAllSelected(!allSelected);
            }}
          >
            {allSelected ? translations.deselectAll : translations.selectAll}
          </Link>
        </Box>
        {showSearchInput && (
          <>
            <ComboboxInput size={"xl"} />
            <Divider my={2} palette="neutral.separator" />
          </>
        )}
        <ComboboxItems />
        {!hideFooter && <Box
          px={2}
          mt={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Link
            as={"button"}
            onClick={() => {
              setSelectedItems([]);
              setAllSelected(false);
            }}
          >
            {translations.clear}
          </Link>
          <Button
            size="xl"
            onClick={() => {
              onApply && onApply(selectedItems);
              onClose && onClose();
            }}
          >
            {translations.apply}
          </Button>
        </Box>}
      </ComboboxContent>
    </>
  );
};
