import React from "react";
import { Flex } from "@chakra-ui/react";
import { EmptyState } from "../EmptyState";
import { Button } from "../Button";
import { useTranslation } from "../ManaUIProvider";
import { useAdvancedFiltersContext } from "../Filters/FiltersProvider";

export const DataGridEmptyState = () => {
  const { clear } = useAdvancedFiltersContext();
  const translations = useTranslation("SuiDataGrid");

  return (
    <Flex justifyContent="center" w="full">
      <EmptyState
        icon="search"
        title={translations.noResultsTitle}
        description={translations.noResultsDescription}
      >
        {!!clear && (
          <Button onClick={clear} variant={"primary"} size="xl">
            {translations.clearFilters}
          </Button>
        )}
      </EmptyState>
    </Flex>
  );
};
