import { checkboxAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";
import { sizeOptions } from "./themeOptions";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const sizes = {
  [sizeOptions.sm]: definePartsStyle({
    label: defineStyle({
      fontSize: "md",
      marginLeft: "2.5",
    }),
    control: defineStyle({
      height: "1rem",
      width: "1rem",
    }),
  }),
  [sizeOptions.md]: definePartsStyle({
    label: defineStyle({
      fontSize: "lg",
      marginLeft: "3",
    }),
    control: defineStyle({
      height: "1.25rem",
      width: "1.25rem",
    }),
  }),
  [sizeOptions.lg]: definePartsStyle({
    label: defineStyle({
      fontSize: "xl",
      marginLeft: "3.5",
    }),
    control: defineStyle({
      height: "1.5rem",
      width: "1.5rem",
    }),
  }),
};

const baseStyle = definePartsStyle(() => {
  return {
    label: {
      _disabled: {
        color: "text.disabled",
        opacity: "1",
      },
    },
    container: {
      "& .chakra-checkbox__control": {
        borderColor: "text.neutralDominant",
        borderWidth: "1.5px",
      },
      "& .chakra-checkbox__control[data-indeterminate]": {
        background: "icon.primary",
        borderColor: "icon.primary",
      },
      _checked: {
        "& .chakra-checkbox__control": {
          background: "icon.primary",
          borderColor: "icon.primary",
        },
      },
      _invalid: {
        "& .chakra-checkbox__control": {
          borderColor: "borders.danger.strong.resting",
        },
        "& .chakra-checkbox__control[data-indeterminate]": {
          background: "borders.danger.strong.resting",
          borderColor: "borders.danger.strong.resting",
        },
        _checked: {
          "& .chakra-checkbox__control": {
            background: "borders.danger.strong.resting",
            borderColor: "borders.danger.strong.resting",
          },
        },
      },
      _disabled: {
        "& .chakra-checkbox__control": {
          borderColor: "icon.disabled",
        },
        "& .chakra-checkbox__control[data-indeterminate]": {
          background: "icon.disabled",
          borderColor: "icon.disabled",
        },
        _checked: {
          "& .chakra-checkbox__control": {
            background: "icon.disabled",
            borderColor: "icon.disabled",
            color: "white",
          },
        },
        _invalid: {
          "& .chakra-checkbox__control": {
            background: "surfaces.page.white",
            borderColor: "icon.disabled",
          },
          "& .chakra-checkbox__control[data-indeterminate]": {
            background: "icon.disabled",
            borderColor: "icon.disabled",
          },
          _checked: {
            "& .chakra-checkbox__control": {
              background: "icon.disabled",
              borderColor: "icon.disabled",
            },
          },
        },
      },
      _hover: {
        "& .chakra-checkbox__control": {
          borderColor: "icon.primaryHover",
        },
        "& .chakra-checkbox__control[data-indeterminate]": {
          background: "icon.primaryHover",
          borderColor: "icon.primaryHover",
        },
        _invalid: {
          "& .chakra-checkbox__control": {
            borderColor: "borders.danger.strong.hover",
          },
          "& .chakra-checkbox__control[data-indeterminate]": {
            background: "borders.danger.strong.hover",
            borderColor: "borders.danger.strong.hover",
          },
        },
        _disabled: {
          "& .chakra-checkbox__control": {
            borderColor: "icon.disabled",
          },
          "& .chakra-checkbox__control[data-indeterminate]": {
            background: "icon.disabled",
            borderColor: "icon.disabled",
          },
        },
        _checked: {
          "& .chakra-checkbox__control": {
            background: "icon.primaryHover",
            borderColor: "icon.primaryHover",
          },
          _invalid: {
            "& .chakra-checkbox__control": {
              background: "borders.danger.strong.hover",
              borderColor: "borders.danger.strong.hover",
            },
          },
          _disabled: {
            "& .chakra-checkbox__control": {
              background: "icon.disabled",
              borderColor: "icon.disabled",
            },
          },
        },
      },
    },
    control: {
      // No token for this radius
      borderRadius: "3px",
    },
  };
});

const Checkbox: ReturnType<typeof defineMultiStyleConfig> =
  defineMultiStyleConfig({ baseStyle, sizes });

export default Checkbox;
