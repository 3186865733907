import React, { PropsWithChildren } from "react";
import { SaasProvider } from "@saas-ui/react";
import clayTheme from "../../clayTheme";
import { chakra, useStyleConfig } from "@chakra-ui/react";
import { ThemeProps } from "./theme";

export const ThemeProvider: React.FC<PropsWithChildren<ThemeProps>> = (
  props,
) => {
  return (
    <SaasProvider
      theme={clayTheme}
      resetCSS={false}
      toastOptions={{ defaultOptions: { position: "bottom" } }}
    >
      <ThemeStyler {...props}>{props.children}</ThemeStyler>
    </SaasProvider>
  );
};

const ThemeStyler: React.FC<PropsWithChildren<ThemeProps>> = (props) => {
  const styles = useStyleConfig("ThemeStyles", props);
  return (
    <chakra.div className="mjolnir-scope" __css={styles}>
      {props.children}
    </chakra.div>
  );
};
