export const baseStyle = () => ({
  borderRadius: "full",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export enum sizeOption {
  xs = "xs",
  sm = "sm",
  md = "md",
  lg = "lg",
  xl = "xl",
}

export const sizes = {
  [sizeOption.xs]: {
    height: 6,
    width: 6,
  },
  [sizeOption.sm]: {
    height: 7,
    width: 7,
  },
  [sizeOption.md]: {
    height: 8,
    width: 8,
  },
  [sizeOption.lg]: {
    height: 9,
    width: 9,
  },
  [sizeOption.xl]: {
    height: 10,
    width: 10,
  },
};
