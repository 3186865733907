import { createContext, RefObject } from "react";
import { sizeOptions } from "./theme/themeOptions";
import { defaultProps } from "./theme/Drawer";
import { ResponsiveValue } from "@chakra-ui/styled-system";

type DrawerContextType = {
  initialSize: ResponsiveValue<keyof typeof sizeOptions>;
  drawerSize: ResponsiveValue<keyof typeof sizeOptions>;
  setDrawerSize: (size: ResponsiveValue<keyof typeof sizeOptions>) => void;
  closeButtonRef: RefObject<HTMLButtonElement>;
};

export const DrawerContext = createContext<DrawerContextType>({
  initialSize: defaultProps.size || sizeOptions.md,
  drawerSize: defaultProps.size || sizeOptions.md,
  setDrawerSize: () => {},
  closeButtonRef: { current: null },
});
