import {
  FilterItem as SaasFilterItem,
  Filter,
  FilterType,
  FilterOperatorId
} from "@saas-ui-pro/react";
import { createContext, useContext } from "react";

export interface FilterItem
  extends Omit<SaasFilterItem, "type" | "value" | "items"> {
  key?: string;
  type?: FilterTypes;
  value?: ValueTypes;
  items?: FilterItems;
}

export interface QuickFilterItem
  extends Omit<
    SaasFilterItem,
     "label" | "type" | "items" | "value" | "defaultOperator" | "operators"
  > {
  key?: string;
  type?: FilterTypes;
  items?:
    | FilterItem[]
    | ((query: string) => Promise<FilterItem[]>)
    | ((query: string) => FilterItem[]);
  value?: ValueTypes;
  operators?: OperatorId[];
  defaultOperator?: OperatorId;
  label?: React.ReactNode | string;
  showSearchbar?: boolean;
  selectAll?: boolean;
  applyOnChange?: boolean;
  optionToString?: (option: QuickFilterItem) => string;
  filterFunction?: (
    inputValue: string,
    options: QuickFilterItem[],
  ) => QuickFilterItem[];
}

export type DateRange = {
  startDate: Date;
  endDate: Date;
};

export type ValueTypes =
  | string
  | string[]
  | number
  | number[]
  | boolean
  | Date
  | DateRange;

export type FilterTypes = FilterType | "date-range" | "date-range-with-time";

export type OperatorId = FilterOperatorId | "betweenDates";

// TODO: the saas component supports this being a function as well for fetching
// filters async
export type FilterItems = FilterItem[];

const FiltersContext = createContext<{
  filters: FilterState;
  setBasicFilter: (key: string, filter: Filter[]) => void;
  setAdvancedFilters: (filter: Filter[]) => void;
  setSearchFilters: (filter: Filter[]) => void;
  clear: () => void;
}>({
  filters: { advanced: [], quick: [], search: [] },
  setAdvancedFilters: (_filters) => {},
  setBasicFilter: (_key, _filters) => {},
  setSearchFilters: (_filters) => {},
  clear: () => {},
});

export type FilterState = {
  quick: Filter[];
  advanced: Filter[];
  search: Filter[];
};

export const AdvancedFiltersProvider = FiltersContext.Provider;
export const useAdvancedFiltersContext = () => {
  return useContext(FiltersContext);
};
