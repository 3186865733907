import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { switchAnatomy } from "@chakra-ui/anatomy";
import { cssVar } from "@chakra-ui/theme-tools";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(switchAnatomy.keys);

const $trackHeight = cssVar("switch-track-height");
const $trackWidth = cssVar("switch-track-width");
const $trackPadding = cssVar("switch-track-padding");

const baseStyle = definePartsStyle(() => ({
  label: {
    fontWeight: "normal",
  },
  track: {
    boxSizing: "border-box",
    [$trackPadding.variable]: "0.125rem",
    padding: `var(--switch-track-padding)`,
    backgroundColor: "components.switch.bgResting",
    _checked: {
      backgroundColor: "text.primary",
      _disabled: {
        backgroundColor: "surfaces.page.soft",
      },
    },
    _focusVisible: {
      outline: "none",
      shadow:
        "0rem 0rem 0rem 0.125rem rgba(162, 159, 248, 0.20), inset 0rem 0rem 0rem 0.0625rem var(--chakra-colors-borders-focus-soft)",
    },
    _disabled: {
      opacity: 1,
      backgroundColor: "surfaces.page.soft",
      shadow:
        "inset 0rem 0rem 0rem 0.0625rem var(--chakra-colors-borders-neutral-soft-resting)",
    },
  },
  thumb: {
    backgroundColor: "surfaces.page.white",
    height:
      "calc(var(--switch-track-height) - (var(--switch-track-padding) * 2))",
    width:
      "calc(var(--switch-track-height) - (var(--switch-track-padding) * 2))",
    _disabled: {
      backgroundColor: "components.switch.bgResting",
    },
  },
}));

const sizes = {
  sm: definePartsStyle({
    label: {
      fontSize: "md",
    },
    track: {
      [$trackWidth.variable]: "1.625rem",
      [$trackHeight.variable]: "1rem",
    },
  }),
  md: definePartsStyle({
    label: {
      fontSize: "lg",
    },
    track: {
      [$trackWidth.variable]: "2.125rem",
      [$trackHeight.variable]: "1.25rem",
    },
  }),
  lg: definePartsStyle({
    label: {
      fontSize: "xl",
    },
    track: {
      [$trackWidth.variable]: "3.125rem",
      [$trackHeight.variable]: "1.75rem",
    },
  }),
};

const Switch: ReturnType<typeof defineMultiStyleConfig> =
  defineMultiStyleConfig({
    baseStyle,
    defaultProps: {
      size: "md",
    },
    sizes,
  });

export default Switch;
