import React from "react";
import { forwardRef } from "@chakra-ui/react";
import {
  Beacon as SaasBeacon,
  BeaconProps as SaasBeaconProps,
} from "@saas-ui-pro/react";

interface BeaconProps extends Omit<SaasBeaconProps, "colorScheme"> {
  palette?: SaasBeaconProps["colorScheme"];
}

const Beacon = forwardRef((props: BeaconProps, ref) => {
  const { palette, ...rest } = props;

  return <SaasBeacon ref={ref} colorScheme={palette} {...rest} />;
});

export { Beacon, type BeaconProps };
