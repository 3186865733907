import React, { useContext, useRef, useState } from "react";
import {
  Drawer as ChakraDrawer,
  DrawerBody as ChakraDrawerBody,
  DrawerCloseButton,
  DrawerContent as ChakraDrawerContent,
  DrawerFooter as ChakraDrawerFooter,
  DrawerHeader as ChakraDrawerHeader,
  DrawerOverlay as ChakraDrawerOverlay,
  DrawerProps as ChakraDrawerProps,
  ModalBodyProps,
  ModalContentProps,
  ModalFooterProps,
  ModalHeaderProps,
  ModalOverlayProps,
  Menu,
  MenuButton,
  Box,
  Flex,
  HStack,
  ChakraProps,
  ThemingProps,
  useMultiStyleConfig,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Divider } from "../Divider";
import {IconButton } from "../IconButton";
import {
  FALArrowUpRightAndArrowDownLeftFromCenter,
  FALArrowDownLeftAndArrowUpRightToCenter,
  FALEllipsis,
  IconProps,
} from "../../icons";
import { sizeOptions } from "./theme/themeOptions";
import { DrawerContext } from "./DrawerContext";
import { defaultProps } from "./theme/Drawer";
import { Tooltip } from "../Tooltip";
import { useTranslation } from "../ManaUIProvider";

export interface DrawerProps
  extends ChakraDrawerProps,
    ThemingProps<"Drawer"> {
  initialFocusRef?: React.RefObject<HTMLElement>;
}

interface IconAction {
  label: string
  IconComponent: React.ComponentType<IconProps>
  onClick: () => void;
}

export interface DrawerHeaderProps
  extends ModalHeaderProps,
    ThemingProps<"DrawerHeader"> {
  children: React.ReactNode;
  menuActions?: React.ReactNode;
  iconActions?: [IconAction, IconAction?, IconAction?]
  showDivider?: boolean;
  showSizeToggle?: boolean;
}

export interface DrawerTitleProps extends ChakraProps {
  children: React.ReactNode;
}

export interface DrawerSubtitleProps extends ChakraProps {
  children: React.ReactNode;
}

export interface DrawerMenuProps extends ChakraProps {
  children: React.ReactNode;
}

export function Drawer(props: DrawerProps) {
  const { children, size, onClose, initialFocusRef, ...rest } = props;
  const [drawerSize, setDrawerSize] = useState(size);
  const closeButtonRef = useRef<HTMLButtonElement>(null);

  const value = {
    initialSize: size || defaultProps.size,
    drawerSize: drawerSize || defaultProps.size,
    setDrawerSize,
    closeButtonRef,
  };

  const onCloseWrapper = () => {
    setDrawerSize(value.initialSize);
    onClose();
  };

  const defaultPlacement =
    useBreakpointValue(["bottom", "bottom", "bottom", "right"] as const, {
      ssr: false,
    }) || ("right" as const);

  return (
    <DrawerContext.Provider value={value}>
      <ChakraDrawer
        onClose={onCloseWrapper}
        size={drawerSize}
        placement={defaultPlacement}
        initialFocusRef={initialFocusRef || closeButtonRef}
        {...rest}
      >
        {children}
      </ChakraDrawer>
    </DrawerContext.Provider>
  );
}

function DrawerSizeToggleButton() {
  const { initialSize, drawerSize, setDrawerSize } = useContext(DrawerContext);

  function toggleSize() {
    if (drawerSize !== sizeOptions.full) {
      setDrawerSize(sizeOptions.full);
    } else {
      setDrawerSize(initialSize);
    }
  }

  const translations = useTranslation("Drawer");

  return (
    <IconButton
      variant="ghost"
      aria-label={translations.toggleExpand}
      tooltipLabel={translations.toggleExpand}
      size="lg"
      icon={
        drawerSize === sizeOptions.full ? (
          <FALArrowDownLeftAndArrowUpRightToCenter />
        ) : (
          <FALArrowUpRightAndArrowDownLeftFromCenter />
        )
      }
      onClick={() => toggleSize()}
    />
  );
}

function DrawerMenu(props: DrawerMenuProps) {
  const { children, ...rest } = props;
  const translations = useTranslation("Drawer");

  return (
    <Menu {...rest}>
      <IconButton
        aria-label={translations.moreActions}
        tooltipLabel={translations.moreActions}
        as={MenuButton}
        variant="ghost"
        size="lg"
        icon={<FALEllipsis />}
      />

      {children}
    </Menu>
  );
}

const TooltipCloseButton = React.forwardRef<HTMLButtonElement, { label: string }>(
  ({ label }, ref) => (
    <Tooltip label={label} placement="bottom">
      <DrawerCloseButton ref={ref} />
    </Tooltip>
  )
);

export function DrawerHeader(props: DrawerHeaderProps) {
  const { children, menuActions, iconActions, showDivider, showSizeToggle, ...rest } = props;
  const { initialSize, closeButtonRef } = useContext(DrawerContext);
  const translations = useTranslation("Drawer");

  return (
    <ChakraDrawerHeader {...rest}>
      <HStack alignItems="flex-start">
        <Flex flexDirection="column">{children}</Flex>
        <Flex ms="auto" gap={2}>
          {initialSize !== "full" && showSizeToggle ? (
            <DrawerSizeToggleButton />
          ) : null}
          {!!menuActions && <DrawerMenu>{menuActions}</DrawerMenu>}
          {!!iconActions && iconActions.map((action, i) => action &&
            <Tooltip label={action.label} placement="bottom">
              <IconButton 
                key={i} 
                icon={<action.IconComponent/>} 
                aria-label={action.label} 
                onClick={action.onClick}
                size="lg"
              />
            </Tooltip>
          )}
          <TooltipCloseButton 
            ref={closeButtonRef}
            label={translations.close}
          />
        </Flex>
      </HStack>
      {!!showDivider && <Divider palette="neutral.separator" mt={6} />}
    </ChakraDrawerHeader>
  );
}

export function DrawerTitle(props: DrawerTitleProps) {
  const { children, ...rest } = props;
  const styles = useMultiStyleConfig("Drawer", { ...props });

  return (
    <Box __css={styles.title} {...rest}>
      {children}
    </Box>
  );
}

export function DrawerSubtitle(props: DrawerSubtitleProps) {
  const { children, ...rest } = props;
  const styles = useMultiStyleConfig("Drawer", { ...props });

  return (
    <Box __css={styles.subtitle} {...rest}>
      {children}
    </Box>
  );
}

export function DrawerBody(props: ModalBodyProps) {
  return <ChakraDrawerBody {...props} />;
}

export function DrawerContent(props: ModalContentProps) {
  return <ChakraDrawerContent {...props} />;
}

export function DrawerFooter(props: ModalFooterProps) {
  return <ChakraDrawerFooter {...props} />;
}

export function DrawerOverlay(props: ModalOverlayProps) {
  return <ChakraDrawerOverlay {...props} />;
}
