export enum variantOptions {
  "neutral.strong" = "neutral.strong",
  "neutral.soft" = "neutral.soft",
  "outline.soft" = "outline.soft",
  "outline.dominant" = "outline.dominant",
  "ghost" = "ghost",
}

export enum sizeOptions {
  md = "md",
  lg = "lg",
  xl = "xl",
  "2xl" = "2xl",
}
