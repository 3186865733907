import { extendTheme } from "@chakra-ui/react";
import { theme as saasUITheme } from "@saas-ui-pro/react";
import { foundations } from "./foundations";
import { components } from "./components/";
import { baseStyles } from "../components/ManaUIProvider/theme";

export const clayThemeConfig = {
  ...foundations,
  components,
  styles: {
    global: () => ({
      // deleted global styles that are unscoped, determined the styles that are set by running
      // `console.dir(JSON.stringify(clayTheme.styles.global()));` below extendTheme
      // TODO: throw a build error if any new declarations are added to global styles.
      body: null,
      "*::placeholder": null,
      "*, *::before, &::after": null,
      ":where(*[class*='mjolnir-'], *[class*='mjolnir-']::before, *[class*='mjolnir-']::after)":
        {
          borderColor: "default-border-color",
          wordWrap: "break-word",
        },
      ":where(*[class*='mjolnir-']::placeholder)": {
        color: "muted",
      },
      ".chakra-portal": baseStyles,
    }),
  },
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
};

const clayTheme = extendTheme(
  clayThemeConfig, // theme extension
  saasUITheme, // base theme
);

export default clayTheme;
