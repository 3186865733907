import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";
import { sizeOptions, variantOptions } from "./themeOptions";
import typography from "../../../clayTheme/foundations/typography";

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(["addon", "field", "element", "group"]);

const baseStyle = definePartsStyle(() => {
  return {
    field: {
      borderRadius: "full",
      borderWidth: "xs",
      width: "auto",
      minWidth: "80px",
      "& .chakra-icon": {
        color: "text.neutralDominant",
      },
      "& .chakra-button__icon:last-child .chakra-icon": {
        display: "none",
      },
      "&[disabled] .chakra-button__icon": {
        _after: {
          backgroundColor: "text.disabledOnSoft",
        },
      },
      "& .chakra-button__icon:last-child": {
        transition: "transform 150ms",
        ms: 0,
        _after: {
          content: "''",
          backgroundColor: "text.neutralDominant",
          mask: "url(\"data:image/svg+xml,%3Csvg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='icon-chevron-down-light'%3E%3Cpath id='Primary' d='M8.78203 10.6812C8.62703 10.8362 8.37203 10.8362 8.21703 10.6812L3.41703 5.88125C3.26203 5.72625 3.26203 5.47125 3.41703 5.31625C3.57203 5.16125 3.82703 5.16125 3.98203 5.31625L8.49953 9.83375L13.017 5.31625C13.172 5.16125 13.427 5.16125 13.582 5.31625C13.737 5.47125 13.737 5.72625 13.582 5.88125L8.78203 10.6812Z' fill='currentColor'/%3E%3C/g%3E%3C/svg%3E\") no-repeat 50% 50%",
          display: "block",
          height: 4,
          width: 4,
        },
      },
      _focusVisible: {
        shadow: "focus",
        borderColor: "borders.secondary.soft.resting",
        _hover: {
          borderColor: "borders.secondary.soft.resting",
        },
      },
      _expanded: {
        shadow: "focus",
        borderWidth: "sm",
        borderColor: "borders.primary.strong.active",
        _hover: {
          borderColor: "borders.primary.strong.active",
        },
        "& .chakra-button__icon:last-child": {
          transform: "rotate(-180deg)",
        },
      },
    },
  };
});

const variants = {
  [variantOptions["neutral.strong"]]: definePartsStyle({
    field: {
      borderColor: "surfaces.neutral.strong.resting",
      color: "text.neutralDominant",
      bg: "surfaces.neutral.strong.resting",
      _hover: {
        borderColor: "surfaces.neutral.strong.hover",
        bg: "surfaces.neutral.strong.hover",
      },
      _expanded: {
        bg: "surfaces.neutral.strong.active",
      },
      _disabled: {
        opacity: 1,
        color: "text.disabledOnSoft",
        bg: "surfaces.neutral.soft.disabled",
        borderColor: "borders.disabled.soft",
        _hover: {
          bg: "surfaces.neutral.soft.disabled",
          borderColor: "borders.disabled.soft",
        },
      },
    },
  }),
  [variantOptions["outline.soft"]]: definePartsStyle({
    field: {
      bg: "surfaces.alpha.soft.200",
      _hover: {
        bg: "surfaces.outline.soft.hover",
      },
      _expanded: {
        bg: "components.inputs.bgRestingTransparent",
      },
      _focusVisible: {
        bg: "surfaces.alpha.soft.200",
      },
      _disabled: {
        opacity: 1,
        color: "text.disabledOnSoft",
        bg: "surfaces.neutral.soft.disabled",
        borderColor: "borders.disabled.soft",
        _hover: {
          bg: "surfaces.neutral.soft.disabled",
          borderColor: "borders.disabled.soft",
        },
      },
    },
  }),
  [variantOptions["outline.dominant"]]: definePartsStyle({
    field: {
      borderColor: "borders.neutral.dominant.resting",
      bg: "surfaces.alpha.soft.200",
      _hover: {
        bg: "surfaces.outline.soft.hover",
      },
      _disabled: {
        opacity: 1,
        color: "text.disabledOnSoft",
        bg: "surfaces.neutral.soft.disabled",
        borderColor: "borders.disabled.soft",
        _hover: {
          bg: "surfaces.neutral.soft.disabled",
          borderColor: "borders.disabled.soft",
        },
      },
    },
  }),
  [variantOptions.ghost]: definePartsStyle({
    field: {
      borderColor: "transparent",
      bg: "transparent",
      _hover: {
        bg: "surfaces.alpha.dominant.100",
        borderColor: "transparent",
      },
      _focusVisible: {
        bg: "surfaces.alpha.dominant.50",
        borderColor: "surfaces.alpha.dominant.50",
      },
      _disabled: {
        opacity: 1,
        bg: "transparent",
        borderColor: "transparent",
        color: "text.disabledOnSoft",
        _hover: {
          bg: "transparent",
          borderColor: "transparent",
        },
      },
    },
  }),
  [variantOptions["neutral.soft"]]: definePartsStyle({
    field: {
      borderColor: "surfaces.neutral.soft.resting",
      color: "text.neutralDominant",
      bg: "surfaces.neutral.soft.resting",
      _hover: {
        borderColor: "surfaces.neutral.soft.hover",
        bg: "surfaces.neutral.soft.hover",
      },
      _expanded: {
        bg: "surfaces.neutral.strong.active",
      },
      _disabled: {
        opacity: 1,
        color: "text.disabledOnSoft",
        bg: "transparent",
        borderColor: "transparent",
        _hover: {
          bg: "transparent",
          borderColor: "transparent",
        },
      },
    },
  }),
};

const sizes = {
  [sizeOptions.md]: definePartsStyle({
    field: {
      py: 0.5,
      px: 2.5,
      gap: 1.5,
      ...typography.textStyles.sm,
    },
  }),
  [sizeOptions.lg]: definePartsStyle({
    field: {
      py: 1,
      px: 3,
      gap: 1.5,
      ...typography.textStyles.md,
    },
  }),
  [sizeOptions.xl]: definePartsStyle({
    field: {
      py: 2,
      px: 3.5,
      gap: 2,
      ...typography.textStyles.lg,
    },
  }),
  [sizeOptions["2xl"]]: definePartsStyle({
    field: {
      py: 3,
      px: 4,
      gap: 2.5,
      ...typography.textStyles.lg,
    },
  }),
};

const defaultProps = {
  variant: variantOptions["neutral.strong"],
};

const Select: ReturnType<typeof defineMultiStyleConfig> =
  defineMultiStyleConfig({
    baseStyle,
    variants,
    sizes,
    defaultProps,
  });
export default Select;
