// Import registered React components

import {ForgotPassword} from '/src/com/yext/platform/users/js/common/forgotPassword';
import {ForgotPasswordConfirm} from '/src/com/yext/platform/users/js/common/forgotPasswordConfirm';
import {NewForgotPassword} from '/src/com/yext/platform/users/js/newforgotpassword';
import {NewForgotPasswordConfirm} from '/src/com/yext/platform/users/js/newforgotpasswordconfirm';
import {NewPartitionLogin} from '/src/com/yext/platform/users/js/newpartitionlogin';
import {NoProductAccess} from '/src/com/yext/platform/users/js/noproductaccess';
import {PartitionLogin} from '/src/com/yext/platform/users/js/partitionlogin';
import {ResetPassword} from '/src/com/yext/platform/users/js/resetpassword';
import {ResetPasswordConfirm} from '/src/com/yext/platform/users/js/resetpasswordconfirm';

const initElements = document.getElementsByClassName('js-yext-login-init');

if (initElements.length > 0) {
  const initData = JSON.parse(initElements[0].innerHTML);
  window['cspStyleNonce'] = initData['cspStyleNonce'];
}
