import { drawerAnatomy } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers,
  useDrawerContext,
} from "@chakra-ui/react";
import typography from "../../../clayTheme/foundations/typography";
import { sizeOptions } from "./themeOptions";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers([...drawerAnatomy.keys, "title", "subtitle"]);

const baseStyle = definePartsStyle(() => {
  const { placement } = useDrawerContext();
  const top = placement === "bottom" ? "20%" : undefined;

  return {
    overlay: {
      background: "blackAlpha.300",
    },
    dialog: {
      shadow: "none",
      borderLeftWidth: "xs",
      borderColor: "borders.neutral.hush.resting",
      backgroundColor: "surfaces.neutral.white.resting",
      transitionProperty: "max-width",
      transitionDuration: "normal",
      top,
      paddingBlockEnd: 6,
    },
    closeButton: {
      top: 0,
      right: 0,
      position: "relative",
      transition: "none",
    },
    header: {
      alignItems: "start",
      px: 6,
      py: 6,
      color: "text.neutralDominant",
    },
    title: {
      ...typography.textStyles["2xl-medium"],
    },
    subtitle: {
      ...typography.textStyles["md"],
    },
    body: {
      px: 6,
    },
    footer: {
      paddingBlockStart: 6,
      paddingBlockEnd: 0,
      py: 6,
    },
  };
});

export const defaultProps = {
  size: sizeOptions.md,
} as const;

const Drawer: ReturnType<typeof defineMultiStyleConfig> =
  defineMultiStyleConfig({
    baseStyle,
    defaultProps,
  });

export default Drawer;
