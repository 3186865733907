import { tagAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle } = createMultiStyleConfigHelpers(tagAnatomy.keys);

const baseStyle = definePartsStyle(() => {
  return {
    label: {
      lineHeight: 1.5,
    },
    container: {
      alignItems: "center",
      bg: "surfaces.neutral.strong.resting",
      borderColor: "transparent",
      borderRadius: "full",
      borderStyle: "solid",
      borderWidth: "1px",
      display: "inline-flex",
      "&": {
        color: "text.neutralDominant",
      },
      _hover: {
        bg: "surfaces.neutral.strong.hover",
      },
      _focus: {
        borderColor: "borders.secondary.soft.resting",
        shadow: "focus",
      },
      _focusVisible: {
        borderColor: "borders.secondary.soft.resting",
        shadow: "focus",
      },
      _disabled: {
        bg: "surfaces.neutral.soft.disabled",
        borderColor: "borders.neutral.soft.disabled",
        color: "text.disabledOnSoft",
        cursor: "not-allowed",
        shadow: "none",
        "& .chakra-icon": {
          color: "icon.disabled",
        },
      },
      "& .chakra-icon": {
        color: "text.neutralDominant",
      },
    },
  };
});

export default baseStyle;
