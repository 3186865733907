import React, {
  ChangeEvent,
  RefObject,
  useMemo,
  useRef,
  useState,
  useEffect,
  useId,
} from "react";
import { DatePickerModal } from "../DatePickerModal";
import { getLocalTimeZone, DateValue } from "@saas-ui/date-picker";
import { Button } from "../Button";
import {
  ActiveFiltersList,
  ActiveFiltersListProps,
  Filter,
  FiltersAddButton,
  FilterOperators,
  FiltersProviderProps,
  TableInstance,
} from "@saas-ui-pro/react";
import {
  ComboboxContent,
  ComboboxTrigger,
  ComboboxItems,
  Combobox,
} from "../Combobox";
import { FiltersProvider as SaasFiltersProvider } from "@saas-ui-pro/react";
import { FALBarsFilter, FALChevronUp, FALColumns3, FALPlus } from "../../icons";
import {
  Box,
  Flex,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  useDisclosure,
  useOutsideClick,
  Wrap,
} from "@chakra-ui/react";
import { Link } from "../Link";
import { SearchInput } from "../SearchInput";
import {
  FilterItem,
  FilterItems,
  FilterState,
  AdvancedFiltersProvider,
  QuickFilterItem,
  ValueTypes,
  OperatorId,
  FilterTypes,
} from "./FiltersProvider";
import { SelectFilter } from "./SelectFilter";
import { QuickFilter } from "./QuickFilter";
import { useTranslation } from "../ManaUIProvider";
import { useModals } from "@saas-ui/react";
import { defaultOperators as operators } from "./Operators";
import isEqual from "lodash/isEqual";

type Column = {
  id: string;
  header: string;
};

interface FiltersProps extends React.PropsWithChildren {
  onChange?: (filters: FilterState) => void;
  onColumnsChange?: (columns: string[]) => void;
  defaultFilters?: Partial<FilterState>;
  quickFilters?: QuickFilterItem[];
  advancedFilters?: FilterItems;
  searchFilters?: FilterItems;
  defaultColumns?: string[];
  allColumns?: Column[];
  activeFilters?: Partial<FilterState>;
  advancedFilterProps?: Omit<FiltersProviderProps, "children">;
  filtersListProps?: ActiveFiltersListProps;
  dataGridRef?: RefObject<TableInstance<any>>;
  searchInputColumns?: string[];
  cta?: React.ReactNode;
  inputValue?: string;
  onInputChange?: (value: string) => void;
  allowClearFilters?: boolean;
  advancedFiltersTriggerText?: string;
}

const operatorIdToTranslationKey = {
  is: "isOperator",
  isNot: "isNotOperator",
  contains: "containsOperator",
  containsNot: "containsNotOperator",
  lessThan: "lessThanOperator",
  moreThan: "moreThanOperator",
  before: "beforeOperator",
  after: "afterOperator",
} as const;

export const Filters = (props: FiltersProps) => {
  const {
    allColumns = [],
    quickFilters = [],
    advancedFilters = [],
    advancedFilterProps,
    filtersListProps,
    onColumnsChange,
    searchInputColumns,
    allowClearFilters = true,
    advancedFiltersTriggerText,
  } = props;

  const translations = useTranslation("Filters");
  const defaultOperators = useMemo<FilterOperators<OperatorId, FilterTypes>>(
    () =>
      operators.map((operator) => ({
        ...operator,
        id: operator.id as OperatorId,
        types: operator.types as FilterTypes[],
        label:
          operator.id in operatorIdToTranslationKey
            ? translations[
                operatorIdToTranslationKey[
                  operator.id as keyof typeof operatorIdToTranslationKey
                ]
              ]
            : operator.label,
      })),
    [translations],
  );

  const [internalFilters, setInternalFilters] = useState<FilterState>(
    props.defaultFilters
      ? {
          advanced: props.defaultFilters.advanced || [],
          quick: props.defaultFilters.quick || [],
          search: props.defaultFilters.search || [],
        }
      : { advanced: [], quick: [], search: [] },
  );

  const filters = useMemo(
    () =>
      props.activeFilters !== null && props.activeFilters !== undefined
        ? {
            advanced: props.activeFilters.advanced || [],
            quick: props.activeFilters.quick || [],
            search: props.activeFilters.search || [],
          }
        : internalFilters,
    [internalFilters, props.activeFilters],
  );
  const setFilters = props.onChange || setInternalFilters;

  const [quickFilterSelectionStates, setQuickFilterSelectionStates] = useState<Record<string, QuickFilterItem[]>>({});

  const setQuickFilterFnSelectionStates = async (
    filters: FiltersProps["activeFilters"],
    quickFilters: FiltersProps["quickFilters"],
  ) => {
    if (quickFilters?.length === 0) {
      return;
    }

    const selectionStates = await (quickFilters || []).reduce<Promise<Record<string, QuickFilterItem[]>>>(
      async (aggPromise, quickFilter) => {
        const agg = await aggPromise;
        const items = quickFilter.items;

        if (!items) return agg;

        const filterItems = (typeof items === "function" ? await items("") : items) || [];
        const filteredValues =
          (filters?.quick || [])
            .filter((filter) => filter.key === quickFilter.key)
            ?.flatMap((filter) => filter.value as ValueTypes | undefined) || [];
        const activeOptions = filterItems.filter(
          (item) => !Array.isArray(item.value) && filteredValues.includes(item.value),
        );

        agg[quickFilter.key || quickFilter.id] = activeOptions;
        return agg;
      },
      Promise.resolve({}),
    );

    setQuickFilterSelectionStates(selectionStates);
  };

  useEffect(() => {
    setQuickFilterFnSelectionStates(filters, quickFilters);
  }, [filters, quickFilters]);

  const [uncontrolledInputValue, setInputValue] = useState("");

  const inputValue =
    props.inputValue !== undefined ? props.inputValue : uncontrolledInputValue;
  const onInputChange =
    props.onInputChange !== undefined
      ? props.onInputChange
      : (value: string) => {
          setInputValue(value);
          if (!value) {
            setSearchFilters([]);
          } else {
            setSearchFilters(
              (searchInputColumns || []).map((col) => ({
                id: col,
                value: value,
                operator: "contains",
              })),
            );
          }
        };

  const modals = useModals();
  const onBeforeEnableFilter = React.useCallback(
    (activeFilter: Filter, filter?: FilterItem): Promise<Filter> => {
      return new Promise((resolve, reject) => {
        const { key, id, value } = activeFilter;
        const type = filter?.type;
        const label = filter?.label;

        if (type === "date" && value === "custom") {
          return modals.open({
            title: label,
            date: new Date(),
            onSubmit: (date: DateValue) => {
              resolve({
                key,
                id,
                value: date.toDate(getLocalTimeZone()),
                operator: activeFilter.operator,
              });
            },
            onClose: () => reject(),
            component: DatePickerModal,
          });
        }

        resolve(activeFilter);
      });
    },
    [modals],
  );

  const onChange =
    props.onChange ||
    ((filters: FilterState) => {
      if (!props.dataGridRef) return;

      const colFilters = Object.values(filters)
        .flat()
        .map((filter) => ({
          id: filter.id,
          key: filter.key || filter.id,
          value: {
            value: filter.value,
            operator: filter.operator || "is",
          },
        }));
      props.dataGridRef.current?.setColumnFilters(colFilters);
    });

  useEffect(() => {
    if (props.defaultFilters && !props.activeFilters) {
      onChange({
        quick: props.defaultFilters.quick || [],
        advanced: props.defaultFilters.advanced || [],
        search: props.defaultFilters.search || [],
      });
    }
  }, []);

  const setBasicFilter = (key: string, newFilters: Filter[]) => {
    const updated = {
      ...filters,
      quick: [...filters.quick.filter((f) => f.key !== key), ...newFilters],
    };
    setFilters(updated);
    onChange(updated);
  };

  const setSearchFilters = (newFilters: Filter[]) => {
    const updated = {
      ...filters,
      search: newFilters,
    };
    setFilters(updated);
    onChange(updated);
  };

  const setAdvancedFilters = (newFilters: Filter[]) => {
    const updated = {
      ...filters,
      advanced: newFilters,
    };
    setFilters(updated);
    onChange(updated);
  };

  const columnOptions = useMemo(
    () =>
      allColumns.map((colName) => ({
        value: colName.id,
        label: colName.header,
      })),
    [],
  );

  const [visibleColumns, setVisibleColumns] = React.useState(
    columnOptions.filter((option) =>
      props.defaultColumns
        ? props.defaultColumns.includes(option.value!)
        : true,
    ),
  );
  const [stagedFilters, setStagedFilters] = useState<Filter[]>([]);
  const [filtersHaveChanged, setFiltersHaveChanged] = useState(false);
  useEffect(() => {
    setStagedFilters(props.activeFilters?.advanced || []);
  }, [props.activeFilters]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const wrappedOnClose = () => {
    setStagedFilters(filters.advanced);
    setFiltersHaveChanged(false);
    // On close, find all popover refs and close them. There may be multiple given advanced filters
    // may have nested popovers.
    if (popoverRef.current) {
      const menuButtons = popoverRef.current.querySelectorAll('[aria-haspopup="menu"]');
      menuButtons.forEach(button => {
        if (button.getAttribute('aria-expanded') === 'true') {
          (button as HTMLButtonElement).click();
        }
      });
    }
    onClose();
  };

  const popoverRef = useRef<HTMLElement>(null);

  useOutsideClick({
    ref: popoverRef,
    handler: (e) => {
      if (
        !(e.target as HTMLElement)?.closest("[role=menu]") &&
        (e.target as HTMLElement)?.getAttribute("aria-controls") !==
          popoverRef.current?.id &&
        !(e.target as HTMLElement)?.closest("[role=dialog]")
      ) {
        wrappedOnClose();
      }
    },
  });

  const activeFiltersExist = !!stagedFilters.length;
  const clear = () => {
    setFilters({ advanced: [], quick: [], search: [] });
    setStagedFilters([]);
    onChange({ advanced: [], quick: [], search: [] });
    setInputValue("");
    setQuickFilterSelectionStates({});
  };

  const resetToDefaults = () => {
    const defaults = {
      advanced: props.defaultFilters?.advanced ?? [],
      quick: props.defaultFilters?.quick ?? [],
      search: props.defaultFilters?.search ?? [],
    };
    setFilters(defaults);
    setStagedFilters([]);
    onChange(defaults);
    setInputValue("");
    setQuickFilterFnSelectionStates(defaults, quickFilters);
  };

  const filtersAreEqual = (
    filter1: Partial<FilterState>,
    filter2: Partial<FilterState>,
  ) => {
    const baseFilter: FilterState = { advanced: [], quick: [], search: [] };
    const completeFilter1 = { ...baseFilter, ...filter1 };
    const completeFilter2 = { ...baseFilter, ...filter2 };
    return isEqual(completeFilter1, completeFilter2);
  };

  const onResetSearch = () => {
    onInputChange("");
    setSearchFilters([]);
  };

  const triggerId = useId();

  const quickFiltersRef = useRef<QuickFilterItem[]>([]);
  const [quickFilterItemsMap, setQuickFilterItemsMap] = useState<Record<string, FilterItem[]>> ({});

  useEffect(() => {
    if (isEqual(quickFiltersRef.current, quickFilters)) {
      return;
    }
    quickFiltersRef.current = quickFilters;

    const fetchFilterItems = async () => {
      const newFilterItemsMap: Record<string, FilterItem[]> = {};

      for (const filter of quickFilters) {
        if (typeof filter.items === "function") {
          const fnFilterItems = await filter.items("");
          newFilterItemsMap[filter.key || ""] = fnFilterItems;
        } else {
          newFilterItemsMap[filter.key || ""] = filter.items || [];
        }
      }

      setQuickFilterItemsMap(newFilterItemsMap);
    };

    fetchFilterItems();
  }, [quickFilters]);

  return (
    <AdvancedFiltersProvider
      value={{
        filters,
        setBasicFilter,
        setAdvancedFilters,
        setSearchFilters,
        clear,
      }}
    >
      <Flex alignItems={"center"} gap={3}>
        {props.cta && (
          <Box
            flexShrink="0"
            position={["fixed", "fixed", "fixed", "static"]}
            bottom={4}
            right={4}
          >
            {props.cta}
          </Box>
        )}
        {searchInputColumns && (
          <Box maxW="300px" flexShrink={0}>
            <SearchInput
              value={inputValue}
              onReset={onResetSearch}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                onInputChange(e.target.value);
              }}
            />
          </Box>
        )}

        <Box w="full" display="flex" gap={3} alignItems={"center"}>
          {quickFilters.map((filter) => {
            const filteredValues =
              filters.quick
                .filter((f) => f.key === filter.key)
                ?.flatMap((filter) => filter.value as ValueTypes | undefined) ||
              [];

            const filterItems = quickFilterItemsMap[filter.key || ""];
            const activeOptions = (filterItems || []).filter(
              (item) =>
                !Array.isArray(item.value) &&
                filteredValues.includes(item.value),
            );

            const handleChange = (options: QuickFilterItem[]) => {
              setQuickFilterSelectionStates((old) => {
                return {
                  ...old,
                  [filter.key || filter.id]: options,
                };
              });
            };

            const handleApply = (options: QuickFilterItem[]) => {
              // @ts-expect-error https://github.com/saas-js/saas-ui-pro/issues/99
              const newFilters: Filter[] = options.length
                ? [
                    {
                      id: filter.id,
                      key: filter.key,
                      operator: filter.defaultOperator || "is",
                      value: options.map((option) =>
                        filter.type === "number"
                          ? Number(option.value)
                          : option.value || "",
                      ),
                    },
                  ]
                : [];

              setQuickFilterSelectionStates((old) => ({
                ...old,
                [filter.key || filter.id]: options,
              }));
              setBasicFilter(filter.key || filter.id, newFilters);
            };

            return filter.items ? (
              <SelectFilter
                optionToString={filter.optionToString}
                selectedOptions={
                  quickFilterSelectionStates[filter.key || filter.id] || []
                }
                onClose={() => {
                  setQuickFilterSelectionStates((old) => {
                    return {
                      ...old,
                      [filter.key || filter.id]: activeOptions,
                    };
                  });
                }}
                onChange={filter.applyOnChange ? handleApply : handleChange}
                onApply={handleApply}
                onClear={() => {
                  setQuickFilterSelectionStates((old) => {
                    return {
                      ...old,
                      [filter.key || filter.id]: [],
                    };
                  });
                }}
                key={filter.key}
                filter={filter}
                filterFunction={filter.filterFunction}
              />
            ) : (
              <QuickFilter
                key={filter.key}
                id={filter.key || ""}
                filter={filter}
              />
            );
          })}

          {!!advancedFilters.length && (
            <Popover
              variant="menu"
              closeOnBlur={false}
              isOpen={isOpen}
              onClose={wrappedOnClose}
            >
              <PopoverTrigger>
                <Button
                  variant="ghost"
                  onClick={() => (isOpen ? wrappedOnClose() : onOpen())}
                  size="lg"
                >
                  <FALBarsFilter />
                  {advancedFiltersTriggerText ?? translations.advanced}{" "}
                  {!!filters.advanced?.length && (
                    <Box
                      sx={{
                        borderRadius: "sm",
                        px: 1,
                        py: 0.5,
                        textStyle: "xs-medium",
                        bg: "surfaces.secondary.strong.resting",
                      }}
                    >
                      {filters.advanced.length}
                    </Box>
                  )}
                  <Box
                    transition={"transform 0.2s ease-in"}
                    transform={isOpen ? "rotate(0deg)" : "rotate(180deg)"}
                  >
                    <FALChevronUp />
                  </Box>
                </Button>
              </PopoverTrigger>
              <PopoverContent ref={popoverRef} w="566px">
                <PopoverHeader>
                  {translations.filters}
                  <PopoverCloseButton
                    onClick={() => wrappedOnClose()}
                    size="lg"
                  />
                </PopoverHeader>
                <PopoverBody maxH="lg" overflowY="auto">
                  <SaasFiltersProvider<OperatorId, FilterTypes>
                    onChange={(f) => {
                      setStagedFilters(f);
                      setFiltersHaveChanged(true);
                    }}
                    operators={defaultOperators}
                    // @ts-expect-error FilterItem types don't support generic options
                    filters={props.advancedFilters}
                    activeFilters={stagedFilters}
                    // @ts-expect-error onBeforeEnableFilter only accepts Saas FilterItem types
                    onBeforeEnableFilter={onBeforeEnableFilter}
                    {...advancedFilterProps}
                  >
                    <Wrap
                      sx={{
                        "& > * ": { display: "flex", alignItems: "center" },
                      }}
                    >
                      <ActiveFiltersList textStyle="sm" {...filtersListProps} />
                      <FiltersAddButton
                        listProps={{
                          zIndex: "popover",
                        }}
                        buttonProps={{
                          variant: "secondary",
                          sx: {
                            borderRadius: "full",
                            h: 8,
                            w: activeFiltersExist ? 8 : undefined,
                            minWidth: "none",
                            "& > span:last-child": {
                              display: activeFiltersExist ? "none" : undefined,
                            },
                            "& > span:first-child": { margin: 0 },
                          },
                        }}
                        icon={<FALPlus />}
                        label={translations.addFilter}
                      />
                    </Wrap>
                  </SaasFiltersProvider>
                </PopoverBody>
                <PopoverFooter>
                  <Flex justifyContent={"space-between"}>
                    <Link
                      as={"button"}
                      disabled={!stagedFilters.length}
                      onClick={() => {
                        setFiltersHaveChanged(true);
                        setStagedFilters([]);
                      }}
                    >
                      {translations.clear}
                    </Link>
                    <Flex gap="3">
                      <Button
                        isDisabled={!filtersHaveChanged}
                        onClick={() => {
                          setAdvancedFilters(stagedFilters);
                          setFiltersHaveChanged(false);
                          onClose();
                        }}
                        size="lg"
                        variant="primary"
                      >
                        {translations.applyFilters}
                      </Button>
                    </Flex>
                  </Flex>
                </PopoverFooter>
              </PopoverContent>
            </Popover>
          )}
          {props.defaultFilters &&
            !filtersAreEqual(filters, props.defaultFilters) && (
              <Box as="span" color="text.link">
                <Link
                  as="button"
                  onClick={resetToDefaults}
                  palette="primary"
                  whiteSpace="nowrap"
                >
                  {translations.resetToDefaults}
                </Link>
              </Box>
            )}
          {allowClearFilters && !!Object.values(filters).flat().length && (
            <Box as="span" color="text.link">
              <Link
                as="button"
                onClick={clear}
                palette="primary"
                whiteSpace="nowrap"
              >
                {translations.clear}
              </Link>
            </Box>
          )}
        </Box>

        {props.allColumns && (
          <Combobox
            multiple={true}
            selectedOptions={visibleColumns}
            setSelectedOptions={(options) => {
              setVisibleColumns(options);
              onColumnsChange?.(options.map((opt) => opt.value as string));
            }}
            options={columnOptions}
            optionToString={(opt) => (opt?.label || "").toString()}
          >
            <ComboboxTrigger size="lg" labelledBy={triggerId}>
              <Flex alignItems="center">
                <FALColumns3 mr={2} />
                <Box id={triggerId}>{translations.view}</Box>
              </Flex>
            </ComboboxTrigger>
            <ComboboxContent>
              <ComboboxItems />
            </ComboboxContent>
          </Combobox>
        )}
      </Flex>
      {props.children}
    </AdvancedFiltersProvider>
  );
};
