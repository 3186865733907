import { accordionAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import typography from "../../../clayTheme/foundations/typography";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(accordionAnatomy.keys);

const baseStyle = definePartsStyle(() => ({
  root: {
    color: "text.neutralDominant",
  },
  icon: {
    pointerEvents: "none",
    borderRadius: "full",
    p: 2,
    h: 6,
    w: 6,
  },
}));

const variants = {
  basic: definePartsStyle({
    panel: {
      ...typography.textStyles["md"],
      transitionDuration: "var(--chakra-transition-duration-normal)",
      transitionProperty: "var(--chakra-transition-property-common)",
      pt: 0,
    },
    button: {
      px: 1.5,
      py: 5,
      display: "flex",
      ...typography.textStyles["lg-semibold"],
      justifyContent: "space-between",
      _hover: {
        background: "surfaces.alpha.dominant.50",
        "&~.chakra-collapse > .chakra-accordion__panel": {
          background: "surfaces.alpha.dominant.50",
        },
        "& .chakra-accordion__icon": {
          background: "surfaces.alpha.dominant.50",
        },
        _disabled: {
          background: "unset",
          "&~.chakra-collapse > .chakra-accordion__panel": {
            background: "unset",
          },
        },
      },
      _focusVisible: {
        shadow: "focus",
        "& .chakra-accordion__icon": {
          shadow: "focus",
        },
      },
      _expanded: {
        "& .chakra-accordion__icon": {
          transform: "rotate(-90deg)",
        },
      },
    },
    icon: {
      fontSize: "lg",
      transform: "rotate(90deg)",
    },
  }),
  step: definePartsStyle({
    root: {
      display: "flex",
      flexDirection: "column",
      color: "text.neutralDominant",
    },
    button: {
      borderRadius: "full",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      p: 0,
      w: 6,
      h: 6,
      ...typography.textStyles["lg-semibold"],
      _focusVisible: {
        outline: "none",
        shadow: "none",
        "& .chakra-accordion__icon": {
          shadow: "focus",
        },
      },
      _expanded: {
        borderBottomColor: "transparent",
      },
      _disabled: {
        opacity: 1,
        color: "text.disabled",
        _hover: {
          backgroundColor: "unset",
        },
      },
    },
    container: {
      borderTop: "none",
      borderBottomWidth: "sm",
      borderBottomColor: "pairs.gray.100",
      "& > .is-expanded": {
        backgroundColor: "surfaces.highlight.primary",
        borderTopWidth: "sm",
        borderTopColor: "borders.primary.strong.resting",
      },
    },
    panel: {
      borderBottomWidth: "sm",
      borderBottomColor: "borders.primary.strong.resting",
      ...typography.textStyles.md,
      px: 10,
      pt: 3.5,
      pb: 6,
    },
  }),
};

const Accordion: ReturnType<typeof defineMultiStyleConfig> =
  defineMultiStyleConfig({
    baseStyle,
    variants,
    defaultProps: {
      variant: "basic",
    },
  });

export default Accordion;
