import { defineStyleConfig } from "@chakra-ui/react";
import { paletteOptions, variantOptions } from "./themeOptions";
import { toTitleCase } from "../../../utils/string";

function variantStyles(palette: string, variant: string) {
  const normalizedPalette = palette || paletteOptions.primary;
  const normalizedVariant =
    palette === paletteOptions.success && variant === variantOptions.strong
      ? "dominant"
      : variant;

  if (variant === "soft") {
    return {
      backgroundColor: `components.banner.bg${toTitleCase(palette)}Soft`,
      color: `components.banner.text${toTitleCase(palette)}`,
    };
  }

  return {
    backgroundColor: `surfaces.${normalizedPalette}.${normalizedVariant}.resting`,
    color: `text.on${toTitleCase(normalizedPalette)}${toTitleCase(normalizedVariant)}`,
  };
}

const Banner = defineStyleConfig({
  baseStyle: ({ isRounded }) => {
    return {
      display: "flex",
      alignItems: "start",
      fontSize: "sm",
      flexDirection: "row",
      padding: 4,
      borderRadius: isRounded ? "lg" : undefined,
    };
  },
  defaultProps: {
    variant: variantOptions.soft,
  },
  variants: {
    [variantOptions.soft]: ({ palette }) => ({
      ...variantStyles(palette, "soft"),
    }),
    [variantOptions.strong]: ({ palette }) => ({
      ...variantStyles(palette, "strong"),
    }),
  },
});

export default Banner;
