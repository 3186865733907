import ThemeStyles from "../../components/ManaUIProvider/theme";
import Accordion from "../../components/Accordion/theme";
import AddButton from "../../components/AddButton/theme";
import AlertDialog from "../../components/AlertDialog/theme";
import Avatar from "../../components/Avatar/theme";
import Badge from "../../components/Badge/theme";
import Banner from "../../components/Banner/theme";
import Button from "../../components/Button/theme";
import Checkbox from "../../components/Checkbox/theme";
import ButtonCheckbox from "../../components/ButtonCheckbox/theme";
import CloseButton from "../../components/CloseButton/theme";
import CompleteButton from "../../components/CompleteButton/theme";
import Divider from "../../components/Divider/theme";
import { Drawer, DrawerCloseButton } from "../../components/Drawer/theme";
import EntityLabel from "../../components/EntityLabel/theme";
import Form from "../../components/Form/theme";
import FormLabel from "../../components/FormLabel/theme";
import FormError from "../../components/FormError/theme";
import IconButton from "../../components/IconButton/theme";
import Kbd from "../../components/Kbd/theme";
import Link from "../../components/Link/theme";
import Modal from "../../components/Modal/theme";
import Radio from "../../components/Radio/theme";
import Spinner from "../../components/Spinner/theme";
import StarRating from "../../components/StarRating/theme";
import SuiStructuredList from "../../components/StructuredList/theme";
import Switch from "../../components/Switch/theme";
import Tabs from "../../components/Tabs/theme";
import Tag from "../../components/Tag/theme";
import Toast from "../../components/Toast/theme";
import Tooltip from "../../components/Tooltip/theme";
import { defineStyleConfig } from "@chakra-ui/styled-system";
import { ComponentStyleConfig } from "@chakra-ui/react";
import Card from "../../components/Card/theme";
import Labels from "../../components/Labels/theme";
import Input from "../../components/Input/theme";
import Popover from "../../components/Popover/theme";
import NumberInput from "../../components/NumberInput/theme";
import IconCheckbox from "../../components/IconCheckbox/theme";
import EmptyState from "../../components/EmptyState/theme";
import SSOButton from "../../components/SSOButton/theme";
import Breadcrumb from "../../components/Breadcrumb/theme";
import AssetBadge from "../../components/AssetBadge/theme";
import SuiSearchInput from "../../components/SearchInput/theme";
import SearchInput from "../../components/SearchInput/theme";
import Progress from "../../components/Progress/theme";
import Textarea from "../../components/Textarea/theme";
import SuiNProgress from "../../components/NProgress/theme";
import SuiBanner from "../../components/BulkActions/theme";
import Stepper from "../../components/Steps/theme";
import SuiCommandBar from "../../components/CommandBar/theme";
import Code from "../../components/Code/theme";
import Menu from "../../components/Menu/theme";
import SuiSelect from "../../components/Select/theme";
import SuiDataGrid from "../../components/DataGrid/theme/datagrid";
import SuiDataGridPagination from "../../components/DataGrid/theme/pagination";
import Filters from "../../components/Filters/theme";
import SuiActiveFiltersList from "../../components/Filters/theme/ActiveFiltersList";
import SuiActiveFilter from "../../components/Filters/theme/ActiveFilter";
import SuiDatePicker from "../../components/DatePicker/theme";
import SuiInputSegment from "../../components/DatePicker/theme/SegmentedInput";
import FillLayout from "../../components/Layouts/theme/Fill";
import TwoPanelLayout from "../../components/Layouts/theme/TwoPanel";
import RightPanel from "../../components/Layouts/theme/RightPanel";
import InboxRightPanel from "../../components/Layouts/theme/InboxRightPanel";
import InboxLayout from "../../components/Layouts/theme/Inbox";
import SuiNavbar from "../../components/Layouts/theme/Navbar";
import KPICards from "../../components/KPICards/theme";
import Timestamp from "../../components/Timestamp/theme";
import BarChart from "../../components/BarChart/theme";
import Map from "../../components/Map/theme";
import Note from "../../components/Note/theme";

export const components: Record<
  string,
  ReturnType<typeof defineStyleConfig> | ComponentStyleConfig | object
> = {
  Accordion,
  AddButton,
  AssetBadge,
  AlertDialog,
  Avatar,
  Badge,
  Banner,
  Breadcrumb,
  Button,
  Card,
  ButtonCheckbox,
  Checkbox,
  CloseButton,
  Code,
  CompleteButton,
  Divider,
  Drawer,
  DrawerCloseButton,
  EmptyState,
  EntityLabel,
  FillLayout,
  Form,
  FormError,
  FormLabel,
  IconButton,
  IconCheckbox,
  Input,
  Kbd,
  Labels,
  Link,
  Menu,
  Modal,
  NumberInput,
  Popover,
  Progress,
  Radio,
  RightPanel,
  InboxRightPanel,
  SearchInput,
  Spinner,
  SSOButton,
  StarRating,
  Stepper,
  SuiActiveFilter,
  SuiActiveFiltersList,
  SuiBanner,
  SuiCommandBar,
  SuiDataGrid,
  SuiDataGridPagination,
  SuiDatePicker,
  SuiInputSegment,
  SuiNavbar,
  SuiNProgress,
  SuiSearchInput,
  SuiSelect,
  SuiStructuredList,
  Switch,
  Tabs,
  Tag,
  Textarea,
  ThemeStyles,
  Toast,
  Tooltip,
  Filters,
  TwoPanelLayout,
  InboxLayout,
  KPICards,
  Timestamp,
  BarChart,
  Map,
  Note,
};
