import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import {
  baseStyle as sharedBaseStyle,
  placeholderStyle,
} from "../../Input/theme/commonStateStyles.ts";
import { variantOptions } from "../theme/themeOptions.ts";

const helpers = createMultiStyleConfigHelpers(["textarea", "container"]);

const resetBorderOutlineBoxShadow = {
  border: "none",
  borderWidth: 0,
  outline: 0,
  boxShadow: "none !important",
};

const Textarea = helpers.defineMultiStyleConfig({
  baseStyle: {
    backgroundColor: "transparent !important",
    backgroundClip: "content-box",
    borderRadius: "none",
    width: "100%",
    height: "auto",
    py: 3,
    px: 2.5,
    boxSizing: "border-box",
    resize: "none !important",
    ...resetBorderOutlineBoxShadow,
    _hover: {
      ...resetBorderOutlineBoxShadow,
    },
    _focus: {
      ...resetBorderOutlineBoxShadow,
    },
    _focusVisible: {
      ...resetBorderOutlineBoxShadow,
    },
    _placeholder: {
      ...placeholderStyle,
    },
    container: {
      ...sharedBaseStyle("focusWithin"),
      resize: "none",
      backgroundClip: "content-box",
      borderRadius: "xl",
      transitionProperty: "common",
      transitionDuration: "normal",
    },
  },
  variants: {
    [variantOptions.gray]: {
      container: {
        backgroundColor: "components.inputs.bgRestingGray",
        borderColor: "borders.neutral.hush.resting",
      },
    },
    [variantOptions.unstyled]: {
      container: {
        border: "none",
        outline: "none",
        boxShadow: "none",
        _hover: {
          border: "none",
          outline: "none",
          boxShadow: "none",
        },
      },
    },
  },
});

export default Textarea;
