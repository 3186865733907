import {logError} from '/ui/lib/errors';
import {yext} from '/ui/lib/msg';

const {
  AbsoluteCenter,
  Box,
  Button,
  Checkbox,
  Divider,
  FALKey,
  HStack,
  Link,
  SubmitButton,
  Text,
  createField,
  useFormContext,
} = require('@yext/mana-ui');

export function isWhitelabeled(whitelabelInfo) {
  return !!whitelabelInfo && whitelabelInfo.businessId != 0;
}

export function LoginSubmitButton({whitelabelInfo, isDisabled, children}) {
  return (
    isWhitelabeled(whitelabelInfo)
      ? <WhitelabeledSubmitButton whitelabelInfo={whitelabelInfo} isDisabled={isDisabled}>
        {children}
      </WhitelabeledSubmitButton>
      : <SubmitButton size={'xl'} disableIfInvalid isDisabled={isDisabled} name={'submission-button'}>
        {children}
      </SubmitButton>
  );
}

export function MainButton({whitelabelInfo, onClick, children}) {
  return (
    isWhitelabeled(whitelabelInfo)
      ? <WhitelabeledMainButton whitelabelInfo={whitelabelInfo} onClick={onClick}>
        {children}
      </WhitelabeledMainButton>
      : <Button size={'xl'} onClick={onClick}>
        {children}
      </Button>
  );
}

export function LoginActions({initialRememberMe}) {
  return (
    <HStack align={'center'} justifyContent={'space-between'} width={'100%'}>
      <CheckboxField
        name="rememberMe"
        defaultValue={initialRememberMe}
      />
      <Link
        as={'a'}
        href={'/users/forgotpassword'}
        palette={'primary'}
        size={'md'}
        style={{whiteSpace: 'nowrap'}}
        flexShrink={0}
      >
        {yext.msg('Forgot Password')}
      </Link>
    </HStack>
  );
}

const CheckboxField = createField(
  React.forwardRef((props, ref) => {
    const {defaultValue, onChange, ...rest} = props;
    const [checked, setChecked] = React.useState(defaultValue);
    const {formState} = useFormContext();

    return (
      <Box marginTop={'-0.5rem'}>
        <Checkbox
          isChecked={checked}
          isDisabled={!formState.isValid}
          onChange={e => {
            setChecked(e.target.checked);
            onChange?.(e.target.checked);
          }}
        >
          <Text fontSize={'md'}>
            {yext.msg('Remember Me')}
          </Text>
        </Checkbox>
      </Box>
    );
  }),
  {
    isControlled: true,
  },
);

export function SsoLogin({onClick, shouldShowSsoLogin}) {
  if (!shouldShowSsoLogin) {
    return null;
  }
  return (
    <>
      <Box position={'relative'} padding={'2'}>
        <Divider />
        <AbsoluteCenter bg={'surfaces.lifted.white'} px={'4'}>
          <Text fontSize={'md'}>
            {yext.msg('Or')}
          </Text>
        </AbsoluteCenter>
      </Box>
      <Button size={'xl'} variant={'outline.dominant'} onClick={onClick} name={'submission-button'}>
        <FALKey/>
        <Text fontSize={'md'}>
          {yext.msg('Sign in with SSO')}
        </Text>
      </Button>
    </>
  );
}

function WhitelabeledSubmitButton({whitelabelInfo, isDisabled, children}) {
  return (
    <SubmitButton
      size={'xl'}
      disableIfInvalid
      isDisabled={isDisabled}
      bg={whitelabelInfo.brandButtonColor}
      color={whitelabelInfo.brandTextColor}
      borderColor={whitelabelInfo.brandButtonColor}
      _hover={{
        opacity: 0.8,
      }}
      _active={{
        opacity: 1,
      }}
    >
      {children}
    </SubmitButton>
  );
}


function WhitelabeledMainButton({whitelabelInfo, onClick, children}) {
  return (
    <Button
      size={'xl'}
      onClick={onClick}
      bg={whitelabelInfo.brandButtonColor}
      color={whitelabelInfo.brandTextColor}
      borderColor={whitelabelInfo.brandButtonColor}
      _hover={{
        opacity: 0.8,
      }}
      _active={{
        opacity: 1,
      }}
    >
      {children}
    </Button>
  );
}

/**
 * Conditionally renders a button used by Yext employees who are directed to
 * Yext login from the Zendesk mobile app's login flow.
 * It allows them to login to Yext via employee oauth so that they don't have to
 * leave the mobile app's login flow.
 */
export function EmployeeZendeskRedirectLogin({redirectUrl}) {
  try {
    const redirectsToZendesk = redirectUrl && redirectUrl.startsWith('/idp/zendesk');

    if (!redirectsToZendesk) {
      return null;
    }
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <Link
          href="https://yext.okta.com/app/zendesk/exk1hm8btz2I3OHC71d8/sso/saml"
          textDecoration={'none'}
          palette={'primary'}
        >
          {yext.msg('I am an Agent')}
        </Link>
      </Box>
    );
  } catch (e) {
    logError(e);
  }
}
