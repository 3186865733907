import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import typography from "../../../clayTheme/foundations/typography";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(["container", "icon", "title", "description"]);

const baseStyle = definePartsStyle({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "text.neutralDominant",
    maxW: "530px",
    gap: 0,
  },
  icon: {
    mb: 6,
  },
  title: {
    ...typography.textStyles["xl-semibold"],
    textAlign: "center",
    mb: 1,
  },
  description: {
    mb: 6,
    ...typography.textStyles["md"],
    color: "text.neutralStrong",
    textAlign: "center",
  },
});

const variants = {
  danger: definePartsStyle({
    title: {
      color: "text.danger",
    },
  }),
};

const EmptyState: ReturnType<typeof defineMultiStyleConfig> =
  defineMultiStyleConfig({ baseStyle, variants });

export default EmptyState;
