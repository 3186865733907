import { defineStyleConfig } from "@chakra-ui/styled-system";
import typography from "../../../clayTheme/foundations/typography";

export default defineStyleConfig({
  baseStyle: ({ theme }) => ({
    borderRadius: theme.radii.md,
    textTransform: "none",
    height: 6,
    display: "inline-flex",
    alignItems: "center",
    ...typography.textStyles["sm-medium"],
    paddingY: "0.1875rem", // 3px -- no token
    paddingX: 2.5,
    gap: 2,
  }),
});
