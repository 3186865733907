import {
  ComboboxOption,
  isButtonOption,
  isLinkOption,
  isSelectOption,
  useComboboxContext,
} from "./ComboboxContext";
import { Box, Checkbox, chakra } from "@chakra-ui/react";
import React from "react";
import { prefixDataProp } from "./utilities";

export interface ComboboxItemProps<T> {
  item: ComboboxOption<T>;
  index: number;
  isSelected: boolean;
  isHighlighted: boolean;
  children?: React.ReactNode;
}

export function ComboboxItem<T>(props: ComboboxItemProps<T>) {
  const { item, children } = props;

  const ChosenBox = isButtonOption(item)
    ? ButtonBox
    : isLinkOption(item)
      ? LinkBox
      : DivBox;

  return (
    <ChosenBox {...props}>
      <Prefix {...props} />
      {children}
    </ChosenBox>
  );
}

function ButtonBox<T>(props: ComboboxItemProps<T>) {
  const { item, children } = props;
  const ctx = useComboboxContext();

  const { baseProps, baseComboProps } = getBoxProps<T>(props, ctx.multiple);
  return (
    <Box
      as="button"
      type="button"
      {...baseProps}
      {...baseComboProps}
      {...ctx.combobox.getItemProps({
        ...baseComboProps,
        onClick: () => {
          item.onClick?.();
          ctx.onClose();
        },
      })}
    >
      {children}
    </Box>
  );
}

function LinkBox<T>(props: ComboboxItemProps<T>) {
  const { item, children } = props;
  const ctx = useComboboxContext();

  const { baseProps, baseComboProps } = getBoxProps<T>(props, ctx.multiple);
  return (
    <Box
      as="a"
      {...baseProps}
      {...ctx.combobox.getItemProps({
        ...baseComboProps,
        href: item.href,
      })}
    >
      {children}
    </Box>
  );
}

function DivBox<T>(props: ComboboxItemProps<T>) {
  const { children } = props;
  const ctx = useComboboxContext();

  const { baseProps, baseComboProps } = getBoxProps<T>(props, ctx.multiple);
  return (
    <Box {...baseProps} {...ctx.combobox.getItemProps(baseComboProps)}>
      {children}
    </Box>
  );
}

function getBoxProps<T>(props: ComboboxItemProps<T>, multiple?: boolean) {
  const { item, index, isSelected } = props;

  const baseProps = {
    w: "full",
    cursor: "pointer",
    bg: props.isHighlighted
      ? "surfaces.neutral.hush.hover"
      : !multiple && isSelected && isSelectOption(item)
        ? "surfaces.highlight.secondary"
        : undefined,
    display: "flex",
    fontWeight: isSelected && isSelectOption(item) ? "medium" : undefined,
    alignItems: "center",
    gap: 4,
    py: 1.5,
    px: 3,
    borderRadius: "lg",
  };

  const baseComboProps = {
    item,
    index,
    "aria-selected": isSelected,
  };
  return { baseProps, baseComboProps };
}

function Prefix<T>(props: ComboboxItemProps<T>) {
  const { multiple, optionToString } = useComboboxContext();
  const { item, isSelected } = props;
  const { labelPrefix } = item;

  const prefixID = React.useId();

  const hasPrefix = !!labelPrefix;

  if (!hasPrefix && !multiple) {
    return null;
  }

  const optionValue = optionToString ? optionToString(item) : "";

  if (multiple) {
    return (
      <Checkbox
        key={prefixID}
        id={prefixID}
        {...prefixDataProp}
        isChecked={isSelected}
        tabIndex={-1}
        value={optionValue}
        onChange={() => null}
        p={1.5}
        size="md"
      />
    );
  }

  return (
    <chakra.span
      key={prefixID}
      id={prefixID}
      data-prefix-parent
      fontSize="lg"
      display="flex"
      alignItems="center"
      lineHeight={0}
    >
      {item.labelPrefix}
    </chakra.span>
  );
}
