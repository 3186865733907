export enum sizeOptions {
  md = "md",
  lg = "lg",
  xl = "xl",
  "2xl" = "2xl",
}

export enum variantOptions {
  primary = "primary",
  secondary = "secondary",
  ghost = "ghost",
  "neutral.soft" = "neutral.soft",
  "neutral.strong" = "neutral.strong",
  "neutral.dominant" = "neutral.dominant",
  "outline.soft" = "outline.soft",
  "outline.dominant" = "outline.dominant",
  "outline.chameleon" = "outline.chameleon",
  success = "success",
  danger = "danger",
  // TODO: these variants shouldn't be directly used
  // they're here to allow other custom button components (SSOButton, ButtonGroup)
  // to share the Button component's base styles
  // We should remove these and instead share the styles without exposing
  // user facing props on the base component
  toggle = "toggle",
  toggleOutline = "toggleOutline",
  soft = "soft",
  strong = "strong",
}
