import React from "react";
import { Box, ThemingProps, useMultiStyleConfig, Icon } from "@chakra-ui/react";
import {
  EmptyBell,
  EmptyBeach,
  EmptyAlert,
  EmptyBox,
  EmptyCalendar,
  EmptyClipboard,
  EmptyClock,
  EmptyCoffee,
  EmptyCreditcard,
  EmptyDate,
  EmptyDocument,
  EmptyDocuments,
  EmptyDone,
  EmptyDragNDrop,
  EmptyEditDocuments,
  EmptyError,
  EmptyFolder,
  EmptyHouse,
  EmptyImages,
  EmptyInbox,
  EmptyLike,
  EmptyLock,
  EmptyMessages,
  EmptyPencil,
  EmptyPin,
  EmptyQandA,
  EmptyQuestion,
  EmptySearch,
  EmptyShield,
  EmptyShopping,
  EmptySnooze,
  EmptyStar,
  EmptyStop,
  EmptyTag,
  EmptyUser,
  EmptyWallet,
} from "../../icons/ManualIcons";
import { iconOptions } from "./theme/themeOptions";

const iconByVariant = {
  alert: EmptyAlert,
  beach: EmptyBeach,
  bell: EmptyBell,
  box: EmptyBox,
  calendar: EmptyCalendar,
  clipboard: EmptyClipboard,
  clock: EmptyClock,
  coffee: EmptyCoffee,
  creditcard: EmptyCreditcard,
  date: EmptyDate,
  document: EmptyDocument,
  documents: EmptyDocuments,
  done: EmptyDone,
  "drag-n-drop": EmptyDragNDrop,
  "edit-documents": EmptyEditDocuments,
  error: EmptyError,
  folder: EmptyFolder,
  house: EmptyHouse,
  images: EmptyImages,
  inbox: EmptyInbox,
  like: EmptyLike,
  lock: EmptyLock,
  messages: EmptyMessages,
  pencil: EmptyPencil,
  pin: EmptyPin,
  "q&a": EmptyQandA,
  question: EmptyQuestion,
  search: EmptySearch,
  shield: EmptyShield,
  shopping: EmptyShopping,
  snooze: EmptySnooze,
  star: EmptyStar,
  stop: EmptyStop,
  tag: EmptyTag,
  user: EmptyUser,
  wallet: EmptyWallet,
};

export interface EmptyStateProps extends ThemingProps<"EmptyState"> {
  title: string;
  icon: keyof typeof iconOptions;
  description: string;
  children?: React.ReactNode;
}

export default function EmptyState(props: EmptyStateProps) {
  const styles = useMultiStyleConfig("EmptyState", props);

  const OurIcon = iconByVariant[props.icon || "alert"];

  return (
    <Box __css={styles.container}>
      <Icon as={OurIcon} __css={styles.icon} height="200px" width="250px" />
      <Box __css={styles.title}>{props.title}</Box>
      <Box __css={styles.description}>{props.description}</Box>
      <Box>{props.children}</Box>
    </Box>
  );
}
