
import {LoginSubmitButton} from '/src/com/yext/platform/users/js/newloginhelpers/logincomponents';
import NewLoginTemplate from '/src/com/yext/platform/users/js/newloginhelpers/newlogintemplate';
import {validatePassword} from '/src/com/yext/platform/users/js/passwordvalidator';
import {registerComponentForJsonDataWithUIKit} from '/ui/lib/easyuikit';
import {AJAX_HEADER, checkResponseStatus} from '/ui/lib/fetch';
import {yext} from '/ui/lib/msg';

const {
  Button,
  Box,
  FALCircleExclamation,
  FALLockKeyhole,
  Flex,
  Form,
  FormControl,
  FormErrorMessage,
  InputLeftElement,
  ListItem,
  OrderedList,
  VStack,
  UnorderedList,
} = require('@yext/mana-ui');

/**
 * Component to render the reset password page
 */
export function ResetPassword({redirectUrl, username, email, firstName, lastName, whitelabelInfo, t, authenticityToken}) {
  return (
    <NewLoginTemplate
      title={yext.msg('Set Password')}
      subtitle={yext.msg('Please set a password for the following user:')}
      cardContent={<CardContent
        redirectUrl={redirectUrl}
        username={username}
        email={email}
        firstName={firstName}
        lastName={lastName}
        whitelabelInfo={whitelabelInfo}
        t={t}
        authenticityToken={authenticityToken}
      />}
      pageType={'resetPassword'}
      username={username}
      whitelabelInfo={whitelabelInfo}
    />
  );
}

function CardContent({redirectUrl, username, email, firstName, lastName, whitelabelInfo, t, authenticityToken}) {
  const [disabled, setDisabled] = React.useState(false);

  const [passwordInvalid, setPasswordInvalid] = React.useState(false);
  const [confirmPasswordInvalid, setConfirmPasswordInvalid] = React.useState(false);

  const [passwordInput, setPasswordInput] = React.useState('');
  const [confirmPasswordInput, setConfirmPasswordInput] = React.useState('');

  const onPasswordChange = event => {
    const password = event.target.value;
    setPasswordInput(password);
    setDisabled(false);
  };

  const isPasswordValid = password => {
    return validatePassword({
      password: password,
      username: username,
      email: email,
      firstName: firstName,
      lastName: lastName,
    });
  };

  const onPasswordBlur = () => {
    const passwordValid = isPasswordValid(passwordInput);
    const confirmPasswordValid = passwordInput === confirmPasswordInput;
    if (!passwordValid || !confirmPasswordValid) {
      setDisabled(true);
    }
    setPasswordInvalid(!passwordValid);
    setConfirmPasswordInvalid(!confirmPasswordValid && confirmPasswordInput);
  };

  const onConfirmPasswordChange = event => {
    const confirmPassword = event.target.value;
    setConfirmPasswordInput(confirmPassword);
    setDisabled(false);
  };

  const onCancel = () => {
    setDisabled(true);
    window.location.href = redirectUrl;
  };

  const onSubmit = formData => {
    setDisabled(true);

    const confirmPasswordValid = passwordInput === confirmPasswordInput;
    const passwordValid = isPasswordValid(passwordInput);
    if (!confirmPasswordValid || !passwordValid) {
      return;
    }

    const data = new FormData();
    data.append('t', t);
    data.append('password', passwordInput);
    data.append('confirmed', confirmPasswordInput);
    data.append('authenticityToken', authenticityToken);
    fetch('/users/setpassword', {
      method: 'POST',
      headers: AJAX_HEADER,
      body: data,
    })
      .then(checkResponseStatus)
      .then(response => response.json())
      .then(json => {
        if (json.error) {
          if (json.error === 'passwordDoesNotMatch') {
            setConfirmPasswordInvalid(true);
          } else if (json.error === 'invalidPassword') {
            setPasswordInvalid(true);
          } else if (json.error === 'noProductAccess') {
            window.location.href = '/users/noproductaccess';
          }
        } else if (json.success) {
          window.location.href = 'setpasswordconfirm?t=' + encodeURIComponent(t);
        }
      });
  };

  return (
    <Form onSubmit={onSubmit}>
      {({Field}) => (
        <>
          <VStack
            spacing={5}
            align={'stretch'}
          >
            <FormControl isInvalid={passwordInvalid}>
              <Field
                name={'password'}
                type={'password'}
                variant={'gray'}
                label={yext.msg('New Password')}
                placeholder={yext.msg('Create strong password')}
                isRequired={true}
                leftAddon={<InputLeftElement><FALLockKeyhole/></InputLeftElement>}
                value={passwordInput}
                onChange={onPasswordChange}
                onBlur={onPasswordBlur}
              />
              <PasswordValidationError />
            </FormControl>
            <FormControl isInvalid={confirmPasswordInvalid}>
              <Field
                name={'confirmed'}
                type={'password'}
                variant={'gray'}
                label={yext.msg('Confirm Password')}
                placeholder={yext.msg('Enter password again')}
                isRequired={true}
                leftAddon={<InputLeftElement><FALLockKeyhole/></InputLeftElement>}
                value={confirmPasswordInput}
                onChange={onConfirmPasswordChange}
                onBlur={onPasswordBlur}
              />
              <ConfirmPasswordError />
            </FormControl>
            <VStack
              spacing={4}
              align={'stretch'}
            >
              <LoginSubmitButton isDisabled={disabled} whitelabelInfo={whitelabelInfo}>
                {yext.msg('Set Password')}
              </LoginSubmitButton>
              <Button
                size={'xl'}
                variant={'outline.dominant'}
                onClick={onCancel}
              >
                {yext.msg('Cancel')}
              </Button>
            </VStack>
          </VStack>
        </>
      )}
    </Form>
  );
}

function PasswordValidationError() {
  return (
    <Flex mt={2} justifyContent={'space-between'} alignItems={'center'} name={'password-validation-error'}>
      <Box display={'flex'} alignItems={'center'}>
        <FormErrorMessage alignItems={'flex-start'}>
          <FALCircleExclamation me={1.5} position={'relative'} top={0.5}/>
          <Box>
            {yext.msg('Your password must meet the following criteria:')}
            <UnorderedList paddingLeft={'1'}>
              <ListItem>
                {yext.msg('Minimum of 12 characters')}
              </ListItem>
              <ListItem>
                {yext.msg('Cannot contain first name, last name, or username')}
              </ListItem>
              <ListItem>
                {yext.msg('Must include at least three of the following:')}
              </ListItem>
              <OrderedList paddingLeft={'1'}>
                <ListItem>
                  {yext.msg('Uppercase Letter (A-Z)')}
                </ListItem>
                <ListItem>
                  {yext.msg('Lowercase Letter (a-z)')}
                </ListItem>
                <ListItem>
                  {yext.msg('Number (0-9)')}
                </ListItem>
                <ListItem>
                  {yext.msg('Special Character (e.g., !, @, #, $)')}
                </ListItem>
              </OrderedList>
            </UnorderedList>
          </Box>
        </FormErrorMessage>
      </Box>
    </Flex>
  );
}

function ConfirmPasswordError() {
  return (
    <Flex mt={2} justifyContent={'space-between'} alignItems={'center'} name={'confirm-password-error'}>
      <Box display={'flex'} alignItems={'center'}>
        <FormErrorMessage>
          <FALCircleExclamation me={1.5} />
          {yext.msg('Passwords do not match. Please try again.')}
        </FormErrorMessage>
      </Box>
    </Flex>
  );
}

document.addEventListener('DOMContentLoaded', () => {
  registerComponentForJsonDataWithUIKit('ResetPassword', ResetPassword);
});
