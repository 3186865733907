export enum variantOptions {
  chameleon = "chameleon",
  onLight = "onLight",
  onDark = "onDark",
}

export enum sizeOptions {
  sm = "sm",
  md = "md",
  lg = "lg",
}
