import React from "react";
import {
  Tooltip as ChakraTooltip,
  TooltipProps as ChakraTooltipProps,
} from "@chakra-ui/react";

export interface TooltipProps extends Omit<ChakraTooltipProps, "colorScheme"> {}

const Tooltip = (props: TooltipProps) => {
  return <ChakraTooltip {...props} hasArrow/>;
};

export default Tooltip;
