import React from "react";
import {
  Tag as ChakraTag,
  TagLabel,
  TagProps as ChakraTagProps,
  ThemingProps,
} from "@chakra-ui/react";
import { FALXmark } from "../../icons";
import { sizeOptions } from "./theme/themeOptions";
import TagTheme from "./theme";
import { useBreakpointValue } from "../../utils/responsive";

export interface TagProps extends ChakraTagProps, ThemingProps<"Tag"> {
  disabled?: boolean;
}

const fontSizeFromTagSize = {
  [sizeOptions.sm]: "xs",
  [sizeOptions.md]: "sm",
  [sizeOptions.lg]: "md",
  [sizeOptions.xl]: "lg",
  [sizeOptions["2xl"]]: "lg",
};

const Tag = (props: TagProps) => {
  const { size, children, disabled, ...rest } = props;

  const breakpointSize =
    (size ? useBreakpointValue(size) : TagTheme?.defaultProps?.size) ||
    sizeOptions.md;

  const fontSize = fontSizeFromTagSize[breakpointSize as sizeOptions];

  const tagChildren = React.Children.map(children, (child) => {
    if (typeof child == "string") {
      return <TagLabel textStyle={fontSize}>{child}</TagLabel>;
    }
    return child;
  });

  return (
    <ChakraTag
      as="button"
      disabled={disabled}
      size={size}
      type="button"
      {...rest}
    >
      {tagChildren}
      <FALXmark iconSize={size === "2xl" ? "xl" : "lg"} />
    </ChakraTag>
  );
};

export default Tag;
