import { createContext } from "@chakra-ui/react-utils";
import { MutableRefObject } from "react";

export type TextAreaMethods = {
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  replaceSelection: (value: string) => void;
  replaceValue: (value: string) => void;
  getCurrentSelection: () => string;
};

export type TextareaAPI = TextAreaMethods & {
  value?: string;
  defaultValue?: string;
  ref: MutableRefObject<HTMLTextAreaElement | null>;
};

export const [ContextProvider, useTextareaContext, TextareaContext] =
  createContext<TextareaAPI>({
    strict: true,
    errorMessage:
      "useTextareaContext must be called from within a child of a Textarea or TextareaField",
  });
