import React from "react";
import {
  Kbd as ChakraKbd,
  KbdProps as ChakraKbdProps,
  ThemingProps,
} from "@chakra-ui/react";

export interface KbdProps extends ChakraKbdProps, ThemingProps<"Kbd"> {}

export default function Kbd(props: KbdProps) {
  return <ChakraKbd {...props} />;
}
