// TODO: figure out how to conver the following rule set to a style sheet, so that
// it can be manually added to storm based on a feature flag being active. These
// styles will inherit and thereby pollute children element styles and it's not
// easy to reset them to whatever they should inherit from.

import { defineStyleConfig, StyleFunctionProps } from "@chakra-ui/react";

const ThemeStyles = defineStyleConfig({
  baseStyle: (props: StyleFunctionProps) =>
    props.activateGlobalStyles ? baseStyles : {},
  defaultProps: {},
});

export const baseStyles = {
  fontFamily: "body",
  color: "app-text",
  bg: "surfaces.page.white",
  transitionProperty: "background-color",
  transitionDuration: "normal",
  lineHeight: "base",
  WebkitFontSmoothing: "antialiased",
  WebkitTextSizeAdjust: "100%",
  MozOsxFontSmoothing: "grayscale",
  TextRendering: "optimizelegibility",
  TouchAction: "manipulation",
  fontSize: "sm",
};

export type ThemeProps = {
  activateGlobalStyles?: boolean;
};

export default ThemeStyles;
