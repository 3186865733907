import { defineStyleConfig } from "@chakra-ui/react";
import { paletteOptions, sizeOptions } from "./themeOptions";

const colorHelper = (colorScheme: string) => {
  return (
    {
      [paletteOptions.blackAlpha]: "pairs.alpha.alphaBlack300",
      [paletteOptions.whiteAlpha]: "whiteAlpha.300",
      [paletteOptions.primary]: "surfaces.primary.strong.resting",
      [paletteOptions.secondary]: "surfaces.secondary.dominant.resting",
      [paletteOptions.gray]: "pairs.gray.500",
    }[colorScheme] || "surfaces.primary.strong.resting"
  );
};

const borderColorHelper = (colorScheme: string) => {
  if (colorScheme === paletteOptions.whiteAlpha)
    return "pairs.alpha.alphaWhite50";
  return "pairs.alpha.alphaBlack50";
};

const Spinner = defineStyleConfig({
  baseStyle: ({ colorScheme }) => {
    return {
      color: colorHelper(colorScheme),
      borderLeftColor: borderColorHelper(colorScheme),
      borderBottomColor: borderColorHelper(colorScheme),
    };
  },
  sizes: {
    [sizeOptions.xs]: {
      height: "0.75rem",
      width: "0.75rem",
    },
    [sizeOptions.sm]: {
      height: "1rem",
      width: "1rem",
    },
    [sizeOptions.md]: {
      height: "1.5rem",
      width: "1.5rem",
    },
    [sizeOptions.lg]: {
      height: "2rem",
      width: "2rem",
    },
    [sizeOptions.xl]: {
      height: "3rem",
      width: "3rem",
    },
  },
  defaultProps: {
    colorScheme: paletteOptions.primary,
    size: sizeOptions.md,
  },
});

export default Spinner;
