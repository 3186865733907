import React from "react";
import { Menu, MenuButton, Box, HStack, Flex, useStyleConfig } from "@chakra-ui/react";
import { IconButton } from "../IconButton";
import {
  FALEllipsis,
  FALArrowDownLeftAndArrowUpRightToCenter,
  FALArrowUpRightAndArrowDownLeftFromCenter,
} from "../../icons";
import { useTwoPanelLayoutContext } from "./TwoPanel";
import { CloseButton } from "../CloseButton";
import { useTranslation } from "../ManaUIProvider";
import { variantOptions } from "./theme/themeOptions";

interface RightPanelProps {
  title: string;
  headerActions?: React.ReactNode;
  variant?: variantOptions;
}

export const RightPanel = (props: React.PropsWithChildren<RightPanelProps>) => {
  const styles = useStyleConfig("RightPanel", props);
  const { title, children, headerActions } = props;
  const { onClose, isExpanded, onToggleExpanded } = useTwoPanelLayoutContext();
  const translations = useTranslation("Layout");

  return (
    <Flex __css={styles}>
      <HStack alignItems="flex-start" mb={2}>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          w="full"
          gap={2}
        >
          <Box textStyle="2xl-medium">{title}</Box>
          <Flex gap={2} alignItems={"center"}>
            <SizeToggleButton isFull={isExpanded} onToggle={onToggleExpanded} />
            {!!headerActions && (
              <Menu>
                <IconButton
                  as={MenuButton}
                  aria-label={translations.toggleMenu}
                  tooltipLabel={translations.toggleMenu}
                  variant="ghost"
                  size="lg"
                  icon={<FALEllipsis />}
                  onClick={() => console.log("Toggle Menu")}
                />
                {headerActions}
              </Menu>
            )}
            <CloseButton onClick={onClose} tooltipLabel={translations.close} />
          </Flex>
        </Flex>
      </HStack>
      <Flex py={4} flexDirection={"column"} overflowY="auto" flexGrow={1}>
        {children}
      </Flex>
    </Flex>
  );
};

interface SizeToggleButtonProps {
  isFull: boolean;
  onToggle: () => void;
}

function SizeToggleButton(props: SizeToggleButtonProps) {
  const translations = useTranslation("Drawer");

  return (
    <IconButton
      aria-label={translations.toggleExpand}
      tooltipLabel={translations.toggleExpand}
      display={{ base: "none", md: "flex" }}
      variant="ghost"
      size="lg"
      icon={
        props.isFull ? (
          <FALArrowDownLeftAndArrowUpRightToCenter />
        ) : (
          <FALArrowUpRightAndArrowDownLeftFromCenter />
        )
      }
      onClick={props.onToggle}
    />
  );
}
