import React from "react";
import {
  Tabs as ChakraTabs,
  TabsProps as ChakraTabsProps,
} from "@chakra-ui/react";
import { variantOptions } from "./theme/themeOptions";

export interface TabsProps
  extends Omit<ChakraTabsProps, "colorScheme" | "size"> {
  variant?: keyof typeof variantOptions;
}

export function Tabs(props: TabsProps) {
  return <ChakraTabs {...props} />;
}
