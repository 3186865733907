import { anatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

const parts = anatomy("navbar").parts(
  "container",
  "inner",
  "brand",
  "content",
  "item",
  "link",
);

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

export default defineMultiStyleConfig({
  baseStyle: definePartsStyle({
    container: {
      px: [5, 5, 8, 8, 10],
      borderBottom: "none",
    },
    inner: {
      px: 0,
    },
  }),
});
