import React from "react";
import { Box, ThemingProps, useMultiStyleConfig } from "@chakra-ui/react";
import { usePanels } from "../Panel";
import { createContext } from "@chakra-ui/react-utils";

export interface TwoPanelLayoutProps extends ThemingProps<"TwoPanelLayout"> {
  left: React.ReactNode;
  right: React.ReactNode;
  isOpen: boolean;
  isExpanded: boolean;
  removeOverflowLeft?: boolean;
  removeOverflowRight?: boolean;
  onToggleExpanded: () => void;
  onOpen: () => void;
  onClose: () => void;
  onToggle: () => void;
}

interface TwoPanelLayoutContextValue {
  onOpen: () => void;
  onClose: () => void;
  onToggle: () => void;
  isOpen: boolean;
  isExpanded: boolean;
  onToggleExpanded: () => void;
}

export const useTwoPanelLayout = () => {
  const panels = usePanels({
    Left: { isOpen: true },
    Right: { isOpen: false, isExpanded: false },
  });

  return {
    onOpen: () => panels.openPanel("Right"),
    onClose: () => panels.closePanel("Right"),
    onToggle: () => panels.togglePanel("Right"),
    isOpen: panels.isOpen("Right"),
    isExpanded: panels.getProperty("Right", "isExpanded") as boolean,
    onToggleExpanded: () =>
      panels.setProperty(
        "Right",
        "isExpanded",
        !panels.getProperty("Right", "isExpanded"),
      ),
  };
};

const [TwoPanelLayoutProvider, useTwoPanelLayoutContext] =
  createContext<TwoPanelLayoutContextValue>({
    name: "TwoPanelLayoutContext",
    errorMessage:
      "useTwoPanelLayoutContext: `context` is undefined, you may have forgotten to wrap your components in `<TwoPanelLayout />`",
  });

export { TwoPanelLayoutProvider, useTwoPanelLayoutContext };

export const TwoPanelLayout = (props: TwoPanelLayoutProps) => {
  const styles = useMultiStyleConfig("TwoPanelLayout", props);
  const defaultFunctions = useTwoPanelLayout();
  const { isOpen, onClose, onOpen, onToggle, isExpanded, onToggleExpanded } = {
    isOpen: props.isOpen === null ? defaultFunctions.isOpen : props.isOpen,
    onClose: props.onClose === null ? defaultFunctions.onClose : props.onClose,
    onOpen: props.onOpen === null ? defaultFunctions.onOpen : props.onOpen,
    onToggle:
      props.onToggle === null ? defaultFunctions.onToggle : props.onToggle,
    isExpanded:
      props.isExpanded === null
        ? defaultFunctions.isExpanded
        : props.isExpanded,
    onToggleExpanded:
      props.onToggleExpanded === null
        ? defaultFunctions.onToggleExpanded
        : props.onToggleExpanded,
  };

  return (
    <TwoPanelLayoutProvider
      value={{
        isOpen,
        onClose,
        onOpen,
        onToggle,
        isExpanded,
        onToggleExpanded,
      }}
    >
      <Box __css={styles.container}>
        <Box __css={styles.inner}>
          <Box __css={styles.left}>{props.left}</Box>
          <Box __css={styles.right}>{props.right}</Box>
        </Box>
      </Box>
    </TwoPanelLayoutProvider>
  );
};
