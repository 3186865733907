export const textStyles = {
  ...generateTextStylesForWeight("normal"),
  ...generateTextStylesForWeight("medium"),
  ...generateTextStylesForWeight("semibold"),
  ...generateTextStylesForWeight("link"),
};

const typography = {
  fontWeights: {
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },

  fonts: {
    heading: `'Inter', 'SF Pro', 'Helvetica Neue', Helvetica, sans-serif`,
    body: `'Inter', 'SF Pro', 'Helvetica Neue', Helvetica, sans-serif`,
    mono: `ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace`,
  },

  fontSizes: {
    xs: "0.625rem",
    sm: "0.75rem",
    md: "0.875rem",
    lg: "1rem",
    xl: "1.125rem",
    "2xl": "1.25rem",
    "3xl": "1.5rem",
    "4xl": "1.875rem",
    "5xl": "2.25rem",
    "6xl": "3rem",
  },

  textStyles,
};

function generateTextStylesForWeight(variant: string) {
  const suffix = variant === "normal" ? "" : `-${variant}`;
  const weight = variant === "link" ? "medium" : variant;

  return {
    [`xs${suffix}`]: {
      fontSize: ["0.75rem", "0.625rem"],
      fontWeight: weight,
      lineHeight: ["150%"],
      letterSpacing: ["0.000625rem", "0.00625rem"],
    },
    [`sm${suffix}`]: {
      fontSize: ["0.75rem", "0.75rem"],
      fontWeight: weight,
      lineHeight: "150%",
      letterSpacing: ["0.000625rem", "0.00625rem"],
    },
    [`md${suffix}`]: {
      fontSize: ["0.875rem", "0.875rem"],
      fontWeight: weight,
      lineHeight: "150%",
      letterSpacing: "-0.005625rem",
    },
    [`lg${suffix}`]: {
      fontSize: ["1rem", "1rem"],
      fontWeight: weight,
      lineHeight: "150%",
      letterSpacing: "-0.01125rem",
    },
    [`xl${suffix}`]: {
      fontSize: ["1.125rem", "1.125rem"],
      fontWeight: weight,
      lineHeight: "150%",
      letterSpacing: "-0.01625rem",
    },
    [`2xl${suffix}`]: {
      fontSize: ["1.25rem", "1.25rem"],
      fontWeight: weight,
      lineHeight: "150%",
      letterSpacing: "-0.020625rem",
    },
    [`3xl${suffix}`]: {
      fontSize: ["1.25rem", "1.5rem"],
      fontWeight: weight,
      lineHeight: ["150%", "125%"],
      letterSpacing: ["-0.020625rem", "-0.029375rem"],
    },
    [`4xl${suffix}`]: {
      fontSize: ["1.5rem", "1.875rem"],
      fontWeight: weight,
      lineHeight: "150%",
      letterSpacing: ["-0.029375rem", "-0.04rem"],
    },
    [`5xl${suffix}`]: {
      fontSize: ["1.75rem", "2.25rem"],
      fontWeight: weight,
      lineHeight: "125%",
      letterSpacing: ["-0.036875rem", "-0.049375rem"],
    },
    [`6xl${suffix}`]: {
      fontSize: ["1.875rem", "3rem"],
      fontWeight: weight,
      lineHeight: ["150%", "125%"],
      letterSpacing: ["-0.04rem", "-0.066875rem"],
    },
  };
}

export default typography;
