import React from "react";
import {
  Card,
  CardBody,
  Flex,
  HStack,
  StatGroup,
  Stat,
  StatLabel,
  StatNumber,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "@chakra-ui/react";
import { paletteOptions } from "../CircleIcon/theme/themeOptions";
import { CircleIcon } from "../CircleIcon";
import * as Icons from "../../icons";

export interface KPICardsProps {
  metrics: KPICardProps[];
}

export default function KPICards(props: KPICardsProps) {
  const { metrics } = props;
  return (
    <StatGroup gap={5} alignItems="stretch">
      {metrics.map((metric) => (
        <KPICard key={metric.label} {...metric} />
      ))}
    </StatGroup>
  );
}

interface KPICardProps {
  icon: keyof typeof Icons;
  iconPalette: keyof typeof paletteOptions;
  popover?: React.ReactElement<typeof PopoverContent>;
  label: string;
  value: string;
}

const KPICard: React.FC<KPICardProps> = (props) => {
  const { icon, iconPalette, popover, label, value } = props;
  const IconComponent = Icons[icon];
  return (
    <Card variant="outline" flexGrow="1" flexBasis="192px">
      <CardBody>
        <Stat>
          <Flex
            gap={4}
            direction="column"
            wrap="wrap"
            justifyContent="space-between"
            alignItems="left"
          >
            <StatLabel display="flex" flexBasis="22px">
              <HStack gap={3}>
                <CircleIcon palette={iconPalette}>
                  {<IconComponent />}
                </CircleIcon>
                <HStack gap={2}>
                  <Text textStyle="md-medium" whiteSpace="nowrap">
                    {label}
                  </Text>
                  {popover && 
                  <Popover>
                    <PopoverTrigger>
                      <Icons.FALCircleInfo />
                    </PopoverTrigger>
                    {popover}
                  </Popover>
                  }
                </HStack>
              </HStack>
            </StatLabel>
            <StatNumber>
              <Text textStyle="4xl-medium" whiteSpace="nowrap">
                {value}
              </Text>
            </StatNumber>
          </Flex>
        </Stat>
      </CardBody>
    </Card>
  );
}
