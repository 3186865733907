import { radioAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";
import { sizeOptions } from "./themeOptions";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(radioAnatomy.keys);

const sizes = {
  [sizeOptions.sm]: definePartsStyle({
    label: defineStyle({
      fontSize: "md",
      marginLeft: "2.5",
    }),
    control: defineStyle({
      height: "1.25rem",
      width: "1.25rem",
    }),
  }),
  [sizeOptions.md]: definePartsStyle({
    label: defineStyle({
      fontSize: "lg",
      marginLeft: "3",
    }),
    control: defineStyle({
      height: "1.5rem",
      width: "1.5rem",
    }),
  }),
  [sizeOptions.lg]: definePartsStyle({
    label: defineStyle({
      fontSize: "xl",
      marginLeft: "3.5",
    }),
    control: defineStyle({
      height: "1.75rem",
      width: "1.75rem",
    }),
  }),
};

const baseStyle = definePartsStyle(() => {
  return {
    label: {
      _disabled: {
        color: "text.disabledOnSoft",
        opacity: "1",
      },
    },
    control: {
      borderWidth: "sm",
      borderColor: "text.neutralDominant",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "surfaces.page.white",
      _hover: {
        shadow: "gray",
        borderColor: "borders.primary.strong.resting",
      },
      _invalid: {
        shadow: "error",
        borderColor: "borders.danger.strong.resting",
        borderWidth: "md",
        _hover: {
          borderColor: "borders.danger.strong.hover",
          shadow: "error",
          _before: {
            backgroundColor: "surfaces.danger.dominant.hover",
          },
        },
      },
      _disabled: {
        borderColor: "borders.neutral.dominant.disabled",
        backgroundColor: "surfaces.neutral.soft.disabled",
        _hover: {
          shadow: "none",
          borderColor: "borders.neutral.dominant.disabled",
        },
        _invalid: {
          borderColor: "borders.neutral.dominant.disabled",
          backgroundColor: "surfaces.neutral.soft.disabled",
          borderWidth: "sm",
          shadow: "none",
          _hover: {
            borderColor: "borders.neutral.dominant.disabled",
            backgroundColor: "surfaces.neutral.soft.disabled",
            shadow: "none",
          },
        },
        _checked: {
          _before: {
            backgroundColor: "surfaces.neutral.dominant.disabled",
          },
        },
      },
      _focusVisible: {
        shadow: "focus",
        borderColor: "borders.active.soft",
      },
      _checked: {
        borderColor: "borders.primary.strong.resting",
        backgroundColor: "surfaces.page.white",
        _hover: {
          borderColor: "borders.primary.strong.hover",
          backgroundColor: "surfaces.page.white",
          _before: {
            backgroundColor: "surfaces.primary.strong.hover",
          },
        },
        _before: {
          backgroundColor: "surfaces.primary.strong.resting",
          height: "65%",
          width: "65%",
        },
        _disabled: {
          borderColor: "borders.neutral.dominant.disabled",
          backgroundColor: "surfaces.neutral.soft.disabled",
        },
        _invalid: {
          borderColor: "borders.danger.strong.resting",
          borderWidth: "sm",
          _disabled: {
            borderColor: "borders.neutral.dominant.disabled",
            backgroundColor: "surfaces.neutral.soft.disabled",
            shadow: "none",
            _hover: {
              _before: {
                borderColor: "borders.neutral.dominant.disabled",
                backgroundColor: "surfaces.neutral.dominant.disabled",
              },
            },
          },
          _hover: {
            borderColor: "borders.danger.strong.hover",
            shadow: "error",
            _before: {
              backgroundColor: "surfaces.danger.dominant.hover",
            },
          },
          _before: {
            backgroundColor: "surfaces.danger.dominant.resting",
          },
        },
      },
    },
  };
});

const Radio: ReturnType<typeof defineMultiStyleConfig> = defineMultiStyleConfig(
  { baseStyle, sizes },
);

export default Radio;
