import React from "react";
import { createContext, useContext } from "react";

const NonceContext = createContext("");

interface NonceProviderProps {
  nonce: string;
  children: React.ReactElement;
}

export const NonceProvider = (props: NonceProviderProps) => {
  const { nonce, ...rest } = props;
  return <NonceContext.Provider value={nonce} {...rest} />;
};

export const useNonce = () => useContext(NonceContext);
