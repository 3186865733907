import { defineStyleConfig } from "@chakra-ui/react";

const StarRating = defineStyleConfig({
  baseStyle: ({ rating }) => {
    return {
      cursor: rating ? "" : "pointer",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    };
  },
});

export default StarRating;
