import { EmotionCache } from "@emotion/react";
import { default as createCache, StylisPlugin } from "@emotion/cache";
import { useEmotionInsertionPoint } from "./insertionPoints";

export const useCache = ({
  nonce,
}: {
  nonce: string | undefined;
}): EmotionCache => {
  const insertionPoint = useEmotionInsertionPoint();
  return createCache({
    key: "mjolnir",
    nonce: nonce,
    insertionPoint: insertionPoint,
    stylisPlugins: [wrapInLayer("mjolnir")],
  });
};

// this solution is taken from https://github.com/emotion-js/emotion/issues/3134
const wrapInLayer: (layerName: string) => StylisPlugin =
  (layerName) => (node) => {
    // if we're not at the root of a <style> tag, leave the tree intact
    if (node.parent) return;

    // don't wrap `@layer` nodes
    if (node.type === "@layer") return;

    // if we're at the root, replace node with `@layer layerName { node }`
    const child = { ...node, parent: node, root: node };
    Object.assign(node, {
      children: [child],
      length: 6,
      parent: null,
      props: [layerName],
      return: "",
      root: null,
      type: "@layer",
      value: `@layer ${layerName}`,
    });
  };
