import { breadcrumbAnatomy as parts } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from "@chakra-ui/styled-system";
import { visuallyHiddenStyle } from "@chakra-ui/react";
import typography from "../../../clayTheme/foundations/typography";

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  link: defineStyle({
    px: 2,
    py: 1.5,
    ...typography.textStyles.sm,
    color: "text.neutralStrong",
    borderRadius: "sm",
    "&[aria-current=page]": {
      fontWeight: "medium",
      color: "text.neutralDominant",
    },
    "&:not([aria-current=page])": {
      cursor: "pointer",
      _hover: {
        textDecoration: "none",
        backgroundColor: "surfaces.neutral.soft.hover",
      },
      _focusVisible: {
        shadow: "focus",
      },
    },
  }),
  item: defineStyle({
    "&:not(:first-child:last-child) .chakra-breadcrumb-home": {
      color: "text.link",
    },
    "&:not(:first-child:last-child) .chakra-breadcrumb-home-text": {
      ...visuallyHiddenStyle,
    },
  }),
  separator: defineStyle({
    fontSize: "lg",
    color: "components.breadcrumb.iconSeparator",
    mx: 2,
  }),
});

const Breadcrumb: ReturnType<typeof defineMultiStyleConfig> =
  defineMultiStyleConfig({
    baseStyle,
  });

export default Breadcrumb;
