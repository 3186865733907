import { sizeOptions, variantOptions } from "./themeOptions";
import { cardAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, cssVar } from "@chakra-ui/react";
import typography from "../../../clayTheme/foundations/typography";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(cardAnatomy.keys);

const $radius = cssVar("card-radius");

const baseStyle = definePartsStyle({
  container: {
    color: "text.neutralDominant",
    baseStyle: () => {
      return {
        backgroundColor: "pairs.gray.whitePair",
      };
    },
  },
  header: {
    p: 0,
  },
  body: {
    p: 0,
    display: "flex",
    flexDirection: "column",
    ...typography.textStyles.lg,
  },
  footer: {
    p: 0,
    pt: 4,
  },
});

const sizes = {
  [sizeOptions.sm]: definePartsStyle({
    container: {
      [$radius.variable]: "radii.lg",
      p: 3.5,
      gap: 3.5,
    },
    header: {
      ...typography.textStyles["2xl-semibold"],
    },
    body: {
      gap: 3.5,
    },
    footer: {},
  }),
  [sizeOptions.md]: definePartsStyle({
    container: {
      [$radius.variable]: "radii.lg",
      p: 5,
      gap: 5,
    },
    header: {
      ...typography.textStyles["3xl-semibold"],
    },
    body: {
      gap: 5,
    },
    footer: {},
  }),
  [sizeOptions.lg]: definePartsStyle({
    container: {
      [$radius.variable]: "radii.lg",
      p: 7,
      gap: 7,
    },
    header: {
      ...typography.textStyles["4xl-semibold"],
    },
    body: {
      gap: 7,
    },
    footer: {},
  }),
};

const variants = {
  [variantOptions.elevated]: definePartsStyle({
    container: {
      shadow: "md",
      borderWidth: "sm",
      borderColor: "borders.neutral.hush.resting",
      backgroundColor: "surfaces.lifted.white",
    },
  }),
  [variantOptions.outline]: definePartsStyle({
    container: {
      borderColor: "borders.neutral.soft.resting",
      backgroundColor: "surfaces.lifted.white",
      borderWidth: "sm",
    },
  }),
  [variantOptions.filled]: definePartsStyle({
    container: {
      backgroundColor: "surfaces.neutral.soft.resting",
    },
  }),
  [variantOptions.transparent]: definePartsStyle({
    container: {
      borderColor: "borders.neutral.hush.resting",
      color: "currentColor",
      backgroundColor: "transparent",
      borderWidth: "sm",
    },
  }),
};

const Card: ReturnType<typeof defineMultiStyleConfig> = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants,
});

export default Card;
