
import {LoginSubmitButton} from '/src/com/yext/platform/users/js/newloginhelpers/logincomponents';
import NewLoginTemplate from '/src/com/yext/platform/users/js/newloginhelpers/newlogintemplate';
import {registerComponentForJsonDataWithUIKit} from '/ui/lib/easyuikit';
import {AJAX_HEADER, checkResponseStatus} from '/ui/lib/fetch';
import {yext} from '/ui/lib/msg';
import {useIsSmallViewport} from '/ui/lib/responsivity';

const {
  Box,
  Button,
  EmptyState,
  FALEnvelope,
  Form,
  FormControl,
  VStack,
  Text,
} = require('@yext/mana-ui');


/**
 * Component to render the reset password page
 */
export function NewForgotPassword({redirectUrl, emailPasswordLink, authenticityToken, whitelabelInfo}) {
  return (
    <NewLoginTemplate
      title={yext.msg('Forgot Password')}
      subtitle={yext.msg('Enter your username and we’ll send a link to reset your password.')}
      cardContent={<CardContent
        redirectUrl={redirectUrl}
        emailPasswordLink={emailPasswordLink}
        authenticityToken={authenticityToken}
        whitelabelInfo={whitelabelInfo}
      />}
      whitelabelInfo={whitelabelInfo}
    />
  );
}

function CardContent({redirectUrl, emailPasswordLink, authenticityToken, whitelabelInfo}) {
  const isSmallViewport = useIsSmallViewport();
  const [disabled, setDisabled] = React.useState(false);
  window.addEventListener('pageshow', () => setDisabled(false));

  const onCancel = () => {
    setDisabled(true);
    window.location.href = redirectUrl;
  };

  const onSubmit = formData => {
    setDisabled(true);
    const data = new FormData();
    data.append('email', formData['email']);
    data.append('authenticityToken', authenticityToken);
    fetch(emailPasswordLink, {
      method: 'POST',
      headers: AJAX_HEADER,
      body: data,
    })
      .then(checkResponseStatus)
      .then(response => {
        window.location.href = response.url;
      })
      .finally(() => setDisabled(false));
  };

  return (
    <FormControl>
      <Form onSubmit={onSubmit}>
        {({Field}) => (
          <>
            {!isSmallViewport
              && <Box mb={'-1.5rem'}><EmptyState icon={'alert'} /></Box>}
            <VStack
              spacing={5}
              align={'stretch'}
            >
              <Field
                name={'email'}
                type={'text'}
                variant={'gray'}
                label={yext.msg('Username')}
                placeholder={'john@abccompany.com'}
                isRequired={true}
                icon={<FALEnvelope/>}
                isDisabled={disabled}
              />
              <VStack
                spacing={4}
                align={'stretch'}
              >
                <LoginSubmitButton isDisabled={disabled} whitelabelInfo={whitelabelInfo}>
                  {yext.msg('Submit')}
                </LoginSubmitButton>
                <Button
                  size={'xl'}
                  variant={'outline.dominant'}
                  onClick={onCancel}
                  isDisabled={disabled}
                >
                  {yext.msg('Cancel')}
                </Button>
              </VStack>
            </VStack>
          </>
        )}
      </Form>
    </FormControl>
  );
}

document.addEventListener('DOMContentLoaded', () => {
  registerComponentForJsonDataWithUIKit('NewForgotPassword', NewForgotPassword);
});
