import { defineStyleConfig } from "@chakra-ui/react";
import { variantOptions, sizeOptions } from "./themeOptions";

const AssetBadge = defineStyleConfig({
  baseStyle: () => {
    return {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "full",
      color: "components.assetBadge.iconNeutralDominant",
      borderWidth: "sm",
      borderColor: "components.assetBadge.borderWhite",
      backgroundColor: "components.assetBadge.bgGray",
    };
  },
  sizes: {
    [sizeOptions.md]: {
      width: 6,
      height: 6,
    },
    [sizeOptions.lg]: {
      width: 8,
      height: 8,
    },
  },
  variants: {
    [variantOptions.image]: {
      backgroundColor: "components.assetBadge.bgPurple",
    },
    [variantOptions.video]: {
      backgroundColor: "components.assetBadge.bgYellow",
    },
    [variantOptions.link]: {
      backgroundColor: "components.assetBadge.bgGreen",
    },
    [variantOptions.facebook]: {
      backgroundColor: "rgba(25, 119, 242, 1)",
    },
    [variantOptions.linkedin]: {
      backgroundColor: "rgba(0, 102, 153, 1)",
    },
    [variantOptions.list]: {},
    [variantOptions.pdf]: {},
    [variantOptions.word]: {},
    [variantOptions.excel]: {},
    [variantOptions.powerpoint]: {},
    [variantOptions.figma]: {},
    [variantOptions.docs]: {},
    [variantOptions.slides]: {},
    [variantOptions.sheets]: {},
    [variantOptions.forms]: {},
    [variantOptions.x]: {},
    [variantOptions.instagram]: {},
    [variantOptions.tiktok]: {},
    [variantOptions.apple]: {},
    [variantOptions["google-business"]]: {},
    [variantOptions.google]: {},
    [variantOptions.herold]: {},
    [variantOptions.widget]: {},
  },
  defaultProps: {
    size: sizeOptions.md,
  },
});

export default AssetBadge;
