import { defineStyleConfig } from "@chakra-ui/react";
import { variantOptions } from "./themeOptions";
import typography from "../../../clayTheme/foundations/typography";

const Code = defineStyleConfig({
  baseStyle: () => {
    return {
      fontFamily: typography.fonts.mono,
    };
  },
  variants: {
    [variantOptions.neutral]: {
      bg: "surfaces.neutral.strong.resting",
      color: "text.onNeutralStrong",
    },
    [variantOptions.secondary]: {
      bg: "components.badge.bgSecondarySoft",
      color: "components.badge.textSecondary",
    },
    [variantOptions.ghost]: {
      bg: "transparent",
      color: "text.onNeutralStrong",
    },
  },
  defaultProps: {
    variant: variantOptions.neutral,
  },
});

export default Code;
