import React, { useContext } from "react";
import { Box, HStack } from "@chakra-ui/react";
import { Button, ButtonProps } from "../Button";
import { Link } from "../Link";
import { sizeOptions } from "./theme/themeOptions";
import { variantOptions as alertDialogVariantOptions } from "../../components/AlertDialog/theme/themeOptions";
import { AlertDialogContext } from "../../components/AlertDialog/AlertDialogContext";
import { variantOptions as buttonVariantOptions } from "../../components/Button/theme/themeOptions";
import breakpoints from "../../clayTheme/foundations/breakpoints";

interface LinkAction extends Omit<ButtonProps, "variant, size"> {
  label: string;
  href: string;
  hideLabel?: boolean;
}

interface ButtonAction extends Omit<ButtonProps, "variant, size"> {
  label: string;
}

export interface ActionFooterProps {
  size?: keyof typeof sizeOptions;
  primaryAction: ButtonAction;
  secondaryAction?: ButtonAction;
  linkAction?: LinkAction;
}

const linkSizeFromActionFooterSize = (size: keyof typeof sizeOptions) => {
  switch (size) {
    case sizeOptions.xl:
      return "lg";
    case sizeOptions.lg:
      return "md";
    case sizeOptions.md:
      return "sm";
    default:
      return "lg";
  }
};

export default function ActionFooter(props: ActionFooterProps) {
  const { size = "xl", primaryAction, secondaryAction, linkAction } = props;
  const { label: secondaryLabel, ...secondaryActionProps } =
    secondaryAction || {};
  const { label: primaryLabel, ...primaryActionProps } = primaryAction || {};
  const alertDialogVariant = useContext(AlertDialogContext);

  let primaryActionVariant = undefined;
  switch (alertDialogVariant.variant) {
    case alertDialogVariantOptions.neutral:
      primaryActionVariant = buttonVariantOptions["neutral.dominant"];
      break;
    case alertDialogVariantOptions.system:
      primaryActionVariant = buttonVariantOptions.primary;
      break;
    case alertDialogVariantOptions.warning:
    case alertDialogVariantOptions.danger:
      primaryActionVariant = buttonVariantOptions.danger;
      break;
    default:
      primaryActionVariant = buttonVariantOptions.primary;
  }

  return (
    <Box
      mt="auto"
      w="full"
      sx={{
        containerType: "inline-size",
        containerName: "outer",
      }}
    >
      <HStack
        gap={3}
        w="full"
        sx={{
          containerType: "inline-size",
          containerName: "inner",
          [`@container outer (min-width: ${breakpoints.base})`]: {
            "&": { flexDirection: "column" },
          },
          [`@container outer (min-width: ${breakpoints.sm})`]: {
            "&": { flexDirection: "row" },
          },
        }}
      >
        {linkAction && (
          <HStack
            gap={3}
            sx={{
              [`@container inner (min-width: ${breakpoints.base})`]: {
                "&": {
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "unset",
                },
                "& > *": { w: "full" },
              },
              [`@container inner (min-width: ${breakpoints.sm})`]: {
                "&": {
                  w: "inherit",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                },
                "& > *": { w: "unset" },
              },
            }}
          >
            <Link
              palette="primary"
              size={linkSizeFromActionFooterSize(size)}
              href={linkAction.href}
            >
              <span>{linkAction.label}</span>
            </Link>
          </HStack>
        )}
        <HStack
          gap={3}
          sx={{
            [`@container inner (min-width: ${breakpoints.base})`]: {
              "&": {
                w: "full",
                flexDirection: "column",
                justifyContent: "unset",
              },
              "& > *": { w: "full" },
            },
            [`@container inner (min-width: ${breakpoints.sm})`]: {
              "&": {
                w: "inherit",
                flexDirection: "row",
                justifyContent: "flex-end",
              },
              "& > *": { w: "unset" },
            },
          }}
        >
          {secondaryAction && (
            <Button
              size={size}
              variant="outline.soft"
              {...secondaryActionProps}
            >
              {secondaryLabel}
            </Button>
          )}
          {primaryAction && (
            <Button
              size={size}
              variant={primaryActionVariant}
              {...primaryActionProps}
            >
              {primaryLabel}
            </Button>
          )}
        </HStack>
      </HStack>
    </Box>
  );
}
