import { FocusableElement } from "@chakra-ui/utils";
import { UseComboboxReturnValue } from "downshift";
import React, { createContext, useContext } from "react";
import { variantOptions } from "./theme/themeOptions";

interface CommonOption<T> {
  data?: T;
  label: React.ReactNode | string;
  labelPrefix?: React.ReactNode | string;
}
export interface ComboboxSelectOption<T = never> extends CommonOption<T> {
  value: string | number;
  onClick?: never;
  href?: never;
}

export type ComboboxOption<T> =
  | ComboboxActionOption<T>
  | ComboboxSelectOption<T>;

export interface ButtonOption<T> extends CommonOption<T> {
  onClick: () => void;
  href?: never;
  value?: never;
  key: string;
}

export interface LinkOption<T> extends CommonOption<T> {
  href: string;
  onClick?: never;
  value?: never;
}

export type ComboboxActionOption<T> = ButtonOption<T> | LinkOption<T>;

export function isButtonOption<T>(
  option: ComboboxOption<T>,
): option is ButtonOption<T> {
  return (option as ButtonOption<T>).onClick !== undefined;
}
export function isLinkOption<T>(
  option: ComboboxOption<T>,
): option is LinkOption<T> {
  return (option as LinkOption<T>).href !== undefined;
}
export function isSelectOption<T>(
  option: ComboboxOption<T>,
): option is ComboboxSelectOption<T> {
  return (option as ComboboxSelectOption<T>).value !== undefined;
}

export interface ComboboxProps<T extends ComboboxOption<unknown>> {
  options: T[];
  multiple?: boolean;
  // Defaults to string includes on the result of itemToString
  // Should this just be a predicate that gets an individual item?
  filterOptions?: ((inputValue: string) => T[]) | ((inputValue: string) => Promise<T[]>);
  // Used if you want to use the component in controlled mode
  setSelectedOptions?: (items: T[]) => void;
  selectedOptions?: T[];
  // Required by internal logic such as setting aria labels, unless your items are themselves strings
  optionToString?: (item: T | null) => string;
  // Can be used to override default behavior of checking referential equality of selected
  // options compared to available options.
  isEqual?: (a: T, b: T) => boolean;
  initialFocusRef?: React.RefObject<FocusableElement>;
  children: React.ReactNode;
  variant?: keyof typeof variantOptions;
  /**
   * If `true`, the popover will be opened in controlled mode.
   */
  isOpen?: boolean;
  isDisabled?: boolean;
  /**
   * Callback fired when the popover opens
   */
  onOpen?: () => void;
  /**
   * Callback fired when the popover closes
   */
  onClose?: () => void;
  triggerRef?: React.RefObject<HTMLButtonElement>;
  popoverRef?: React.RefObject<HTMLDivElement>;
  searchbarRef?: React.RefObject<HTMLInputElement>;
  overrideSearchInputDetection?: boolean;
  /**
   * If `false`, the popover doesn't close on outside click
   */
  popoverCloseOnBlur?: boolean;
}

type WithRequiredProperty<Type, Key extends keyof Type> = Type & {
  [Property in Key]-?: Type[Property];
};

const ComboboxContext = createContext<any | null>(null);

interface ComboboxContext<
  T extends ComboboxActionOption<any> | ComboboxSelectOption<any>,
> extends WithRequiredProperty<
    ComboboxProps<T>,
    "isOpen" | "onOpen" | "onClose"
  > {
  combobox: UseComboboxReturnValue<T>;
  hasInput: boolean;
}

export const ComboboxProvider = ComboboxContext.Provider;
export function useComboboxContext<
  T extends ComboboxActionOption<any> | ComboboxSelectOption<any>,
>() {
  return useContext<ComboboxContext<T>>(ComboboxContext);
}
