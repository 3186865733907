import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { chakra, HStack, Text, useMultiStyleConfig } from "@chakra-ui/react";
import { cx } from "@chakra-ui/utils";
import {
  DataGrid as SUIDataGrid,
  type DataGridPaginationProps as SUIDataGridPaginationProps,
  type DataGridProps as SUIDataGridProps,
  useDataGridContext,
} from "@saas-ui-pro/react";
import {
  FieldOptions,
  SelectButton,
  SelectList,
  SelectOption,
} from "@saas-ui/react";
import React, { KeyboardEventHandler } from "react";
import {
  FALChevronDown,
  FALChevronUp,
  FALChevronLeft,
  FALChevronRight,
} from "../../icons";
import IconButton, { IconButtonProps } from "../IconButton/IconButton";
import { useTranslation } from "../ManaUIProvider";
import { Select, type SelectProps } from "../Select";
import { DataGridEmptyState } from "./DataGridEmptyState";

export type DataGridPaginationProps = SUIDataGridPaginationProps & {
  paginationOptions?: SelectOption[];
};

export type DataGridLogPaginationProps = DataGridPaginationProps & {
  previousButtonDisabled?: boolean;
  nextButtonDisabled?: boolean;
  pageIndex: number;
  pageSize: number;
  onPageSizeChange: (pageSize: number) => void;
  onPageIndexChange: (pageIndex: number) => void;
};

type DataGridIconProps = Omit<
  SUIDataGridProps<any>["icons"],
  "sortAscending" | "sortDescending"
>;

export type DataGridProps<T> = SUIDataGridProps<
  T extends object ? T : never
> & {
  icons?: DataGridIconProps;
};

type DataGridPaginationButtonProps = Partial<IconButtonProps> & {
  isDisabled?: boolean;
};

const useDefaultPaginationOptions = () => {
  const defaultPaginationOptions: SelectOption[] = [
    { label: `10`, value: "10" },
    { label: `25`, value: "25" },
    { label: `50`, value: "50" },
    { label: `100`, value: "100" },
  ];

  return defaultPaginationOptions;
};

export const DataGrid = <T extends object>(props: DataGridProps<T>) => {
  const translations = useTranslation("SuiDataGrid");
  const defaultPaginationOptions = useDefaultPaginationOptions();

  const suiProps = {
    ...props,
    noResults: props.noResults || DataGridEmptyState,
    initialState: {
      pageSize: Number(defaultPaginationOptions[0].value),
      ...props.initialState,
    },
    translations: {
      translations,
      ...props.translations,
    },
    icons: {
      sortAscending: <FALChevronUp />,
      sortDescending: <FALChevronDown />,
      sort: <FALChevronDown />,
      ...props.icons,
    },
    sx: {
      ...props.sx,
      "& tbody tr:hover": {
        cursor: props.onRowClick ? "pointer" : undefined,
      },
      "& th div": {
        fontSize: "sm",
      },
    },
    className: cx("sui-data-grid", "mjolnir-data-grid", props.className),
  };

  if (props.onRowClick) {
    if (!suiProps.slotProps) {
      suiProps.slotProps = {};
    }

    const existingData = suiProps.slotProps.row ?? {};

    suiProps.slotProps.row = ({ row, table }) => {
      const keyPressHandler: {
        onKeyUp: KeyboardEventHandler<HTMLTableRowElement>;
      } = {
        onKeyUp: (e: React.KeyboardEvent<HTMLTableRowElement>) => {
          if (e.key === "Enter" || e.key === " ") {
            // @ts-expect-error - keyboard event isn't the same as click event, hopefully this won't be an issue in practice
            props.onRowClick?.(row, e);
          }
        },
      };

      if (typeof existingData === "function") {
        const data = existingData({ row, table });
        return { ...data, ...keyPressHandler };
      }

      return {
        ...existingData,
        ...keyPressHandler,
      };
    };
  }

  return <SUIDataGrid {...suiProps} />;
};

const usePaginationSettings = (paginationOptions?: SelectOption[]) => {
  const defaultPaginationOptions = useDefaultPaginationOptions();

  const options = (paginationOptions ?? defaultPaginationOptions).map(
    (option) => {
      return option;
    },
  );

  const isDisabled =
    options.filter((option) => option?.isDisabled).length === options.length;

  return {options, isDisabled};
}

// TODO: eelco is going to be releasing an update that will allow us to not override the next / previous buttons
// and instead just pass in the icons we want to use like we do above. He's also going to change the behavior of the main
// component so it doesn't render it's default internals if you pass children, so we can customize the internals.
export const DataGridPagination = (props: DataGridPaginationProps) => {
  const {
    className,
    onChange,
    children,
    size = "sm",
    variant,
    paginationOptions,
    ...rest
  } = props;
  const { instance } = useDataGridContext();

  const state = instance.getState();

  const {options, isDisabled} = usePaginationSettings(paginationOptions);

  const {
    pagination: { pageIndex, pageSize },
  } = state;

  const styles = useMultiStyleConfig("SuiDataGridPagination", {
    size,
  });

  React.useEffect(() => {
    onChange?.({ pageIndex, pageSize });
  }, [pageIndex, pageSize, onChange]);

  const onPageSizeChange =
    onChange !== undefined
      ? (value: string) => {
        onChange({ pageIndex, pageSize: Number(value) });
      }
      : undefined;

  const onPageIndexChange =
    onChange !== undefined
      ? (newPageIndex: number) => {
        onChange({ pageIndex: newPageIndex, pageSize });
      }
      : undefined;

  return (
    <chakra.div
      className={cx("sui-data-grid__pagination", className)}
      __css={styles.container}
      {...rest}
    >
      {options.length > 1 && <DataGridPageSizeControl
        isDisabled={isDisabled}
        options={options as FieldOptions<SelectOption>}
        onChange={onPageSizeChange}
        value={String(pageSize)}
      />}
      <DataGridPaginationButtons
        currentPage={pageIndex}
        totalPages={instance.getPageCount()}
        onChange={onPageIndexChange}
      />
    </chakra.div>
  );
};

export const DataGridLogPagination = (props: DataGridLogPaginationProps) => {
  const {
    className,
    onChange,
    children,
    size = "sm",
    variant,
    paginationOptions,
    previousButtonDisabled,
    nextButtonDisabled,
    pageIndex,
    pageSize,
    onPageSizeChange,
    onPageIndexChange,
    ...rest
  } = props;
  const {options, isDisabled} = usePaginationSettings(paginationOptions);

  const styles = useMultiStyleConfig("SuiDataGridPagination", {
    size,
  });

  return (
    <chakra.div
      className={cx("sui-data-grid__pagination", className)}
      __css={styles.container}
      {...rest}
    >
      <DataGridPageSizeControl
        isDisabled={isDisabled}
        options={options as FieldOptions<SelectOption>}
        onChange={pageSize => onPageSizeChange(Number(pageSize))}
        value={String(pageSize)}
      />
      <DataGridPaginationButtons
        currentPage={pageIndex}
        onChange={pageIndex => onPageIndexChange(Number(pageIndex))}
        remotePagination={true}
        previousButtonDisabled={previousButtonDisabled}
        nextButtonDisabled={nextButtonDisabled}
      />
    </chakra.div>
  );
};


const DataGridPageSizeControl: React.FC<Partial<SelectProps>> = ({
  ...props
}) => {
  const { instance } = useDataGridContext();
  const showMsg = useTranslation("SuiDataGrid.show");
  const handleChange: typeof props.onChange =
    props.onChange ||
    ((newValue: string) => {
      instance.setPageSize(Number(newValue));
    });

  return (
    <FormControl
      display={"flex"}
      flexDirection={"row"}
      gap={2}
      alignItems={"center"}
    >
      <FormLabel
        m={0}
        color={"text.neutralSoft"}
        textStyle={"md"}
        fontWeight={400}
      >
        {showMsg}
      </FormLabel>
      <Select
        size={"sm"}
        name="pageSize"
        variant={"outline"}
        computePositionOnMount={true}
        {...props}
        onChange={handleChange}
      >
        <SelectList />
        <SelectButton />
      </Select>
    </FormControl>
  );
};

const DataGridPaginationButtons: React.FC<{
  currentPage: number;
  totalPages?: number;
  onChange?: (pageIndex: number) => void;
  remotePagination?: boolean;
  previousButtonDisabled?: boolean;
  nextButtonDisabled?: boolean;
}> = ({ currentPage, totalPages, onChange , remotePagination, previousButtonDisabled, nextButtonDisabled}) => {
  const translations = useTranslation("SuiDataGrid");
  const { instance } = useDataGridContext();
  const { nextPage, previousPage } = instance;

  const onPrevClick =
    onChange !== undefined ? () => onChange(currentPage - 1) : previousPage;
  const onNextClick =
    onChange !== undefined ? () => onChange(currentPage + 1) : nextPage;

  return (
    <HStack ml={"auto"}>
      <DataGridPaginationPreviousButton
        onClick={onPrevClick}
        isDisabled={remotePagination ? previousButtonDisabled : !instance.getCanPreviousPage()} />
      <Text
        whiteSpace={"nowrap"}
        as="div"
        textStyle={"md"}
        color={"text.neutralSoft"}
      >
        {translations.page} {currentPage + 1}
        {remotePagination ? "" : " " + translations.of + " " + (totalPages !== undefined && totalPages > 100 ? " 100+" : totalPages)}
      </Text>
      <DataGridPaginationNextButton
        onClick={onNextClick}
        isDisabled={remotePagination ? nextButtonDisabled : !instance.getCanNextPage()} />
    </HStack>
  );
};

const DataGridPaginationNextButton: React.FC<DataGridPaginationButtonProps> = ({isDisabled, ...props}) => {
  const { translations } = useDataGridContext();

  return (
    <IconButton
      {...props}
      variant={"outline.soft"}
      size={"lg"}
      isDisabled={isDisabled}
      icon={<FALChevronRight />}
      aria-label={translations.nextPage}
      tooltipLabel={translations.nextPage}
    />
  );
};

const DataGridPaginationPreviousButton: React.FC<DataGridPaginationButtonProps> = ({isDisabled, ...props}) => {
  const { translations } = useDataGridContext();

  return (
    <IconButton
      {...props}
      variant={"outline.soft"}
      size={"lg"}
      isDisabled={isDisabled}
      icon={<FALChevronLeft />}
      aria-label={translations.previousPage}
      tooltipLabel={translations.previousPage}
    />
  );
};
