// DO NOT EDIT: autogenerated by brokkr
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck https://github.com/pd4d10/vite-plugin-svgr/issues/91

import React from "react";
import { useColorMode, Icon } from "@chakra-ui/react";

const useIconIsLight = ({ isInverted }) => {
  const colorMode = useColorMode().colorMode;
  return (
    (colorMode === "light" && !isInverted) ||
    (colorMode === "dark" && isInverted)
  );
};
import { default as AdobeAEMLightmode } from "../icons/logo-icons/AdobeAEMLightmode.svg?react";
import { default as AdobeAEMDarkmode } from "../icons/logo-icons/AdobeAEMDarkmode.svg?react";
export const AdobeAEM = (props) => {
  const asIcon = useIconIsLight(props) ? AdobeAEMLightmode : AdobeAEMDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as AdobeAcrobatLightmode } from "../icons/logo-icons/AdobeAcrobatLightmode.svg?react";
import { default as AdobeAcrobatDarkmode } from "../icons/logo-icons/AdobeAcrobatDarkmode.svg?react";
export const AdobeAcrobat = (props) => {
  const asIcon = useIconIsLight(props)
    ? AdobeAcrobatLightmode
    : AdobeAcrobatDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as AdobeAfterEffectsLightmode } from "../icons/logo-icons/AdobeAfterEffectsLightmode.svg?react";
import { default as AdobeAfterEffectsDarkmode } from "../icons/logo-icons/AdobeAfterEffectsDarkmode.svg?react";
export const AdobeAfterEffects = (props) => {
  const asIcon = useIconIsLight(props)
    ? AdobeAfterEffectsLightmode
    : AdobeAfterEffectsDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as AdobeCreativeCloudLightmode } from "../icons/logo-icons/AdobeCreativeCloudLightmode.svg?react";
import { default as AdobeCreativeCloudDarkmode } from "../icons/logo-icons/AdobeCreativeCloudDarkmode.svg?react";
export const AdobeCreativeCloud = (props) => {
  const asIcon = useIconIsLight(props)
    ? AdobeCreativeCloudLightmode
    : AdobeCreativeCloudDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as AdobeIllustratorLightmode } from "../icons/logo-icons/AdobeIllustratorLightmode.svg?react";
import { default as AdobeIllustratorDarkmode } from "../icons/logo-icons/AdobeIllustratorDarkmode.svg?react";
export const AdobeIllustrator = (props) => {
  const asIcon = useIconIsLight(props)
    ? AdobeIllustratorLightmode
    : AdobeIllustratorDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as AdobeInDesignLightmode } from "../icons/logo-icons/AdobeInDesignLightmode.svg?react";
import { default as AdobeInDesignDarkmode } from "../icons/logo-icons/AdobeInDesignDarkmode.svg?react";
export const AdobeInDesign = (props) => {
  const asIcon = useIconIsLight(props)
    ? AdobeInDesignLightmode
    : AdobeInDesignDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as AdobePhotoshopLightmode } from "../icons/logo-icons/AdobePhotoshopLightmode.svg?react";
import { default as AdobePhotoshopDarkmode } from "../icons/logo-icons/AdobePhotoshopDarkmode.svg?react";
export const AdobePhotoshop = (props) => {
  const asIcon = useIconIsLight(props)
    ? AdobePhotoshopLightmode
    : AdobePhotoshopDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as AdobePremiereLightmode } from "../icons/logo-icons/AdobePremiereLightmode.svg?react";
import { default as AdobePremiereDarkmode } from "../icons/logo-icons/AdobePremiereDarkmode.svg?react";
export const AdobePremiere = (props) => {
  const asIcon = useIconIsLight(props)
    ? AdobePremiereLightmode
    : AdobePremiereDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as AdobeLightmode } from "../icons/logo-icons/AdobeLightmode.svg?react";
import { default as AdobeDarkmode } from "../icons/logo-icons/AdobeDarkmode.svg?react";
export const Adobe = (props) => {
  const asIcon = useIconIsLight(props) ? AdobeLightmode : AdobeDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as AirtableLightmode } from "../icons/logo-icons/AirtableLightmode.svg?react";
import { default as AirtableDarkmode } from "../icons/logo-icons/AirtableDarkmode.svg?react";
export const Airtable = (props) => {
  const asIcon = useIconIsLight(props) ? AirtableLightmode : AirtableDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as AppleMapsLightmode } from "../icons/logo-icons/AppleMapsLightmode.svg?react";
import { default as AppleMapsDarkmode } from "../icons/logo-icons/AppleMapsDarkmode.svg?react";
export const AppleMaps = (props) => {
  const asIcon = useIconIsLight(props) ? AppleMapsLightmode : AppleMapsDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as AppleLightmode } from "../icons/logo-icons/AppleLightmode.svg?react";
import { default as AppleDarkmode } from "../icons/logo-icons/AppleDarkmode.svg?react";
export const Apple = (props) => {
  const asIcon = useIconIsLight(props) ? AppleLightmode : AppleDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as AsanaLightmode } from "../icons/logo-icons/AsanaLightmode.svg?react";
import { default as AsanaDarkmode } from "../icons/logo-icons/AsanaDarkmode.svg?react";
export const Asana = (props) => {
  const asIcon = useIconIsLight(props) ? AsanaLightmode : AsanaDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as AssetBankLightmode } from "../icons/logo-icons/AssetBankLightmode.svg?react";
import { default as AssetBankDarkmode } from "../icons/logo-icons/AssetBankDarkmode.svg?react";
export const AssetBank = (props) => {
  const asIcon = useIconIsLight(props) ? AssetBankLightmode : AssetBankDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as BingLightmode } from "../icons/logo-icons/BingLightmode.svg?react";
import { default as BingDarkmode } from "../icons/logo-icons/BingDarkmode.svg?react";
export const Bing = (props) => {
  const asIcon = useIconIsLight(props) ? BingLightmode : BingDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as BizzaboLightmode } from "../icons/logo-icons/BizzaboLightmode.svg?react";
import { default as BizzaboDarkmode } from "../icons/logo-icons/BizzaboDarkmode.svg?react";
export const Bizzabo = (props) => {
  const asIcon = useIconIsLight(props) ? BizzaboLightmode : BizzaboDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as BoxLogoLightmode } from "../icons/logo-icons/BoxLogoLightmode.svg?react";
import { default as BoxLogoDarkmode } from "../icons/logo-icons/BoxLogoDarkmode.svg?react";
export const BoxLogo = (props) => {
  const asIcon = useIconIsLight(props) ? BoxLogoLightmode : BoxLogoDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as BynderLightmode } from "../icons/logo-icons/BynderLightmode.svg?react";
import { default as BynderDarkmode } from "../icons/logo-icons/BynderDarkmode.svg?react";
export const Bynder = (props) => {
  const asIcon = useIconIsLight(props) ? BynderLightmode : BynderDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as ConfluenceLightmode } from "../icons/logo-icons/ConfluenceLightmode.svg?react";
import { default as ConfluenceDarkmode } from "../icons/logo-icons/ConfluenceDarkmode.svg?react";
export const Confluence = (props) => {
  const asIcon = useIconIsLight(props)
    ? ConfluenceLightmode
    : ConfluenceDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as ContentfulLightmode } from "../icons/logo-icons/ContentfulLightmode.svg?react";
import { default as ContentfulDarkmode } from "../icons/logo-icons/ContentfulDarkmode.svg?react";
export const Contentful = (props) => {
  const asIcon = useIconIsLight(props)
    ? ContentfulLightmode
    : ContentfulDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as DatabaseLightmode } from "../icons/logo-icons/DatabaseLightmode.svg?react";
import { default as DatabaseDarkmode } from "../icons/logo-icons/DatabaseDarkmode.svg?react";
export const Database = (props) => {
  const asIcon = useIconIsLight(props) ? DatabaseLightmode : DatabaseDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as DiscordLightmode } from "../icons/logo-icons/DiscordLightmode.svg?react";
import { default as DiscordDarkmode } from "../icons/logo-icons/DiscordDarkmode.svg?react";
export const Discord = (props) => {
  const asIcon = useIconIsLight(props) ? DiscordLightmode : DiscordDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as DropboxLightmode } from "../icons/logo-icons/DropboxLightmode.svg?react";
import { default as DropboxDarkmode } from "../icons/logo-icons/DropboxDarkmode.svg?react";
export const Dropbox = (props) => {
  const asIcon = useIconIsLight(props) ? DropboxLightmode : DropboxDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as DrupalLightmode } from "../icons/logo-icons/DrupalLightmode.svg?react";
import { default as DrupalDarkmode } from "../icons/logo-icons/DrupalDarkmode.svg?react";
export const Drupal = (props) => {
  const asIcon = useIconIsLight(props) ? DrupalLightmode : DrupalDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as EloquaLightmode } from "../icons/logo-icons/EloquaLightmode.svg?react";
import { default as EloquaDarkmode } from "../icons/logo-icons/EloquaDarkmode.svg?react";
export const Eloqua = (props) => {
  const asIcon = useIconIsLight(props) ? EloquaLightmode : EloquaDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as FacebookLightmode } from "../icons/logo-icons/FacebookLightmode.svg?react";
import { default as FacebookDarkmode } from "../icons/logo-icons/FacebookDarkmode.svg?react";
export const Facebook = (props) => {
  const asIcon = useIconIsLight(props) ? FacebookLightmode : FacebookDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as FeedLightmode } from "../icons/logo-icons/FeedLightmode.svg?react";
import { default as FeedDarkmode } from "../icons/logo-icons/FeedDarkmode.svg?react";
export const Feed = (props) => {
  const asIcon = useIconIsLight(props) ? FeedLightmode : FeedDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as FigmaLightmode } from "../icons/logo-icons/FigmaLightmode.svg?react";
import { default as FigmaDarkmode } from "../icons/logo-icons/FigmaDarkmode.svg?react";
export const Figma = (props) => {
  const asIcon = useIconIsLight(props) ? FigmaLightmode : FigmaDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as GitHubLightmode } from "../icons/logo-icons/GitHubLightmode.svg?react";
import { default as GitHubDarkmode } from "../icons/logo-icons/GitHubDarkmode.svg?react";
export const GitHub = (props) => {
  const asIcon = useIconIsLight(props) ? GitHubLightmode : GitHubDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as GoogleBusinessLightmode } from "../icons/logo-icons/GoogleBusinessLightmode.svg?react";
import { default as GoogleBusinessDarkmode } from "../icons/logo-icons/GoogleBusinessDarkmode.svg?react";
export const GoogleBusiness = (props) => {
  const asIcon = useIconIsLight(props)
    ? GoogleBusinessLightmode
    : GoogleBusinessDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as GoogleDocsLightmode } from "../icons/logo-icons/GoogleDocsLightmode.svg?react";
import { default as GoogleDocsDarkmode } from "../icons/logo-icons/GoogleDocsDarkmode.svg?react";
export const GoogleDocs = (props) => {
  const asIcon = useIconIsLight(props)
    ? GoogleDocsLightmode
    : GoogleDocsDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as GoogleDriveLightmode } from "../icons/logo-icons/GoogleDriveLightmode.svg?react";
import { default as GoogleDriveDarkmode } from "../icons/logo-icons/GoogleDriveDarkmode.svg?react";
export const GoogleDrive = (props) => {
  const asIcon = useIconIsLight(props)
    ? GoogleDriveLightmode
    : GoogleDriveDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as GoogleFormsLightmode } from "../icons/logo-icons/GoogleFormsLightmode.svg?react";
import { default as GoogleFormsDarkmode } from "../icons/logo-icons/GoogleFormsDarkmode.svg?react";
export const GoogleForms = (props) => {
  const asIcon = useIconIsLight(props)
    ? GoogleFormsLightmode
    : GoogleFormsDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as GoogleSheetsLightmode } from "../icons/logo-icons/GoogleSheetsLightmode.svg?react";
import { default as GoogleSheetsDarkmode } from "../icons/logo-icons/GoogleSheetsDarkmode.svg?react";
export const GoogleSheets = (props) => {
  const asIcon = useIconIsLight(props)
    ? GoogleSheetsLightmode
    : GoogleSheetsDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as GoogleSlidesLightmode } from "../icons/logo-icons/GoogleSlidesLightmode.svg?react";
import { default as GoogleSlidesDarkmode } from "../icons/logo-icons/GoogleSlidesDarkmode.svg?react";
export const GoogleSlides = (props) => {
  const asIcon = useIconIsLight(props)
    ? GoogleSlidesLightmode
    : GoogleSlidesDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as GoogleLightmode } from "../icons/logo-icons/GoogleLightmode.svg?react";
import { default as GoogleDarkmode } from "../icons/logo-icons/GoogleDarkmode.svg?react";
export const Google = (props) => {
  const asIcon = useIconIsLight(props) ? GoogleLightmode : GoogleDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as GreenhouseLightmode } from "../icons/logo-icons/GreenhouseLightmode.svg?react";
import { default as GreenhouseDarkmode } from "../icons/logo-icons/GreenhouseDarkmode.svg?react";
export const Greenhouse = (props) => {
  const asIcon = useIconIsLight(props)
    ? GreenhouseLightmode
    : GreenhouseDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as HearsayLightmode } from "../icons/logo-icons/HearsayLightmode.svg?react";
import { default as HearsayDarkmode } from "../icons/logo-icons/HearsayDarkmode.svg?react";
export const Hearsay = (props) => {
  const asIcon = useIconIsLight(props) ? HearsayLightmode : HearsayDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as HeroldLightmode } from "../icons/logo-icons/HeroldLightmode.svg?react";
import { default as HeroldDarkmode } from "../icons/logo-icons/HeroldDarkmode.svg?react";
export const Herold = (props) => {
  const asIcon = useIconIsLight(props) ? HeroldLightmode : HeroldDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as HighspotLightmode } from "../icons/logo-icons/HighspotLightmode.svg?react";
import { default as HighspotDarkmode } from "../icons/logo-icons/HighspotDarkmode.svg?react";
export const Highspot = (props) => {
  const asIcon = useIconIsLight(props) ? HighspotLightmode : HighspotDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as HootSuiteLightmode } from "../icons/logo-icons/HootSuiteLightmode.svg?react";
import { default as HootSuiteDarkmode } from "../icons/logo-icons/HootSuiteDarkmode.svg?react";
export const HootSuite = (props) => {
  const asIcon = useIconIsLight(props) ? HootSuiteLightmode : HootSuiteDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as HubspotLightmode } from "../icons/logo-icons/HubspotLightmode.svg?react";
import { default as HubspotDarkmode } from "../icons/logo-icons/HubspotDarkmode.svg?react";
export const Hubspot = (props) => {
  const asIcon = useIconIsLight(props) ? HubspotLightmode : HubspotDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as InstagramFilledLightmode } from "../icons/logo-icons/InstagramFilledLightmode.svg?react";
import { default as InstagramFilledDarkmode } from "../icons/logo-icons/InstagramFilledDarkmode.svg?react";
export const InstagramFilled = (props) => {
  const asIcon = useIconIsLight(props)
    ? InstagramFilledLightmode
    : InstagramFilledDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as InstagramOutlineLightmode } from "../icons/logo-icons/InstagramOutlineLightmode.svg?react";
import { default as InstagramOutlineDarkmode } from "../icons/logo-icons/InstagramOutlineDarkmode.svg?react";
export const InstagramOutline = (props) => {
  const asIcon = useIconIsLight(props)
    ? InstagramOutlineLightmode
    : InstagramOutlineDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as JiraLightmode } from "../icons/logo-icons/JiraLightmode.svg?react";
import { default as JiraDarkmode } from "../icons/logo-icons/JiraDarkmode.svg?react";
export const Jira = (props) => {
  const asIcon = useIconIsLight(props) ? JiraLightmode : JiraDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as JiveLightmode } from "../icons/logo-icons/JiveLightmode.svg?react";
import { default as JiveDarkmode } from "../icons/logo-icons/JiveDarkmode.svg?react";
export const Jive = (props) => {
  const asIcon = useIconIsLight(props) ? JiveLightmode : JiveDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as LinkedinLightmode } from "../icons/logo-icons/LinkedinLightmode.svg?react";
import { default as LinkedinDarkmode } from "../icons/logo-icons/LinkedinDarkmode.svg?react";
export const Linkedin = (props) => {
  const asIcon = useIconIsLight(props) ? LinkedinLightmode : LinkedinDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as MSExcelLightmode } from "../icons/logo-icons/MSExcelLightmode.svg?react";
import { default as MSExcelDarkmode } from "../icons/logo-icons/MSExcelDarkmode.svg?react";
export const MSExcel = (props) => {
  const asIcon = useIconIsLight(props) ? MSExcelLightmode : MSExcelDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as MSOffice365Lightmode } from "../icons/logo-icons/MSOffice365Lightmode.svg?react";
import { default as MSOffice365Darkmode } from "../icons/logo-icons/MSOffice365Darkmode.svg?react";
export const MSOffice365 = (props) => {
  const asIcon = useIconIsLight(props)
    ? MSOffice365Lightmode
    : MSOffice365Darkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as MSOutlookLightmode } from "../icons/logo-icons/MSOutlookLightmode.svg?react";
import { default as MSOutlookDarkmode } from "../icons/logo-icons/MSOutlookDarkmode.svg?react";
export const MSOutlook = (props) => {
  const asIcon = useIconIsLight(props) ? MSOutlookLightmode : MSOutlookDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as MSPowerpointLightmode } from "../icons/logo-icons/MSPowerpointLightmode.svg?react";
import { default as MSPowerpointDarkmode } from "../icons/logo-icons/MSPowerpointDarkmode.svg?react";
export const MSPowerpoint = (props) => {
  const asIcon = useIconIsLight(props)
    ? MSPowerpointLightmode
    : MSPowerpointDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as MSTeamLightmode } from "../icons/logo-icons/MSTeamLightmode.svg?react";
import { default as MSTeamDarkmode } from "../icons/logo-icons/MSTeamDarkmode.svg?react";
export const MSTeam = (props) => {
  const asIcon = useIconIsLight(props) ? MSTeamLightmode : MSTeamDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as MSWordLightmode } from "../icons/logo-icons/MSWordLightmode.svg?react";
import { default as MSWordDarkmode } from "../icons/logo-icons/MSWordDarkmode.svg?react";
export const MSWord = (props) => {
  const asIcon = useIconIsLight(props) ? MSWordLightmode : MSWordDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as MagnetoLightmode } from "../icons/logo-icons/MagnetoLightmode.svg?react";
import { default as MagnetoDarkmode } from "../icons/logo-icons/MagnetoDarkmode.svg?react";
export const Magneto = (props) => {
  const asIcon = useIconIsLight(props) ? MagnetoLightmode : MagnetoDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as MarketoLightmode } from "../icons/logo-icons/MarketoLightmode.svg?react";
import { default as MarketoDarkmode } from "../icons/logo-icons/MarketoDarkmode.svg?react";
export const Marketo = (props) => {
  const asIcon = useIconIsLight(props) ? MarketoLightmode : MarketoDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as MicrosoftLightmode } from "../icons/logo-icons/MicrosoftLightmode.svg?react";
import { default as MicrosoftDarkmode } from "../icons/logo-icons/MicrosoftDarkmode.svg?react";
export const Microsoft = (props) => {
  const asIcon = useIconIsLight(props) ? MicrosoftLightmode : MicrosoftDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as NotionLightmode } from "../icons/logo-icons/NotionLightmode.svg?react";
import { default as NotionDarkmode } from "../icons/logo-icons/NotionDarkmode.svg?react";
export const Notion = (props) => {
  const asIcon = useIconIsLight(props) ? NotionLightmode : NotionDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as OnedriveLightmode } from "../icons/logo-icons/OnedriveLightmode.svg?react";
import { default as OnedriveDarkmode } from "../icons/logo-icons/OnedriveDarkmode.svg?react";
export const Onedrive = (props) => {
  const asIcon = useIconIsLight(props) ? OnedriveLightmode : OnedriveDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as OpenTableLightmode } from "../icons/logo-icons/OpenTableLightmode.svg?react";
import { default as OpenTableDarkmode } from "../icons/logo-icons/OpenTableDarkmode.svg?react";
export const OpenTable = (props) => {
  const asIcon = useIconIsLight(props) ? OpenTableLightmode : OpenTableDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as OutreachLightmode } from "../icons/logo-icons/OutreachLightmode.svg?react";
import { default as OutreachDarkmode } from "../icons/logo-icons/OutreachDarkmode.svg?react";
export const Outreach = (props) => {
  const asIcon = useIconIsLight(props) ? OutreachLightmode : OutreachDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as PinterestLightmode } from "../icons/logo-icons/PinterestLightmode.svg?react";
import { default as PinterestDarkmode } from "../icons/logo-icons/PinterestDarkmode.svg?react";
export const Pinterest = (props) => {
  const asIcon = useIconIsLight(props) ? PinterestLightmode : PinterestDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as RedditLightmode } from "../icons/logo-icons/RedditLightmode.svg?react";
import { default as RedditDarkmode } from "../icons/logo-icons/RedditDarkmode.svg?react";
export const Reddit = (props) => {
  const asIcon = useIconIsLight(props) ? RedditLightmode : RedditDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as SalesforceLightmode } from "../icons/logo-icons/SalesforceLightmode.svg?react";
import { default as SalesforceDarkmode } from "../icons/logo-icons/SalesforceDarkmode.svg?react";
export const Salesforce = (props) => {
  const asIcon = useIconIsLight(props)
    ? SalesforceLightmode
    : SalesforceDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as SeismicLightmode } from "../icons/logo-icons/SeismicLightmode.svg?react";
import { default as SeismicDarkmode } from "../icons/logo-icons/SeismicDarkmode.svg?react";
export const Seismic = (props) => {
  const asIcon = useIconIsLight(props) ? SeismicLightmode : SeismicDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as ShopifyLightmode } from "../icons/logo-icons/ShopifyLightmode.svg?react";
import { default as ShopifyDarkmode } from "../icons/logo-icons/ShopifyDarkmode.svg?react";
export const Shopify = (props) => {
  const asIcon = useIconIsLight(props) ? ShopifyLightmode : ShopifyDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as ShowpadLightmode } from "../icons/logo-icons/ShowpadLightmode.svg?react";
import { default as ShowpadDarkmode } from "../icons/logo-icons/ShowpadDarkmode.svg?react";
export const Showpad = (props) => {
  const asIcon = useIconIsLight(props) ? ShowpadLightmode : ShowpadDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as SitecoreLightmode } from "../icons/logo-icons/SitecoreLightmode.svg?react";
import { default as SitecoreDarkmode } from "../icons/logo-icons/SitecoreDarkmode.svg?react";
export const Sitecore = (props) => {
  const asIcon = useIconIsLight(props) ? SitecoreLightmode : SitecoreDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as SlackLightmode } from "../icons/logo-icons/SlackLightmode.svg?react";
import { default as SlackDarkmode } from "../icons/logo-icons/SlackDarkmode.svg?react";
export const Slack = (props) => {
  const asIcon = useIconIsLight(props) ? SlackLightmode : SlackDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as SociabbbleLightmode } from "../icons/logo-icons/SociabbbleLightmode.svg?react";
import { default as SociabbbleDarkmode } from "../icons/logo-icons/SociabbbleDarkmode.svg?react";
export const Sociabbble = (props) => {
  const asIcon = useIconIsLight(props)
    ? SociabbbleLightmode
    : SociabbbleDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as SpotifyLightmode } from "../icons/logo-icons/SpotifyLightmode.svg?react";
import { default as SpotifyDarkmode } from "../icons/logo-icons/SpotifyDarkmode.svg?react";
export const Spotify = (props) => {
  const asIcon = useIconIsLight(props) ? SpotifyLightmode : SpotifyDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as SpredfastLightmode } from "../icons/logo-icons/SpredfastLightmode.svg?react";
import { default as SpredfastDarkmode } from "../icons/logo-icons/SpredfastDarkmode.svg?react";
export const Spredfast = (props) => {
  const asIcon = useIconIsLight(props) ? SpredfastLightmode : SpredfastDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as SprinklrLightmode } from "../icons/logo-icons/SprinklrLightmode.svg?react";
import { default as SprinklrDarkmode } from "../icons/logo-icons/SprinklrDarkmode.svg?react";
export const Sprinklr = (props) => {
  const asIcon = useIconIsLight(props) ? SprinklrLightmode : SprinklrDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as SquareLogoLightmode } from "../icons/logo-icons/SquareLightmode.svg?react";
import { default as SquareLogoDarkmode } from "../icons/logo-icons/SquareDarkmode.svg?react";
export const SquareLogo = (props) => {
  const asIcon = useIconIsLight(props)
    ? SquareLogoLightmode
    : SquareLogoDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as SquarespaceLightmode } from "../icons/logo-icons/SquarespaceLightmode.svg?react";
import { default as SquarespaceDarkmode } from "../icons/logo-icons/SquarespaceDarkmode.svg?react";
export const Squarespace = (props) => {
  const asIcon = useIconIsLight(props)
    ? SquarespaceLightmode
    : SquarespaceDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as TikTokLightmode } from "../icons/logo-icons/TikTokLightmode.svg?react";
import { default as TikTokDarkmode } from "../icons/logo-icons/TikTokDarkmode.svg?react";
export const TikTok = (props) => {
  const asIcon = useIconIsLight(props) ? TikTokLightmode : TikTokDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as TwitterLightmode } from "../icons/logo-icons/TwitterLightmode.svg?react";
import { default as TwitterDarkmode } from "../icons/logo-icons/TwitterDarkmode.svg?react";
export const Twitter = (props) => {
  const asIcon = useIconIsLight(props) ? TwitterLightmode : TwitterDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as VeevaLightmode } from "../icons/logo-icons/VeevaLightmode.svg?react";
import { default as VeevaDarkmode } from "../icons/logo-icons/VeevaDarkmode.svg?react";
export const Veeva = (props) => {
  const asIcon = useIconIsLight(props) ? VeevaLightmode : VeevaDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as VimeoLightmode } from "../icons/logo-icons/VimeoLightmode.svg?react";
import { default as VimeoDarkmode } from "../icons/logo-icons/VimeoDarkmode.svg?react";
export const Vimeo = (props) => {
  const asIcon = useIconIsLight(props) ? VimeoLightmode : VimeoDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as WebHookLightmode } from "../icons/logo-icons/WebHookLightmode.svg?react";
import { default as WebHookDarkmode } from "../icons/logo-icons/WebHookDarkmode.svg?react";
export const WebHook = (props) => {
  const asIcon = useIconIsLight(props) ? WebHookLightmode : WebHookDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as WixLightmode } from "../icons/logo-icons/WixLightmode.svg?react";
import { default as WixDarkmode } from "../icons/logo-icons/WixDarkmode.svg?react";
export const Wix = (props) => {
  const asIcon = useIconIsLight(props) ? WixLightmode : WixDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as WordpressLightmode } from "../icons/logo-icons/WordpressLightmode.svg?react";
import { default as WordpressDarkmode } from "../icons/logo-icons/WordpressDarkmode.svg?react";
export const Wordpress = (props) => {
  const asIcon = useIconIsLight(props) ? WordpressLightmode : WordpressDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as XLightmode } from "../icons/logo-icons/XLightmode.svg?react";
import { default as XDarkmode } from "../icons/logo-icons/XDarkmode.svg?react";
export const X = (props) => {
  const asIcon = useIconIsLight(props) ? XLightmode : XDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as YelpLightmode } from "../icons/logo-icons/YelpLightmode.svg?react";
import { default as YelpDarkmode } from "../icons/logo-icons/YelpDarkmode.svg?react";
export const Yelp = (props) => {
  const asIcon = useIconIsLight(props) ? YelpLightmode : YelpDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as YextLightmode } from "../icons/logo-icons/YextLightmode.svg?react";
import { default as YextDarkmode } from "../icons/logo-icons/YextDarkmode.svg?react";
export const Yext = (props) => {
  const asIcon = useIconIsLight(props) ? YextLightmode : YextDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as YouTubeLightmode } from "../icons/logo-icons/YouTubeLightmode.svg?react";
import { default as YouTubeDarkmode } from "../icons/logo-icons/YouTubeDarkmode.svg?react";
export const YouTube = (props) => {
  const asIcon = useIconIsLight(props) ? YouTubeLightmode : YouTubeDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as ZendeskLightmode } from "../icons/logo-icons/ZendeskLightmode.svg?react";
import { default as ZendeskDarkmode } from "../icons/logo-icons/ZendeskDarkmode.svg?react";
export const Zendesk = (props) => {
  const asIcon = useIconIsLight(props) ? ZendeskLightmode : ZendeskDarkmode;
  return <Icon as={asIcon} {...props} />;
};
import { default as ZoomLightmode } from "../icons/logo-icons/ZoomLightmode.svg?react";
import { default as ZoomDarkmode } from "../icons/logo-icons/ZoomDarkmode.svg?react";
export const Zoom = (props) => {
  const asIcon = useIconIsLight(props) ? ZoomLightmode : ZoomDarkmode;
  return <Icon as={asIcon} {...props} />;
};
