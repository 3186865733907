import { progressAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { sizeOptions } from "./themeOptions";

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(progressAnatomy.keys);

const sizes = {
  [sizeOptions.xs]: definePartsStyle({
    track: { height: 1 },
    filledTrack: { height: 1 },
  }),
  [sizeOptions.sm]: definePartsStyle({
    track: { height: 2 },
    filledTrack: { height: 2 },
  }),
};

const baseStyle = definePartsStyle(() => {
  return {
    track: {
      bg: "surfaces.neutral.strong.resting",
    },
    filledTrack: {
      bg: "surfaces.primary.strong.resting",
      transitionProperty: "width",
      transitionDuration: "0.2s",
      transitionTimingFunction: "ease-in-out",
    },
  };
});

const Progress: ReturnType<typeof defineMultiStyleConfig> =
  defineMultiStyleConfig({
    baseStyle,
    sizes,
    defaultProps: {
      size: sizeOptions.sm,
    },
  });

export default Progress;
