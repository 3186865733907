import { defineStyleConfig } from "@chakra-ui/react";
import { variantOptions } from "./themeOptions";

const InboxRightPanel = defineStyleConfig({
  baseStyle: () => ({
    ps: [5, 5, 4, 4, 4],
    pe: [5, 5, 8, 8, 10],
    py: 6,
    w: "full",
    h: "full",
    display: 'flex',
    flexDirection: "column",
  }),
  variants: {
    [variantOptions.white]: () => {
      return {
        backgroundColor: 'surfaces.lifted.white',
      };
    },
    [variantOptions.soft]: () => {
      return {
        backgroundColor: 'surfaces.lifted.soft',
      };
    },
    [variantOptions.strong]: () => {
      return {
        backgroundColor: 'surfaces.lifted.strong',
      };
    },
  },
  defaultProps: {
    variant: variantOptions.white,
  },
});

export default InboxRightPanel;
