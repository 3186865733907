import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from "@chakra-ui/styled-system";
import { sizeOptions } from "./themeOptions";

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(["input", "reset"]);

const baseStyle = definePartsStyle({
  input: defineStyle({
    borderRadius: "full",
  }),
  reset: defineStyle({}),
});

const sizes = {
  [sizeOptions.lg]: definePartsStyle({
    input: {
      height: 8,
    },
  }),
  [sizeOptions.xl]: definePartsStyle({
    input: {
      height: 10,
    },
  }),
};

const SearchInput: ReturnType<typeof defineMultiStyleConfig> =
  defineMultiStyleConfig({
    baseStyle,
    sizes,
  });

export default SearchInput;
