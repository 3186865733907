import React from "react";
import { Text, BreadcrumbLinkProps, BreadcrumbLink } from "@chakra-ui/react";
import { FALHouse } from "../../icons";
import { cx } from "@chakra-ui/utils";
import { useTranslation } from "../ManaUIProvider";

export interface BreadcrumbHomeProps extends BreadcrumbLinkProps {}

export function BreadcrumbHome(props: BreadcrumbHomeProps) {
  const { className, ...rest } = props;

  const cls = cx("chakra-breadcrumb-home", className);
  const homeTranslation = useTranslation("Breadcrumb.home");

  return (
    <BreadcrumbLink
      display="flex"
      alignItems="center"
      className={cls}
      {...rest}
    >
      <FALHouse />{" "}
      <Text className="chakra-breadcrumb-home-text" ms={3}>
        {homeTranslation}
      </Text>
    </BreadcrumbLink>
  );
}
