import React, { forwardRef } from "react";
import {
  Box,
  BoxProps,
  Textarea as ChakraTextarea,
  TextareaProps as ChakraTextareaProps,
  useFormControlProps,
  useMultiStyleConfig,
  useMergeRefs,
} from "@chakra-ui/react";
import { variantOptions } from "../../components/Textarea/theme/themeOptions";
import { TextareaContextProvider } from "./TextareaContextProvider";
import { TextareaAPI, useTextareaContext } from "./TextareaContext";

export type TextareaProps = Omit<
  ChakraTextareaProps,
  "variant" | "onChange" | "value" | "defaultValue"
> &
  Pick<TextareaAPI, "onChange" | "value" | "defaultValue"> & {
    textareaToolbar?: React.ReactElement;
    variant?: keyof typeof variantOptions;
    isDisabled?: boolean;
    isInvalid?: boolean;
    maxLength?: number;
    containerProps?: BoxProps;
  };

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  function ForwardedTextarea(props: TextareaProps, ref) {
    const { name, value, defaultValue, onChange, ...rest } = props;

    return (
      <TextareaContextProvider
        defaultValue={defaultValue}
        value={value}
        name={name}
        onChange={onChange}
      >
        <TextareaContent {...rest} name={name} ref={ref} />
      </TextareaContextProvider>
    );
  },
);

const TextareaContent = forwardRef<HTMLTextAreaElement, TextareaProps>(
  function ForwardedTextareaContent(props: TextareaProps, _ref) {
    const { textareaToolbar, containerProps, variant, maxLength, ...rest } =
      props;

    // formRest will contain all of the props if you pass in props, but
    // the type definition doesn't include the custom ones and so it will
    // inadvertantly pass them to the DOM unless it's done this way.
    const { isDisabled, isInvalid, isReadOnly, isRequired, ...formRest } =
      useFormControlProps(rest);

    const { onChange, value, ref: contextRef } = useTextareaContext();
    const ref = useMergeRefs(_ref, contextRef);

    const styles = useMultiStyleConfig("Textarea", {
      isDisabled,
      isInvalid,
      isRequired,
      variant,
    });

    return (
      <Box
        __css={styles.container}
        // the chakra generated selectors rely simply on the presence of the data attributes
        // unless they are undefined, they will be added to the DOM
        // and the styles will be applied.
        data-disabled={isDisabled ? true : undefined}
        data-invalid={isInvalid ? true : undefined}
        data-readonly={isReadOnly ? true : undefined}
        data-required={isRequired ? true : undefined}
        {...containerProps}
      >
        <React.Fragment>
          <ChakraTextarea
            __css={styles.textarea}
            value={value}
            onChange={(e) => {
              onChange && onChange(e);
            }}
            ref={ref}
            isDisabled={isDisabled}
            isInvalid={isInvalid}
            isReadOnly={isReadOnly}
            isRequired={isRequired}
            {...formRest}
          />
          {textareaToolbar && (
            <Box py={3} px={2.5}>
              {textareaToolbar}
            </Box>
          )}
        </React.Fragment>
      </Box>
    );
  },
);
