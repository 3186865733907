import { Button } from "@chakra-ui/react";
import { FALCheck } from "../../icons";
import React from "react";
import { QuickFilterItem, useAdvancedFiltersContext } from "./FiltersProvider";

interface QuickFilterProps {
  id: string;
  filter: QuickFilterItem;
}
export const QuickFilter = (props: QuickFilterProps) => {
  const { id } = props;
  const ctx = useAdvancedFiltersContext();

  const isActive = !!ctx.filters.quick.filter((f) => f.key === id)?.length;
  const variant = isActive ? "secondary" : "outline.soft";

  const handleToggle = () => {
    const operator = props.filter.defaultOperator;
    if (isActive) {
      ctx.setBasicFilter(id, []);
    } else {
      // @ts-expect-error filter operator types don't support generic options
      ctx.setBasicFilter(id, [{ ...props.filter, operator }]);
    }
  };

  return (
    <Button size={"lg"} variant={variant} onClick={handleToggle} gap={1.5}>
      {isActive && <FALCheck />}
      {props.filter.label}
    </Button>
  );
};
