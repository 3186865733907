import baseStyle from "./baseStyle";
import variants from "./variants";
import { variantOptions, paletteOptions } from "./themeOptions";

export const defaultProps = {
  variant: variantOptions.solid,
  colorScheme: paletteOptions.primary,
};

const theme = {
  ...baseStyle,
  ...variants,
  defaultProps,
};

export default theme;
