import React from "react";
import {
  chakra,
  AccordionItem as ChakraAccordionItem,
  AccordionItemProps,
} from "@chakra-ui/react";

export function AccordionItem(props: AccordionItemProps) {
  const { children, ...rest } = props;

  return (
    <ChakraAccordionItem {...rest}>
      {({ isExpanded, isDisabled }) => (
        <chakra.div className={isExpanded ? "is-expanded" : undefined}>
          {typeof children === "function"
            ? children({ isExpanded, isDisabled })
            : children}
        </chakra.div>
      )}
    </ChakraAccordionItem>
  );
}
