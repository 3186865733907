import React, {createContext, useEffect, useMemo, useRef, useState} from "react";
import {Box, ChakraProps, ThemingProps, useMultiStyleConfig,} from "@chakra-ui/react";

import {GoogleMaps, Map as MapType, MapOptions, MapProvider,} from "@yext/components-tsx-maps";

import {Coordinate as MapCoordinate, GeoBounds, Projection, Unit} from "@yext/components-tsx-geo";

import {snazzyMapOptions} from "./Snazzy";
import Marker, {MarkerProps} from "./Marker";
import {CSSObject} from "@emotion/react";

export interface Coordinate {
  latitude: number;
  longitude: number;
}

export const MapContext = createContext<MapContextType | null>(null);

export interface MapContextType {
  map: MapType;
  provider: MapProvider;
}

const defaultPadding: Padding = {bottom: 50, left: 50, right: 50, top: 50};

function getPadding(padding: number | Padding | undefined): Padding {
  if (typeof padding === "number") {
    return Number.isNaN(padding)
        ? defaultPadding
        : {bottom: padding, left: padding, right: padding, top: padding};
  } else if (padding === undefined) {
    return defaultPadding;
  }
  return padding;
}

export interface Padding {
  bottom: number | (() => number);
  left: number | (() => number);
  right: number | (() => number);
  top: number | (() => number);
}

const googleMapDefaultApiKey: string = 'AIzaSyBWYfW02A7CVs2bYSUNpX98EKDtkRX5lB4';

export interface MapProps extends ChakraProps, ThemingProps<"Map"> {
  defaultCenter: Coordinate;
  defaultZoom: number;
  apiKey?: string;
  singlePinZoom?: number;
  bounds?: Coordinate[];
  coordinatePadding?: number | Padding;
  className?: string;
  children?: any;
  controlEnabled?: boolean;
  panHandler?: (previousBounds: GeoBounds, currentBounds: GeoBounds) => void;
  panStartHandler?: (currentBounds: GeoBounds) => void;
  provider?: MapProvider;
  providerOptions?: { [key: string]: any };
  clusterEnabled?: boolean;
  clusterRadius?: number;
  markerComponent: React.ElementType<MarkerProps>;
  clusterComponent: React.ElementType<MarkerProps>;

}

export default function Map(props: MapProps): React.JSX.Element {
  const apiKey = props.apiKey || googleMapDefaultApiKey;
  const provider = props.provider || GoogleMaps;

  const wrapper = useRef(null);

  const [center, setCenter] = useState(props.defaultCenter);
  const [loaded, setLoaded] = useState(false);
  const [map, setMap] = useState<MapType>();
  const [zoom, setZoom] = useState(props.defaultZoom);
  const [clusteredMarkers, setClusteredMarkers] = useState<React.ReactElement<MarkerProps>[]>([]);
  const [nonClusteredMarkers, setNonClusteredMarkers] = useState<React.ReactElement<MarkerProps>[]>([]);

  const shouldCluster = props.clusterEnabled || false;
  const MarkerComponent = props.markerComponent || Marker;
  const ClusterComponent = props.clusterComponent || Marker;
  const clusterRadius = props.clusterRadius || 50;

  // On map move
  useEffect(() => {
    if (!loaded || !map) {
      return;
    }

    setZoom(map.getZoom());
  }, [center, loaded, map]);

  // On bounds change / init
  useEffect(() => {
    if (!props.bounds || !loaded || !map) {
      return;
    }

    const coordinates = props.bounds.map((bound) => new MapCoordinate(bound));
    map.fitCoordinates(coordinates);
  }, [loaded, map, props.bounds]);

  // On map provider load
  useEffect(() => {
    if (!loaded || map) {
      return;
    }

    // Update center on map move
    const _panHandler = (previous: GeoBounds, current: GeoBounds) => {
      if (props.panHandler) {
        props.panHandler(previous, current);
      }
      setCenter(current.getCenter());
    };

    const _panStartHandler = (current: GeoBounds) => {
      if (props.panStartHandler) {
        props.panStartHandler(current);
      }
    };

    const _providerOptions = {
      ...props.providerOptions,
      // our snazzyMaps styling only applies to GoogleMaps
      ...(provider === GoogleMaps ? snazzyMapOptions : {}),
      defaultZoom: props.defaultZoom,
    };

    const mapOptions = new MapOptions()
        .withControlEnabled(props.controlEnabled === true)
        .withDefaultCenter(center)
        .withDefaultZoom(zoom)
        .withPadding(getPadding(props.coordinatePadding))
        .withPanHandler(_panHandler)
        .withPanStartHandler(_panStartHandler)
        .withProvider(provider)
        .withProviderOptions(_providerOptions)
        .withSinglePinZoom(props.singlePinZoom)
        .withWrapper(wrapper.current)
        .build();

    setMap(mapOptions);
  }, [
    center,
    loaded,
    map,
    props,
    props.controlEnabled,
    props.padding,
    props.panStartHandler,
    props.provider,
    props.providerOptions,
    props.singlePinZoom,
    provider,
    zoom,
  ]);

  function extractMarkers(children: React.ReactNode): React.ReactElement<MarkerProps>[] {
    const markers: React.ReactElement<MarkerProps>[] = [];
    React.Children.forEach(children, child => {
      if (React.isValidElement(child) && child.props.coordinate) {
        markers.push(child as React.ReactElement<MarkerProps>);
      }
    });
    return markers;
  }

  function clusterMarkers(markers: React.ReactElement<MarkerProps>[], zoom: number, clusterRadius: number) {
    const clusterRadiusRadians = (clusterRadius * Math.PI) / 2 ** (zoom + 7);
    const markersInRadius = markers.map((_, index) => [index]);
    const markerClusters = [];

    // Calculate the distances of each marker to each other.
    markers.forEach((marker, index) => {
      for (let otherIndex = index; otherIndex < markers.length; otherIndex++) {
        if (otherIndex !== index) {
          const distance = new MapCoordinate(
              marker.props.coordinate).distanceTo(new MapCoordinate(markers[otherIndex].props.coordinate), Unit.RADIAN, Projection.MERCATOR);

          if (distance <= clusterRadiusRadians) {
            markersInRadius[index].push(otherIndex);
            markersInRadius[otherIndex].push(index);
          }
        }
      }
    });


    // Loop until there are no markers left to cluster
    let maxCount = 1;
    while (maxCount) {
      maxCount = 0;
      let chosenIndex = 0;

      // Find the marker with the most other marker within radius
      markersInRadius.forEach((markerGroup, index) => {
        if (markerGroup.length > maxCount) {
          maxCount = markerGroup.length;
          chosenIndex = index;
        }
      });

      // If there are no more markers within clustering radius of another marker, break
      if (!maxCount) {
        break;
      }

      // Add markers to a new cluster, and remove them from markersInRadius
      const chosenMarkers = markersInRadius[chosenIndex];
      const cluster = [];

      markersInRadius[chosenIndex] = [];

      for (const index of chosenMarkers) {
        const markerGroup = markersInRadius[index];

        // Add the marker to this cluster and remove it from consideration for other clusters
        cluster.push(markers[index]);
        markersInRadius[index] = [];
        markerGroup.forEach((otherIndex) =>
            markersInRadius[otherIndex].splice(
                markersInRadius[otherIndex].indexOf(index),
                1
            )
        );
      }

      markerClusters.push(cluster);
    }

    return markerClusters;
  }

  const memoizedClusters = useMemo(() => {
    if (!map || !shouldCluster) return [];
    const markers = extractMarkers(props.children).filter(marker =>
        map.getBounds().contains(new MapCoordinate(marker.props.coordinate)));
    return clusterMarkers(markers, zoom, clusterRadius);
  }, [props.children, map, shouldCluster, zoom, clusterRadius, center]);


  // Update clusters on map events
  useEffect(() => {
    if (!loaded || !map || !shouldCluster) {
      return;
    }

    const updateClusters = () => {
      const nonClustered: React.ReactElement<MarkerProps>[] = [];
      const clustered: React.ReactElement<MarkerProps>[] = [];

      memoizedClusters?.forEach((cluster) => {
        // Add markers back to map if they are in a cluster of 1.
        if (cluster.length === 1) {
          const markerId = `${cluster[0].props.coordinate.latitude}-${cluster[0].props.coordinate.longitude}`;
          nonClustered.push(
              <MarkerComponent
                  key={markerId}
                  {...cluster[0].props}
              />);
        }
        if (cluster.length > 1) {
          // Calculate center of all markers in the cluster.
          // Used to set the coordinate of the marker as well as generate a unique id.
          const clusterCenter: Coordinate = GeoBounds.fit(
              cluster.map((marker) => new MapCoordinate(marker.props.coordinate))
          ).getCenter(Projection.MERCATOR);

          const clusterId = `${clusterCenter.latitude}-${clusterCenter.longitude}-${cluster.length}`;

          // Add cluster marker to array to be rendered.
          clustered.push(<ClusterComponent
              coordinate={clusterCenter}
              id={clusterId}
              variant="cluster"
              key={clusterId}
              clusterText={cluster.length.toString()}
              onClick={() =>
                  map.fitCoordinates(
                      cluster.map((marker) => new MapCoordinate(marker.props.coordinate)),
                      true,
                      Infinity
                  )
              }
          />);
        }
      });

      setNonClusteredMarkers(nonClustered);
      setClusteredMarkers(clustered);
    };

    updateClusters();

  }, [loaded, map, props.children, zoom, center, shouldCluster, clusterRadius]);

  // On mount
  useEffect(() => {
    if (loaded || map || !wrapper.current) {
      return;
    }

    provider
        .load(apiKey, {
          ...props.providerOptions,
        })
        .then(() => setLoaded(true));
  }, [loaded, map, apiKey, props.providerOptions, provider]);

  const styles = useMultiStyleConfig("Map", {...props});

  // hide the screen-reader text element for humans
  const screenReaderTextStyle: CSSObject = {
    "& .sr-only": {
      border: 0,
      "clip-path": "rect(0, 0, 0, 0)",
      height: "1px",
      width: "1px",
      margin: "-1px",
      padding: "0px",
      overflow: "hidden",
      "white-space": "nowrap",
      position: "absolute",
    },
  };

  function getClusteredChildren() {
    return (
        <>
          {nonClusteredMarkers}
          {clusteredMarkers}
        </>
    );
  }

  const children = shouldCluster ? getClusteredChildren() : props.children;

  return (
      <Box
          __css={styles.map}
          css={{...screenReaderTextStyle}}
          className={props.className}
          ref={wrapper}
      >
        {map && (
            <MapContext.Provider value={{map, provider}}>
              {children}
            </MapContext.Provider>
        )}
      </Box>
  );
}
