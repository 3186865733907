import React from "react";
import {
  ToggleButtonGroup as SaasToggleButtonGroup,
  ToggleButtonGroupProps,
} from "@saas-ui-pro/react";
import { sizeOptions } from "./theme/themeOptions";

type Value = string | number;

type Type = "checkbox" | "radio";

export const ToggleButtonGroup = <
  TValue extends Value,
  TType extends Type = Type,
>(
  props: ToggleButtonGroupProps<TValue, TType>,
) => {
  const size = props.size || sizeOptions.md;
  const { variant = "neutral.strong" } = props;

  const mappedVariant = variant === "outline.soft" ? "toggleOutline" : "toggle";

  const padding =
    variant === "outline.soft"
      ? 0
      : {
          [sizeOptions.md]: "0.1875rem",
          [sizeOptions.lg]: 1,
          [sizeOptions.xl]: 1.5,
          [sizeOptions["2xl"]]: 2,
        }[size.toString()];

  const height = {
    [sizeOptions.md]: 6,
    [sizeOptions.lg]: 8,
    [sizeOptions.xl]: 10,
    [sizeOptions["2xl"]]: 12,
  }[size.toString()];

  const gap =
    variant === "outline.soft"
      ? 0
      : {
          [sizeOptions.md]: 1.5,
          [sizeOptions.lg]: 1.5,
          [sizeOptions.xl]: 2,
          [sizeOptions["2xl"]]: 2,
        }[size.toString()];

  const bg =
    variant === "outline.soft"
      ? "transparent"
      : "surfaces.neutral.strong.resting";

  return (
    <SaasToggleButtonGroup
      sx={{
        display: "flex",
        alignItems: "center",
        flexGrow: 0,
        width: "fit-content",
        padding,
        height,
        backgroundColor: bg,
        borderRadius: "full",
        border: "0.8px solid var(--chakra-colors-borders-neutral-soft-resting)",
        gap,
        "& > :not(style) ~ :not(style)": { margin: 0 },
        "& > :first-child .chakra-button": {
          borderLeftRadius: "full",
        },
        "& > :not(:first-child) .chakra-button":
          variant === "outline.soft"
            ? {
                borderLeftStyle: "solid",
                borderLeftWidth: "xs",
                borderLeftColor: "borders.neutral.soft.resting",
              }
            : {},
        "& > :last-child .chakra-button": {
          borderRightRadius: "full",
        },
      }}
      ml={0}
      isAttached={false}
      {...props}
      variant={mappedVariant}
    />
  );
};
