import React, { FC, PropsWithChildren } from "react";
import {
  Avatar as ChakraAvatar,
  AvatarProps as ChakraAvatarProps,
  AvatarBadge as ChakraAvatarBadge,
  AvatarBadgeProps as ChakraAvatarBadgeProps,
  AvatarGroup as ChakraAvatarGroup,
  AvatarGroupProps as ChakraAvatarGroupProps,
  Box,
  Flex,
} from "@chakra-ui/react";
import { sizeOptions, avatarGroupSizeOptions } from "./theme/themeOptions";
import { FASUser } from "../../icons/FontAwesomeIcons";
import { IconSize } from "../../icons";
import { Tooltip } from "../Tooltip";
import { useTranslation } from "../ManaUIProvider";

export type AvatarProps = ChakraAvatarProps & {
  size?: keyof typeof sizeOptions;
  index?: number;
  disabled?: boolean;
  "data-within-avatar-group"?: boolean;
  hideTooltip?: boolean;
};
export type AvatarBadgeProps = ChakraAvatarBadgeProps;
export type AvatarGroupProps = Omit<ChakraAvatarGroupProps, "max"> & {
  size: keyof typeof avatarGroupSizeOptions;
};

const avatarDimensionFromAvatarGroupSize = (
  size: keyof typeof avatarGroupSizeOptions,
) => {
  // Return values from src/clayTheme/foundations/spacing.ts
  switch (size) {
    case avatarGroupSizeOptions["3xl"]:
      return 14;
    case avatarGroupSizeOptions["2xl"]:
      return 12;
    case avatarGroupSizeOptions["xl"]:
      return 10;
    case avatarGroupSizeOptions["lg"]:
      return 8;
    case avatarGroupSizeOptions["md"]:
    default:
      return 6;
  }
};

const spacingFromAvatarGroupSize = (
  size: keyof typeof avatarGroupSizeOptions,
) => {
  switch (size) {
    case avatarGroupSizeOptions["3xl"]:
      return "-0.5rem"; // 8px
    case avatarGroupSizeOptions["2xl"]:
      return "-0.375rem"; // 6px
    case avatarGroupSizeOptions["xl"]:
      return "-0.3125rem"; // 5px
    case avatarGroupSizeOptions["lg"]:
      return "-0.1875rem"; // 3px
    case avatarGroupSizeOptions["md"]:
    default:
      return "-0.125rem"; // 2px
  }
};

/**
 * This is an extension of the Chakra Avatar component that adds a custom fallback icon
 * and `index` prop.
 *
 * The `index` prop is used to render the Avatar background and text colors within `baseStyle.ts`.
 *
 * @param props {AvatarProps}
 * @returns {React.ReactElement}
 */
const Avatar: FC<AvatarProps> = (props) => {
  const {
    children,
    variant,
    index = 0,
    name,
    size,
    hideTooltip,
    ...rest
  } = props;
  const anonymousLabel = useTranslation("Avatar.anonymous");

  let avatar = (
    <ChakraAvatar
      icon={<FASUser iconSize={(size as IconSize) || "md"} />}
      index={index}
      name={name}
      size={size}
      {...rest}
    >
      {children}
    </ChakraAvatar>
  );

  if (!hideTooltip) {
    avatar = (
      <Tooltip placement="top" label={name || anonymousLabel}>
        {avatar}
      </Tooltip>
    );
  }

  return avatar;
};

/**
 * @param props {AvatarBadgeProps}
 * @returns {React.ReactElement}
 */
const AvatarBadge: FC<AvatarBadgeProps> = (props) => {
  const { ...rest } = props;

  return <ChakraAvatarBadge {...rest} />;
};

/**
 * @param props {AvatarGroupProps}
 * @returns {React.ReactElement}
 */
const AvatarGroup: FC<AvatarGroupProps> = (props) => {
  const { children, size, ...rest } = props;
  const anonymousLabel = useTranslation("Avatar.anonymous");

  const spacing = spacingFromAvatarGroupSize(
    size || avatarGroupSizeOptions["md"],
  );

  const cloned = React.Children.map(props.children, (child) => {
    const item = child as React.ReactElement<PropsWithChildren<AvatarProps>>;
    if (React.isValidElement(child)) {
      return React.cloneElement(item, {
        "data-within-avatar-group": true,
      });
    }
  });

  const maxAvatarsInGroup = 5;
  const totalAvatars = React.Children.count(children);

  const getExcessAvatarsNames = () => {
    const hasExcessAvatars = totalAvatars > maxAvatarsInGroup;

    if (!hasExcessAvatars) {
      return null;
    }

    const allAvatarNames = React.Children.map(props.children, (child) => {
      const item = child as React.ReactElement<PropsWithChildren<AvatarProps>>;
      return item.props.name || anonymousLabel;
    });

    const excessNames =
      allAvatarNames && allAvatarNames.slice(maxAvatarsInGroup, totalAvatars);

    return (
      <Tooltip placement="bottom" label={excessNames?.join(", ")}>
        <Box
          as="span"
          position="absolute"
          background="transparent"
          right={0}
          top={0}
          width={avatarDimensionFromAvatarGroupSize(size)}
          height={avatarDimensionFromAvatarGroupSize(size)}
        ></Box>
      </Tooltip>
    );
  };

  return (
    <Flex position="relative" display="inline-flex">
      <ChakraAvatarGroup
        {...rest}
        size={size}
        spacing={spacing}
        max={maxAvatarsInGroup}
      >
        {cloned}
      </ChakraAvatarGroup>
      {getExcessAvatarsNames()}
    </Flex>
  );
};

export { Avatar, AvatarBadge, AvatarGroup };
