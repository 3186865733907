/**
 * This file is forked from Chakra's CSSReset component to allow us to
 * fix a bug with their css reset scoping strategy that allows
 * some global styles to still leak (e.g. body, html, main).
 *
 * Additionally, the scoping strategy makes the reset more specific
 * than the chakra component styles, so this adds a css layer to
 * reduce the specificity of all of the reset styles.
 */
import { useEffect } from "react";
import { useResetInsertionPoint } from "./insertionPoints";
import resetCss from "./reset.css?inline";

export const useReset = (nonce: string) => {
  const resetInsertionPoint = useResetInsertionPoint();
  useEffect(() => {
    if (document.getElementById("mjolnir-reset")) return;
    const reset = document.createElement("style");
    reset.id = "mjolnir-reset";
    reset.setAttribute("nonce", nonce);
    reset.innerText = resetCss
      .replace(/(?:\r\n|\r|\n)/g, "")
      .replace(/\s\s+/g, " ");
    document.head.insertBefore(reset, resetInsertionPoint!.nextElementSibling);
  });
};
