import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { sizeOptions } from "./themeOptions";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(["container", "inner", "top", "left", "right"]);

const baseStyle = definePartsStyle((props) => {
  const isOpen = props.isOpen;
  const removeOverflowLeft = props.removeOverflowLeft;
  const removeOverflowRight = props.removeOverflowRight;

  const rightPanelDisplay = {
    base: isOpen ? undefined : "none",
  };

  const rightBorder = isOpen
    ? {
        borderLeftWidth: "xs",
        borderLeftStyle: "solid",
        borderLeftColor: "borders.neutral.soft.resting",
      }
    : {};

  const isExpanded = props.isExpanded;
  return {
    container: {
      display: "flex",
      flexDirection: "column",
      h: "full",
      w: "full",
    },
    top: {
      px: [5, 5, 8, 8, 10],
    },
    inner: {
      display: "flex",
      justifyContent: "space-between",
      flexBasis: 0,
      minHeight: 0,
      flexGrow: 1,
    },
    left: {
      overflowY: removeOverflowLeft ? "hidden" : "auto",
      flexShrink: 0,
      w: { base: "full", md: !isOpen ? "full" : "calc(100% - 464px)" },
    },
    right: {
      bg: "surfaces.page.white",
      zIndex: "modal",
      overflowY: removeOverflowRight ? "hidden" : "auto",
      bottom: 0,
      top: 0,
      ...rightBorder,
      display: rightPanelDisplay,
      position: "fixed",
      right: isOpen ? 0 : "-100%",
      w: { base: "full", md: isExpanded ? "full" : "464px" },
      flexGrow: 1,
    },
  };
});

const rightPanelWidths = {
  xs: "20rem",
  sm: "28rem",
  md: "32rem",
  lg: "42rem",
  xl: "56rem",
};

export const sizes = {
  [sizeOptions.xs]: definePartsStyle((props) => {
    return {
      left: {
        w: {
          base: "full",
          md: !props.isOpen ? "full" : `calc(100% - ${rightPanelWidths.xs})`,
        },
      },
      right: {
        w: {
          base: "full",
          md: props.isExpanded ? "full" : rightPanelWidths.xs,
        },
      },
    };
  }),
  [sizeOptions.sm]: definePartsStyle((props) => {
    return {
      left: {
        w: {
          base: "full",
          md: !props.isOpen ? "full" : `calc(100% - ${rightPanelWidths.sm})`,
        },
      },
      right: {
        w: {
          base: "full",
          md: props.isExpanded ? "full" : rightPanelWidths.sm,
        },
      },
    };
  }),
  [sizeOptions.md]: definePartsStyle((props) => {
    return {
      left: {
        w: {
          base: "full",
          md: !props.isOpen ? "full" : `calc(100% - ${rightPanelWidths.md})`,
        },
      },
      right: {
        w: {
          base: "full",
          md: props.isExpanded ? "full" : rightPanelWidths.md,
        },
      },
    };
  }),
  [sizeOptions.lg]: definePartsStyle((props) => {
    return {
      left: {
        w: {
          base: "full",
          md: !props.isOpen ? "full" : `calc(100% - ${rightPanelWidths.lg})`,
        },
      },
      right: {
        w: {
          base: "full",
          md: props.isExpanded ? "full" : rightPanelWidths.lg,
        },
      },
    };
  }),
  [sizeOptions.xl]: definePartsStyle((props) => {
    return {
      left: {
        w: {
          base: "full",
          md: !props.isOpen ? "full" : `calc(100% - ${rightPanelWidths.xl})`,
        },
      },
      right: {
        w: {
          base: "full",
          md: props.isExpanded ? "full" : rightPanelWidths.xl,
        },
      },
    };
  }),
  [sizeOptions.full]: definePartsStyle(() => {
    return {
      right: {
        w: "full",
      },
    };
  }),
};

const TwoPanelLayout = defineMultiStyleConfig({
  baseStyle,
  sizes,
});

export default TwoPanelLayout;
