import React from "react";
import {
  Divider as ChakraDivider,
  DividerProps as ChakraDividerProps,
} from "@chakra-ui/react";
import { paletteOptions } from "./theme/themeOptions";

export interface DividerProps extends Omit<ChakraDividerProps, "colorScheme"> {
  palette?: keyof typeof paletteOptions;
}

export default function Divider(props: DividerProps) {
  const { palette, ...rest } = props;
  // @ts-expect-error intentional misuse of colorScheme
  return <ChakraDivider colorScheme={palette} {...rest} />;
}
