import { defineStyleConfig } from "@chakra-ui/react";
import { sizes, paletteOptions } from "./themeOptions";
import { textStyles } from "../../../clayTheme/foundations/typography";

const colorSchemeToColor: Record<paletteOptions, string> = {
  [paletteOptions.primary]: "text.link",
  [paletteOptions.chameleon]: "currentColor",
};

const getTextDecorationStyles = (colorScheme: string) => {
  let textDecorationStyles = {};

  switch (colorScheme) {
    case paletteOptions.primary:
      textDecorationStyles = {
        textDecoration: "none",
        _hover: {
          textDecoration: "underline",
          _disabled: {
            textDecoration: "none",
          },
        },
        _focus: {
          textDecoration: "underline",
          _disabled: {
            textDecoration: "none",
          },
        },
      };
      break;
    case paletteOptions.chameleon:
    default:
      textDecorationStyles = {
        textDecoration: "underline",
        _hover: {
          textDecoration: "none",
          _disabled: {
            textDecoration: "underline",
          },
        },
        _focus: {
          textDecoration: "none",
          _disabled: {
            textDecoration: "underline",
          },
        },
      };
  }

  return textDecorationStyles;
};

const Link = defineStyleConfig({
  baseStyle: ({ colorScheme }) => ({
    color: colorSchemeToColor[(colorScheme || "chameleon") as paletteOptions],
    display: "inline-flex",
    alignItems: "center",
    fontSize: "inherit",
    fontWeight: "medium",
    borderRadius: "sm",
    textUnderlineOffset: "0.25rem",
    gap: "0.5em",
    ...getTextDecorationStyles(colorScheme),
    "&:has(.chakra-icon)": {
      textDecoration: "none",
      _hover: {
        textDecoration: "underline",
        _disabled: {
          textDecoration: "none",
        },
      },
      _focus: {
        textDecoration: "underline",
        _disabled: {
          textDecoration: "none",
        },
      },
    },
    _disabled: {
      color: "text.disabled",
      cursor: "not-allowed",
    },
  }),
  sizes: {
    ...Object.keys(sizes).reduce(
      (agg, size) => ({
        ...agg,
        [size]: {
          ...textStyles[`${size}-link`],
        },
      }),
      {},
    ),
  },
});

export default Link;
