import { numberInputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { sizeOptions } from "./themeOptions";
import { baseStyle as sharedBaseStyle } from "../../Input/theme/commonStateStyles";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(numberInputAnatomy.keys);

const baseStyle = definePartsStyle({
  root: {
    color: "text.neutralDominant",
    backgroundColor: "surfaces.page.white",
    position: "relative",
    border: "none",
    _before: {
      content: "''",
      mask: "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' focusable='false' class='chakra-icon chakra-icon mjolnir-nnd6fl'%3E%3Cpath fill='currentColor' d='M207.6 51.6c2-8.6-3.4-17.2-12-19.2s-17.2 3.4-19.2 12l-23 99.6H48c-8.8 0-16 7.2-16 16s7.2 16 16 16h98L109.1 336H16c-8.8 0-16 7.2-16 16s7.2 16 16 16h85.7L80.4 460.4c-2 8.6 3.4 17.2 12 19.2s17.2-3.4 19.2-12l23-99.6H261.7l-21.3 92.4c-2 8.6 3.4 17.2 12 19.2s17.2-3.4 19.2-12l23-99.6H400c8.8 0 16-7.2 16-16s-7.2-16-16-16H302l36.9-160H432c8.8 0 16-7.2 16-16s-7.2-16-16-16H346.3l21.3-92.4c2-8.6-3.4-17.2-12-19.2s-17.2 3.4-19.2 12l-23 99.6H186.3l21.3-92.4zM178.9 176H306L269.1 336H142l36.9-160z'%3E%3C/path%3E%3C/svg%3E\")",
      maskPosition: "center",
      maskRepeat: "no-repeat",
      backgroundColor: "icon.placeholder",
      position: "absolute",
      left: 2.5,
      top: "50%",
      height: "10px",
      width: "10px",
      transform: "translateY(-50%)",
      zIndex: 1,
    },
    _focusVisible: {
      _before: {
        color: "text.placeholder",
      },
    },
  },
  field: {
    ps: 8,
  },
});

const variants = {
  outline: definePartsStyle({
    field: {
      ...sharedBaseStyle,
      borderRadius: "lg",
      borderWidth: "xs",
      borderStyle: "solid",
      borderColor: "borders.neutral.soft.resting",
    },
    stepperGroup: {
      margin: "0.4px",
      top: 0,
      right: 0,
      borderRadius: "lg",
      height: "calc(100% - 1px)",
    },
    stepper: {
      backgroundColor: "surfaces.page.white",
      _hover: {
        backgroundColor: "surfaces.outline.soft.hover",
      },
      _disabled: {
        backgroundColor: "surfaces.neutral.dominant.disabled",
        borderColor: "borders.neutral.strong.resting",
        _active: {
          backgroundColor: "surfaces.neutral.dominant.disabled",
          borderColor: "borders.neutral.strong.resting",
        },
      },
      "&:first-child": {
        borderTopRightRadius: "lg",
      },
      "&:last-child": {
        borderBottomRightRadius: "lg",
      },
    },
  }),
};

const sizes = {
  [sizeOptions.sm]: definePartsStyle({
    field: {
      height: 8,
    },
  }),
  [sizeOptions.md]: definePartsStyle({
    field: {
      height: 10,
    },
  }),
  [sizeOptions.lg]: definePartsStyle({
    field: {
      height: 12,
    },
  }),
};

const NumberInput: ReturnType<typeof defineMultiStyleConfig> =
  defineMultiStyleConfig({
    baseStyle,
    sizes,
    variants,
    defaultProps: { variant: "outline" },
  });

export default NumberInput;
