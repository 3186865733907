import React from "react";
import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
  useStyleConfig,
  ThemingProps,
  forwardRef,
} from "@chakra-ui/react";
import { Tooltip } from "../Tooltip";
import { useBreakpointValue } from "../../utils/responsive";
import { shapeOptions, sizeOptions } from "./theme/themeOptions";
import iconButtonTheme from "./theme/index";

export interface IconButtonProps
  extends Omit<ChakraButtonProps, "size" | "variant" | "children">,
    ThemingProps<"IconButton"> {
  icon: React.JSX.Element;
  shape?: keyof typeof shapeOptions;
  /**
   * "aria-label" is required but src/components/Combobox/ComboboxTrigger.tsx returns it as undefined
   * if "aria-labelledby" is provided.
   */
  "aria-label": string | undefined;
  tooltipLabel?: React.ReactNode;
}

const sizeToFontSize = {
  [sizeOptions.xs]: "xs",
  [sizeOptions.md]: "sm",
  [sizeOptions.lg]: "md",
  [sizeOptions.xl]: "lg",
  [sizeOptions["2xl"]]: "lg",
};

const IconButton = forwardRef(function IconButton(props: IconButtonProps, ref) {
  const {
    "aria-label": ariaLabel,
    variant,
    size,
    colorScheme,
    tooltipLabel,
    icon,
    ...rest
  } = props;

  const styles = useStyleConfig("IconButton", props);

  const defaultSize = iconButtonTheme.defaultProps?.size || sizeOptions.md;
  const breakpointSize = useBreakpointValue(size || defaultSize);

  const defaultIconProps = {
    iconSize: sizeToFontSize[breakpointSize ?? defaultSize],
  };

  const newProps = {
    ...defaultIconProps,
    ...icon.props,
  };

  const clonedIcon = React.cloneElement(icon, newProps);

  const iconButton = (
    <ChakraButton aria-label={ariaLabel} __css={styles} ref={ref} {...rest}>
      {clonedIcon}
    </ChakraButton>
  );

  if (tooltipLabel) {
    return <Tooltip label={tooltipLabel}>{iconButton}</Tooltip>;
  }

  return iconButton;
});

export default IconButton;
