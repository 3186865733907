import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { sizeOptions } from "./themeOptions";
import typography from "../../../clayTheme/foundations/typography";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(["container", "icon"]);

const ButtonCheckbox: ReturnType<typeof defineMultiStyleConfig> =
  defineMultiStyleConfig({
    baseStyle: ({ isDisabled, isChecked }) => {
      const iconColor = isDisabled
        ? "icon.disabled"
        : isChecked
          ? "text.onPrimarySoft"
          : "text.neutralDominant";
      return definePartsStyle({
        icon: {
          pointerEvents: "none",
          color: iconColor,
        },
        container: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "text.neutralDominant",
          borderRadius: "full",
          shadow:
            "inset 0 0 0 0.05rem var(--chakra-colors-borders-neutral-soft-resting)",
          backgroundColor: "surfaces.page.white",
          cursor: "pointer",
          _hover: {
            shadow:
              "inset 0 0 0 0.05rem var(--chakra-colors-borders-primary-strong-resting)",
            backgroundColor: "surfaces.alpha.dominant.50",
            _focusVisible: {
              shadow:
                "var(--chakra-shadows-focus), inset 0 0 0 0.05rem var(--chakra-colors-borders-primary-strong-resting)",
            },
          },
          _checked: {
            backgroundColor: "surfaces.primary.soft.resting",
            borderColor: "borders.primary.strong.resting",
            color: "text.onPrimarySoft",
            shadow:
              "inset 0 0 0 0.125rem var(--chakra-colors-borders-primary-strong-resting)",
            _focusVisible: {
              shadow:
                "var(--chakra-shadows-focus), inset 0 0 0 0.125rem var(--chakra-colors-borders-primary-strong-resting)",
            },
          },
          _focusVisible: {
            shadow: "focus",
          },
          _disabled: {
            cursor: "not-allowed",
            color: "text.disabledOnSoft",
            shadow:
              "inset 0 0 0 0.05rem var(--chakra-colors-borders-neutral-soft-resting)",
            _checked: {
              backgroundColor: "surfaces.neutral.strong.disabled",
              _hover: {
                backgroundColor: "surfaces.neutral.strong.disabled",
              },
            },
            _hover: {
              backgroundColor: "surfaces.page.white",
              shadow:
                "inset 0 0 0 0.05rem var(--chakra-colors-borders-neutral-soft-resting)",
            },
          },
        },
      });
    },
    sizes: {
      [sizeOptions.xs]: {
        container: {
          h: 6,
          px: 2.5,
          gap: 1.5,
          ...typography.textStyles.sm,
        },
      },
      [sizeOptions.sm]: {
        container: {
          h: 8,
          px: 3,
          gap: 1.5,
          ...typography.textStyles.md,
        },
      },
      [sizeOptions.md]: {
        container: {
          h: 10,
          px: 3.5,
          gap: 2,
          ...typography.textStyles.lg,
        },
      },
      [sizeOptions.lg]: {
        container: {
          h: 12,
          px: 4,
          gap: 2.5,
          ...typography.textStyles.lg,
        },
      },
    },
    defaultProps: {
      size: sizeOptions.md,
    },
  });

export default ButtonCheckbox;
