import React, { FC } from "react";
import {
  BadgeProps as ChakraBadgeProps,
  Badge as ChakraBadge,
  forwardRef,
} from "@chakra-ui/react";
import { paletteOptions } from "./theme/themeOptions";

export type BadgeProps = ChakraBadgeProps & {
  palette: keyof typeof paletteOptions;
};

/**
 * The badge component will be used to display 'tags' or 'pills' in the UI associated
 * with other data or elements.  This is an extension of the Chakra Badge component
 * that adds the ability to include leading and trailing icons.
 *
 * @param props {BadgeProps}
 * @returns {React.ReactElement}
 */
export const Badge: FC<BadgeProps> = forwardRef((props, ref) => {
  const { children, palette, variant, ...rest } = props;

  return (
    // @ts-expect-error intentional misuse of colorScheme
    <ChakraBadge ref={ref} variant={variant} colorScheme={palette} {...rest}>
      {children}
    </ChakraBadge>
  );
});
