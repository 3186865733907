import React from "react";
import {
  Text,
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
  useStyleConfig,
  VisuallyHidden,
  ThemingProps,
} from "@chakra-ui/react";
import { FALKey, Facebook, Google, FALEnvelope } from "../../icons";
import { FacebookSSO, GitHubSSO } from "../../icons/ManualIcons";
import { sizeOptions, variantOptions } from "./theme/themeOptions";

export type Provider = "google" | "github" | "facebook" | string;

export interface SSOButtonProps
  extends Omit<ChakraButtonProps, "children" | "size" | "variant">,
    ThemingProps<"SSOButton"> {
  provider: Provider;
  size: keyof typeof sizeOptions;
  variant: keyof typeof variantOptions;
}

const iconForProvider = (provider: string, variant: variantOptions) => {
  if (provider === "google") {
    return <Google boxSize={5} />;
  } else if (provider === "github") {
    return <GitHubSSO boxSize={5} />;
  } else if (provider === "facebook") {
    return variant === variantOptions.strong ? (
      <FacebookSSO boxSize={5} />
    ) : (
      <Facebook boxSize={5} />
    );
  } else if (provider === "email") {
    return <FALEnvelope fontSize="3xl" />;
  }

  return <FALKey fontSize="3xl" />;
};

const labelForProvider = (provider: Provider) => {
  return (
    {
      google: "Google",
      github: "Github",
      facebook: "Facebook",
    }[provider] || provider
  );
};

export default function SSOButton({
  provider,
  size = sizeOptions.lg,
  variant = variantOptions.strong,
  ...props
}: SSOButtonProps): JSX.Element {
  const styles = useStyleConfig("SSOButton", { provider, size, variant });

  const icon = iconForProvider(
    provider,
    (variant as variantOptions) || variantOptions.strong,
  );
  const label = labelForProvider(provider);

  const TextComponent = size === sizeOptions.lg ? Text : VisuallyHidden;

  return (
    <ChakraButton {...props} __css={styles} gap={3} size="xl">
      {icon} <TextComponent>Sign in with {label}</TextComponent>
    </ChakraButton>
  );
}
