import React from "react";
import { Box, Button, PopoverAnchor } from "@chakra-ui/react";
import { isSelectOption, useComboboxContext } from "./ComboboxContext";
import { sizeOptions } from "../Button/theme/themeOptions";
import { FALChevronUp } from "../../icons";
import { CountBadge } from "./CountBadge";
import { IconButton } from "../IconButton";

export interface ComboboxTriggerProps {
  children?: React.ReactNode;
  icon?: React.JSX.Element;
  size?: keyof typeof sizeOptions;
  label?: string; // want to figure out how to optionally require this or aria-labellecby
  labelledBy?: string;
}

export const ComboboxTrigger = (props: ComboboxTriggerProps) => {
  const { size } = props;

  const {
    combobox,
    triggerRef,
    hasInput,
    onOpen,
    onClose,
    isOpen,
    isDisabled,
    selectedOptions,
    variant = "outline.soft",
    multiple,
    optionToString,
  } = useComboboxContext();

  const buttonContents =
    !multiple &&
    selectedOptions?.length &&
    isSelectOption(selectedOptions[0]) &&
    optionToString
      ? optionToString(selectedOptions[0])
      : props.children || "Select";

  const buttonProps = combobox.getToggleButtonProps({
    onClick: () => {
      isOpen ? onClose() : onOpen();
    },
    borderColor: isOpen ? "borders.primary.strong.active" : undefined,
    sx: {
      "&:hover": {
        borderColor: isOpen ? "borders.primary.strong.active" : undefined,
      },
    },
    isDisabled,
    ref: triggerRef,
    tabIndex: undefined,
    onKeyDown: (e) => {
      // For some reason when using the useCombobox hook pressing "enter" on the toggle button doesn't trigger the onclick
      // handler. When using useSelect it does, but doesn't trigger the onKeyDown handler
      if (!isOpen && e.code === "ArrowDown") {
        onOpen();
      } else if (!isOpen && !hasInput && e.code === "Enter") {
        onOpen();
      } else if (isOpen && e.code === "Escape") {
        onClose();
      }
    },
    overflow: "hidden",
  });

  return (
    <Box display="inline-flex" position="relative" maxWidth="100%">
      <PopoverAnchor>
        {props.icon ? (
          <IconButton
            {...buttonProps}
            size="xl"
            variant={variant}
            icon={props.icon}
            aria-label={props.labelledBy ? undefined : props.label}
            aria-labelledby={props.labelledBy}
            tooltipLabel={props.labelledBy ? undefined : props.label}
          />
        ) : (
          <Button {...buttonProps} variant={variant} size={size}>
            <Box
              as="span"
              display="block"
              overflow="hidden"
              textOverflow="ellipsis"
              minWidth="0px"
              flex="1 1 auto"
            >
              {buttonContents}
            </Box>
            {multiple && !!selectedOptions?.length && (
              <CountBadge count={selectedOptions.length} />
            )}
            <Box
              transition={"transform 0.2s ease-in"}
              transform={isOpen ? "rotate(0deg)" : "rotate(180deg)"}
            >
              <FALChevronUp pointerEvents={"none"} />
            </Box>
          </Button>
        )}
      </PopoverAnchor>
      {!!props.icon && !!selectedOptions?.length && (
        <Box
          h={2.5}
          w={2.5}
          borderRadius="full"
          bg="surfaces.primary.strong.resting"
          position="absolute"
          top={0}
          right={0}
        />
      )}
    </Box>
  );
};
