import React, { ReactNode, useRef } from "react";
import { ContextProvider, TextareaAPI } from "./TextareaContext";
import { useFormContext, useWatch } from "@saas-ui/react";
import { useCallbackRef } from "@chakra-ui/react";

type TextareaContextProviderProps = Pick<
  TextareaAPI,
  "value" | "onChange" | "defaultValue"
> & {
  children: ReactNode;
  name?: string;
};

export const TextareaContextProvider: React.FC<
  TextareaContextProviderProps
> = ({ children, name = "", value = undefined, ...rest }) => {
  const shouldUseFormAPI = useShouldUseFormAPI(name);
  if (shouldUseFormAPI) {
    return (
      <WithinFormProvider name={name} value={value} {...rest}>
        {children}
      </WithinFormProvider>
    );
  }

  return (
    <DefaultProvider value={value} {...rest}>
      {children}
    </DefaultProvider>
  );
};

type DefaultProviderProps = Omit<TextareaContextProviderProps, "name">;

const DefaultProvider: React.FC<DefaultProviderProps> = ({
  children,
  value: propsValue,
  defaultValue,
  onChange,
}) => {
  const propsOnChange = useCallbackRef(onChange);
  const [internalValue, internalSetValue] = React.useState(defaultValue || "");

  const isControlled = propsValue !== undefined && !!propsOnChange;
  const passedValue = isControlled ? propsValue : internalValue;

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (!isControlled) {
        internalSetValue(event.target.value);
      }
      propsOnChange && propsOnChange(event);
    },
    [propsOnChange, isControlled],
  );

  const api = useMakeAPI({
    value: passedValue,
    onChange: handleChange,
  });
  return <ContextProvider value={api}>{children}</ContextProvider>;
};

type WithinFormProviderProps = Omit<TextareaContextProviderProps, "name"> & {
  name: string;
};

const WithinFormProvider: React.FC<WithinFormProviderProps> = ({
  children,
  name = "",
  value: propValue,
  onChange,
  defaultValue,
}) => {
  const formCtxt = useFormContext();

  const propsOnChange = useCallbackRef(onChange);
  const isControlled = !!propValue && !!propsOnChange;

  const { getValues: getFormFieldValue, setValue: setFormFieldValue } =
    formCtxt;

  const watcher = useWatch({ name, defaultValue });

  const setValueWrap = React.useCallback(
    (value: string) => {
      setFormFieldValue(name, value, {
        shouldDirty: true,
        shouldValidate: true,
        shouldTouch: true,
      });
    },
    [name, setFormFieldValue],
  );

  const formValue = isControlled
    ? propValue
    : (watcher && watcher[name]) || getFormFieldValue(name);

  const onChangeWrap = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      propsOnChange && propsOnChange(event);
      setValueWrap(event.target.value);
    },
    [propsOnChange, setValueWrap],
  );

  const api = useMakeAPI({
    value: formValue,
    onChange: onChangeWrap,
  });
  return <ContextProvider value={api}>{children}</ContextProvider>;
};

type makeAPIProps = {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
};

const useMakeAPI = ({ value, onChange }: makeAPIProps): TextareaAPI => {
  const ref = useRef<HTMLTextAreaElement | null>(null);

  return {
    onChange,
    replaceValue: React.useCallback(
      (value: string) => {
        if (!ref.current) return;
        const textarea = ref.current!;
        nativeSetValue(textarea, value);
      },
      [ref],
    ),
    replaceSelection: React.useCallback(
      (value: string) => {
        if (!ref.current) return;
        const textarea = ref.current!;
        nativeSetValue(textarea, insertAtCaret(textarea, value));
      },
      [ref],
    ),
    getCurrentSelection: React.useCallback(() => {
      if (ref.current) {
        return getCurrentSelection(ref.current);
      }
      return "";
    }, [ref]),
    value,
    ref,
  };
};

const useShouldUseFormAPI = (name: string): boolean => {
  const formCtx = useFormContext();
  return !!name && Object.keys(formCtx).length > 0;
};

function getCurrentSelection(textarea: HTMLTextAreaElement): string {
  return textarea.value.substring(
    textarea.selectionStart,
    textarea.selectionEnd,
  );
}

function insertAtCaret(textarea: HTMLTextAreaElement, text: string): string {
  const start = textarea.selectionStart;
  const end = textarea.selectionEnd;

  const textBefore = textarea.value.substring(0, start);
  const textAfter = textarea.value.substring(end, textarea.value.length);
  const combined = textBefore + text + textAfter;

  textarea.selectionStart = textarea.selectionEnd = start + text.length;
  textarea.focus();
  return combined;
}

function nativeSetValue(textarea: HTMLTextAreaElement, value: string) {
  const nativeSetter = Object.getOwnPropertyDescriptor(
    window.HTMLTextAreaElement.prototype,
    "value",
  )!.set;
  nativeSetter!.call(textarea, value);
  textarea.dispatchEvent(new Event("input", { bubbles: true }));
}
