export enum paletteOptions {
  "neutral.dominant" = "neutral.dominant",
  "neutral.strong" = "neutral.strong",
  chameleon = "chameleon",
}

export enum timestampOptions {
  DATE = "DATE",
  DATE_RELATIVITY = "DATE_RELATIVITY",
  DATE_TIME = "DATE_TIME",
  DATE_RANGE = "DATE_RANGE",
  DATE_TIME_RANGE = "DATE_TIME_RANGE",
  DATE_TIME_RELATIVITY = "DATE_TIME_RELATIVITY",
  DATE_TIME_SECONDS = "DATE_TIME_SECONDS",
  DATE_TIME_SECONDS_RELATIVITY = "DATE_TIME_SECONDS_RELATIVITY",
}

export enum orientationOptions {
  "horizontal" = "horizontal",
  "vertical" = "vertical",
}
