import { createContext } from "react";
import { variantOptions } from "./theme/themeOptions";
import { ResponsiveValue } from "@chakra-ui/styled-system";

type AlertDialogContextType = {
  variant?: ResponsiveValue<keyof typeof variantOptions>;
};

export const AlertDialogContext = createContext<AlertDialogContextType>({
  variant: undefined,
});
