import React from "react";
import {
  Box,
  CheckboxProps,
  useMultiStyleConfig,
  useCheckbox,
  ThemingProps,
} from "@chakra-ui/react";
import { FALSquare, FALCheck } from "../../icons/FontAwesomeIcons";

export interface ButtonCheckboxProps
  extends Omit<CheckboxProps, "size" | "variant">,
    ThemingProps<"ButtonCheckbox"> {}

const ButtonCheckbox = (props: ButtonCheckboxProps) => {
  const { getInputProps, getCheckboxProps } = useCheckbox(props);
  const input = getInputProps();
  const checkbox = getCheckboxProps();

  const { icon, ...rest } = props;

  const styles = useMultiStyleConfig("ButtonCheckbox", {
    ...props,
    isChecked: input.checked,
  });

  const statusIcon = input.checked ? <FALCheck /> : <FALSquare />;

  return (
    <Box {...rest}>
      <input {...input} />
      <Box {...checkbox} __css={styles.container}>
        <Box __css={styles.icon}>{statusIcon}</Box>
        {props.children}
      </Box>
    </Box>
  );
};

export default ButtonCheckbox;
