
import {MainButton} from '/src/com/yext/platform/users/js/newloginhelpers/logincomponents';
import NewLoginTemplate from '/src/com/yext/platform/users/js/newloginhelpers/newlogintemplate';
import {registerComponentForJsonDataWithUIKit} from '/ui/lib/easyuikit';
import {yext} from '/ui/lib/msg';

const {
  Box,
  EmptyState,
  VStack,
} = require('@yext/mana-ui');

/**
 * Component to render the reset password confirmation page
 */
export function NewForgotPasswordConfirm({whitelabelInfo}) {
  return (
    <NewLoginTemplate
      title={yext.msg('Reset Request Sent')}
      subtitle={yext.msg('If an account exists with this email or username, we\'ve sent a password reset link. Please check your email.')}
      cardContent={<CardContent whitelabelInfo={whitelabelInfo} />}
      whitelabelInfo={whitelabelInfo}
    />
  );
}

function CardContent({whitelabelInfo}) {
  const onClick = () => {
    window.location.href = 'login';
  };

  return (
    <VStack spacing={0} align={'stretch'}>
      <Box mb={'-1.5rem'}><EmptyState icon={'like'} /></Box>
      <MainButton onClick={onClick} whitelabelInfo={whitelabelInfo}>
        {yext.msg('Return to Sign In')}
      </MainButton>
    </VStack>
  );
}

document.addEventListener('DOMContentLoaded', () => {
  registerComponentForJsonDataWithUIKit('NewForgotPasswordConfirm', NewForgotPasswordConfirm);
});
