import typography from "../../../clayTheme/foundations/typography";

const disabledStyle = {
  borderColor: "borders.neutral.strong.disabled",
  opacity: 1,
  backgroundColor: "surfaces.neutral.soft.disabled",
  color: "text.disabled",
  borderWidth: "sm",
  "&:not(:placeholder-shown)": {
    borderWidth: "sm",
    borderColor: "borders.neutral.strong.disabled",
  },
  _hover: {
    borderWidth: "sm",
    borderColor: "borders.neutral.strong.disabled",
    backgroundColor: "surfaces.neutral.soft.disabled",
    color: "text.disabled",
    shadow: "none",
  },
  _focusVisible: {
    borderColor: "borders.neutral.strong.disabled",
    backgroundColor: "surfaces.neutral.soft.disabled",
    color: "text.disabled",
    shadow: "none",
  },
  _invalid: {
    borderColor: "borders.neutral.strong.disabled",
    backgroundColor: "surfaces.neutral.soft.disabled",
    color: "text.disabled",
    shadow: "none",
    _hover: {
      borderColor: "borders.neutral.strong.disabled",
      shadow: "none",
    },
  },
};

export const placeholderStyle = {
  color: "text.placeholder",
  opacity: 1,
};

type FocusMethod = "focusVisible" | "focusWithin";

export const baseStyle = (focusMethod: FocusMethod = "focusVisible") => {
  return {
    color: "text.neutralDominant",
    backgroundColor: "components.inputs.bgRestingWhite",
    ...typography.textStyles.md,
    _placeholder: placeholderStyle,
    borderWidth: "xs",
    borderStyle: "solid",
    borderColor: "borders.neutral.soft.resting",
    _hover: {
      borderColor: "borders.primary.strong.hover",
      shadow: "gray",
    },
    [`_${focusMethod}`]: {
      shadow: "focus",
      borderColor: "borders.primary.strong.active",
      boxShadow:
        "var(--chakra-shadows-focus), inset 0 0 0 0.075rem var(--chakra-colors-borders-primary-strong-active)",
      outline: "none",
      _invalid: {
        borderColor: "borders.danger.strong.resting",
        shadow: "error",
        "&:not(:placeholder-shown)": {
          boxShadow:
            "var(--chakra-shadows-error), inset 0 0 0 0.075rem var(--chakra-colors-borders-danger-strong-active)",
          _hover: {
            borderColor: "borders.danger.strong.active",
            boxShadow:
              "var(--chakra-shadows-error), inset 0 0 0 0.075rem var(--chakra-colors-borders-danger-strong-active)",
          },
        },
      },
      "&:not(:placeholder-shown)": {
        boxShadow:
          "var(--chakra-shadows-focus), inset 0 0 0 0.075rem var(--chakra-colors-borders-primary-strong-active)",
        borderColor: "borders.primary.strong.active",
      },
    },
    _invalid: {
      borderColor: "borders.danger.strong.resting",
      shadow: "error",
      boxShadow: "error",
      "&:not(:placeholder-shown)": {
        borderColor: "borders.danger.strong.resting",
      },
      _hover: {
        borderColor: "borders.danger.strong.active",
      },
      _disabled: {
        borderWidth: "sm",
        borderColor: "borders.neutral.strong.disabled",
        boxShadow: "none",
      },
    },
    _disabled: disabledStyle,
    _groupDisabled: disabledStyle,
  };
};
