import React from "react";
import AbstractMarker, {
  MarkerProps as AbstractMarkerProps,
} from "./AbstractMarker";
import { chakra, Icon, ThemingProps } from "@chakra-ui/react";
import { default as MajorSvg } from "./Major.svg?react";
import { default as MinorSvg } from "./Minor.svg?react";
import { useResponsiveValue } from "@saas-ui/react-utils";

export interface MarkerProps
    extends Omit<AbstractMarkerProps, "children">,
        ThemingProps<"Marker"> {
  active?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  clusterText?: string;
}

const markerDetailsByVariant = {
  cluster: {
    svg: null,
    boxSize: "56px",
    css: {
      svg: {
        "--map-marker-main": "#E685B5",
        "--map-marker-detail": "#1C1D20",
        "--map-marker-text": "#1C1D20",
      },
      "svg:hover": {
        "--map-marker-main": "#DE5C9D",
        "--map-marker-detail": "white",
        "--map-marker-text": "white",
      },
      "svg.disabled": {
        "--map-marker-main": "#DADCE0",
        "--map-marker-detail": "#DADCE0",
        "--map-marker-text": "#97999D",
      },
    },
  },
  major: {
    svg: MajorSvg,
    boxSize: "40px",
    css: {
      svg: {
        transition: "transform 100ms ease-in-out",
        "--map-marker-main": "#5A58F2",
        "--map-marker-detail": "white",
      },
      "svg:hover": {
        // The translation makes it stay properly anchored at the bottom.
        transform: "scale(1.45) translate(0, -11.25%)",
        "--map-marker-main": "#372AE0",
      },
      "svg.disabled": {
        "--map-marker-detail": "#7979F5",
      },
    },
  },
  minor: {
    svg: MinorSvg,
    boxSize: "40px",
    css: {
      svg: {
        transition: "transform 100ms ease-in-out",
        "--map-marker-main": "#C5B3E6",
        "--map-marker-detail": "white",
      },
      "svg:hover": {
        transform: "scale(1.13)",
        "--map-marker-main": "#A98EDA",
      },
      "svg.disabled": {
        "--map-marker-main": "#DADCE0",
        "--map-marker-detail": "#DADCE0",
      },
      "svg.active": {
        "--map-marker-main": "#5A58F2",
      },
    },
  },
};

function getClusterIcon(
    boxSize: string,
    className?: string,
    clusterText?: string,
    fontSize?: string,
) {
  return (
      <Icon boxSize={boxSize} className={className} fontSize={fontSize || "6px"}>
        <g clipPath="url(#clip0_14435_2397)">
          <circle
              fill="var(--map-marker-main)"
              stroke="var(--map-marker-detail)"
              cx="50%"
              cy="50%"
              r="48%"
          />
          <text
              fill="var(--map-marker-text)"
              x="50%"
              y="50%"
              dominantBaseline="middle"
              textAnchor="middle"
          >
            {clusterText}
          </text>
        </g>
        <defs>
          <clipPath id="clip0_14435_2397">
            <circle cx="50%" cy="50%" r="50%"/>
          </clipPath>
        </defs>
      </Icon>
  );
}

export default function Marker(props: MarkerProps): React.JSX.Element {
  const variant = (useResponsiveValue(props.variant) ||
    "minor") as keyof typeof markerDetailsByVariant;
  const isMajor = variant === "major";
  const isCluster = variant === "cluster";
  const {svg, css, boxSize} = markerDetailsByVariant[variant];

  const _css = {
    // Specifically use the g element instead of the full svg, so that only the exact shape is interactive
    // leave disabled if disabled
    "svg g": {
      "pointer-events": props.disabled ? "none" : "fill",
    },
    ...css,
  };

  const className = props.disabled
      ? "disabled"
      : props.active
          ? "active"
          : undefined;

  // the major icon is anchored near the bottom of the icon
  return (
    <AbstractMarker
      onClick={props.onClick}
      anchorX={0.5}
      anchorY={isMajor ? 0.8 : 0.5}
      {...props}
    >
      <chakra.div __css={_css} tabIndex={0}>
        {isCluster || !svg ? (
          getClusterIcon(boxSize, className, props.clusterText)
        ) : (
          <Icon as={svg} boxSize={boxSize} className={className} />
        )}
      </chakra.div>
    </AbstractMarker>
  );
}
