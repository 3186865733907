import { defineStyleConfig } from "@chakra-ui/react";
import { sizeOptions, variantOptions } from "./themeOptions";

export const defaultProps = {
  size: sizeOptions.lg,
  variant: variantOptions.neutral,
} as const;

// Chakra defaults AlertDialog styles to inherit from Modal styles
const AlertDialog = defineStyleConfig({
  variants: {
    [variantOptions.neutral]: {},
    [variantOptions.system]: {},
    [variantOptions.warning]: {},
    [variantOptions.danger]: {},
  },
  sizes: {
    [sizeOptions.xs]: {},
    [sizeOptions.lg]: {},
  },
  defaultProps,
});

export default AlertDialog;
