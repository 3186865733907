
import {MainButton} from '/src/com/yext/platform/users/js/newloginhelpers/logincomponents';
import NewLoginTemplate from '/src/com/yext/platform/users/js/newloginhelpers/newlogintemplate';
import {registerComponentForJsonDataWithUIKit} from '/ui/lib/easyuikit';
import {yext} from '/ui/lib/msg';

const {
  Box,
  EmptyState,
  VStack,
} = require('@yext/mana-ui');

/**
 * Component to render the reset password confirmation page
 */
export function ResetPasswordConfirm({username, whitelabelInfo}) {
  return (
    <NewLoginTemplate
      title={yext.msg('Success!')}
      subtitle={yext.msg('The password for the following user has been reset:')}
      cardContent={<CardContent whitelabelInfo={whitelabelInfo} />}
      pageType={'resetPassword'}
      username={username}
      whitelabelInfo={whitelabelInfo}
    />
  );
}

function CardContent({whitelabelInfo}) {
  const onClick = () => {
    window.location.href = '/s/';
  };

  return (
    <VStack spacing={0} align={'stretch'} name={'confirm-button'}>
      <Box mb={'-1.5rem'}><EmptyState icon={'like'} /></Box>
      <MainButton onClick={onClick} whitelabelInfo={whitelabelInfo}>
        {yext.msg('Go to Account')}
      </MainButton>
    </VStack>
  );
}

document.addEventListener('DOMContentLoaded', () => {
  registerComponentForJsonDataWithUIKit('ResetPasswordConfirm', ResetPasswordConfirm);
});
