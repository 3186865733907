import { defineStyleConfig } from "@chakra-ui/react";
import { sizeOptions, variantOptions } from "./themeOptions";
import { Provider } from "../SSOButton";
import typography from "../../../clayTheme/foundations/typography";

const colorMappings = (provider: Provider, variant: variantOptions) => {
  if (provider === "email") {
    if (variant === variantOptions.strong) {
      return {
        bg: "surfaces.primary.strong.resting",
        hoverBg: "surfaces.primary.strong.hover",
        color: "text.onNeutralDominant",
        disabledBg: "surfaces.neutral.dominant.disabled",
        disabledColor: "text.disabledOnSoft",
        iconColor: "white",
      };
    } else if (variant === variantOptions.soft) {
      return {
        bg: "surfaces.alpha.soft.50",
        hoverBg: "surfaces.alpha.dominant.100",
        color: "text.neutralDominant",
        border: "borders.neutral.dominant.resting",
      };
    }
  } else if (provider === "google") {
    if (variant === variantOptions.strong) {
      return {
        bg: "surfaces.alpha.soft.50",
        hoverBg: "surfaces.alpha.dominant.100",
        color: "text.neutralDominant",
        border: "borders.neutral.dominant.resting",
        borderWidth: "md",
      };
    } else if (variant === variantOptions.soft) {
      return {
        bg: "surfaces.alpha.soft.50",
        hoverBg: "surfaces.alpha.dominant.100",
        color: "text.neutralDominant",
        border: "borders.neutral.dominant.resting",
      };
    }
  } else if (provider === "github") {
    if (variant === variantOptions.strong) {
      return {
        bg: "surfaces.neutral.dominant.resting",
        hoverBg: "surfaces.neutral.dominant.hover",
        color: "text.onNeutralDominant",
        border: undefined,
        disabledBg: "surfaces.neutral.dominant.disabled",
        disabledColor: "text.disabledOnSoft",
        iconColor: "white",
      };
    } else if (variant === variantOptions.soft) {
      return {
        bg: "surfaces.alpha.soft.50",
        hoverBg: "surfaces.alpha.dominant.100",
        color: "text.neutralDominant",
        border: "borders.neutral.dominant.resting",
      };
    }
  } else if (provider === "facebook") {
    if (variant === variantOptions.strong) {
      return {
        bg: "#1877F2",
        hoverBg: "#0C63D4",
        color: "white",
        border: undefined,
        disabledBg: "surfaces.neutral.dominant.disabled",
        disabledColor: "text.disabledOnSoft",
        iconColor: "white",
      };
    } else if (variant === variantOptions.soft) {
      return {
        bg: "surfaces.alpha.soft.50",
        hoverBg: "surfaces.alpha.dominant.100",
        color: "text.neutralDominant",
        border: "borders.neutral.dominant.resting",
        iconColor: "#1977F2",
        disabledIconColor: "#1977F2",
      };
    }
  } else {
    if (variant === variantOptions.strong) {
      return {
        bg: "surfaces.primary.strong.resting",
        hoverBg: "surfaces.primary.strong.hover",
        color: "text.onNeutralDominant",
        border: undefined,
        disabledBg: "surfaces.neutral.dominant.disabled",
        disabledColor: "text.disabledOnSoft",
        iconColor: "white",
      };
    }

    return {
      bg: "surfaces.alpha.soft.50",
      hoverBg: "surfaces.alpha.dominant.100",
      color: "text.neutralDominant",
      border: "borders.neutral.dominant.resting",
    };
  }

  // I don't know why typescript requires this line to be convinced that this function will not
  // return undefined.
  return {};
};

const SSOButton = defineStyleConfig({
  baseStyle: ({ provider, variant }) => {
    const nullSafeVariant = variant || "strong";
    const {
      bg,
      border,
      color,
      hoverBg,
      borderWidth,
      disabledBg,
      disabledColor,
      iconColor,
      disabledIconColor,
    } = colorMappings(provider, nullSafeVariant);

    return {
      height: 12,
      display: "flex",
      alignItems: "center",
      borderRadius: "full",
      shadow: "xs",
      backgroundColor: bg,
      borderColor: border,
      borderWidth: borderWidth || "sm",
      ...typography.textStyles["md-medium"],
      color,
      _hover: {
        backgroundColor: hoverBg,
      },
      _focusVisible: {
        shadow: "focus",
        outline: "none",
      },
      "& .chakra-icon": {
        color: iconColor || "text.neutralDominant",
      },
      _disabled: {
        color: disabledColor || "text.disabled",
        backgroundColor: disabledBg || bg,
        borderColor:
          variant === "google" || variant === variantOptions.soft
            ? "borders.neutral.dominant.disabled"
            : "surfaces.neutral.dominant.disabled",
        "& .chakra-icon": {
          color:
            disabledIconColor ||
            (variant === variantOptions.strong
              ? "icon.disabledOnStrong"
              : "icon.disabled"),
          opacity:
            provider === "google" ||
            (provider === "facebook" && variant === variantOptions.soft)
              ? 0.4
              : 1,
        },
        cursor: "not-allowed",
        _hover: {
          backgroundColor: disabledBg || bg,
        },
      },
    };
  },
  sizes: {
    [sizeOptions.sm]: {
      width: 12,
      p: 2.5,
      justifyContent: "center",
    },
    [sizeOptions.lg]: {
      px: 4,
      py: 2.5,
    },
  },
  variants: variantOptions,
  defaultProps: {
    size: sizeOptions.lg,
  },
});

export default SSOButton;
