export enum paletteOptions {
  neutralDominant = "neutralDominant",
  neutralStrong = "neutralStrong",
  primary = "primary",
  secondary = "secondary",
  success = "success",
  danger = "danger",
  warning = "warning",
}

export enum variantOptions {
  solid = "solid",
  outline = "outline",
  subtle = "subtle",
}

export enum sizeOptions {
  sm = "sm",
}
