import { createTheme } from "thememirror";
import { tags as t } from "@lezer/highlight";

export const manaCodeBlockThemeLight = createTheme({
  variant: "light",
  settings: {
    background: "#F7F9FE", // "surfaces.lifted.soft",
    foreground: "#5B5D60", // "text.neutral-strong",
    caret: "#5A58F2", // "text.primary",
    selection: "#C8C5FA", // "surfaces.primary.soft.hover",
    lineHighlight: "#E9EBEF", // "surfaces.neutral.strong.resting",
    gutterBackground: "#F7F9FE", // "surfaces.lifted.soft",
    gutterForeground: "#6F7074", // "text.neutral-soft",
  },
  styles: [
    {
      tag: t.comment,
      color: "#B7B9BC", // "text.disabled",
    },
    {
      tag: t.variableName,
      color: "#5A58F2", // "text.primary",
    },
    {
      tag: [t.string, t.special(t.brace)],
      color: "#DE5C9D", // "pairs.pink.pink-pair-400",
    },
    {
      tag: t.number,
      color: "#1AA179", // "pairs.teal.teal-pair-600",
    },
    {
      tag: t.bool,
      color: "#087990", // "pairs.cyan.cyan-pair-700",
    },
    {
      tag: t.null,
      color: "#E35D6A", // "pairs.red.red-pairs-400",
    },
    {
      tag: t.keyword,
      color: "#1C1D20", // "text.neutral-dominant",
    },
    {
      tag: t.operator,
      color: "#6F7074", // "text.neutral-soft",
    },
    {
      tag: t.className,
      color: "#198754", // "#text.success",
    },
    {
      tag: t.definition(t.typeName),
      color: "#CA6510", // "pairs.orange.orange-pair-600",
    },
    {
      tag: t.typeName,
      color: "#5A58F2", // "text.primary",
    },
    {
      tag: t.angleBracket,
      color: "#1C1D20", // "text.neutral-dominant",
    },
    {
      tag: t.tagName,
      color: "#5A58F2", // "text.primary",
    },
    {
      tag: t.attributeName,
      color: "#1C1D20", // "text.neutral-dominant",
    },
    {
      tag: t.color,
      color: "#1AA179", // "pairs.teal.teal-pair-600",
    },
  ],
});

export const manaCodeBlockThemeDark = createTheme({
  variant: "dark",
  settings: {
    background: "#323437", // "surfaces.lifted.soft",
    foreground: "#DADCE0", // "text.neutral-strong",
    caret: "#A29FF8", // "text.primary",
    selection: "#C8C5FA", // "surfaces.primary.soft.hover",
    lineHighlight: "#5B5D60", // "surfaces.neutral.strong.resting",
    gutterBackground: "#323437", // "surfaces.lifted.soft",
    gutterForeground: "#97999D", // "text.neutral-soft",
  },
  styles: [
    {
      tag: t.comment,
      color: "#97999D", // "text.disabled",
    },
    {
      tag: t.variableName,
      color: "#A29FF8", // "text.primary",
    },
    {
      tag: [t.string, t.special(t.brace)],
      color: "#D63384", // "pairs.pink.pink-pair-400",
    },
    {
      tag: t.number,
      color: "#79DFC1", // "pairs.teal.teal-pair-600",
    },
    {
      tag: t.bool,
      color: "#9EEAF9", // "pairs.cyan.cyan-pair-700",
    },
    {
      tag: t.null,
      color: "#DC3545", // "pairs.red.red-pairs-400",
    },
    {
      tag: t.keyword,
      color: "#F7F9FE", // "text.neutral-dominant",
    },
    {
      tag: t.operator,
      color: "#97999D", // "text.neutral-soft",
    },
    {
      tag: t.className,
      color: "#75B798", // "#text.success",
    },
    {
      tag: t.definition(t.typeName),
      color: "#FEB272", // "pairs.orange.orange-pair-600",
    },
    {
      tag: t.typeName,
      color: "#A29FF8", // "text.primary",
    },
    {
      tag: t.angleBracket,
      color: "#F7F9FE", // "text.neutral-dominant",
    },
    {
      tag: t.tagName,
      color: "#A29FF8", // "text.primary",
    },
    {
      tag: t.attributeName,
      color: "#F7F9FE", // "text.neutral-dominant",
    },
    {
      tag: t.color,
      color: "#79DFC1", // "pairs.teal.teal-pair-600",
    },
  ],
});
