
import FinePrint from '/src/com/yext/platform/users/js/newloginhelpers/fineprint';
import {isWhitelabeled} from '/src/com/yext/platform/users/js/newloginhelpers/logincomponents';
import {yext} from '/ui/lib/msg';

const {
  Box,
  Button,
  Card,
  Center,
  FALArrowLeft,
  Flex,
  Link,
  Text,
  VStack,
  Yext,
  Image,
} = require('@yext/mana-ui');

/**
 * Shared component for the login pages. Renders the common components shared by all login pages,
 * such as the logo, title, card, and footer.
 */
export default function NewLoginTemplate({
  title,
  subtitle,
  cardContent,
  whitelabelInfo,
  username,
  pageType,
  isMobileRedirect,
  showBackToUsername,
  isAllState,
}) {
  return (
    <Flex direction={'column'} minH={'100vh'} maxW={'100vw'}>
      <Center flex={1} padding={8}>
        <VStack w={'384px'} spacing={8} align={'stretch'}>
          {isAllState
            && <Text
              fontSize={'sm'}
              textAlign={'center'}
              dangerouslySetInnerHTML={{__html: yext.msg('<strong>ATTENTION!</strong> Your previous Agent Admin Panel login credentials have been deprecated. Moving forward, use this <a href="https://agencygateway1.allstate.com/wps/myportal/ID/112985">quick link</a> to access AAP from Gateway. Ensure you are logged onto Gateway first for the quick link to work.')}}
            />
          }
          <Title title={title} subtitle={subtitle} whitelabelInfo={whitelabelInfo} />
          {pageType === 'resetPassword' && (
            <Card size={'sm'} variant={'transparent'} maxW={'100%'} mx={'auto'}>
              <Text fontSize={'md'} color={'text.primary'} textAlign={'center'}>
                {username}
              </Text>
            </Card>
          )}
          <Card size={'lg'} variant={'elevated'}>
            {cardContent}
          </Card>
          {showBackToUsername && <BackToUsernamePage />}
          {pageType === 'login' && !isWhitelabeled(whitelabelInfo) && !isMobileRedirect && <HearsaySignIn />}
        </VStack>
      </Center>
      <Box as={'footer'} textAlign={'center'} paddingY={4}>
        <FinePrint whitelabelInfo={whitelabelInfo} />
      </Box>
    </Flex>
  );
}

function HearsaySignIn() {
  return (<Flex align={'center'} justify={'center'}>
    <Text fontSize={'md'} mr={1}>
      {yext.msg('Looking for Hearsay products? ')}
    </Text>
    <Link
      as={'a'}
      href={'https://login.hearsaysocial.com'}
      palette={'primary'}
      size={'md'}
    >
      {yext.msg('Sign in Here')}
    </Link>
  </Flex>);
}

function Title({title, subtitle, whitelabelInfo}) {
  return (
    <VStack spacing={[4, 10]}>
      {hasWhitelabelLogo(whitelabelInfo) ? <WhitelabelLogo whitelabelInfo={whitelabelInfo}/> : <Yext fontSize={'7xl'}/>}
      <VStack spacing={0.5}>
        <Text fontSize={'4xl'} color={'text.neutralDominant'} align={'center'}>
          {title}
        </Text>
        <Text fontSize={'md'} color={'text.neutralSoft'} align={'center'}>
          {subtitle}
        </Text>
      </VStack>
    </VStack>
  );
}

function WhitelabelLogo({whitelabelInfo}) {
  return (
    <Image
      src={whitelabelInfo.scanLogo}
      width={'190px'}
      height={'120px'}
      objectFit={'contain'}
      backgroundRepeat={'no-repeat'}
      backgroundPosition={'center'}
    />
  );
}

const BackToUsernamePage = () => {
  return (
    <Button
      onClick={onBackToUsername}
      size={'md'}
      variant={'ghost'}
      alignSelf={'center'}
      marginBottom={'-0.5rem'}
    >
      <FALArrowLeft/>
      {yext.msg('Back')}
    </Button>
  );
};

const onBackToUsername = () => {
  window.location = '/users/login' + window.location.search;
};

const hasWhitelabelLogo = whitelabelInfo => {
  return isWhitelabeled(whitelabelInfo) && whitelabelInfo.scanLogo;
};
