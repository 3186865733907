import { defineStyleConfig, defineStyle } from "@chakra-ui/react";
import { Dict } from "@chakra-ui/utils";
import { WithCSSVar } from "@chakra-ui/styled-system";
import { paletteOptions, variantOptions } from "./themeOptions";

export default defineStyleConfig({
  variants: {
    [variantOptions.solid]: defineStyle((props) => {
      const scheme = colorSchemeForVariant({
        variant: variantOptions.solid,
        colorScheme: props.colorScheme as paletteOptions,
        theme: props.theme as WithCSSVar<Dict>,
      });
      return scheme;
    }),
    [variantOptions.outline]: defineStyle((props) => {
      return colorSchemeForVariant({
        variant: variantOptions.outline,
        colorScheme: props.colorScheme as paletteOptions,
        theme: props.theme as WithCSSVar<Dict>,
      });
    }),
    [variantOptions.subtle]: defineStyle((props) => {
      return colorSchemeForVariant({
        variant: variantOptions.subtle,
        colorScheme: props.colorScheme as paletteOptions,
        theme: props.theme as WithCSSVar<Dict>,
      });
    }),
  },
});

export interface ColorSchemeProps {
  variant: variantOptions;
  colorScheme: paletteOptions;
  theme: WithCSSVar<Dict>;
}

/**
 * Chakra's Badge 'border' is actually an inset 'box-shadow'.
 *
 * This template literal takes the default Badge box-shadow styles for the 'outline' variant
 * and inserts the respective color scheme's specified 'border' color.
 *
 * @see https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/badge.ts#L55
 */
const boxShadowStyles = (color: string) =>
  `inset 0 0 0px 1px var(--chakra-colors-${color})`;

const colorSchemeForVariant = ({ variant, colorScheme }: ColorSchemeProps) => {
  switch (colorScheme) {
    case paletteOptions.neutralDominant:
      if (variant === variantOptions.outline) {
        return {
          bg: "surfaces.page.white",
          borderColor: "borders.neutral.dominant.resting",
          borderWidth: "sm",
          color: "components.badge.textNeutral",
          shadow: boxShadowStyles("borders.neutral.dominant.resting"),
        };
      }
      if (variant === variantOptions.subtle) {
        return {
          bg: "components.badge.bgNeutralStrong",
          color: "components.badge.textNeutralStrong",
        };
      }
      return {
        bg: "surfaces.neutral.dominant.resting",
        color: "text.onNeutralDominant",
      };
    case paletteOptions.neutralStrong:
      if (variant === variantOptions.outline) {
        return {
          bg: "surfaces.neutral.strong.resting",
          borderColor: "borders.neutral.strong.resting",
          borderWidth: "sm",
          color: "components.badge.textNeutral",
          shadow: boxShadowStyles("borders.neutral.strong.resting"),
        };
      }
      if (variant === variantOptions.subtle) {
        return {
          bg: "components.badge.bgNeutralSoft",
          color: "components.badge.textNeutralStrong",
        };
      }
      return {
        bg: "surfaces.neutral.strong.resting",
        color: "components.badge.textNeutral",
      };
    case paletteOptions.primary:
      if (variant === variantOptions.outline) {
        return {
          bg: "components.badge.bgPrimarySoft",
          borderColor: "borders.primary.strong.resting",
          borderWidth: "sm",
          color: "components.badge.textPrimary",
          shadow: boxShadowStyles("borders.primary.strong.resting"),
        };
      }
      if (variant === variantOptions.subtle) {
        return {
          bg: "components.badge.bgPrimarySoft",
          color: "components.badge.textPrimary",
        };
      }
      return {
        bg: "components.badge.bgPrimaryStrong",
        color: "components.badge.textWhite",
      };
    case paletteOptions.secondary:
      if (variant === variantOptions.outline) {
        return {
          bg: "components.badge.bgSecondarySoft",
          borderColor: "borders.secondary.strong.resting",
          borderWidth: "sm",
          color: "components.badge.textSecondary",
          shadow: boxShadowStyles("borders.secondary.strong.resting"),
        };
      }
      if (variant === variantOptions.subtle) {
        return {
          bg: "components.badge.bgSecondarySoft",
          color: "components.badge.textSecondary",
        };
      }
      return {
        bg: "components.badge.bgSecondaryStrong",
        color: "components.badge.textWhite",
      };
    case paletteOptions.success:
      if (variant === variantOptions.outline) {
        return {
          bg: "components.badge.bgSuccessSoft",
          borderColor: "components.badge.textSuccess",
          borderWidth: "sm",
          color: "components.badge.textSuccess",
          shadow: boxShadowStyles("components.badge.textSuccess"),
        };
      }
      if (variant === variantOptions.subtle) {
        return {
          bg: "components.badge.bgSuccessSoft",
          color: "components.badge.textSuccess",
        };
      }
      return {
        bg: "components.badge.bgSuccessStrong",
        color: "text.onSuccessDominant",
      };
    case paletteOptions.danger:
      if (variant === variantOptions.outline) {
        return {
          bg: "components.badge.bgDangerSoft",
          borderColor: "borders.danger.strong.resting",
          borderWidth: "sm",
          color: "components.badge.textDanger",
          shadow: boxShadowStyles("borders.danger.strong.resting"),
        };
      }
      if (variant === variantOptions.subtle) {
        return {
          bg: "components.badge.bgDangerSoft",
          color: "components.badge.textDanger",
        };
      }
      return {
        bg: "components.badge.bgDangerStrong",
        color: "text.onDangerDominant",
      };
    case paletteOptions.warning:
      if (variant === variantOptions.outline) {
        return {
          bg: "components.badge.bgWarningSoft",
          borderColor: "borders.warning.strong.resting",
          borderWidth: "sm",
          color: "components.badge.textWarning",
          shadow: boxShadowStyles("borders.warning.strong.resting"),
        };
      }
      if (variant === variantOptions.subtle) {
        return {
          bg: "components.badge.bgWarningSoft",
          color: "components.badge.textWarning",
        };
      }
      return {
        bg: "components.badge.bgWarningStrong",
        color: "text.onWarningStrong",
      };
  }
};
