import React from "react";
import {
  Box,
  Flex,
  Heading,
  ChakraProps,
  ThemingProps,
  useStyleConfig,
} from "@chakra-ui/react";
import { CloseButton } from "../CloseButton";
import { Spinner } from "../Spinner";
import {
  FALCircleCheck,
  FALCircleExclamation,
  FALCircleInfo,
  FALTriangleExclamation,
} from "../../icons";
import { statusOptions } from "./theme/themeOptions";

export interface ToastProps extends ChakraProps, ThemingProps<"Toast"> {
  title: string;
  description?: React.ReactElement | string;
  onClose?: () => void;
  status: keyof typeof statusOptions;
}

export default function Toast(props: ToastProps) {
  const { title, description, status, onClose = () => {}, ...rest } = props;

  const statusWithFallback = status || "info";
  const styles = useStyleConfig("Toast", { status: statusWithFallback });

  let icon = <></>;

  if (status === statusOptions.warning) {
    icon = <FALTriangleExclamation height={4} width={4} />;
  } else if (status === statusOptions.danger) {
    icon = <FALCircleExclamation height={4} width={4} />;
  } else if (status === statusOptions.success) {
    icon = <FALCircleCheck height={4} width={4} />;
  } else if (status === statusOptions.loading) {
    icon = <Spinner palette="primary" size="sm" speed="0.48s" />;
  } else {
    icon = <FALCircleInfo height={4} width={4} />;
  }

  return (
    <Box __css={styles} {...rest}>
      <Flex alignItems="start" flexBasis="100%" gap={4}>
        <Flex flexShrink={0}>{icon}</Flex>
        <Flex flexDirection="column" alignItems="start">
          <Heading as="h3" fontWeight="semibold" size="sm" mb={1}>
            {title}
          </Heading>
          {description && <Box flex="1 1 auto">{description}</Box>}
        </Flex>
        <Flex ml="auto">
          <CloseButton
            variant="chameleon"
            position="relative"
            marginBlockStart={-2}
            marginInlineEnd={-2}
            onClick={() => onClose()}
          />
        </Flex>
      </Flex>
    </Box>
  );
}
