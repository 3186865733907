import React from "react";
import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
  useStyleConfig,
} from "@chakra-ui/react";
import { FALCheck } from "../../icons";
import { sizeOption } from "../../clayTheme/components/dashedButtonStyles";

export interface CompleteButtonProps
  extends Omit<ChakraButtonProps, "children"> {
  isComplete?: boolean;
}

export default function CompleteButton(props: CompleteButtonProps) {
  const { size, isComplete, ...rest } = props;

  const styles = useStyleConfig("CompleteButton", props);

  return (
    <ChakraButton __css={styles} {...rest}>
      <FALCheck iconSize={(size as sizeOption) || sizeOption.md} />
    </ChakraButton>
  );
}
